import {Component, Input} from '@angular/core';
import {ConsumerFinanceApplication} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import { PaymentChartComponent } from '../../chart-component/payment-chart.component';



@Component({
    selector: 'consumer-quote',
    templateUrl: './consumer-quote.component.html',
    styleUrls: ['./consumer-quote.component.scss'],
    standalone: true,
    imports: [PaymentChartComponent]
})
export class ConsumerQuoteComponent {

  @Input({required: true}) application!: ConsumerFinanceApplication;
  @Input({required: true}) amortizationScheduleData!: AmortisationScheduleEntry[];
  @Input({required: true}) amortizationChartData!: AmortizationChartData;
  @Input({required: true}) paymentChartData!: PaymentChartData;
  @Input({required: true}) repaymentEstimationData!: RepaymentEstimationEntry[];
}
