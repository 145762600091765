<div class="asset-credit-component">
  <mat-accordion>
    <mat-expansion-panel [expanded]="isPanelExpanded(0)" (opened)="expandPanel(0)" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          DSCR
        </mat-panel-title>
      </mat-expansion-panel-header>
        <dscr-calculator
        [application]="application"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn">
      </dscr-calculator>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="isPanelExpanded(1)" (opened)="expandPanel(1)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          LVR
        </mat-panel-title>
      </mat-expansion-panel-header>
        <lvr-calculator
        [applicationId]="application.ApplicationId"
        [getLvrCalculatorValueFn]="getLvrCalculatorValueFn"
        [updateLvrCalculatorValueFn]="updateLvrCalculatorValueFn">
      </lvr-calculator>
    </mat-expansion-panel>
 
  </mat-accordion>
</div>
