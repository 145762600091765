import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidatorFn, Validators, FormGroup, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AbstractControlValueAccessor } from '../abstract-control-value-accessor';
import {compareMatch, CurrencyInputValue} from '@portal-workspace/grow-shared-library';
import {
  formControlErrorKeys,
  formControlErrorMessage,
  minMaxExcludingMaxValidator,
  pxToRem
} from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import numeral from 'numeral';
import { maxValidator, minMaxValidator, minValidator } from '@portal-workspace/grow-ui-library';
import {MARK, Mark} from '@portal-workspace/grow-ui-library/mark';
import { NgxCurrencyInputMode, NgxCurrencyDirective } from 'ngx-currency'
import _ from 'lodash';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';
import { DisableControlDirective } from '../../directives/disable-control.directive';


@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    selector: 'currency-input',
    templateUrl: './currency-input.component.html',
    styleUrls: ['./currency-selection.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencyInputComponent), multi: true },
        { provide: MARK, useExisting: forwardRef(() => CurrencyInputComponent) },
    ],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgxCurrencyDirective, DisableControlDirective]
})
export class CurrencyInputComponent extends AbstractControlValueAccessor<CurrencyInputValue> implements OnInit, OnChanges, AfterContentChecked, Mark {

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  options = { prefix: '', thousands: ',', decimal: '.', inputMode: NgxCurrencyInputMode.Natural, align: 'left' }


  @Input({required: false}) title = 'Currency';
  @Input({required: false}) hint: string = '';
  @Input({required: false}) readonly = false;
  @Input({required: false}) required = true;
  @Input({required: false}) disabled = false;
  @Input({required: false}) defaultValue: number | null = null;
  @Input({required: false}) prefix:boolean = true;
  @Input({required: false}) min: number | null = 0;
  @Input({required: false}) max: number | null = null;
  @Input({required: false}) maxExcluding: number | null = null;
  @Input({required: false}) placeholder?: string ;

  subscriptions: Subscription[] = [];

  pxToRem = pxToRem;

  formGroup!: FormGroup<{
    value: FormControl<CurrencyInputValue>
  }>;
  formControlValue!: FormControl<CurrencyInputValue>;

  constructor(private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    const validators = this.createValidators();
    this.formControlValue = this.formBuilder.control(this.defaultValue, validators);
    this.formGroup = this.formBuilder.group({
      value: this.formControlValue
    });
    if (this.disabled) {
      this.formControlValue.disable();
    }
    const sub = this.formControlValue.valueChanges.pipe(
      delay(0),
      // distinctUntilChanged(compareMatch),
      tap(r => {
        // if (this.disabled) {
        //   this.formControlValue.disable();
        // } else {
        //   this.formControlValue.enable();
        // }
        if (this.formControlValue.valid||this.formControlValue.disabled) {
          const v = ((r !== null && r !== undefined) ? _.round(numeral(r).value()!, 2) : r);
          this.propagateChange(v);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }

  createValidators(): ValidatorFn[] {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.min != null && this.maxExcluding) {
      validators.push(minMaxExcludingMaxValidator(this.min, this.maxExcluding))
    }
    else if (this.min != null && this.max != null) {
      validators.push(minMaxValidator(this.min, this.max));
    } else if (this.min != null) {
      validators.push(minValidator(this.min));
    } else if (this.max != null) {
      validators.push(maxValidator(this.max));
    }
    return validators;
  }

  doWriteValue(v: CurrencyInputValue | undefined): void | CurrencyInputValue {
    // if (!this.disabled) {
      this.formControlValue.setValue(v ?? null);
      // return undefined;
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if ((changes as any).min || (changes as any).max || (changes as any).required || (changes as any).maxExcluding) {
        if (this.formControlValue) {
          const validators = this.createValidators();
          this.formControlValue.setValidators(validators);
          this.formControlValue.updateValueAndValidity();
          if (this.formControlValue.invalid) {
            this.formControlValue.setValue(null);
            // this.propagateChange(null);
          }
        }
      } else if ((changes as any).disabled) {
        if (this.formControlValue) {
          const isDisabled = (changes as any).disabled.currentValue;
          if (isDisabled) {
            this.formControlValue.disable();
          } else {
            this.formControlValue.enable();
          }
        }
      }
    }
  }
  mark() {
    this.formGroup.markAllAsTouched();
  }
}
