<div class="overdraft-account-statements-component">
  @if (loader.tableLoading.inProgress$ | async) {
    <div class="p-2">
      <list-content-loader></list-content-loader>
    </div>
  }
  <div [ngClass]="{hidden: (loader.tableLoading.inProgress$ | async), 'table-section': true, 'pb-3': true}">

    <div class="refresh row mt-2 mr-2">
      <div class="col-12 header" fxLayout="row" fxLayoutAlign="end start" fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start">
        <button mat-flat-button class="btn btn-lg" (click)="reload()">
          <span class="mdi mdi-refresh custom-icon-size" matPrefix></span>
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="width-100 mb-2">
      @for (column of displayedColumns; track column) {
        <ng-container matColumnDef="{{column}}">
          <th mat-header-cell *matHeaderCellDef>{{getColumnTitles(column)}}</th>
          <td mat-cell *matCellDef="let element">
            @if (column === 'statements') {
              Statement for {{' '}}
              {{moment(element.calendar.cycle_closing_date).format('MMMM')}}{{' '}}
              {{moment(element.calendar.cycle_closing_date).format('YYYY')}}
              <br><span>
              {{moment(element.calendar.cycle_closing_date).format(STANDARD_MOMENT_DATE_FORMAT)}}
            </span>
          }
          @if (column === 'download') {
            <div class="download">
              <div class="icon-container">
                <span onclick=event.stopPropagation() (click)="downloadInPDF(element)" >
                  <span class="mdi mdi-file-pdf-box custom-icon-size"></span>
                  <div>PDF</div>
                </span>
              </div>
              <div class="icon-container">
                <span onclick=event.stopPropagation() (click)="downloadInCSV(element)" >
                  <span class="mdi mdi-file-document-outline custom-icon-size"></span>
                  <div>CSV</div>
                </span>
              </div>
              <div class="icon-container">
                <span onclick=event.stopPropagation() (click)="downloadInOFX(element)" >
                  <span class="mdi mdi-file-document-outline custom-icon-size"></span>
                  <div>OFX</div>
                </span>
              </div>
            </div>
          }
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row cursor-pointer"
      (click)="onRowClicked(element)">
    </tr>
  </table>
</div>
</div>