import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ApplicationProgressInfo } from '@portal-workspace/grow-shared-library';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, DecimalPipe } from '@angular/common';


@Component({
    selector: 'app-dashboard-progress-track',
    templateUrl: './dashboard-progress-track.component.html',
    styleUrls: ['./dashboard-progress-track.component.scss'],
    standalone: true,
    imports: [NgClass, ExtendedModule, DecimalPipe]
})
export class DashboardProgressTrackComponent implements OnInit {
  @Input({required: true}) application!: ApplicationProgressInfo;
  @Output() clickApplication = new EventEmitter();

  applicationStep: number = 0;
  constructor() { }

  ngOnInit(): void {
    this.applicationStep = this.getApplicationStep();
  }

  getApplicationStep() {
    if (this.application && this.application?.StageName) {
      const stageName = this.application?.StageName.toUpperCase();
      switch (stageName) {
        case 'DRAFT':
        case 'LEAD':
          return 0;
        case 'SUBMITTED TO CREDIT':
          return 1;
        case 'WAITING FOR BANK STATEMENTS':
          return 2;
        case 'MORE INFORMATION':
          return 3;
        case 'CREDIT APPROVED':
          return 4;
        case 'COMPLIANCE REVIEW':
          return 5;
        case 'DOCUMENTATION':
        case 'DOCS OUT':
        case 'SETTLEMENT PENDING':
          return 6;
        case 'QA':
        case 'SETTLEMENT':
          return 7;
        case 'CLOSED WON':
          return 8;
        case 'CLOSED LOST':
          return 9;
        default:
          return 0;
      }
    }
    return 0;
  }

  onClick() {
    this.clickApplication.emit(this.application?.ApplicationID);
  }

}
