
<div class="selectable-tags-dialog">
    <mat-dialog-content class= 'max-height-100-vh'>
      <div class="row separator-top">
        <div class="col selected-chips">
          <div class="title">Select those document types your file contains</div>
          <mat-card appearance="outlined" class="card separator-top">
            <mat-card-content>
              <mat-chip-listbox #chipList aria-label="Tag selection">
                @for (tag of tags; track tag) {
                  <mat-chip-option
                  (removed)="remove(tag)">
                  {{tag.type}}
                  <button matChipRemove>
                    <span class="mdi mdi-close-circle-outline"></span>
                  </button>
                </mat-chip-option>
              }
              </mat-chip-listbox>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      @if (showMaxTagErrorMessage) {
        <div class="row separator-top">
          <div class="col error-message">
            Maximum 10 tags are allowed for each document.
          </div>
        </div>
      }
        <div class="row separator-top">
            <div class="col all-chips">
              <mat-chip-listbox aria-label="Tag selection">
                @for (tag of selectableTags; track tag) {
                  <mat-chip-option
                    (click)="addTag(tag)">
                    {{tag.type}}
                  </mat-chip-option>
                }
                @if (enableCustomTag) {
                  <mat-chip-option (click)="addCustomTag()">
                    + Custom Tag
                  </mat-chip-option>
                }
              </mat-chip-listbox>
            </div>
        </div>

        <div class="row separator-top">
          <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
            @if (tags) {
              <button mat-flat-button color="primary" (click)="onSubmit($event)">Save Documents</button>
            }
          </div>
        </div>
    </mat-dialog-content>
</div>  
  