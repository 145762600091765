import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './page/login-page/login.page';
import { TwoFAPage} from './page/2fa-page/2fa.page';
import { ResetTwoFAPage } from './page/reset-2fa-page/reset-2fa.page';
import { SidemenuLayout } from './layout/sidemenu-layout/sidemenu.layout';
import { AssetFinancePage } from './page/asset-finance/asset-finance.page';
import { AccreditationPage } from './page/accreditation-page/accreditation.page';
import { RegistrationPage } from './page/registration-page/registration.page';
import { LgRebateCalculatorPage } from './page/lg-rebate-calculator-page/lg-rebate-calculator.page';
import { AssetFinanceCalculatorPage } from './page/asset-finance-calculator-page/asset-finance-calculator.page';
import { BusinessLoansCalculatorPage } from './page/business-loans-calculator-page/business-loans-calculator.page';
import { BusinessOverdraftCalculatorPage } from './page/business-overdraft-calculator-page/business-overdraft-calculator.page';
import { AssetProductSelectorPage} from './page/asset-finance/asset-Product-selector.page';
import { BusinessFinancePage } from './page/business-finance-page/business-finance.page';
import { BusinessOverdraftPage } from './page/business-overdraft-page/business-overdraft.page';
import { InsurancePremiumPage } from './page/insurance-premium-page/insurance-premium.page';
import { ApplicationsPage } from './page/applications-page/applications.page';
import { ManualPaymentPage } from './page/manual-payment-page/manual-payment.page';
import { UsersPage } from './page/users-page/users.page';
import { CompaniesPage } from './page/companies-page/companies.page';
import { NotificationsPage } from './page/notifications-page/notifications.page';
import { RateCardsPage } from './page/rate-cards-page/rate-cards.page';
import { SetMaintenancePage } from './page/set-maintenance-page/set-maintenance.page';
import { OauthPage } from './page/oauth-page/oauth.page';
import { UnverifiedUsersPage } from './page/unverified-users-page/unverified-users.page';
import { CustomersPage } from './page/customers-page/customers.page';
import { ConsumerAssetFinancePage } from './page/consumer-asset-finance-page/consumer-asset-finance.page';
import {validJwtGuard} from './guard/valid-jwt.guard';
import { ForgotPasswordPage } from './page/forgot-password-page/forgot-password.page';
import { ResetPasswordPage } from './page/reset-password-page/reset-password.page';
import { NewApplicationPage } from './page/new-application-page/new-application.page';
import { CommercialFinancePage } from './page/commercial-finance-page/commercial-finance.page';
import { DirectApplicationLayout } from './layout/direct-application-layout/direct-application.layout'
import { SolarPage } from './page/solar-page/solar.page'
import { ApplicationPage } from './page/application-page/application.page';
import { ProfilePage } from './page/profile-page/profile.page';
import { CustomerDetailsPage } from './page/customer-details-page/customer-details.page';
import { ConsumerFinancePage } from './page/consumer-finance-page/consumer-finance.page';
import { ApplicationErrorPage } from './page/error-page/application-error.page';
import { RateCardDetailPage } from './page/rate-card-detail-page/rate-card-detail.page';
import { UserPage } from './page/user-page/user.page';
import { NotificationsManagementPage } from './page/notifications-management-page/notifications-management.page';
import { CompanyPage } from './page/company-page/company.page';
import { ForceResetPasswordPage } from './page/force-reset-password-page/force-reset-password.page';
import { DigitalIdIndividualVerificationPage } from './page/digital-id-individual-verification-page/digital-id-individual-verification.page';
import { EmailVerificationPage } from './page/email-verification-page/email-verification.page';
import { ResetTwoFAQrCodePage } from './page/reset-2fa-qr-code-page/reset-2fa-qr-code.page';
import { MinimalLayout } from './layout/minimal-layout/minimal.layout';
import { MaintenancePage } from './page/maintenance-page/maintenance.page';
import { FaqPage } from './page/faq-page/faq.page';
import { UnactivatedPage } from './page/unactivated-page/unactivated.page';
import { LockedUserPage } from './page/locked-user-page/locked-user.page';
import { AuditLogsPage } from './page/audit-logs-page/audit-logs.page';
import { PortalDashboardPage } from './page/portal-dashboard-page/portal-dashboard.page';
import { OverdraftProductPage } from './page/overdraft-product-page/overdraft-product.page';
import { PendingApprovalsPage } from './page/pending-approvals-page/pending-approvals.page';
import { PendingApprovalUserPage } from './page/pending-approval-user-page/pending-approval-user.page';
import { DefaultErrorPage } from './page/default-error-page/default-error.page';
import { DocusignOauthCallbackPage } from './page/docusign-oauth-callback-page/docusign-oauth-callback.page';
import { DocumentsPage } from './page/documents-page/documents.page';
import { DocumentWorklistPage } from './page/document-worklist-page/document-worklist.page';
import { UnverifiedPage } from './page/unverified-page/unverified.page';
import { ResetTwoFASmsVerificationPage } from './page/reset-2fa-sms-verification-page/reset-2fa-sms-verification.page';
import { BankStatementsPage } from './page/bank-statements-page/bank-statements.page';
import { ReportingPage} from './page/reporting-page/reporting.page';
import {AccreditationDetailsPage} from './page/accreditation-details-page/accreditation-details.page';
import {AccreditationListingPage} from './page/accreditation-listing-page/accreditation-listing.page';
import { PromotionPage } from './page/promotion-page/promotion.page';
import {RedbookPage} from './page/redbook-page/redbook.page';
import { TransactionsPage } from './page/transactions-page/transactions.page';
import { AcceptPrivacyConsentPage } from './page/accept-privacy-consent-page/accept-privacy-consent.page';
import {
  allowAssetFinanceApplicationSubmissionGuard,
  allowBusinessLoanApplicationSubmissionGuard,
  allowBusinessOverdraftApplicationSubmissionGuard,
  allowCommercialFinanceApplicationSubmissionGuard,
  allowConsumerFinanceApplicationSubmissionGuard,
  allowCorporateLoansApplicationSubmissionGuard,
  allowInsurancePremiumApplicationSubmissionGuard
} from './guard/abstract-allow-application-submission.guard';
import {DigitalIdUserVerificationPage} from './page/digital-id-user-verification-page/digital-id-user-verification.page';
import { validCorporateLoansGuard } from './guard/valid-corporate-loans.guard';
import { validBusinessFinanceGuard } from './guard/valid-business-finance.guard';
import { validBusinessOverdraftGuard } from './guard/valid-business-overdraft.guard';
import { validInsurancePremiumGuard } from './guard/valid-insurance-premium.guard';
import { validConsumerFinanceGuard } from './guard/valid-consumer-finance.guard';
import {validCommercialFinanceGuard} from './guard/valid-commercial-finance.guard';
import {validAssetFinanceGuard} from './guard/valid-asset-finance.guard';
import {maintenanceModeGuard} from './guard/maintenance-mode.guard';
import { alreadyLoginGuard } from './guard/already-login.guard';
import { activatedAccountGuard } from './guard/activated-account.guard';
import { activatedUserLockedGuard } from './guard/user-locked-access.guard';
import { applicationAccessGuard } from './guard/application-access.guard';
import {adminAndAnalystAccessGuard} from './guard/admin-and-analyst-access.guard';
import {adminCompanyAdminAndAnalystAccessGuard} from './guard/admin-companyadmin-and-analyst-access.guard';
import {accreditationAccessGuard, accreditationListingAccessGuard, allApplicationsAccessGuard, applicationsAccessGuard, assetFinanceAccessGuard, assetFinanceCalculatorAccessGuard, auditLogsAccessGuard, bankStatementsAnalysisAccessGuard, bankStatementsStatusAccessGuard, bsaSettingsAccessGuard, businessAndOverdraftBorrowingEstimatorAccessGuard, businessOverdraftAccessGuard, businessOverdraftCalculatorAccessGuard, businessTermLoanAccessGuard, businessTermLoanCalculatorAccessGuard, clientServicesWorklistAccessGuard, commercialFinanceAccessGuard, companiesAccessGuard, companyAccessGuard, consumerAssetFinanceAccessGuard, consumerFinanceAccessGuard, corporateLoanAccessGuard, creditWorklistAccessGuard, customerAccessGuard, customersAccessGuard, dashboardAccessGuard, overdraftProductAccessGuard, directSaleBusinessOverdraftAccessGuard, directSalebusinessTermLoanAccessGuard, documentsAccessGuard, documentWorklistAccessGuard, faqAccessGuard, insurancePremiumAccessGuard, manualPaymentAccessGuard, newApplicationAccessGuard, notificationsAccessGuard, notificationsManagementAccessGuard, oauthAccessGuard, pendingApprovalsAccessGuard, pendingApprovalTransactionsAccessGuard, pendingApprovalUserAccessGuard, pismoAccountAccessGuard, pismoAccountsAccessGuard, pismoStatementDetailsAccessGuard, promotionAccessGuard, rateCardAccessGuard, rateCardsAccessGuard, redBookAccessGuard, reportinAccessgGuard, sendPrivacyConsentAccessGuard, setMaintenanceAccessGuard, settlementsWorklistAccessGuard, transactionsAccessGuard, unverifiedUsersAccessGuard, userAccessGuard, usersAccessGuard, whitelabelSettingsAccessGuard, whitelabelSettingsDetailsAccessGuard} from './guard';
import { adminAccessGuard } from './guard/admin-access.guard';
import { adminAndCreditAccessGuard } from './guard/admin-and-credit-access.guard';
import { validEmailAndMobileVerificationGuard } from './guard/valid-email-and-mobile-verification-guard.service';
import { validBrokerGuard } from './guard/valid-broker.guard';
import {valid2faAccessGuard} from './guard/valid-2fa-access.guard';
import { PismoDisplayTransactionsPage } from './page/pismo-display-transactions-page/pismo-display-transactions.page'
import {CardOperatorLayout} from "./layout/card-operator-layout/card-operator.layout";
import {CardOperatorDashboardPage} from "./page/card-operator-dashboard-page/card-operator-dashboard.page";
import {AllApplicationSearchPage} from "./page/all-application-search-page/all-application-search.page";
import {validCardOperatorGuard} from "./guard/valid-card-operator.guard";
import {validTransactionsGuard} from "./guard/valid-transaction.guard";
import {ErrorPage} from "./page/error-page/error.page";
import {PismoAccountsPage} from "./page/pismo-accounts-page/pismo-accounts.page";
import {PismoAccountPage} from "./page/pismo-account-page/pismo-account.page";
import {BankfeedsInstitutionsPage} from "./page/bankfeeds-institutions-page/bankfeeds-institutions.page";
import { DirectSalePage } from "./page/direct-sale-page/direct-sale.page";
import { DirectSaleBusinessOverdraftPage } from "./page/direct-sale-business-overdraft-page/direct-sale-business-overdraft.page";
import { DirectSaleBusinessLoansPage } from './page/direct-sale-business-loans-page/direct-sale-business-loans.page';
import { PismoStatementDetailsPage } from './page/pismo-statement-details-page/pismo-statement-details.page';
import {CorporateLoan2Page} from "./page/corporate-loan-page/corporate-loan2.page";
import { PrivacyConsentPage } from './page/privacy-consent-page/privacy-consent.page';
import {
  BusinessAndOverdraftBorrowingEstimatorPage
} from "./page/business-and-overdraft-borrowing-estimator-page/business-and-overdraft-borrowing-estimator.page";
import {
  BankStatementAnalysisSettingsPage
} from "./page/bank-statement-analysis-settings-page/bank-statement-analysis-settings.page";
import { SendPrivacyConsentPage } from "./page/send-privacy-consent-page/send-privacy-consent.page";
import { BankStatementsUploadPage } from "./page/bank-statements-upload-page/bank-statements-upload.page";
import { BasiqUserAuthPage } from './page/basiq-user-auth-page/basiq-user-auth.page';
import { PendingApprovalTransactionsPage } from "./page/pending-approval-transactions-page/pending-approval-transactions.page";
import {WhitelabelSettingsPage} from "./page/whitelable-settings-page/whitelabel-settings.page";
import {AddWhitelabelSettingsDetailsPage} from "./page/whitelabel-settings-details-page/add-whitelabel-settings-details.page";
import {whiteLabelResolveFn} from "./service/whitelabel.resolver";
import {
  EditWhitelabelSettingsDetailsPage
} from "./page/whitelabel-settings-details-page/edit-whitelabel-settings-details.page";
import {assetFinanceProductTypeGuard} from "./guard/asset-finance-product-type.guard";

const defaultRoute: Route = { path: '', pathMatch: 'full', redirectTo: '/s/new-application' };
const defaultMinimalRoute: Route = { path: '', pathMatch: 'full', redirectTo: '/m/login' };
const defaultCardOperatorRoute: Route = { path: '', pathMatch: 'full', redirectTo: '/op/dashboard'};

export const routes: Routes = [
  defaultRoute,
  { path: 'demo-components', loadChildren: () => import('./app-demo-routes').then(m => m.demoRoutes) },
  { path: 'reset-password/:code', pathMatch: 'full', redirectTo: '/m/reset-password/:code?source=:source' },
  { path: 'individual-verification/:verificationCode', pathMatch: 'full', redirectTo: '/m/individual-verification/:verificationCode' },
  { path: 'email-verification/:code', pathMatch: 'full', redirectTo: '/m/email-verification/:code' },
  { path: 'reset-2fa-qr-code',pathMatch: 'full',  redirectTo: '/m/reset-2fa-qr-code'},
  {
    path: 'admin', children: [
      {
        path: 'docusigncallback', component: DocusignOauthCallbackPage, canActivate: [validJwtGuard()],
      }
    ]
  },
  {
    path: 'op',
    component: CardOperatorLayout,
    canActivate: [maintenanceModeGuard()],
    children: [
      defaultCardOperatorRoute,
      { path: 'dashboard', component: CardOperatorDashboardPage, canActivate: [validJwtGuard(), activatedAccountGuard(), validCardOperatorGuard()] },
      { path: 'error/:errorCode', data: {}, component: ErrorPage },
    ],
  },
  {
    path: 'm',
    component: MinimalLayout,
    children: [
      defaultMinimalRoute,
      { path: 'forgot-password', component: ForgotPasswordPage, canActivate: [maintenanceModeGuard()] },
      { path: 'reset-password/:code', component: ResetPasswordPage, canActivate: [maintenanceModeGuard()] },
      { path: 'force-reset-password', component: ForceResetPasswordPage, canActivate: [maintenanceModeGuard()] },
      { path: 'individual-verification/:verificationCode', component: DigitalIdIndividualVerificationPage, canActivate: [maintenanceModeGuard()] },
      { path: 'basiq-user-auth/:basiqId', component: BasiqUserAuthPage, canActivate: [maintenanceModeGuard()] },
      { path: 'user-verification/:verificationCode/:token', component: DigitalIdUserVerificationPage, canActivate: [maintenanceModeGuard()] },
      { path: 'email-verification/:code', component: EmailVerificationPage, canActivate: [maintenanceModeGuard()] },
      { path: 'accreditation', component: AccreditationPage, canActivate: [maintenanceModeGuard()] },
      { path: 'signup', component: RegistrationPage, canActivate: [maintenanceModeGuard()] },
      { path: 'login', component: LoginPage, canActivate: [maintenanceModeGuard(), alreadyLoginGuard()] },
      { path: '2fa', component: TwoFAPage, canActivate: [maintenanceModeGuard(),validJwtGuard(),activatedAccountGuard()]},
      { path: 'reset-2fa', component:ResetTwoFAPage, canActivate: [maintenanceModeGuard(),validJwtGuard(),activatedAccountGuard()]},
      { path: 'reset-2fa-qr-code', component: ResetTwoFAQrCodePage, canActivate:[maintenanceModeGuard(), activatedAccountGuard()]},
      { path: 'reset-2fa-sms-verification', component: ResetTwoFASmsVerificationPage, canActivate:[maintenanceModeGuard(), activatedAccountGuard()]},
      { path: 'maintenance', component: MaintenancePage },
      { path: 'unactivated', component: UnactivatedPage, canActivate: [validJwtGuard()] },
      { path: 'lockeduser', component: LockedUserPage, canActivate: [validJwtGuard()] },
      { path: 'unverified', component: UnverifiedPage, canActivate: [validJwtGuard()] },
      { path: 'bankfeeds/institutions', component: BankfeedsInstitutionsPage, canActivate: [maintenanceModeGuard()] },
      { path: '**', redirectTo: '/m/login' }
    ]
  },
  {
    path: 's',
    component: SidemenuLayout,
    canActivate: [maintenanceModeGuard(), validJwtGuard(), activatedAccountGuard(), validEmailAndMobileVerificationGuard(), validBrokerGuard(), valid2faAccessGuard()],
    runGuardsAndResolvers: 'always',
    children: [
      defaultRoute,
      { path: 'profile', data: { menuItemId: 'profile' }, component: ProfilePage, canActivate: [validJwtGuard(), activatedAccountGuard() , activatedUserLockedGuard()] },
      { path: 'dashboard', data: { menuItemId: 'dashboard' }, component: PortalDashboardPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(), dashboardAccessGuard()] },
      { path: 'overdraft-product', data: { menuItemId: 'overdraft-product' }, component: OverdraftProductPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(), overdraftProductAccessGuard()] },
      { path: 'lg-rebate-calculator', data: { menuItemId: 'lg-rebate-calculator' }, component: LgRebateCalculatorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard()] },
      { path: 'new-application', data: { menuItemId: 'new-application' }, component: NewApplicationPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),newApplicationAccessGuard()] },
      { path: 'asset-finance-calculator', data: { menuItemId: 'asset-finance-calculator' }, resolve: { ratecard: 'AssetFinanceRatecardResolver' }, component: AssetFinanceCalculatorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),assetFinanceCalculatorAccessGuard()] },
      { path: 'business-term-loan-calculator', data: { menuItemId: 'business-loans-calculator' }, resolve: { ratecard: 'BusinessLoansRatecardResolver' }, component: BusinessLoansCalculatorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),businessTermLoanCalculatorAccessGuard()] },
      { path: 'business-overdraft-calculator', data: { menuItemId: 'business-overdraft-calculator' }, resolve: { ratecard: 'BusinessOverdraftRatecardResolver' }, component: BusinessOverdraftCalculatorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),businessOverdraftCalculatorAccessGuard()] },
      { path: 'business-and-overdraft-borrowing-estimator', data: { menuItemId: 'business-and-overdraft-borrowing-estimator'}, component: BusinessAndOverdraftBorrowingEstimatorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),businessAndOverdraftBorrowingEstimatorAccessGuard()]},
      { path: 'asset-finance-product-selector', data: { menuItemId: 'asset-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver' }, component: AssetProductSelectorPage, canActivate: [validJwtGuard(), allowAssetFinanceApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),assetFinanceAccessGuard()] },
      { path: 'asset-finance-product-selector/:applicationId', data: { menuItemId: 'asset-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver', application: 'ApplicationResolver' }, component: AssetProductSelectorPage, canActivate: [validJwtGuard(), activatedAccountGuard(), validAssetFinanceGuard(), applicationAccessGuard(), activatedUserLockedGuard(),assetFinanceAccessGuard()] },
      { path: 'asset-finance', data: { menuItemId: 'asset-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver' }, component: AssetFinancePage, canActivate: [validJwtGuard(), allowAssetFinanceApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),assetFinanceAccessGuard(), assetFinanceProductTypeGuard()] },
      { path: 'asset-finance/:applicationId', data: { menuItemId: 'asset-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver', application: 'ApplicationResolver' }, component: AssetFinancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), validAssetFinanceGuard(), applicationAccessGuard(), activatedUserLockedGuard(),assetFinanceAccessGuard(), assetFinanceProductTypeGuard()] },
      { path: 'corporate-loan', data: { menuItemId: 'corporate-loans' }, resolve: { ratecard: 'CorporateLoansRatecardResolver' }, component: CorporateLoan2Page, canActivate: [validJwtGuard(), allowCorporateLoansApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),corporateLoanAccessGuard()] },
      { path: 'corporate-loan/:applicationId', data: { menuItemId: 'corporate-loans' }, resolve: { ratecard: 'CorporateLoansRatecardResolver', application: 'ApplicationResolver' }, component: CorporateLoan2Page, canActivate: [validJwtGuard(), activatedAccountGuard(), validCorporateLoansGuard(), applicationAccessGuard(), activatedUserLockedGuard(),corporateLoanAccessGuard()] },
      { path: 'business-term-loan', data: { menuItemId: 'business-finance' }, resolve: { ratecard: 'BusinessLoansRatecardResolver' }, component: BusinessFinancePage, canActivate: [validJwtGuard(), allowBusinessLoanApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),businessTermLoanAccessGuard()] },
      { path: 'business-term-loan/:applicationId', data: { menuItemId: 'business-finance' }, resolve: { ratecard: 'BusinessLoansRatecardResolver', application: 'ApplicationResolver' }, component: BusinessFinancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), validBusinessFinanceGuard(), applicationAccessGuard(), activatedUserLockedGuard(),businessTermLoanAccessGuard()] },
      { path: 'business-overdraft', data: { menuItemId: 'business-overdraft' }, resolve: { ratecard: 'BusinessOverdraftRatecardResolver' }, component: BusinessOverdraftPage, canActivate: [validJwtGuard(), allowBusinessOverdraftApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),businessOverdraftAccessGuard()] },
      { path: 'business-overdraft/:applicationId', data: { menuItemId: 'business-overdraft' }, resolve: { ratecard: 'BusinessOverdraftRatecardResolver', application: 'ApplicationResolver' }, component: BusinessOverdraftPage, canActivate: [validJwtGuard(), validBusinessOverdraftGuard(), applicationAccessGuard(), activatedUserLockedGuard(),businessOverdraftAccessGuard()] },
      { path: 'insurance-premium', data: { menuItemId: 'insurance-premium' }, resolve: { ratecard: 'InsurancePremiumRatecardResolver' }, component: InsurancePremiumPage, canActivate: [validJwtGuard(), allowInsurancePremiumApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),insurancePremiumAccessGuard()] },
      { path: 'insurance-premium/:applicationId', data: { menuItemId: 'insurance-premium' }, resolve: { ratecard: 'InsurancePremiumRatecardResolver', application: 'ApplicationResolver' }, component: InsurancePremiumPage, canActivate: [validJwtGuard(), activatedAccountGuard(), validInsurancePremiumGuard(), applicationAccessGuard(), activatedUserLockedGuard(),insurancePremiumAccessGuard()] },
      { path: 'consumer-asset-finance', data: { menuItemId: 'consumer-asset-finance' }, resolve: { ratecard: 'ConsumerRatecardResolver' }, component: ConsumerAssetFinancePage, canActivate: [validJwtGuard(), allowConsumerFinanceApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),consumerAssetFinanceAccessGuard()] },
      { path: 'consumer-asset-finance/:applicationId', data: { menuItemId: 'consumer-asset-finance' }, resolve: { ratecard: 'ConsumerRatecardResolver', application: 'ApplicationResolver' }, component: ConsumerAssetFinancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), validConsumerFinanceGuard(), applicationAccessGuard(), activatedUserLockedGuard(),consumerAssetFinanceAccessGuard()] },
      { path: 'consumer-finance', data: { menuItemId: 'consumer-finance' }, resolve: { ratecard: 'ConsumerRatecardResolver', application: 'ApplicationResolver' }, component: ConsumerFinancePage, canActivate: [validJwtGuard(), allowConsumerFinanceApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),consumerFinanceAccessGuard()] },
      { path: 'consumer-finance/:applicationId', data: { menuItemId: 'consumer-finance' }, resolve: { ratecard: 'ConsumerRatecardResolver', application: 'ApplicationResolver' }, component: ConsumerFinancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), validConsumerFinanceGuard(), activatedUserLockedGuard(),consumerFinanceAccessGuard()] },
      { path: 'commercial-finance', data: { menuItemId: 'commercial-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver' }, component: CommercialFinancePage, canActivate: [validJwtGuard(), allowCommercialFinanceApplicationSubmissionGuard(), activatedAccountGuard(), activatedUserLockedGuard(),commercialFinanceAccessGuard()] },
      { path: 'commercial-finance/:applicationId', data: { menuItemId: 'commercial-finance' }, resolve: { ratecard: 'AssetFinanceRatecardResolver', application: 'ApplicationResolver' }, component: CommercialFinancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), validCommercialFinanceGuard(), activatedUserLockedGuard(),commercialFinanceAccessGuard()] },
      { path: 'applications', data: { menuItemId: 'applications' }, component: ApplicationsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),applicationsAccessGuard()] },
      { path: 'application/:applicationId/:innerTabs', data: {menuItemId: 'applications'}, resolve: {application: 'ApplicationResolver'}, component: ApplicationPage, canActivate: [validJwtGuard(), activatedAccountGuard(), applicationAccessGuard(), activatedUserLockedGuard()]},
      { path: 'application/:applicationId', data: { menuItemId: 'applications' }, resolve: { application: 'ApplicationResolver' }, component: ApplicationPage, canActivate: [validJwtGuard(), activatedAccountGuard(), applicationAccessGuard(), activatedUserLockedGuard()] },
      { path: 'customers', data: { menuItemId: 'customers' }, component: CustomersPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),customersAccessGuard()] },
      { path: 'customer/:customerId', data: { menuItemId: 'customers' }, resolve: { customerUser: 'CustomerUserResolver' }, component: CustomerDetailsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),customerAccessGuard()] },
      { path: 'manual-payment', data: { menuItemId: 'manual-payment' }, component: ManualPaymentPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),manualPaymentAccessGuard()] },
      { path: 'users', data: { menuItemId: 'users' }, component: UsersPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminCompanyAdminAndAnalystAccessGuard(), activatedUserLockedGuard(),usersAccessGuard()] },
      { path: 'user/:userId', data: { menuItemId: 'users' }, resolve: { user: 'UserResolver' }, component: UserPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminCompanyAdminAndAnalystAccessGuard(), activatedUserLockedGuard(),userAccessGuard()] },
      { path: 'user/:userId/:userType', data: { menuItemId: 'users' }, resolve: { user: 'UserResolver' }, component: UserPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminCompanyAdminAndAnalystAccessGuard, activatedUserLockedGuard(),userAccessGuard()] },
      { path: 'pending-approvals', data: { menuItemId: 'pending-approvals' }, component: PendingApprovalsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),pendingApprovalsAccessGuard()] },
      { path: 'pending-approval-user/:userId', data: { menuItemId: 'pending-approvals' }, resolve: { user: 'UserResolver' }, component: PendingApprovalUserPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),pendingApprovalUserAccessGuard()] },
      { path: 'companies', data: { menuItemId: 'companies' }, component: CompaniesPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),companiesAccessGuard()] },
      { path: 'company/:originatorBusinessId', data: { menuItemId: 'companies' }, resolve: { originatorBusiness: 'OriginatorBusinessResolver' }, component: CompanyPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),companyAccessGuard()] },
      { path: 'notifications', data: { menuItemId: 'notifications' }, component: NotificationsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),notificationsAccessGuard()] },
      { path: 'rate-cards', data: { menuItemId: 'rate-cards' }, component: RateCardsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),rateCardsAccessGuard()] },
      { path: 'rate-card/:rateCardId', data: { menuItemId: 'rate-cards' }, resolve: { ratecards: 'RatecardsResolver' }, component: RateCardDetailPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),rateCardAccessGuard()] },
      { path: 'rate-card', data: { menuItemId: 'rate-cards' }, component: RateCardDetailPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),rateCardAccessGuard()] },
      { path: 'notifications-management', data: { menuItemId: 'notifications-management' }, component: NotificationsManagementPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),notificationsManagementAccessGuard()] },
      { path: 'set-maintenance', data: { menuItemId: 'set-maintenance' }, component: SetMaintenancePage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),setMaintenanceAccessGuard()] },
      { path: 'oauth', data: {menuItemId: 'oauth'}, component: OauthPage , canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),oauthAccessGuard()] },
      { path: 'promotion', data: { menuItemId: 'promotion' }, component: PromotionPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAccessGuard(), activatedUserLockedGuard(),promotionAccessGuard()] },
      { path: 'bsa-settings', data: { menuItemId: 'bsa-settings' }, component: BankStatementAnalysisSettingsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndCreditAccessGuard(), activatedUserLockedGuard(),bsaSettingsAccessGuard()] },
      { path: 'unverified-users', data: { menuItemId: 'unverified-users' }, component: UnverifiedUsersPage, canActivate: [validJwtGuard(), activatedAccountGuard(), adminAndAnalystAccessGuard(), activatedUserLockedGuard(),unverifiedUsersAccessGuard()] },
      { path: 'transactions', data: { menuItemId: 'transactions' }, component: TransactionsPage, canActivate: [validJwtGuard(), activatedAccountGuard(),  activatedUserLockedGuard(),validTransactionsGuard(),transactionsAccessGuard()] },
      { path: 'pending-approval-transactions', data: { menuItemId: 'pending-approval-transactions' }, component: PendingApprovalTransactionsPage, canActivate: [validJwtGuard(), activatedAccountGuard(),  activatedUserLockedGuard(),pendingApprovalTransactionsAccessGuard()] },
      { path: 'faq', component: FaqPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),faqAccessGuard()] },
      { path: 'audit-logs', data: {menuItemId: 'audit-log'}, component: AuditLogsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),auditLogsAccessGuard()] },
      { path: 'application-error/:applicationId', data: {}, component: ApplicationErrorPage },
      { path: 'bank-statements-analysis', data: { menuItemId: 'bank-statements-analysis' }, component: BankStatementsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),bankStatementsAnalysisAccessGuard()] },
      { path: 'bank-statements-status', data: { menuItemId: 'bank-statements-status', fullWidth: true }, component: BankfeedsInstitutionsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),bankStatementsStatusAccessGuard()] },
      { path: 'accreditation', data: { menuItemId: 'accreditation' }, component: AccreditationPage, canActivate: [validJwtGuard(), activatedUserLockedGuard(),accreditationAccessGuard()] },
      { path: 'documents', data: {menuItemId: 'documents'}, component: DocumentsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),documentsAccessGuard()] },
      { path: 'document-worklist', data: {menuItemId: 'document-worklist'}, component: DocumentWorklistPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),documentWorklistAccessGuard()] },
      { path: 'credit-worklist', data: {menuItemId: 'credit-worklist'}, component: DocumentWorklistPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),creditWorklistAccessGuard()] },
      { path: 'client-services-worklist', data: {menuItemId: 'client-services-worklist'}, component: DocumentWorklistPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),clientServicesWorklistAccessGuard()] },
      { path: 'settlements-worklist', data: { menuItemId: 'settlements-worklist' }, component: DocumentWorklistPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),settlementsWorklistAccessGuard()] },
      { path: 'reporting', data: {menuItemId: 'reporting'}, component: ReportingPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),reportinAccessgGuard()]},
      { path: 'accreditation-listing', component: AccreditationListingPage , canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),accreditationListingAccessGuard()] },
      { path: 'accreditation/:accreditationId', data: { menuItemId: 'accreditations' }, resolve: { accreditation: 'AccreditationResolver' }, component: AccreditationDetailsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), applicationAccessGuard(), activatedUserLockedGuard(),accreditationAccessGuard()] },
      { path: 'redbook', data: {menuItemId: 'redbook'}, component: RedbookPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),redBookAccessGuard()] },
      { path: 'send-privacy-consent', data: {menuItemId: 'privacy-consent'}, component: SendPrivacyConsentPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),sendPrivacyConsentAccessGuard()] },
      { path: 'customer/:customerId/:pismoAccountNumber', data: {menuItemId: 'customers' },  resolve: { customerUser: 'CustomerUserResolver' }, component: PismoDisplayTransactionsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),customerAccessGuard()] },
      { path: 'pismo-accounts', data: {menuItemId: 'pismo-accounts'}, component: PismoAccountsPage, canActivate: [validJwtGuard(), activatedAccountGuard(),pismoAccountsAccessGuard()] },
      { path: 'pismo-account/:pismoAccountNumber', data: {menuItemId: 'pismo-account'}, component: PismoAccountPage, canActivate: [validJwtGuard(), activatedAccountGuard(),pismoAccountAccessGuard()] },
      { path: 'pismo-statement-details/:userId/:pismoAccountNumber/:statementId', data: {menuItemId: 'pismo-account'}, component: PismoStatementDetailsPage, canActivate: [validJwtGuard(), activatedAccountGuard(),pismoStatementDetailsAccessGuard()] },
      { path: 'all-applications', data: { menuItemId: 'all-applications' }, component:AllApplicationSearchPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),allApplicationsAccessGuard()]},
      { path: 'whitelabel-settings', data: { menuItemId:'whitelabel-settings'}, component: WhitelabelSettingsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),whitelabelSettingsAccessGuard()]},
      { path: 'whitelabel-settings-details/:originatorBusinessId', data: {menuitemId: 'whitelabel-settings'}, resolve: { whitelabel: whiteLabelResolveFn }, component: EditWhitelabelSettingsDetailsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedUserLockedGuard(),whitelabelSettingsDetailsAccessGuard()]},
      { path: 'whitelabel-settings-details', data: {menuitemId: 'whitelabel-settings'}, component: AddWhitelabelSettingsDetailsPage, canActivate: [validJwtGuard(), activatedAccountGuard(), activatedAccountGuard(), activatedUserLockedGuard(),whitelabelSettingsDetailsAccessGuard()]},
      { path: '**', redirectTo: '/s/applications' },
    ]
  },
  {
    path: 'd',
    component: DirectApplicationLayout,
    children: [
      defaultRoute,
      { path: 'Solar', component: SolarPage },
      { path: 'application-error/:applicationId', data: {}, component: ApplicationErrorPage },
      { path: 'direct-sale', component: DirectSalePage, canActivate: [maintenanceModeGuard()] },
      { path: 'direct-sale/business-overdraft', component: DirectSaleBusinessOverdraftPage, canActivate: [maintenanceModeGuard()] },
      { path: 'direct-sale/business-overdraft/:encodedApplicationId', resolve: { application: 'DirectSaleApplicationResolver' }, component: DirectSaleBusinessOverdraftPage, canActivate: [maintenanceModeGuard(), validBusinessOverdraftGuard(true)] },
      { path: 'direct-sale/business-term-loan', component: DirectSaleBusinessLoansPage, canActivate: [maintenanceModeGuard()] },
      { path: 'direct-sale/business-term-loan/:encodedApplicationId', resolve: { application: 'DirectSaleApplicationResolver' }, component: DirectSaleBusinessLoansPage, canActivate: [maintenanceModeGuard(), validBusinessFinanceGuard(true)] },
      { path: 'privacy-consent/:token', component: PrivacyConsentPage, resolve: { privacyConsent: 'PrivacyConsentResolver' }, canActivate: [maintenanceModeGuard()] },
      { path: 'accept-privacy-consent/:token', component: AcceptPrivacyConsentPage, resolve: { privacyConsent: 'PrivacyConsentResolver' }, canActivate: [maintenanceModeGuard()] },
      { path: 'bank-statements-upload/:token', component: BankStatementsUploadPage, resolve: { bankStatements: 'BankStatementsUploadResolver' }, canActivate: [maintenanceModeGuard()] },
    ]
  },
  {
    path: '**', component: MinimalLayout,
    children: [
      defaultRoute,
      { path: '**', component: DefaultErrorPage }
    ]
  } as Route

];

// WEB-4790
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
