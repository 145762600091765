import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
  AzureStorageDocument,
  Metadata,
  getFileTags,
  DocumentTag,
  AzureFileTagable,
  AzureFileMetadatable, UploadAzureFiles, UploadAzureFileLike
} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'upload-files-with-tag-dialog',
    templateUrl: './upload-files-with-tag-dialog.component.html',
    styleUrls: ['./upload-files-with-tag-dialog.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatFormFieldModule, FlexModule]
})
export class UploadFilesWithTagDialogComponent implements OnInit {
  subscriptions: Subscription[] = [];

  getFileTags = getFileTags;
  @Input({required: false}) docs: UploadAzureFileLike[] = [];
  @Input({required: false}) metadata: Metadata = {};
  @Input({required: false}) defaultTags: DocumentTag[] = [];
  @Input({required: false}) buttonText: string = "Upload Documents";
  @Output() uploadDocs = new EventEmitter<UploadAzureFiles>();
  @Output() deleteDoc = new EventEmitter<number>();

  constructor(private applicationDialogService: ApplicationDialogService) { }

  ngOnInit(): void {
  }

  onUploadDoc() {
    this.subscriptions.push(this.applicationDialogService.openUploadFileWithTagsDialog({
        title: 'Upload document',
        allTags: this.defaultTags ?? [],
        metadata: this.metadata
      }).afterClosed().pipe(
        tap((r) => {
          if (r && r.valid) {
            this.uploadDocs.emit(r.files);
          }
        })
      ).subscribe()
    )
  }

  onDeleteDoc(index: number) {
    this.deleteDoc.emit(index);
  }
}
