<div class="redbook-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Redbook Lookup</div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9 col-md-12">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Make</mat-label>
                <mat-select [formControl]="formControlAssetMake">
                  @for (makeOption of makeSelection; track makeOption) {
                    <mat-option [value]="makeOption">
                      {{makeOption?.description ?? ''}} (Vehicle Types: {{makeOption?.vehicletypes}})
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Family</mat-label>
                <mat-select [formControl]="formControlAssetFamily">
                  @for (familyOption of familySelection; track familyOption) {
                    <mat-option [value]="familyOption">
                      {{familyOption?.description ?? ''}} (Vehicle Type: {{familyOption?.vehicletype}})
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <mat-form-field>
                <mat-label>Year</mat-label>
                <mat-select [formControl]="formControlAssetYear">
                  @for (yearOption of yearSelection; track yearOption) {
                    <mat-option [value]="yearOption">
                      {{yearOption?.description ?? ''}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <mat-form-field>
                <mat-label>Model</mat-label>
                <mat-select [formControl]="formControlAssetModel">
                  @for (modelOption of modelSelection; track modelOption) {
                    <mat-option [value]="modelOption">
                      {{modelOption?.description ?? ''}}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          @if (formControlAssetVehicle && formControlAssetVehicle.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <redbook-details [vehicle]="formControlAssetVehicle.value" [expand]="true"></redbook-details>
              </div>
            </div>
          }
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
