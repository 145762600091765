import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  Application,
  AssetConditionType,
  AssetSelectionComponentValue,
  BalloonPaymentType,
  BrokerageSelectionType,
  BrokerageSelectionValue,
  BrokerageSelectionValueOptions,
  calculateAssetFinanceEstimation,
  calculateBusinessLoanEstimation,
  calculateBusinessOverdraftEstimation,
  calculateConsumerAssetFinanceEstimation,
  ContractTemplateData,
  DeleteContractFileFn,
  FinanceType,
  GenerateContractDialogData,
  GenerateContractDialogResult,
  GenerateContractFn,
  GenerateContractResponse,
  GetApplicationindividualsPayload,
  IndividualWithResult,
  LoanTermType,
  LoanTermValue,
  parseJSON,
  PaymentFrequencyType,
  PaymentFrequencyValue,
  PaymentFrequencyValueOptions,
  PpsrAsset,
  RatecardDetails,
  RepaymentFrequencyType,
  SendContractEmailsFn,
  SendContractResponse,
  User,
  yesNoToBoolean,
  YesNoValue,
  applicantIndividual,
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationName,
  getAssetAvgRetail,
  getAssetCategory,
  getAssetCategoryObj,
  getAssetCondition,
  getAssetNewPrice,
  getAssetSpec,
  getAssetType,
  getAssetTypeObj,
  getAssetYear,
  getBalloonPaymentPercentage,
  getBrokerage,
  getBrokerName,
  getBrokerOriginationFee,
  getCompanyName,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getExpense,
  getFacilityFee,
  getFinanceType,
  getLoanAmount,
  getBrokerageAmount,
  getPrimaryBusinessAddress,
  getPropertyOwner,
  primaryCommercialEntity,
  getApplicationAssetCategory,
  getAdditionalSecurity,
  Contact,
  AssetSettlementGetApplicationAssetFn,
  DigitalIdGetApplicationIndividualsFn,
  GetRateCardDetailsFn,
  EditAddressDialogResult,
  Address2,
  Address2ComponentValue,
  getPrimaryBusinessAddressObject,
  SyncContractDetailsToSfFn,
  getAppSalesforceId,
  SyncContractDetailsBody,
  ConsumerIndividual, Individual,
  getBalloonPayment, getPaymentType, getContractFileName, getApplicationCustomerName, getAssetGoodRetail,
  getSignersRole,
  getSpecialConditions, ContractEmailBody, removeUnprintableChar, GetApplicationOwnerFn,
  ApplicationOwner,
  TransactionType,
  TransactionValue,
  PrivateSaleType,
  GetAccountDetailsFromSfFn,
  SfAccountDetails,
  UpdateApplicationFn,
  SaveApplicationData,
  CommercialEntity,
  UpdateApplicationData,
  booleanToYesNo,
  ContractPricingDetails,
  isExternalUser,
  getCreditRateAdjustment,
  getRbaRate,
  getDocFee, validateAssetDepositAgainstApplicationDeposit, CalculateBusinessOverdraftEstimationResult,
  CalculateCorporateLoanEstmationResult,
  calculateCorporateLoanEstimation, getLtv, getSecurityType, GetOriginatorBusinessByIdFn, OriginatorBusiness,
  getRepaymentType,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ApplicationDialogService,
  applicationToAdverseOnFile,
  applicationToAsset,
  applicationToBrokerage,
  applicationToBrokerOriginationFee,
  applicationToDepositAmount,
  applicationToDirectorScore,
  applicationToDocFee,
  applicationToEquifaxScoreAboveThreshold,
  applicationToFacilityEstablishmentFee,
  applicationToFacilityEstablishmentFeePercent,
  applicationToInterestRate,
  applicationToInvoiceAmount,
  applicationToLoanAmount,
  applicationToLoanTerms,
  applicationToPaymentFrequency,
  applicationToPrivateSales,
  applicationToPropertyOwner,
  applicationToTransactionValue,
  PortalHotToastService,
  setNextButtonText,
  setPrevNextButtonVisibilityFn,
  setSecondaryButtonFn,
  setSecondaryButtonText,
  setSubmitStepFn,
  setupUntilDestroy,
  validateEmail,
  createAbnInputMask,
  applicationToHybrid,
  applicationToApplicantEmail,
  createAcnInputMask,
  checkContractIndividuals,
  formControlErrorKeys,
  formControlErrorMessage,
  applicationToDocFeeFinanced,
  getUser,
  applicationToMargin,
  setStepper2StepConfig,
  applicationToRateDiscount,
  applicationToMonthlyAccountKeepingFee, applicationToFinanceType
} from '@portal-workspace/grow-ui-library';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MessageBoxComponent} from '../message-box/message-box.component';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {Sort} from '@angular/material/sort';
import {CollectionViewer, DataSource, SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import moment from 'moment';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTableModule } from '@angular/material/table';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexModule } from '@angular/flex-layout/flex';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet, DecimalPipe, NgClass } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { ApplicationStepper2Component } from '../application-stepper-component/application-stepper2.component';
import _ from 'lodash';
import {PercentagePipe} from '../../pipes/percentage.pipe';
import numeral from 'numeral';
export class InternalDataSource extends DataSource<IndividualWithResult> {

  subject = new BehaviorSubject<IndividualWithResult[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<IndividualWithResult[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(users: IndividualWithResult[]) {
    this.subject.next(users);
  }

}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './generate-contract.dialog.html',
    styleUrls: ['./generate-contract.dialog.scss'],
    standalone: true,
  imports: [
    ApplicationStepper2Component,
    MatSelectModule,
    CdkStepperModule,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    InputMaskModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    PdfViewerModule,
    DecimalPipe,
    LooseCurrencyPipe,
    NgClass,
    MatDialogModule,
    MessageBoxComponent,
    PercentagePipe
]
})
export class GenerateContractDialog implements OnInit {
  sendContractEmailsFn!: SendContractEmailsFn;
  getRatecardDetailsFn!: GetRateCardDetailsFn;
  getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  generateContractFn!: GenerateContractFn;
  getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  deleteContractFileFn!: DeleteContractFileFn;
  syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  getApplicationOwnerFn!: GetApplicationOwnerFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  updateApplicationFn!: UpdateApplicationFn;
  getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;

  //displayWithFn: (value:  IndividualWithResult) => string;

  depositValidationMessage: string | null = null;
  rateCard!: RatecardDetails;

  application!: Application;
  subscriptions: Subscription[] = [];
  primaryContact!: Contact | Individual | ConsumerIndividual | null;
  compareWithRepaymentFrequencyFn: (option: PaymentFrequencyValue, selected: PaymentFrequencyValue) => boolean = (option, selected) => {
    return (!!option && !!selected && option.type === selected.type);
  };

  // step 1
  formGroupStep1!: FormGroup; // asset finance & consumer asset finance
  formGroupStep1BusinessLoan!: FormGroup;
  formGroupStep1BusinessOverdraft!: FormGroup;
  formGroupStep1CorporateLoans!: FormGroup;

  formControlStep1TotalInterest!: FormControl<number | null>;
  formControlStep1InvoicePrice!: FormControl<number | null>;
  formControlStep1TotalPayment!: FormControl<number | null>;
  formControlStep1PaymentInAdvanceOrArrears!: FormControl<string | null>;
  formControlStep1DocFee!: FormControl<number | null>;
  formControlStep1InterestRate!: FormControl<number | null>;
  formControlStep1DisplayedMargin!: FormControl<number | null>;
  formControlStep1Deposit!: FormControl<number | null>;
  formControlStep1LoanTerm!: FormControl<LoanTermValue | null>;
  formControlStep1Residual!: FormControl<number | null>;
  formControlStep1RepaymentFrequency!: FormControl<PaymentFrequencyValue | null>;
  formControlStep1BrokerageAmount!: FormControl<number | null>;
  formControlStep1NumberOfInstallment!: FormControl<number | null>;
  formControlStep1BrokerOriginationFee!: FormControl<number | null>;
  formControlStep1PrivateSale!: FormControl<PrivateSaleType | null>;
  formControlStep1TransactionType!: FormControl<TransactionValue | null>;
  formControlStep1Repayment!: FormControl<number | null>;
  formControlStep1AmountFinanced!: FormControl<number | null>;
  formControlStep1PropertyOwner!: FormControl<boolean | null>;
  formControlStep1AdverseOnFile!: FormControl<boolean | null>;
  formControlStep1DirectorScore!: FormControl<boolean | null>;
  formControlStep1EquifaxScoreAboveThreshold!: FormControl<boolean | null>;
  formControlStep1LoanAmount!: FormControl<number | null>;
  formControlStep1FacilityEstablishmentFeePercent!: FormControl<BrokerageSelectionValue | null>;
  formControlStep1FacilityEstablishmentFee!: FormControl<number | null>;
  formControlStep1BrokerageDollar!: FormControl<number | null>;
  formControlStep1LegalName!: FormControl<string | null>;
  formControlStep1ABN!: FormControl<string | null>;
  formControlStep1ACN!: FormControl<string | null>;
  formControlStep1Address!: FormControl<Address2ComponentValue>;
  formControlStep1DisplayedRate!: FormControl<number | null>;
  formControlStep1Email!: FormControl<string | null>;
  formControlStep1DocFeeFinanced!: FormControl<boolean | null>;
  formControlStep1Hybrid!: FormControl<boolean | null>;
  formControlStep1RateDiscount!: FormControl<number | null>;
  formControlStep1Commission!: FormControl<number | null>;
  formControlStep1TotalEarnedInclGst!: FormControl<number | null>;
  editInvoicePrice = false;
  editPaymentInAdvanceOrArrears = false;
  editInterestRate = false;
  editLoanTerm = false;
  editResidual = false;
  editRepaymentFrequency = false;
  editBrokerage = false;
  editBrokerOriginationFee = false;
  editLoanAmount = false;
  editLegalName = false;
  editABN = false;
  editACN = false;
  editEmail = false;
  editDeposit = false;
  editDocFeeFinanced = false;
  editRateDiscount = false;
  invoicePrice!: number | null;
  loanAmount!: number;
  PaymentFrequencyValueOptions = PaymentFrequencyValueOptions;
  BrokerageSelectionValueOptions = BrokerageSelectionValueOptions;
  commissionStep2 = 0; // consumer - amount paid to the broker
  oversCommissionStep2 = 0; // consumer - amount paid to us

  // step 2
  formGroupStep2!: FormGroup;
  fromControlStep2CcEmail!: FormControl<string | null>;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  ccEmailListStep2: string[] = [];
  dataSourceStep2 = new InternalDataSource();
  displayColumnsStep2 = ["fullName", "email", "mobile", "role", "primaryContact"];
  selectionStep2 = new SelectionModel<any>(true, []);
  ccEmailListStep2Error = false;
  allIndividuals: IndividualWithResult[] = [];
  contractName: string = "";
  watermarkedContractName: string = "";
  contractBase64: string = "";
  contractBuffer!: Uint8Array;
  assets: PpsrAsset[] = [];
  foundPrimaryContactIndividualStep2 = false;
  primaryContactCheckbox: boolean[] = [];
  primaryContactStep2!: IndividualWithResult;

  formGroupStep3!: FormGroup;
  apiUrl!: string;

  formGroupStep4!: FormGroup;

  setSecondaryButtonText = setSecondaryButtonText;
  setSecondaryButtonFn = setSecondaryButtonFn;
  setPrevNextButtonVisibilityFn = setPrevNextButtonVisibilityFn;

  createAbnInputMask = createAbnInputMask();
  createAcnInputMask = createAcnInputMask();
  getSignersRole = getSignersRole;
  isExternalUser = isExternalUser;
  loggedInUser: User | null = getUser();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: GenerateContractDialogData,
    private dialogRef: MatDialogRef<GenerateContractDialog, GenerateContractDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
    this.sendContractEmailsFn = data.sendContractEmailsFn;
    this.getRatecardDetailsFn = data.getRatecardDetailsFn;
    this.getApplicationIndividualsFn = data.getApplicationIndividualsFn;
    this.generateContractFn = data.generateContractFn;
    if (data?.getApplicationAssetFn) {
      this.getApplicationAssetFn = data.getApplicationAssetFn;
    }
    this.deleteContractFileFn = data.deleteContractFileFn;
    this.syncContractDetailsToSfFn = data.syncContractDetailsToSfFn;
    this.getApplicationOwnerFn = data.getApplicationOwnerFn;
    this.getAccountDetailsFromSfFn = data.getAccountDetailsFromSfFn;
    this.updateApplicationFn = data.updateApplicationFn;
    this.getOriginatorBusinessByIdFn = data.getOriginatorBusinessByIdFn;
    this.application = data.application;
    this.ccEmailListStep2 = [this.application.AppInfo.UserEmail];
    this.apiUrl = data.apiUrl;
    this.primaryContact = this.application.Individuals.find(ind => ind.Role === 'Applicant') ?? this.application?.Contacts
    console.log('============application', this.application);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.initStep1();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.populate();
  }

  populate() {
    this.formControlStep1TotalInterest.setValue(0);/////////
    this.formControlStep1TotalPayment.setValue(0);/////////
    this.formControlStep1PaymentInAdvanceOrArrears.setValue(getPaymentType(this.application));
    this.formControlStep1Deposit.setValue(applicationToDepositAmount(this.application));
    this.formControlStep1Residual.setValue(0);/////////
    this.formControlStep1PrivateSale.setValue(applicationToPrivateSales(this.application));
    this.formControlStep1TransactionType.setValue(applicationToTransactionValue(this.application));
    this.formControlStep1Repayment.setValue(0);/////////
    this.formControlStep1AmountFinanced.setValue(0);/////////
    this.formControlStep1PropertyOwner.setValue(applicationToPropertyOwner(this.application));
    this.formControlStep1AdverseOnFile.setValue(applicationToAdverseOnFile(this.application));
    this.formControlStep1DirectorScore.setValue(applicationToDirectorScore(this.application));
    this.formControlStep1EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.application));
    this.formControlStep1LoanAmount.setValue(getLoanAmount(this.application));
    this.formControlStep1FacilityEstablishmentFeePercent.setValue(applicationToFacilityEstablishmentFeePercent(this.application));
    this.formControlStep1ABN.setValue(getAbn(this.application));
    this.formControlStep1Address.setValue(getPrimaryBusinessAddressObject(this.application));
    this.formControlStep1ACN.setValue(getAcn(this.application));
    this.formControlStep1DisplayedRate.setValue(0); /////
    this.formControlStep1Commission.setValue(0);
    this.formControlStep1TotalEarnedInclGst.setValue(0);
    this.formControlStep1Email.setValue(this.application.ApplicationType !== 'Consumer' ? this.application.Contacts?.Email! : applicationToApplicantEmail(this.application));
    this.formControlStep1DocFeeFinanced.setValue(applicationToDocFeeFinanced(this.application));
    this.formControlStep1Hybrid.setValue(applicationToHybrid(this.application));
    this.formControlStep1RateDiscount.setValue(applicationToRateDiscount(this.application));

    // // invoice price
    if (this.application.ApplicationType === 'Consumer' || this.application.ApplicationType === 'AssetFinance') {
      this.getApplicationAssets();
    }

    // rate
    this.formControlStep1InterestRate.setValue(Number(applicationToInterestRate(this.application)));

    //margin
    this.formControlStep1DisplayedMargin.setValue(applicationToMargin(this.application));

    // doc fee
    this.formControlStep1DocFee.setValue(Number(applicationToDocFee(this.application)));

    // broker origination fee
    this.formControlStep1BrokerOriginationFee.setValue(applicationToBrokerOriginationFee(this.application));
    this.formControlStep1BrokerOriginationFee.addValidators(Validators.max(applicationToBrokerOriginationFee(this.application) ?? 0));

    // brokerage
    const brokerage = Number(applicationToBrokerage(this.application)?.type ?? 0);
    this.loanAmount = getLoanAmount(this.application);
    const brokerageAmount = getBrokerageAmount(this.application) ? getBrokerageAmount(this.application) : Math.round(this.loanAmount * brokerage / 100 * 100) / 100;
    this.formControlStep1BrokerageAmount.setValue(brokerageAmount);
    this.formControlStep1BrokerageDollar.setValue(brokerageAmount);
    // max validation for formControlStep1BrokerageAmount is included in getApplicationAssets() due to invoicePrice dependency
    this.formControlStep1BrokerageDollar.addValidators(Validators.max(brokerageAmount));

    // repayment
    const repaymentFrequency = applicationToPaymentFrequency(this.application);
    const loanTerm = applicationToLoanTerms(this.application);
    this.formControlStep1LoanTerm.setValue(loanTerm);
    this.formControlStep1RepaymentFrequency.setValue(repaymentFrequency);
    this.updateNumberOfInstallment(repaymentFrequency, loanTerm);

    // facility establishment fee
    this.formControlStep1FacilityEstablishmentFee.setValue(applicationToFacilityEstablishmentFee(this.application));

    // add BDM and broker to the cc list
    if (this.application.AppInfoSalesforceID) {
      this.subscriptions.push(
        this.getApplicationOwnerFn(this.application.AppInfoSalesforceID)
        .pipe(this.toastService.spinnerObservable())
        .subscribe(
          (r: ApplicationOwner | null) => {
            if (r && r.Email) {
              this.ccEmailListStep2.push(r.Email);
            }
          }
        )
      )
    }

    // get salesforce account level info
    if (this.application.AppInfo.CustomerId) {
      this.subscriptions.push(
        this.getAccountDetailsFromSfFn(this.application.AppInfo.CustomerId)
        .pipe(this.toastService.spinnerObservable())
        .subscribe((r: SfAccountDetails | null) => {
          if (r) {
            this.formControlStep1LegalName.setValue(r.Name);
          } else {
            this.formControlStep1LegalName.setValue(getCompanyName(this.application));
          }
        })
      )
    } else {
      this.formControlStep1LegalName.setValue(getCompanyName(this.application));
    }

    // get rate discount validation from the rate card
    this.subscriptions.push(
      this.getRatecardDetailsFn(this.application.UserId, this.application.ApplicationType).subscribe(
        (rateCard: RatecardDetails) => {
          this.formControlStep1RateDiscount.clearValidators()
          this.formControlStep1RateDiscount.setValidators([Validators.required, Validators.min(0), Validators.max(rateCard.MaxBrokerRateDiscount)])
          this.formControlStep1RateDiscount.updateValueAndValidity();
        }
      )
    )

  }

  initStep1() {
    this.formControlStep1TotalInterest = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1InvoicePrice = this.formBuilder.control(null, [Validators.required, Validators.min(0)]);
    this.formControlStep1TotalPayment = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1PaymentInAdvanceOrArrears = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1DocFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1InterestRate = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Deposit = this.formBuilder.control(null, [Validators.required, Validators.min(applicationToDepositAmount(this.application) ?? 0)]);
    this.formControlStep1LoanTerm = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Residual = this.formBuilder.control(null, [Validators.required, Validators.min(0)]);
    this.formControlStep1RepaymentFrequency = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1BrokerageAmount = this.formBuilder.control(null, [Validators.required, Validators.min(0)]);
    this.formControlStep1NumberOfInstallment = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1BrokerOriginationFee = this.formBuilder.control(null, [Validators.required, Validators.min(0)]);
    this.formControlStep1PrivateSale = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1TransactionType = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Repayment = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1AmountFinanced = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1PropertyOwner = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1AdverseOnFile = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1DirectorScore = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1EquifaxScoreAboveThreshold = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1FacilityEstablishmentFeePercent = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1FacilityEstablishmentFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1BrokerageDollar = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1DocFeeFinanced = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Hybrid = this.formBuilder.control(false);
    this.formControlStep1RateDiscount = this.formBuilder.control(null, [Validators.required, Validators.min(0)]);
    if (this.application.ApplicationType === 'AssetFinance') {
      this.formControlStep1LegalName = this.formBuilder.control(null, [Validators.required]);
      this.formControlStep1ABN = this.formBuilder.control(null, [Validators.required]);
      this.formControlStep1ACN = this.formBuilder.control(null);
      this.formControlStep1Address = this.formBuilder.control(null, [Validators.required]);
      this.formControlStep1Email = this.formBuilder.control(null, [Validators.required]);
    } else {
      this.formControlStep1LegalName = this.formBuilder.control(null);
      this.formControlStep1ABN = this.formBuilder.control(null);
      this.formControlStep1ACN = this.formBuilder.control(null);
      this.formControlStep1Address = this.formBuilder.control(null);
      this.formControlStep1Email = this.formBuilder.control(null, [Validators.required]);
    }

    if (this.application.ApplicationType === 'BusinessOverdraft' || this.application.ApplicationType=== 'CorporateLoans') {
      this.formControlStep1DisplayedMargin = this.formBuilder.control(null, [Validators.required]);
    } else {
      this.formControlStep1DisplayedMargin = this.formBuilder.control(null);
    }

    this.formControlStep1DisplayedRate = this.formBuilder.control(null);
    this.formControlStep1Commission = this.formBuilder.control(null);
    this.formControlStep1TotalEarnedInclGst = this.formBuilder.control(null);
    this.formGroupStep1 = this.formBuilder.group({
      totalInterest: this.formControlStep1TotalInterest,
      invoicePrice: this.formControlStep1InvoicePrice,
      totalPayment: this.formControlStep1TotalPayment,
      paymentInAdvanceOrArrears: this.formControlStep1PaymentInAdvanceOrArrears,
      docFee: this.formControlStep1DocFee,
      interestRate: this.formControlStep1InterestRate,
      margin: this.formControlStep1DisplayedMargin,
      deposit: this.formControlStep1Deposit,
      loanTerm: this.formControlStep1LoanTerm,
      residual: this.formControlStep1Residual,
      repaymentFrequency: this.formControlStep1RepaymentFrequency,
      brokerage: this.formControlStep1BrokerageAmount,
      numberOfInstallment: this.formControlStep1NumberOfInstallment,
      brokerOriginationFee: this.formControlStep1BrokerOriginationFee,
      privateSale: this.formControlStep1PrivateSale,
      transactionType: this.formControlStep1TransactionType,
      repayment: this.formControlStep1Repayment,
      amountFinanced: this.formControlStep1AmountFinanced,
      legalName: this.formControlStep1LegalName,
      abn: this.formControlStep1ABN,
      acn: this.formControlStep1ACN,
      address: this.formControlStep1Address,
      displayedRate: this.formControlStep1DisplayedRate,
      companyEmail: this.formControlStep1Email,
      docFeeFinanced: this.formControlStep1DocFeeFinanced,
      rateDiscount: this.formControlStep1RateDiscount,
      commission: this.formControlStep1Commission,
      totalEarnedInclGst: this.formControlStep1TotalEarnedInclGst,
    });

    this.formGroupStep1BusinessLoan = this.formBuilder.group({
      loanAmount: this.formControlStep1LoanAmount,
      lowEquifaxScore: this.formControlStep1EquifaxScoreAboveThreshold,
      adverseOnFile: this.formControlStep1AdverseOnFile,
      brokerage: this.formControlStep1BrokerageAmount,
      propertyOwner: this.formControlStep1PropertyOwner,
      loanTerm: this.formControlStep1LoanTerm,
      directorScore: this.formControlStep1DirectorScore,
      docFee: this.formControlStep1DocFee,
      legalName: this.formControlStep1LegalName,
      abn: this.formControlStep1ABN,
      acn: this.formControlStep1ACN,
      address: this.formControlStep1Address,
      companyEmail: this.formControlStep1Email,
    })

    this.formGroupStep1BusinessOverdraft = this.formBuilder.group({
      loanAmount: this.formControlStep1LoanAmount,
      lowEquifaxScore: this.formControlStep1EquifaxScoreAboveThreshold,
      adverseOnFile: this.formControlStep1AdverseOnFile,
      brokerage: this.formControlStep1BrokerageAmount,
      propertyOwner: this.formControlStep1PropertyOwner,
      loanTerm: this.formControlStep1LoanTerm,
      directorScore: this.formControlStep1DirectorScore,
      facilityEstablishmentFeePercent: this.formControlStep1FacilityEstablishmentFeePercent,
      facilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee,
      brokerageDollar: this.formControlStep1BrokerageDollar,
      legalName: this.formControlStep1LegalName,
      abn: this.formControlStep1ABN,
      acn: this.formControlStep1ACN,
      address: this.formControlStep1Address,
      companyEmail: this.formControlStep1Email,
    })

    this.formGroupStep1CorporateLoans = this.formBuilder.group({
      loanAmount: this.formControlStep1LoanAmount,
      lowEquifaxScore: this.formControlStep1EquifaxScoreAboveThreshold,
      adverseOnFile: this.formControlStep1AdverseOnFile,
      brokerage: this.formControlStep1BrokerageAmount,
      propertyOwner: this.formControlStep1PropertyOwner,
      loanTerm: this.formControlStep1LoanTerm,
      directorScore: this.formControlStep1DirectorScore,
      facilityEstablishmentFeePercent: this.formControlStep1FacilityEstablishmentFeePercent,
      facilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee,
      brokerageDollar: this.formControlStep1BrokerageDollar,
      legalName: this.formControlStep1LegalName,
      abn: this.formControlStep1ABN,
      acn: this.formControlStep1ACN,
      address: this.formControlStep1Address,
      companyEmail: this.formControlStep1Email,
    })



    if (this.application.ApplicationType === "BusinessLoans") {
      setStepper2StepConfig(this.formGroupStep1BusinessLoan, {
        nextStepClickedFn: async stepper => {
          stepper.next();
        },
        previousStepClickedFn: async (stepper) => {
          this.onClickClose()
        },
        previousStepButtonText:"Close"
      });
    } else if (this.application.ApplicationType === "BusinessOverdraft" ) {
      setStepper2StepConfig(this.formGroupStep1BusinessOverdraft, {
        previousStepButtonText:"Close",
        nextStepClickedFn: async stepper => {
          stepper.next();
        },
        previousStepClickedFn: async (stepper) => {
          this.onClickClose()
        },

      });

    }
    else if (this.application.ApplicationType === "CorporateLoans") {
      setStepper2StepConfig(this.formGroupStep1CorporateLoans, {
        previousStepButtonText:"Close",
        nextStepClickedFn: async stepper => {
          stepper.next();
        },
        previousStepClickedFn: async (stepper) => {
          this.onClickClose()
        },

      });

    }
    else {
      setStepper2StepConfig(this.formGroupStep1, {
        nextStepClickedFn: async stepper => {
          stepper.next();
        },
        previousStepClickedFn: async (stepper) => {
          this.onClickClose()
        },
        previousStepButtonText:"Close"
      });
    }

    // recalculation
    if (this.application.ApplicationType === "BusinessLoans") {
      this.recalculationForBusinessLoan();
    } else if (this.application.ApplicationType === "BusinessOverdraft" ) {
      this.populateBrokerageForOverdraft()
      this.recalculationForBusinessOverdraft();
    } else if (this.application.ApplicationType === "CorporateLoans") {
      this.populateBrokerageForCorporateLoans()
      this.recalculationForCorporateLoans();
    } else {
      this.recalculationForAssetFinanceAndConsumerAssetFinance();
    }
  }

  initStep2() {
    this.fromControlStep2CcEmail = this.formBuilder.control(null, Validators.email);
    this.formGroupStep2 = this.formBuilder.group({
      email: this.fromControlStep2CcEmail,
    });

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
         // check if has a signer or not
      if (!this.checkSignerExistsInIndividuals()) {
        this.applicationDialogService.openAlertDialog({
          message: `Warning`,
          subMessage: `You need to have a signer for generating the contract.`,
        });
        return;
      }

      // check if has select a primary contact or not
      const { checked, primaryContactIndex } = this.getCheckboxResult();
      if (!this.foundPrimaryContactIndividualStep2 && !checked) {
        this.applicationDialogService.openAlertDialog({
          message: `Warning`,
          subMessage: `Please select a primary contact before proceeding to the next step.`,
        });
        return;
      }
      if (primaryContactIndex >= 0) {
        this.primaryContactStep2 = this.allIndividuals[primaryContactIndex];
      }

      // check if all individuals' mobile number are valid
      if (!this.validateIndividualMobileNumbers()) {
        this.applicationDialogService.openAlertDialog({
          message: `Warning`,
          subMessage: `Please make sure the mobile numbers of all signers are valid. You cannot generate a contract using landline numbers.`
        })
        return;
      }

      // if already have a contract, delete it
      this.deleteExistingContract();

      this.subscriptions.push(
        this.generateContractFn({
          signers: this.allIndividuals.map((i: IndividualWithResult, index: number) => { return {
            email: i.Email,
            name: i.GivenName + " " + i.SurName,
            role: i.SignerRole as string,
            mobile: i.MobileNumber,
            address: i.HomeAddress?.UnformattedAddress ?? "",
            isPrimaryContact: index === primaryContactIndex,
            thirdPartyEntity: i?.thirdPartyEntity ? i.thirdPartyEntity.organisationName + ` (ABN: ${i.thirdPartyEntity.abn})` : "",
            additionalRoles: i?.additionalRoles ?? [],
          }}),
          ccs: this.ccEmailListStep2.map(email => { return {
            email: email
          }}),
          templateData: this.templateData,
          applicationType: this.application.ApplicationType,
          financeType: this.application.AppInfo.FinanceType,
          applicationId: this.application.ApplicationId
        }
        ).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((res: GenerateContractResponse) => {
          this.contractName = res.fileName ?? "";
          this.watermarkedContractName = res.watermarkedFileName ?? "";
          this.contractBase64 = res.base64 ?? "";
          // stepper.next();
          setTimeout(() => {
            this.base64ToArrayBuffer(this.contractBase64);
            stepper.next();
          });
        })
      )
      },
    });



    this.subscriptions.push(
      this.getApplicationIndividualsFn(this.application.ApplicationId).subscribe((result: GetApplicationindividualsPayload) => {
        // including logics for corporate guarantor here
        // If an individual’s role is Others and does not have additional roles, we do not show him/her in the signers’ table.
        // If an individual’s role is Others and does have additional roles, we show him/her in the signers’ table.
        this.allIndividuals = result.individuals
          .filter(i => !i.deleted && !(i.SignerRole === 'Others' && (!i.additionalRoles || !i.additionalRoles?.length)))
          .map(i => {
            return {
              ...i,
              GivenName: removeUnprintableChar(i.GivenName) ?? "",
              SurName: removeUnprintableChar(i.SurName) ?? "",
            }
          });
        console.log(this.allIndividuals);
        this.primaryContactCheckbox = this.allIndividuals.map(() => false);
        this.foundPrimaryContactIndividualStep2 = !!this.allIndividuals.find(i =>
          i.GivenName === this.primaryContact?.GivenName && i.SurName === this.primaryContact?.SurName
        );
        const primaryContactIndividualIndex = this.allIndividuals.findIndex(i =>
          i.GivenName === this.primaryContact?.GivenName && i.SurName === this.primaryContact?.SurName
        );
        if (this.foundPrimaryContactIndividualStep2) {
          this.primaryContactCheckbox[primaryContactIndividualIndex] = true
        }

        this.dataSourceStep2.update(this.allIndividuals);
      })
    )

    this.subscriptions.push(
      this.fromControlStep2CcEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.ccEmailListStep2Error = false;
        }
      })
    )
  }

  initStep3() {
    this.formGroupStep3 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep3, {
      nextStepButtonText:"Send Contract",
      nextStepClickedFn: async stepper => {
         // check if uniqueness of individual email and mobile
      const ok = checkContractIndividuals(this.application, this.applicationDialogService);
      if (ok) {
        // check if has select a primary contact or not
        const { checked, primaryContactIndex } = this.getCheckboxResult();

        // contract email body
        const contractEmailBody: ContractEmailBody = {
          signers: this.allIndividuals.map((i: IndividualWithResult, index: number) => { return {
            email: i.Email,
            name: i.GivenName + " " + i.SurName,
            role: i.SignerRole as string,
            mobile: i.MobileNumber,
            address: i.HomeAddress?.UnformattedAddress ?? "",
            isPrimaryContact: index === primaryContactIndex,
            thirdPartyEntity: i?.thirdPartyEntity ? i.thirdPartyEntity.organisationName + ` (ABN: ${i.thirdPartyEntity.abn})` : ""
          }}),
          ccs: this.ccEmailListStep2.map(email => { return {
            email: email
          }}),
          contractName: this.contractName,
          emailSubject: this.emailSubject,
          applicationId: this.application.ApplicationId,
          customerName: getApplicationCustomerName(this.application),
          pricingDetails: this.contractPricingDetails,
          userId: this.loggedInUser?.UserId ?? 0
        }
        if (this.application.ApplicationType === 'Consumer' || this.application.ApplicationType === 'AssetFinance') {
          contractEmailBody.assets = this.assets;
          contractEmailBody.loanAmount = (this.formControlStep1InvoicePrice.value ?? 0) - (this.formControlStep1Deposit.value ?? 0);
        }
        this.subscriptions.push(
          this.sendContractEmailsFn(contractEmailBody).pipe(
            this.toastService.spinnerObservable(),
          ).subscribe((response: SendContractResponse) => {
            if (response.success) {
              stepper.next();
            } else {
              if (response.statusCode === 401) {
                this.applicationDialogService.openAlertDialog({
                  message: `Authorization Error`,
                  subMessage: response.error
                });
              }
            }
          })
        )
        this.syncModifiedDetailsToSf();
      }
      },
      showSecondarySubmitButton:true,
      secondaryButtonText:"Preview Contract",
      secondaryButtonClickedFn : async (stepper) => {
        this.downloadContract(this.watermarkedContractName);
      },
    });

  }

  initStep4() {
    this.formGroupStep4 = this.formBuilder.group({});


    setStepper2StepConfig(this.formGroupStep4, {
      nextStepButtonText:"Close",
      nextStepClickedFn: async stepper => {
        this.deleteExistingContract();
        this.dialogRef.close();
      },
      showSecondarySubmitButton:true,
      secondaryButtonText:"Download Contract",
      secondaryButtonClickedFn : async (stepper) => {
        this.downloadContract(this.contractName);
      },
    });

  }

  onClickClose() {
    this.deleteExistingContract();
    this.dialogRef.close()
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.ccEmailListStep2.push(value);
      }
      // Clear the input value
      event.chipInput!.clear();
      this.ccEmailListStep2Error = false;
    } else if (value) {
      this.ccEmailListStep2Error = true;
    }
  }

  remove(email: string): void {
    const index = this.ccEmailListStep2.indexOf(email);

    if (index >= 0) {
      this.ccEmailListStep2.splice(index, 1);
    }
  }

  onSortData($event: Sort) {

  }

  getApplicationAssets() {
    this.subscriptions.push(
      this.getApplicationAssetFn(this.application.ApplicationId).subscribe((assets: PpsrAsset[]) => {
        this.assets = assets;
        const invoicePrice = this.assets.reduce((partial: number, asset: PpsrAsset) => partial + (asset?.SettlementAssetDetails?.InvoicePrice ?? 0), 0);
        this.invoicePrice = applicationToInvoiceAmount(this.application);
        this.formControlStep1InvoicePrice.setValue(invoicePrice);
        this.formControlStep1InvoicePrice.addValidators(Validators.max((this.invoicePrice ?? 0) * 1.05));


        // maximum value for brokerage
        const deposit = applicationToDepositAmount(this.application)
        const maxAssetFinanceBrokerage = _.round((invoicePrice - deposit!) * 0.08, 2) + 1; // 8 % of invoiceamount (add $1 to avoid rounding issues in pricing)
        if (this.application.ApplicationType === 'AssetFinance') {
          // this.formControlStep1BrokerageAmount.addValidators(Validators.max(maxAssetFinanceBrokerage));
          this.formControlStep1BrokerageAmount.addValidators((control) => {
             const depositAmount = this.formControlStep1Deposit.value ?? 0;
             const depositAmountExGST = Math.round(depositAmount * 1000 / 11) / 100;
             const financeType = applicationToFinanceType(this.application);
             const _deposit = financeType?.type === 'rental' ? depositAmountExGST : depositAmount;
             const _maxAssetFinanceBrokerage = _.round((invoicePrice - _deposit!) * 0.08, 2) + 1; // 8 % of invoiceamount (add $1 to avoid rounding issues in pricing)
             if (control.value > _maxAssetFinanceBrokerage) {
               return {max: {max: _maxAssetFinanceBrokerage}};
             }
             return null;
          });
        } else {
          this.formControlStep1BrokerageAmount.addValidators(Validators.max(maxAssetFinanceBrokerage));
        }

        // validate deposit
        this.depositValidationMessage = null;
        if (deposit) {
          const {totalDeposit, applicationDeposit, valid} = validateAssetDepositAgainstApplicationDeposit(deposit, assets);
          if (!valid) {
            this.depositValidationMessage = `Application deposit $${applicationDeposit} does not match total asset deposit $${totalDeposit}`;
          }
        }

        if (invoicePrice > (this.invoicePrice ?? 0) * 1.05) {
          console.log(` invoice price (all assets) = ${invoicePrice}, application invoice price ${this.invoicePrice}`);
          this.applicationDialogService.openAlertDialog({
            message: "Warning",
            subMessage: `The invoice amount from all assets is greater than the maximum allowed value. Please contact us on 1300 001 420.`,
          }).afterClosed().subscribe(() => {
            this.dialogRef.close();
          })
        }
      })
    )
  }

  updateNumberOfInstallment(repaymentFrequency: PaymentFrequencyValue | null, loanTerm: LoanTermValue | null) {
    let numberOfInstallment = 0;
    if (repaymentFrequency?.type === "Monthly") {
      numberOfInstallment = Number(loanTerm?.type ?? "0");
    } else if (repaymentFrequency?.type === "Fortnightly") {
      numberOfInstallment = 26 * Number(loanTerm?.type ?? "0") / 12;
    } else if (repaymentFrequency?.type === "Weekly") {
      numberOfInstallment = 52 * Number(loanTerm?.type ?? "0") / 12;
    }
    console.log("numberOfInstallment: ", numberOfInstallment)
    this.formControlStep1NumberOfInstallment.setValue(numberOfInstallment);
  }

  toggleAllSelection() {
    const users = this.dataSourceStep2.subject.getValue();
    for (const user of users) {
      this.selectionStep2.toggle(user);
    }
  }

  onEditAddress() {
    this.subscriptions.push(
      this.applicationDialogService.openEditAddressDialog({
        address: this.formControlStep1Address.value
      }).afterClosed().subscribe((r: EditAddressDialogResult | undefined ) => {
        if (r && r.readyForSubmission) {
          this.formControlStep1Address.setValue(r.address);
        }
      })
    )
  }

  editField(fieldName: "invoicePrice" | "paymentInAdvanceOrArrears" | "interestRate" | "loanTerm" | "docFeeFinanced" | "deposit" |
    "residual" | "repaymentFrequency" | "brokerage" | "brokerOriginationFee" | "loanAmount" | "legalName" | "ABN" | "ACN" | "companyEmail" | "rateDiscount") {
    switch (fieldName) {
      case "invoicePrice":
        if (this.editInvoicePrice) {
          if (this.formControlStep1InvoicePrice.valid) {
            this.editInvoicePrice = !this.editInvoicePrice;
          }
        } else {
          this.editInvoicePrice = !this.editInvoicePrice;
        }
        break;
      case "paymentInAdvanceOrArrears":
        this.editPaymentInAdvanceOrArrears = !this.editPaymentInAdvanceOrArrears;
        break;
      case "interestRate":
        this.editInterestRate = !this.editInterestRate;
        break;
      case "loanTerm":
        this.editLoanTerm = !this.editLoanTerm;
        break;
      case "residual":
        if (this.editResidual) {
          if (this.formControlStep1Residual.valid) {
            this.editResidual = !this.editResidual;
          }
        } else {
          this.editResidual = !this.editResidual;
        }
        break;
      case "repaymentFrequency":
        this.editRepaymentFrequency = !this.editRepaymentFrequency;
        break;
      case "brokerage":
        if (this.editBrokerage) {
          if (this.formControlStep1BrokerageAmount.valid) {
            this.editBrokerage = !this.editBrokerage;
          }
        } else {
          this.editBrokerage = !this.editBrokerage;
        }
        break;
      case "brokerOriginationFee":
        if (this.editBrokerOriginationFee) {
          if (this.formControlStep1BrokerOriginationFee.valid) {
            this.editBrokerOriginationFee = !this.editBrokerOriginationFee;
          }
        } else {
          this.editBrokerOriginationFee = !this.editBrokerOriginationFee;
        }
        break;
      case "loanAmount":
        if (this.editLoanAmount) {
          if (this.formControlStep1LoanAmount.valid) {
            this.editLoanAmount = !this.editLoanAmount;
          }
        } else {
          this.editLoanAmount = !this.editLoanAmount;
        }
        break;
      case "legalName":
        if (this.editLegalName) {
          if (this.formControlStep1LegalName.valid) {
            this.editLegalName = !this.editLegalName;
          }
        } else {
          this.editLegalName = !this.editLegalName;
        }
        break;
      case "ABN":
        if (this.editABN) {
          if (this.formControlStep1ABN.valid) {
            this.editABN = !this.editABN;
          }
        } else {
          this.editABN = !this.editABN;
        }
        break;
      case "ACN":
        if (this.editACN) {
          if (this.formControlStep1ACN.valid) {
            this.editACN = !this.editACN;
          }
        } else {
          this.editACN = !this.editACN;
        }
        break;
      case "companyEmail":
        if (this.editEmail) {
          if (this.formControlStep1Email.valid) {
            this.editEmail = !this.editEmail;
          }
        } else {
          this.editEmail = !this.editEmail;
        }
        break;
      case "docFeeFinanced":
        if (this.editDocFeeFinanced) {
          if (this.formControlStep1DocFeeFinanced.valid) {
            this.editDocFeeFinanced = !this.editDocFeeFinanced;
          }
        } else {
          this.editDocFeeFinanced = !this.editDocFeeFinanced;
        }
        break;
      case "deposit":
        if (this.editDeposit) {
          if (this.formControlStep1Deposit.valid) {
            this.editDeposit = !this.editDeposit;
          }
        } else {
          this.editDeposit = !this.editDeposit;
        }
        break;
      case "rateDiscount":
        if (this.editRateDiscount) {
          if (this.formControlStep1RateDiscount.valid) {
            this.editRateDiscount = !this.editRateDiscount;
          }
        } else {
          this.editRateDiscount = !this.editRateDiscount;
        }
        break;
    }
  }

  get templateData(): ContractTemplateData {
    const repaymentFrequency = this.formControlStep1RepaymentFrequency.value?.type ?? 'Monthly';
    return {
      company: {
        name: this.formControlStep1LegalName.value ?? "",
        abn: this.formControlStep1ABN.value ?? "",
        address: this.formControlStep1Address.value?.address ?? "",
        email: this.primaryContactStep2.Email ?? "",
        acn: this.formControlStep1ACN.value ?? "",
        phone: this.primaryContactStep2?.MobileNumber ?? "",
      },
      customer: {
        name: getApplicationName(this.application),
        email: applicantIndividual(this.application)?.Email ?? "",
        mobile: applicantIndividual(this.application)?.MobileNumber ?? "",
        address: applicantIndividual(this.application)?.HomeAddress?.UnformattedAddress ?? "",
      },
      expense: getExpense(this.application),
      invoicePrice: this.formControlStep1InvoicePrice.value ?? 0,
      deposit: this.formControlStep1Deposit.value ?? 0,
      docFeeFinanced: !!this.formControlStep1DocFeeFinanced.value,
      loanAmount: this.application.ApplicationType == 'BusinessLoans' ? this.formControlStep1LoanAmount.value ?? 0 : (this.formControlStep1InvoicePrice.value ?? 0) - (this.formControlStep1Deposit.value ?? 0),
      totalInterest: this.formControlStep1TotalInterest.value ?? 0, //changed this to add brokerage in the field below
      totalInterestInclBrokerage: (this.formControlStep1TotalInterest.value ?? 0) + (this.formControlStep1BrokerageAmount.value ?? 0),
      amountFinanced: this.formControlStep1AmountFinanced.value ?? 0,
      totalAmount: this.formControlStep1TotalPayment.value ?? 0,
      docFee: this.formControlStep1DocFee.value ?? 0,
      numberOfInstallments: this.formControlStep1NumberOfInstallment.value ?? 0,
      repaymentFrequency: repaymentFrequency,
      repaymentFrequencyTypeName: repaymentFrequency.slice(0, repaymentFrequency.length - 2) + "s",
      residual: this.formControlStep1Residual.value ?? 0,
      repayment: this.formControlStep1Repayment.value ?? 0,
      repaymentGST: Math.round((this.formControlStep1Repayment.value ?? 0) * 0.1 * 100) / 100,
      brokerOriginationFee: this.formControlStep1BrokerOriginationFee.value ?? 0,
      term: Number(this.formControlStep1LoanTerm.value?.type ?? '0'),
      rate: this.application.ApplicationType == "Consumer" ? (this.formControlStep1DisplayedRate.value ?? 0) : (this.formControlStep1InterestRate.value ?? 0),
      margin: this.formControlStep1DisplayedMargin.value ?? 0,
      assets: this.assets.map(asset => {
        return {
          description: asset?.SettlementAssetDetails?.description ?? "",
          invoicePrice: asset?.SettlementAssetDetails?.InvoicePrice ?? 0,
          invoiceNumber: String(asset?.invoiceNumber) ?? "",
          supplier: asset?.SettlementAssetDetails?.supplier ?
            (asset.SettlementAssetDetails?.supplier as any)?.SupplierName :
            (parseJSON((asset as any).PrivateSellerDetails)?.business ? parseJSON((asset as any).PrivateSellerDetails)?.business?.organisationName :
              parseJSON((asset as any).PrivateSellerDetails).name + ' ' + parseJSON((asset as any).PrivateSellerDetails).lastName),
          serialNumber: asset?.SettlementAssetDetails?.serialNumber ? (asset?.SettlementAssetDetails?.serialNumberType ?
            asset?.SettlementAssetDetails?.serialNumberType + ": " + asset?.SettlementAssetDetails?.serialNumber :
            "Serial Number: " + asset?.SettlementAssetDetails?.serialNumber) : ""
        }
      }),
      brokerName: getBrokerName(this.application) ?? '',
      // brokerage:  Math.round(getLoanAmount(this.application) * getBrokerage(this.application) / 100 * 100) / 100,
      brokerage: this.formControlStep1BrokerageAmount.value ?? 0,
      facilityLimit: this.formControlStep1LoanAmount.value ?? 0,
      facilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee.value ?? 0,
      paymentInAdvanceOrArrears: this.formControlStep1PaymentInAdvanceOrArrears.value ?? "",
      additionalSecurity: getAdditionalSecurity(this.application),
      specialConditions: getSpecialConditions(this.application),
      transactionType: (this.formControlStep1TransactionType.value?.type) ?? "",
      monthlyAmountKeepingFee: this.rateCard?.MonthlyAccountKeepingFee ?? 0,
    }
  }

  deleteExistingContract() {
    if (this.contractName) {
      this.subscriptions.push(
        this.deleteContractFileFn({
          fileName: this.contractName
        }).subscribe()
      )
    }
  }

  downloadContract(fileName: string) {
    const newFileName = getContractFileName(this.application);
    const url = this.apiUrl + "/download-contract/" +  fileName + "/" + newFileName;
    const res = window.open(url);
    if (!res || res.closed || typeof res.closed === 'undefined') {
      this.applicationDialogService.openAlertDialog({
        message: 'Warn',
        subMessage: 'Please disable Popup blocker and try again',
      });
      return;
    }
  }

  base64ToArrayBuffer(base64: string) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
    }
    this.contractBuffer = (bytes.buffer as Uint8Array);
  }

  recalculationForAssetFinanceAndConsumerAssetFinance() {
    const ac = getAssetCategoryObj(this.application);
    const at = getAssetTypeObj(this.application);
    const assetCategory = getApplicationAssetCategory(this.application); // as string
    const assetType = getAssetType(this.application); // as string
    const assetSpec = getAssetSpec(this.application);
    const assetGoodRetail = getAssetGoodRetail(this.application);
    const assetNewPrice = getAssetNewPrice(this.application);
    const assetSelectionValue = applicationToAsset(this.application);
    const monthlyAccountKeepingFee = applicationToMonthlyAccountKeepingFee(this.application);


    this.subscriptions.push(
      combineLatest([
        this.getRatecardDetailsFn(this.application.UserId, this.application.ApplicationType),
        this.getOriginatorBusinessByIdFn(this.application.CompanyId),
        this.formControlStep1InvoicePrice.valueChanges,
        this.formControlStep1DocFee.valueChanges,
        this.formControlStep1Deposit.valueChanges,
        this.formControlStep1LoanTerm.valueChanges,
        this.formControlStep1RepaymentFrequency.valueChanges,
        this.formControlStep1BrokerageAmount.valueChanges,
        this.formControlStep1BrokerOriginationFee.valueChanges,
        this.formControlStep1PrivateSale.valueChanges,
        this.formControlStep1DocFeeFinanced.valueChanges,
        this.formControlStep1TransactionType.valueChanges,
        this.formControlStep1RateDiscount.valueChanges,
      ]).subscribe((
        [rateCard, originatorBusiness, invoicePrice, docFee, deposit, loanTerm, repaymentFrequency, brokerageAmount, brokerOriginationFee, privateSale, docFeeFinanced, transactionType, rateDiscount]:
          [RatecardDetails, OriginatorBusiness | null, number | null, number | null, number | null, LoanTermValue | null, PaymentFrequencyValue | null,
              number | null, number | null, PrivateSaleType | null, boolean | null, TransactionValue | null, number | null]
      ) => {
        this.rateCard = rateCard;
        const date = moment().add(1, 'day');

        const financialType = (getFinanceType(this.application) as string).toLowerCase().split(' ').join('-') as FinanceType;
        const data = {
          paymentFrequencyType: repaymentFrequency?.type as PaymentFrequencyType,
          assetConditionType: getAssetCondition(this.application) as AssetConditionType ?? null,
          assetSelectionValue: assetSelectionValue!,
          assetYear: parseFloat(getAssetYear(this.application) != '' ? getAssetYear(this.application) as any :  "0"),
          loanTerm: numeral(loanTerm?.type).value() ?? 0,
          financeType: financialType,
          //balloonPaymentType: String(getBalloonPaymentPercentage(this.application)) as BalloonPaymentType,
          brokerageType: String((brokerageAmount ?? 0) * 100 / this.loanAmount) as BrokerageSelectionType,
          loanAmount: financialType === 'rental' ? Math.round(100 * (Number(invoicePrice) - Number(deposit) * 10 / 11)) / 100 : Number(invoicePrice) - Number(deposit),
          businessSearchValue: null,
          existingApplicationBureauReport: this.application.CompanyDetails,
          propertyOwnership: yesNoToBoolean(getPropertyOwner(this.application)),
          docFeeFinanced: !!docFeeFinanced,
          brokerOriginationFee: brokerOriginationFee as number,
          brokerage: (brokerageAmount ?? 0) * 100 / this.loanAmount,
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          equifaxScoreAbove600: yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          privateSaleOrLeaseback: privateSale !== 'No',
          balloonPayment: getBalloonPaymentPercentage(this.application),
          rateCard: rateCard,
          invoiceAmount: Number(invoicePrice),
          brokerageAmount: brokerageAmount as number,
          deposit: Number(deposit),
          balloonPaymentAmount: getBalloonPayment(this.application),
          creditRateAdjustment: getCreditRateAdjustment(this.application),
          transactionType: transactionType?.type,
          repaymentType: getRepaymentType(this.application) ?? ''
        };

        const rst = this.application.ApplicationType === "Consumer" ?
          calculateConsumerAssetFinanceEstimation(date, {
            type: 'Consumer',
            hybrid: !!this.formControlStep1Hybrid.value,
            ppsrFee: rateCard.PpsrFee,
            rateDiscount: rateDiscount ?? 0,
            percentagePaidToDealerOrBroker: originatorBusiness ? originatorBusiness.PercentagePaid : 100,
            monthlyAccountKeepingFee: monthlyAccountKeepingFee,
            ...data,
          }) : calculateAssetFinanceEstimation(date, {
            type: 'AssetFinance',
            ...data,
            paymentType: String(getPaymentType(this.application)),
            docFee: getDocFee(this.application) as number,
          });

        const paymentChartData = rst.totalPaymentBreakupDialogData.paymentChartData;
        console.log(paymentChartData)
        if (this.application.ApplicationType === "Consumer") {
          this.formControlStep1DisplayedRate.setValue(paymentChartData?.displayedInterest ?? 0);
          this.formControlStep1Commission.setValue(paymentChartData?.commission ?? 0);
          this.formControlStep1TotalEarnedInclGst.setValue(paymentChartData?.totalEarnedInclGst ?? 0);
          this.commissionStep2 = paymentChartData?.commission ?? 0;
          this.oversCommissionStep2 = paymentChartData?.amountToUs ?? 0;
        }
        this.formControlStep1AmountFinanced.setValue(paymentChartData.amountFinanced);
        this.formControlStep1Repayment.setValue(this.application.ApplicationType === "Consumer" ? (paymentChartData?.repaymentPlusMonthlyAccountKeepingFee ?? 0) : paymentChartData.emiAmt);
        this.formControlStep1TotalInterest.setValue(paymentChartData.interestAmt);
        this.formControlStep1TotalPayment.setValue(paymentChartData.totalAmt);
        this.formControlStep1Residual.setValue(paymentChartData.rv);
        this.formControlStep1InterestRate.setValue(Math.round(paymentChartData.totalInterest * 100) / 100);
        this.updateNumberOfInstallment(repaymentFrequency, loanTerm);
      })
    )
  }

  recalculationForBusinessLoan() {
    this.subscriptions.push(
      combineLatest([
        this.getRatecardDetailsFn(this.application.UserId,  this.application.ApplicationType),
        this.formControlStep1RepaymentFrequency.valueChanges,
        this.formControlStep1LoanTerm.valueChanges,
        this.formControlStep1BrokerageAmount.valueChanges,
        this.formControlStep1LoanAmount.valueChanges,
        this.formControlStep1DocFeeFinanced.valueChanges
      ]).subscribe(([rateCard, repaymentFrequency, loanTerm, brokerageAmount, loanAmount, docFeeFinanced]: [
        RatecardDetails, PaymentFrequencyValue | null,  LoanTermValue | null, number | null, number | null, boolean | null
      ]) => {
        this.rateCard = rateCard;
        const date = moment().add(1, 'day');
        const rst = calculateBusinessLoanEstimation(date, {
          type: 'BusinessLoans',
          paymentFrequencyType: repaymentFrequency?.type as PaymentFrequencyType,
          loanTermType: loanTerm?.type as LoanTermType,
          brokerageType: String((brokerageAmount ?? 0) * 100 / this.loanAmount) as BrokerageSelectionType,
          loanAmount: loanAmount ?? 0,
          businessSearchValue: null,
          bureauReport: this.application.CompanyDetails,
          brokerage: Number(brokerageAmount),
          lowEquifaxScore:  yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          directorScore: applicationToDirectorScore(this.application),
          propertyOwner: yesNoToBoolean(getPropertyOwner(this.application)),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          rateCard: rateCard,
          brokerageAmount: brokerageAmount ?? 0,
          docFee: getDocFee(this.application) as number,
          docFeeFinanced: !!docFeeFinanced,
          paymentType: String(getPaymentType(this.application)),
          creditRateAdjustment: getCreditRateAdjustment(this.application),
        })

        const paymentChartData = rst.totalPaymentBreakupDialogData.paymentChartData;
        console.log(paymentChartData);
        this.formControlStep1InterestRate.setValue(Math.round(paymentChartData.totalInterest * 100) / 100);
        this.formControlStep1AmountFinanced.setValue(paymentChartData.amountFinanced);
        this.formControlStep1Repayment.setValue(paymentChartData.emiAmt);
        this.formControlStep1TotalInterest.setValue(paymentChartData.interestAmt);
        this.formControlStep1TotalPayment.setValue(paymentChartData.totalAmt);
        this.updateNumberOfInstallment(repaymentFrequency, loanTerm);
      })
    )
  }

  recalculationForBusinessOverdraft() {
    this.subscriptions.push(
      combineLatest([
        this.getRatecardDetailsFn(this.application.UserId,  this.application.ApplicationType),
        this.formControlStep1LoanTerm.valueChanges,
        this.formControlStep1LoanAmount.valueChanges,
        this.formControlStep1FacilityEstablishmentFeePercent.valueChanges,
        this.formControlStep1BrokerageDollar.valueChanges,
      ]).subscribe(([rateCard, loanTerm, loanAmount, facilityEstablishmentFeePercent, brokerageAmount]: [
        RatecardDetails,LoanTermValue | null, number | null, BrokerageSelectionValue | null, number | null
      ]) => {
        this.rateCard = rateCard;
        const date = moment().add(1, 'day');
        const rst: CalculateBusinessOverdraftEstimationResult = calculateBusinessOverdraftEstimation(date, {
          type: 'BusinessOverdraft',
          loanTermType: loanTerm?.type as LoanTermType,
          // loanAmount: loanAmount ?? 0,
          lowEquifaxScore:  yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          directorScore: applicationToDirectorScore(this.application),
          propertyOwner: yesNoToBoolean(getPropertyOwner(this.application)),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          rateCard: rateCard,
          facilityLimit: loanAmount ?? 0,
          facilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent?.type ?? 0),
          brokerageAmount: brokerageAmount ?? 0,
          docFee: getDocFee(this.application) as number,
          creditRateAdjustment: getCreditRateAdjustment(this.application),
        });
        this.formControlStep1InterestRate.setValue(Math.round(rst.term.interestRate * 100) / 100);
        this.formControlStep1DisplayedMargin.setValue(Math.round(100 * (rst.term.interestRate - getRbaRate(this.application))) / 100);
        this.formControlStep1FacilityEstablishmentFee.setValue(rst.term.facilityEstablishmentFee);
        this.formControlStep1DocFee.setValue(rst.term.docFee);
        this.formControlStep1Repayment.setValue(rst.term.monthlyFacilityFee);
        this.formControlStep1AmountFinanced.setValue(rst.term.amountAvailable);
        this.updateNumberOfInstallment(this.formControlStep1RepaymentFrequency.value, loanTerm);
      })
    )
  }

  recalculationForCorporateLoans() {
    this.subscriptions.push(
      combineLatest([
        this.getRatecardDetailsFn(this.application.UserId,  this.application.ApplicationType),
        this.formControlStep1LoanTerm.valueChanges,
        this.formControlStep1LoanAmount.valueChanges,
        this.formControlStep1FacilityEstablishmentFeePercent.valueChanges,
        this.formControlStep1BrokerageDollar.valueChanges,
      ]).subscribe(([rateCard, loanTerm, loanAmount, facilityEstablishmentFeePercent, brokerageAmount]: [
        RatecardDetails,LoanTermValue | null, number | null, BrokerageSelectionValue | null, number | null
      ]) => {
        this.rateCard = rateCard;
        const date = moment().add(1, 'day');
        const rst: CalculateCorporateLoanEstmationResult = calculateCorporateLoanEstimation(date, {
          type: 'CorporateLoan',
          loanTermType: loanTerm?.type as LoanTermType,
          // loanAmount: loanAmount ?? 0,
          lowEquifaxScore:  yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          directorScore: applicationToDirectorScore(this.application),
          propertyOwner: yesNoToBoolean(getPropertyOwner(this.application)),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          rateCard: rateCard,
          facilityLimit: loanAmount ?? 0,
          facilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent?.type ?? 0),
          brokerageAmount: brokerageAmount ?? 0,
          docFee: getDocFee(this.application) as number,
          creditRateAdjustment: getCreditRateAdjustment(this.application),
          ltvType: getLtv(this.application) ?? undefined,
          securityType: getSecurityType(this.application) ?? undefined,
        });
        this.formControlStep1InterestRate.setValue(Math.round(rst.term.interestRate * 100) / 100);
        this.formControlStep1DisplayedMargin.setValue(Math.round(100 * (rst.term.interestRate - getRbaRate(this.application))) / 100);
        this.formControlStep1FacilityEstablishmentFee.setValue(rst.term.facilityEstablishmentFee);
        this.formControlStep1DocFee.setValue(rst.term.docFee);
        this.formControlStep1Repayment.setValue(rst.term.monthlyFacilityFee);
        this.formControlStep1AmountFinanced.setValue(rst.term.amountAvailable);
        this.updateNumberOfInstallment(this.formControlStep1RepaymentFrequency.value, loanTerm);
      })
    )
  }



  populateBrokerageForOverdraft() {
    this.subscriptions.push(
      this.getRatecardDetailsFn(this.application.UserId,  this.application.ApplicationType).subscribe(rateCard => {
        const date = moment().add(1, 'day');
        const loanTerm = this.formControlStep1LoanTerm.value;
        const loanAmount = this.formControlStep1LoanAmount.value;
        const facilityEstablishmentFeePercent = this.formControlStep1FacilityEstablishmentFeePercent.value;
        const rst: CalculateBusinessOverdraftEstimationResult = calculateBusinessOverdraftEstimation(date, {
          type: 'BusinessOverdraft',
          loanTermType: loanTerm?.type as LoanTermType,
          // loanAmount: loanAmount ?? 0,
          lowEquifaxScore:  yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          directorScore: applicationToDirectorScore(this.application),
          propertyOwner: yesNoToBoolean(getPropertyOwner(this.application)),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          rateCard: rateCard,
          facilityLimit: loanAmount ?? 0,
          facilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent?.type ?? 0),
          docFee: getDocFee(this.application) as number,
          creditRateAdjustment: getCreditRateAdjustment(this.application),
        });
        this.formControlStep1BrokerageDollar.setValue(rst.term.brokerageDollar);
        this.formControlStep1BrokerageDollar.clearValidators();
        this.formControlStep1BrokerageDollar.setValidators([Validators.required, Validators.max(rst.term.brokerageDollar)]);
        this.formControlStep1BrokerageDollar.updateValueAndValidity();
      })
    )
  }

  populateBrokerageForCorporateLoans() {
    this.subscriptions.push(
      this.getRatecardDetailsFn(this.application.UserId,  this.application.ApplicationType).subscribe(rateCard => {
        const date = moment().add(1, 'day');
        const loanTerm = this.formControlStep1LoanTerm.value;
        const loanAmount = this.formControlStep1LoanAmount.value;
        const facilityEstablishmentFeePercent = this.formControlStep1FacilityEstablishmentFeePercent.value;
        const rst: CalculateCorporateLoanEstmationResult = calculateCorporateLoanEstimation(date, {
          type: 'CorporateLoan',
          loanTermType: loanTerm?.type as LoanTermType,
          // loanAmount: loanAmount ?? 0,
          lowEquifaxScore:  yesNoToBoolean(getEquifaxScoreAboveThresold(this.application)),
          directorScore: applicationToDirectorScore(this.application),
          propertyOwner: yesNoToBoolean(getPropertyOwner(this.application)),
          adverseOnFile: yesNoToBoolean(getAdverseOnFile(this.application)),
          rateCard: rateCard,
          facilityLimit: loanAmount ?? 0,
          facilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent?.type ?? 0),
          docFee: getDocFee(this.application) as number,
          creditRateAdjustment: getCreditRateAdjustment(this.application),
          ltvType: getLtv(this.application),
          securityType: getSecurityType(this.application),
        });
        this.formControlStep1BrokerageDollar.setValue(rst.term.brokerageDollar);
        this.formControlStep1BrokerageDollar.clearValidators();
        this.formControlStep1BrokerageDollar.setValidators([Validators.required, Validators.max(rst.term.brokerageDollar)]);
        this.formControlStep1BrokerageDollar.updateValueAndValidity();
      })
    )
  }


  onChangeCheckbox(event: MatCheckboxChange, index: number) {
    console.log(event);
    if (event.checked) {
      this.primaryContactCheckbox[index] = true;
      for (let i = 0; i < this.primaryContactCheckbox.length; i++) {
        if (i !== index) {
          this.primaryContactCheckbox[i] = false;
        }
      }
    }
  }

  private getCheckboxResult(): { checked: boolean, primaryContactIndex: number } {
    let primaryContactIndex = -1;
    let checked = false;
    for (let i = 0; i < this.primaryContactCheckbox.length; i++) {
      if (this.primaryContactCheckbox[i]) {
        checked = true;
        primaryContactIndex = i;
        break;
      }
    }
    return {
      checked,
      primaryContactIndex
    }
  }

  private checkSignerExistsInIndividuals(): boolean {
    for (const individual of this.allIndividuals) {
      if (individual.SignerRole === "Signer" || individual.SignerRole === "GuarantorSigner") {
        return true;
      }
    }
    return false;
  }

  private validateIndividualMobileNumbers(): boolean {
    for (const individual of this.allIndividuals) {
      if (!individual.MobileNumber.startsWith('04')) {
        return false;
      }
    }
    return true;
  }

  get emailSubject(): string {
    if (this.application.ApplicationType === "Consumer") {
      return `Dynamoney Consumer Credit Contract - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
    } else if (this.application.ApplicationType === "BusinessLoans") {
      return `Dynamoney Term Loan Agreement - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
    } else if (this.application.ApplicationType === "BusinessOverdraft") {
      return `Dynamoney Overdraft Loan Agreement - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
    }  else if (this.application.ApplicationType === "CorporateLoans") {
      return `Dynamoney Corporate Loan Agreement - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
    } else if (this.application.ApplicationType === "AssetFinance") {
      if (this.application.AppInfo.FinanceType === "Chattel Mortgage") {
        return `Dynamoney Secured Loan Agreement - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
      } else if (this.application.AppInfo.FinanceType === "Rental") {
        return `Dynamoney Rental Agreement - ${this.application.BrokerAppId} Dated ${moment().format("hh-mm A DD-MM-YYYY")}`;
      }
    }

    return "";
  }

  syncModifiedDetailsToSf() {
    let invoiceAmount = this.formControlStep1InvoicePrice.value ?? 0;
    if (this.application.ApplicationType === 'BusinessLoans' || this.application.ApplicationType === 'BusinessOverdraft'  || this.application.ApplicationType === 'CorporateLoans') {
      invoiceAmount = this.formControlStep1LoanAmount.value ?? 0;
    }

    let body: SyncContractDetailsBody = {
      brokerageAmount: this.formControlStep1BrokerageAmount.value ?? 0,
      brokerOriginationFee: this.formControlStep1BrokerOriginationFee.value ?? 0,
      invoiceAmount: invoiceAmount,
      salesforceId: getAppSalesforceId(this.application) ?? '',
      brokerageDollar: this.formControlStep1BrokerageAmount.value ?? 0,
    };
    if (this.application.ApplicationType === 'AssetFinance') {
      body = {
        ...body,
        legalName: this.formControlStep1LegalName.value ?? '',
        address: this.formControlStep1Address.value?.address ?? '',
        abn: this.formControlStep1ABN.value ?? '',
        acn: this.formControlStep1ACN.value ?? '',
        docFeeFinanced: !!this.formControlStep1DocFeeFinanced.value,
      }
    }
    if (this.application.ApplicationType === 'Consumer') {
      body = {
        ...body,
        rate: this.formControlStep1DisplayedRate.value ?? 0,
      }
    }
    if (this.application.ApplicationType === 'BusinessOverdraft') {
      body = {
        ...body,
        facilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee.value ?? 0,
      }
    }

    if (this.application.ApplicationType === 'CorporateLoans') {
      body = {
        ...body,
        facilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee.value ?? 0,
      }
    }

    if (this.application.ApplicationType === 'BusinessOverdraft') {
      body = {
        ...body,
        docFeeFinanced: !!this.formControlStep1DocFeeFinanced.value,
      }
    }

    this.subscriptions.push(
      this.syncContractDetailsToSfFn(body).subscribe()
    )
  }

  // get updatedPrimaryCommercialEntity() {
  //   const commercialEntity = this.application.CommercialEntities;
  //   if (commercialEntity.length) {
  //     const primaryEntityIndex = commercialEntity.findIndex((comm: CommercialEntity) => (comm).Type === 'Primary');
  //     if (primaryEntityIndex > -1) {
  //       const address = commercialEntity[primaryEntityIndex].PrinciplePlaceofBusiness;
  //       commercialEntity[primaryEntityIndex] = {
  //         ...commercialEntity[primaryEntityIndex],
  //         ABN: this.formControlStep1ABN.value ?? '',
  //         ACN: this.formControlStep1ACN.value ?? '',
  //         LegalName: this.formControlStep1LegalName.value ?? '',
  //         PrinciplePlaceofBusiness: {
  //           ...address,
  //           UnformattedAddress: this.formControlStep1Address.value?.address ?? '',
  //         }
  //       }
  //     }
  //   }

  //   return commercialEntity;
  // }

  get contractPricingDetails(): ContractPricingDetails {
    let contractPricingDetails: ContractPricingDetails = {};
    if (this.application.ApplicationType === 'AssetFinance') {
      const invoiceAmount = this.formControlStep1InvoicePrice.value ?? 0;
      const deposit = this.formControlStep1Deposit.value ?? 0;
      const loanAmount = invoiceAmount - deposit;
      const balloonPayment = this.formControlStep1Residual.value ?? 0;
      const brokerageAmount = this.formControlStep1BrokerageAmount.value ?? 0;
      contractPricingDetails = {
          InvoiceAmount: invoiceAmount,
          Deposit: deposit,
          LoanAmount: loanAmount,
          AmountFinance: this.formControlStep1AmountFinanced.value ?? 0,
          BalloonPaymentPercentage: Math.round(Number(balloonPayment * 100 / loanAmount) * 100) / 100,
          Repayment: this.formControlStep1Repayment.value ?? 0,
          BrokerageAmount: brokerageAmount,
          Brokerage: Math.round(brokerageAmount * 100 * 100 / loanAmount) / 100,
          BrokerOriginationFee: this.formControlStep1BrokerOriginationFee.value ?? 0,
          DocFeeFinanced: booleanToYesNo(this.formControlStep1DocFeeFinanced.value),
          Rate: this.formControlStep1InterestRate.value ?? 0,
      };
    } else if (this.application.ApplicationType === 'Consumer') {
      const invoiceAmount = this.formControlStep1InvoicePrice.value ?? 0;
      const deposit = this.formControlStep1Deposit.value ?? 0;
      const loanAmount = invoiceAmount - deposit;
      const balloonPayment = this.formControlStep1Residual.value ?? 0;
      const brokerageAmount = this.commissionStep2;
      const oversCommission = this.oversCommissionStep2;
      const brokeragePercentage = loanAmount ? brokerageAmount / loanAmount : 0;
      const apr = this.formControlStep1DisplayedRate.value ?? 0;
      const rateDiscount = this.formControlStep1RateDiscount.value ?? 0;
      contractPricingDetails = {
        InvoiceAmount: invoiceAmount,
        Deposit: deposit,
        LoanAmount: loanAmount,
        AmountFinance: this.formControlStep1AmountFinanced.value ?? 0,
        BalloonPaymentPercentage: Math.round(Number(balloonPayment * 100 / loanAmount) * 100) / 100,
        Repayment: this.formControlStep1Repayment.value ?? 0,
        BrokerOriginationFee: this.formControlStep1BrokerOriginationFee.value ?? 0,
        DocFeeFinanced: booleanToYesNo(this.formControlStep1DocFeeFinanced.value),
        APR: apr,
        RateDiscount: rateDiscount,
        Rate: apr + rateDiscount - this.rateCard.MaxBrokerRateDiscount,
        BrokerageAmount: brokerageAmount,
        Brokerage: brokeragePercentage,
        OversCommission: oversCommission,
        //Rate: this.formControlStep1InterestRate.value ?? 0,
      };
    } else if (this.application.ApplicationType === 'BusinessLoans') {
      const loanAmount = this.formControlStep1LoanAmount.value ?? 0;
      const brokerageAmount = this.formControlStep1BrokerageAmount.value ?? 0;
      contractPricingDetails = {
        LoanAmount: loanAmount,
        Repayment: this.formControlStep1Repayment.value ?? 0,
        BrokerageAmount: brokerageAmount,
        BrokerOriginationFee: this.formControlStep1BrokerOriginationFee.value ?? 0,
        Rate: this.formControlStep1InterestRate.value ?? 0,
        DocFeeFinanced: booleanToYesNo(this.formControlStep1DocFeeFinanced.value),
      };
    } else if (this.application.ApplicationType === 'BusinessOverdraft') {
      const loanAmount = this.formControlStep1LoanAmount.value ?? 0;
      const brokerageAmount = this.formControlStep1BrokerageDollar.value ?? 0;
      contractPricingDetails = {
        LoanAmount: loanAmount,
        BrokerageAmount: brokerageAmount,
        BrokerageDollar: brokerageAmount,
        FacilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee.value ?? 0,
        Rate: this.formControlStep1InterestRate.value ?? 0,
        Margin: this.formControlStep1DisplayedMargin.value ?? 0,
      }
    }else if (this.application.ApplicationType === 'CorporateLoans') {
      const loanAmount = this.formControlStep1LoanAmount.value ?? 0;
      const brokerageAmount = this.formControlStep1BrokerageDollar.value ?? 0;
      contractPricingDetails = {
        LoanAmount: loanAmount,
        BrokerageAmount: brokerageAmount,
        BrokerageDollar: brokerageAmount,
        FacilityEstablishmentFee: this.formControlStep1FacilityEstablishmentFee.value ?? 0,
        Rate: this.formControlStep1InterestRate.value ?? 0,
        Margin: this.formControlStep1DisplayedMargin.value ?? 0,
      }
    }


    return contractPricingDetails;
  }
}
