<div class="asset-finance-summary-component">
  @if (summary) {
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="mat-h2">Summary</div>
        </div>
      </div>
      @if (summary.repayment || summary.estimatedDrawDownDate) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>{{summary.paymentFrequency?.type ?? 'Monthly'}} repayments</mat-label>
              <input matInput class="ml-4" type="input" readonly name="monthlyRepayment" [ngModel]="summary.repayment">
              <span class="mdi mdi-currency-usd" matPrefix></span>
            </mat-form-field>
          </div>
          <div class="col-lg-3 col-md-12">
            <mat-form-field>
              <mat-label>Estimated draw down date</mat-label>
              <input matInput type="input" readonly name="estimatedDrawDownDate" [ngModel]="displayableDate(summary.estimatedDrawDownDate)">
            </mat-form-field>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Application ID</div>
            <div body>{{summary.brokerApplicationId}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Organisation</div>
            <div body>{{summary.organisation?.organisationName}}</div>
          </cell>
        </div>
               <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Broker Contact</div>
            <div body>{{summary.brokerContact?.FirstName}} {{summary.brokerContact?.LastName}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>ABN</div>
            <div body>{{summary.abn}}</div>
          </cell>
        </div>
        @if (summary.organisationType?.name=='Company') {
          <div class="col-lg-3 col-md-12" >
            <cell>
              <div label>ACN</div>
              <div body>{{summary.acn}}</div>
            </cell>
          </div>
        }
        @if (summary.productType) {
          <div class="col-lg-3 col-md-12" >
            <cell>
              <div label>Product Type</div>
              @if(summary.productType === 'FAST DOC'){<div body>Low Doc</div>}@else{
                <div body>{{summary.productType | titlecase}}</div>}
            </cell>
          </div>
          <div class="col-lg-3 col-md-12" >
            <cell>
              <div label>Switch To Easy Doc</div>
              <div body>{{summary.isSwitchToEasyDoc ? 'Yes' : 'No'}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.organisationType) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Organisation type</div>
              <div body>{{summary.organisationType.name}}</div>
            </cell>
          </div>
        }
        @if (summary.financeType) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Finance type</div>
              <div body>{{summary.financeType.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Revenue</div>
            <div body>{{summary.annualRevenue}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Commercial premises</div>
            <div body>{{booleanToYesNo(summary.operatesInCommercialPremises)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.primaryIndustry) {
          <div class="col-lg-6 col-md-13">
            <cell>
              <div label>Primary industry</div>
              <div body>{{summary.primaryIndustry.name}}</div>
            </cell>
          </div>
        }
        @if (summary.industrySector) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Industry sector</div>
              <div body>{{summary.industrySector.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.address) {
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Primary business address</div>
              <div body>{{summary.address.address}}</div>
            </cell>
          </div>
        }
        @if (summary.businessLandline) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Business landline</div>
              <div body>{{summary.businessLandline}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Broker</div>
            <div body>{{summary.broker?.entityName}}</div>
          </cell>
        </div>
        @if (summary.brokerFlowId) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Brokerflow Id</div>
              <div body>{{summary.brokerFlowId}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Balloon payment</div>
            <div body>{{summary.balloonPaymentValue | looseCurrency}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Deposit amount (incl GST)</div>
            <div body>{{summary.depositAmount | looseCurrency}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Loan amount</div>
            <div body>{{summary.loanAmount | looseCurrency}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.asset) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Asset category</div>
              <div body>{{summary.asset.category.value}}</div>
            </cell>
          </div>
          <div class="col-lg-6 cl-md-12">
            <cell>
              <div label>Asset type</div>
              <div body>{{summary.asset.type.value}}</div>
            </cell>
          </div>
        </div>
        <mat-divider [inset]="true"></mat-divider>
      }
      @if (summary.asset) {
        @if (summary.asset.make) {
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Make</div>
                <div body>{{summary.asset.make}}</div>
              </cell>
            </div>
            @if (summary.asset.family) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Family</div>
                  <div body>{{summary.asset.family}}</div>
                </cell>
              </div>
            }
            @if (summary.asset.year) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Year</div>
                  <div body>{{summary.asset.year}}</div>
                </cell>
              </div>
            }
          </div>
        }
        <mat-divider [inset]="true"></mat-divider>
      }
      @if (summary.asset || summary.assetCondition) {
        <div class="row">
          @if (summary.asset?.model) {
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Model</div>
                <div body>{{summary.asset?.model}}</div>
              </cell>
            </div>
          }
          @if (summary.assetCondition) {
            <div class="col-lg-3 col-md-12">
              <cell>
                <div label>Asset condition</div>
                <div body>{{summary.assetCondition.type}}</div>
              </cell>
            </div>
          }
        </div>
      }
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.asset?.vehicle) {
        @if (summary.asset?.vehicle) {
          <div class="row">
            @if (summary.asset?.vehicle?.avgretail) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Average Retail</div>
                  <div body>{{summary.asset?.vehicle?.avgretail | looseCurrency}}</div>
                </cell>
              </div>
            }
            @if (summary.asset?.vehicle?.goodretail) {
              <div class="col-lg-3 col-md-12">
                <cell>
                  <div label>Good Retail</div>
                  <div body>{{summary.asset?.vehicle?.goodretail | looseCurrency}}</div>
                </cell>
              </div>
            }
          </div>
        }
        <mat-divider [inset]="true"></mat-divider>
      }
      <div class="row">
        @if (summary.invoiceAmount) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Invoice amount</div>
              <div body>{{summary.invoiceAmount | looseCurrency}}</div>
            </cell>
          </div>
        }
        @if (summary.loanTerms) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Loan term (in months)</div>
              <div body>{{summary.loanTerms}}</div>
            </cell>
          </div>
        }
        @if (summary.paymentFrequency) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Payment Frequency</div>
              <div body>{{summary.paymentFrequency.name}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="row">
        @if (summary.transactionType) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Transaction Type</div>
              <div body>{{summary.transactionType.name}}</div>
            </cell>
          </div>
        }
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Director Equifax score > 500</div>
            <div body>{{booleanToYesNo(summary.equifaxScoreAboveThreshold)}}</div>
          </cell>
        </div>
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Adverse on file</div>
            <div body>{{booleanToYesNo(summary.adverseOnFile)}}</div>
          </cell>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
      @if (summary.asset && summary.asset.assetPurpose) {
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <cell>
              <div label>Asset Purpose</div>
              <div body>{{summary.asset.assetPurpose.name}}</div>
            </cell>
          </div>
          @if (summary.asset.assetPurposeDescription) {
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Asset Purpose Description</div>
                <div body><span title="summary.asset.assetPurposeDescription" [innerHTML]="summary.asset.assetPurposeDescription | ellipsis"></span></div>
              </cell>
            </div>
          }
          @if (summary.asset.assetExistingFinanceCommitment) {
            <div class="col-lg-6 col-md-12">
              <cell>
                <div label>Asset has an existing finance commitment</div>
                <div body>{{summary.asset.assetExistingFinanceCommitment.name}}</div>
              </cell>
            </div>
          }
        </div>
      }
      @if (summary.asset && summary.asset.assetPurpose) {
        <mat-divider [inset]="true"></mat-divider>
      }
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <cell>
            <div label>Doc Fee financed</div>
            <div body>{{booleanToYesNo(summary.docFee)}}</div>
          </cell>
        </div>
        @if (summary.brokerOriginationFee) {
          <div class="col-lg-3 col-md-12">
            <cell>
              <div label>Broker origination fee (excl GST)</div>
              <div body>{{summary.brokerOriginationFee | looseCurrency}}</div>
            </cell>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <!-- DIRECTOR -->
      <div class="mt-3">
        <directors-summary [directors]="_directors"></directors-summary>
      </div>
      <!-- TRUSTEE -->
      <div class="mt-3">
        <trustees-summary [trustees]="_trustee"></trustees-summary>
      </div>
      <!-- PARTNER -->
      <div class="mt-3">
        <partners-summary [partners]="_partners"></partners-summary>
      </div>
      <!-- MEMBER -->
      <div class="mt-3">
        <members-summary [members]="_members"></members-summary>
      </div>
      <!-- SOLE TRADER -->
      <div class="mt-3">
        <soletraders-summary [soletraders]="_soleTraders"></soletraders-summary>
      </div>
      <!-- GUARANTOR -->
      <div class="mt-3">
        <guarantors-summary [guarantors]="summary.guarantors"></guarantors-summary>
      </div>
      <div class="mt-3">
        <primary-contact-summary [primaryContact]="summary.primaryContact"></primary-contact-summary>
      </div>
      <div class="row">
        @if (summary.brokerFlowId) {
          <div class="col-lg-6 col-md-12 mt-3">
            <div class="mat-h4-custom">Brokerflow Document ID</div>
            <div body class="mb-3">{{summary.brokerFlowId}}</div>
          </div>
        }
      </div>
      <mat-divider [inset]="true"></mat-divider>
      <div class="mt-3">
        <references-summary [references]=" summary.references"></references-summary>
      </div>
      @if (summary.applicationNotes) {
        <div class="row">
          <div class="col-12 mt-3">
            <div class="mat-h3">Application notes</div>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-6 col-md-12 word-wrap">
          {{summary.applicationNotes}}
        </div>
      </div>
    </div>
  }
</div>
