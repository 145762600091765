<div class="reset-password-page">
  @if (!valid) {
    <div>
      <message-box title="Error" type="danger">
        {{error}}
      </message-box>
    </div>
  }
  @if (valid) {
    <div>
      <ng-container *ngTemplateOutlet="currentTemplate"></ng-container>
    </div>
  }
</div>

<ng-template #resetPasswordTemplate>
  <div class="reset-password-form-template">
    <div class="mat-h3 text-align-center">Set up your password</div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroup" >
      <div fxFlex="1 0 auto" class="form-field">
        <confirm-password-admin [formControl]="formControlConfirmPassword" ></confirm-password-admin>
      </div>
      <div fxFlex="1 0 auto" class="form-button">
        <button mat-stroked-button color="primary" [disabled]="formGroup.invalid" (click)="onSavePassword($event)">Save my password</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #confirmationTemplate>
  <div class="confirmation-template">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center separator-bottom">
      Your password has been set and you can now login by clicking the button below.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="form-button">
      <button fxFlex="1 0 auto" mat-stroked-button color="primary" (click)="onLoginAgain($event)">Login Now</button>
    </div>
    <div fxLayoutAlign="center center" class="form-button">
      <a href='https://play.google.com/store/apps/details?id=au.com.grow.mobileapp&hl=en_AU&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style="padding-left: 15px;"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style="height: 70px; width: auto;"></a>
      <a href="https://apps.apple.com/au/app/dynamoney/id1579277086?itsct=apps_box_badge&amp;itscg=30200" style="padding: 11.2px 6px"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1690848000" alt="Download on the App Store" style="display: block; height: 47.6px"></a>
    </div>
  </div>
</ng-template>

<ng-template #portalConfirmationTemplate>
  <div class="confirmation-template">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center separator-bottom">
      Your password has been set and you can now login by clicking the button below.
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" class="form-button">
      <button fxFlex="1 0 auto" mat-stroked-button color="primary" (click)="onLoginAgain($event)">Login Now</button>
    </div>
  </div>
</ng-template>

<ng-template #mobileConfirmationTemplate>
  <div class="confirmation-template">
    <div class="mat-h3 text-align-center">All set!</div>
    <div class="mat-body form-text text-align-center separator-bottom">
      Your password has been set and you can now login by clicking the button below.
    </div>
    <div fxLayoutAlign="center center" class="form-button">
      <a href='https://play.google.com/store/apps/details?id=au.com.grow.mobileapp&hl=en_AU&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style="padding-left: 15px;"><img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style="height: 70px; width: auto;"></a>
      <a href="https://apps.apple.com/au/app/dynamoney/id1579277086?itsct=apps_box_badge&amp;itscg=30200" style="padding: 11.2px 6px"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1690848000" alt="Download on the App Store" style="display: block; height: 47.6px"></a>
    </div>
  </div>
</ng-template>