import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { UntilDestroy } from '@ngneat/until-destroy';
import { User} from '@portal-workspace/grow-shared-library';
import { Router } from '@angular/router';
import moment from 'moment';
import {
  ApplicationService,
} from '../../service/application.service';
import {
  ApplicationMonthlyStatistics,
  ApplicationSort,
  ApplicationStatistics, Article, GetNewsResult,
  InProgressApplicationStatistics, SettledApplicationStatistics, SimplifiedApplication,
  ApplicationTypes,
} from '@portal-workspace/grow-shared-library';
// @ts-ignore
import { Subscription } from 'rxjs';
import {getCssVariableContent, hexToRgb, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {
  navigationUrlForAssetFinance,
  navigationUrlForBusinessFinance,
  navigationUrlForConsumerAssetFinance,
  navigationUrlForInsurancePremium,
  navigationUrlForBusinessOverdraft, navigationUrlForApplication,
  navigationUrlForCorporateLoan
} from '../../service/navigation-urls';
import { ApplicationDialogService, getUser } from '@portal-workspace/grow-ui-library';
import { ApplicationProgressInfo } from '@portal-workspace/grow-shared-library';
import { WidgetDetails, WidgetTitles } from '@portal-workspace/grow-shared-library';
import {PaginablePayloadApiResponse, PayloadApiResponse} from '@portal-workspace/grow-shared-library';
import {loadingFor} from '@ngneat/loadoff';
import {environment} from '../../../environments/environment';
import { ApplicationTilesComponent } from '@portal-workspace/grow-ui-library';
import { DashboardProgressTrackComponent } from '@portal-workspace/grow-ui-library';
import { DashboardNewsComponent } from '@portal-workspace/grow-ui-library';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DashboardTopWidgetComponent } from '@portal-workspace/grow-ui-library';
import { AsyncPipe, SlicePipe } from '@angular/common';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './portal-dashboard.page.html',
    styleUrls: ['./portal-dashboard.page.scss'],
    standalone: true,
    imports: [DashboardTopWidgetComponent, MatButtonToggleModule, FlexModule, MatTabsModule, DashboardNewsComponent, DashboardProgressTrackComponent, ApplicationTilesComponent, AsyncPipe, SlicePipe]
})
export class PortalDashboardPage implements OnInit, AfterViewInit {

  public dynamoneyBlue =  getCssVariableContent('--grow-primary-palette-500'); // '#5D40FF';
  public datasets: (number[])[] = [];
  public data: number[] = [];
  public applicationVolumeChart: any;
  public totalSettlementsChart: any;
  public clicked = true;
  public clicked1 = false;
  public totalApplications: number = 0;
  public totalPending = 0;
  public totalApproved = 0;
  public loggedInUser = getUser()!;
  public allApplications: ApplicationProgressInfo[] = [];
  public thisMonthApplications: number = 0;
  public prevMonthApplications: number = 0;
  public thisMonthApplicationsMonthYearInText: string = '';
  public prevMonthApplicationMonthYearInText: string = '';
  public monthlyAppPercentage: number = 0;
  public monthlyPendingPercentage: number = 0;
  public rollingSettledPercentage: number = 0;
  public monthlyApprovedIncreasePercentage: number = 0;
  public monthlyData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public newsData: Article[] = [];
  public totalMonthlyApplications: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public totalInProgressApps: number = 0;
  public underReviewApps: number = 0;
  public approvedApps: number = 0;
  public settledThisMonth: number = 0;
  public settledLastMonth: number = 0;
  public settledThisMonthMonthYearInText = '';
  public settledLastMonthMonthYearInText = '';
  public rolling30days_approval: number = 0;
  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  totalApprovalRate: number = 0;
  thisMonthAppRate: number = 0;
  prevMonthAppRate: number = 0;
  totalSettled: number = 0;
  chartOptions: any;
  volumeChartStepSize = 50;
  settlementChartStepSize = 1;
  dates: string[] = [];
  subscriptions: Subscription[] = [];
  totalApplicationLoader = loadingFor('TotalApplication');
  inProgressApplicationLoader = loadingFor('InProgressApplication');
  settlementApplicationLoader =  loadingFor('SettlementApplication');
  chartingApplicationLoader = loadingFor('ChartingApplication');
  environment = environment;

  displayGraphFlag: 'application-volume' | 'total-settlement' | null  =  null;

  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private dialogService: ApplicationDialogService
  ) {}

  ngOnInit() {
    setupUntilDestroy(this);
    this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
    this.datasets = [
      [0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ];
    this.data = this.datasets[0];

    this.getNews();
    this.getApplications();  // applications : Progress track
    this.getTotalApplications();//  Applications Section
    this.getInProgressApplications();//  In Progress Section
    this.getSettledApplications();// Settlements & Approval Rate section
    this.getMonthWiseApproved(); // Charts (application volume & total settlements)
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      this.dynamoneyBlue = getCssVariableContent('--grow-primary-palette-500');
      const date1 = moment().format('YYYY-MM-DD');
      const date2 = moment().subtract(11, 'month').format('YYYY-MM-DD');
      this.dates = PortalDashboardPage.dateRange(date2, date1);
      const self = this;
      this.dates.forEach((element: string, index: number) => {
        const arr1 = element.split('-');
        element = self.months[Number(arr1[0]) - 1] + ' ' + arr1[1];
        this.dates[index] = element;
      });

      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        plugins:{
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            },
            grid: {
              display: true,
              borderDash: [5, 5]
            }
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              stepSize: this.volumeChartStepSize,
              beginAtZero: true
            }
          }
        }
      };
      this.displayApplicationVolumeChart();
    });
  }

  private static dateRange(startDate: string, endDate: string): string[] {
    const start      = startDate.split('-');
    const end        = endDate.split('-');
    const startYear  = parseInt(start[0], 10);
    const endYear    = parseInt(end[0], 10);
    const dates      = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1], 10) - 1;
      const startMon = i === startYear ? parseInt(start[1], 10) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        const displayMonth = month;
        dates.push([displayMonth, i].join('-'));
      }
    }
    return dates;
  }

  private static getChartStepSize(array: number[], steps: number, minimumStep: number): number {
    const max = Math.max(...array);
    const min = Math.min(...array);
    if (max < minimumStep) {
      minimumStep = Math.ceil(minimumStep / 2);
    }
    return Math.max(Math.floor((max - min) / (steps * minimumStep)) * minimumStep, minimumStep);
  }

  getMonthWiseApproved() {
    const params = {
      userId : this.loggedInUser.UserId as number,
      role : this.loggedInUser.AccessLevel as string,
      // aggregatorId: this.loggedInUser.AggregatorId as number,
      OriginatorBusinessId: this.loggedInUser.OriginatorBusinessId as number
    };
    this.subscriptions.push(this.applicationService.getApplicationMonthWiseStatistics(params).pipe(
      this.chartingApplicationLoader.ChartingApplication.track(),
    ).subscribe((r: PayloadApiResponse<ApplicationMonthlyStatistics>) => {
      const res: ApplicationMonthlyStatistics = r.payload;
      const date1 = moment().format('YYYY-MM-DD');
      const date2 = moment().subtract(11, 'month').format('YYYY-MM-DD');
      const a = PortalDashboardPage.dateRange(date2, date1);
      const data = res.monthly_settlement;
      const self = this;
      data.forEach((element) => {
        const i = a.indexOf(element.monthNumber + '-' + element.yearNumber);
        this.monthlyData[i] = element.counts;
      });
      if (this.totalSettlementsChart !== undefined) {
        this.settlementChartStepSize = PortalDashboardPage.getChartStepSize(this.monthlyData, 4, 1);
        this.totalSettlementsChart.data.datasets[0].data = this.monthlyData;
        this.totalSettlementsChart.options.scales.y.ticks.stepSize = this.settlementChartStepSize;
        this.totalSettlementsChart.update();
      }

      const totalApplications = res.app_volume;
      totalApplications.forEach((element) => {
        const i = a.indexOf(element.monthNumber + '-' + element.yearNumber);
        this.totalMonthlyApplications[i] = element.counts;
      });
      if (this.applicationVolumeChart !== undefined) {
        this.volumeChartStepSize = PortalDashboardPage.getChartStepSize(this.totalMonthlyApplications, 4, 20);
        this.applicationVolumeChart.data.datasets[0].data = this.totalMonthlyApplications;
        this.applicationVolumeChart.options.scales.y.ticks.stepSize = this.volumeChartStepSize;
        this.applicationVolumeChart.update();
      }
    }));
  }

  getTotalApplications() {
    const params = {
      userId : this.loggedInUser.UserId as number,
      role : this.loggedInUser.AccessLevel as string,
      status: '',
      // aggregatorId: this.loggedInUser.AggregatorId as number,
      OriginatorBusinessId: this.loggedInUser.OriginatorBusinessId as number
    };
    this.subscriptions.push(this.applicationService.getApplicationStatistics(params).pipe(
      this.totalApplicationLoader.TotalApplication.track()
    ).subscribe((r: PayloadApiResponse<ApplicationStatistics>) => {
      const res: ApplicationStatistics = r.payload;
      this.totalApplications = res.totalApplications == null ? 0 : res.totalApplications;
      this.thisMonthApplications = res.thisMonth == null ? 0 : res.thisMonth;
      this.prevMonthApplications = res.prevMonth == null ? 0 : res.prevMonth;
      this.thisMonthApplicationsMonthYearInText = res.thisMonthText;
      this.prevMonthApplicationMonthYearInText = res.prevMonthText;
      if (res.prevLast30days !== 0) {
        this.monthlyAppPercentage = ((res.last30days / res.prevLast30days) * 100) - 100;
      } else {
        if (res.last30days !== 0) {
          this.monthlyAppPercentage = 100;
        }
      }
    }));
  }

  getInProgressApplications() {
    const params = {
      userId : this.loggedInUser.UserId as number,
      role : this.loggedInUser.AccessLevel as string,
      status: 'pending',
      // aggregatorId: this.loggedInUser.AggregatorId as number,
      OriginatorBusinessId: this.loggedInUser.OriginatorBusinessId as number
    };
    this.subscriptions.push(this.applicationService.getInProgressApplicationStatistics(params).pipe(
      this.inProgressApplicationLoader.InProgressApplication.track()
    ).subscribe((r: PayloadApiResponse<InProgressApplicationStatistics>) => {
      const res: InProgressApplicationStatistics = r.payload;
      this.totalInProgressApps = res.total_inprogress;
      this.underReviewApps = res.underreview_apps;
      this.approvedApps = res.approved_apps;
    }));
  }

  getSettledApplications() {
    const params = {
      userId : this.loggedInUser.UserId as number,
      role : this.loggedInUser.AccessLevel as string,
      status: 'approved',
      // aggregatorId: this.loggedInUser.AggregatorId as number,
      OriginatorBusinessId: this.loggedInUser.OriginatorBusinessId as number
    };
    this.subscriptions.push(this.applicationService.getSettledApplicationStatistics(params).pipe(
      this.settlementApplicationLoader.SettlementApplication.track()
    ).subscribe((r: PayloadApiResponse<SettledApplicationStatistics>) => {
      const res: SettledApplicationStatistics = r.payload;
      this.totalSettled = res.settled_apps;
      this.settledThisMonth = res.settled_thisMonth;
      this.settledLastMonth = res.settled_lastMonth;
      this.totalApproved = res.approved_apps;
      this.settledThisMonthMonthYearInText = res.thisMonthText;
      this.settledLastMonthMonthYearInText = res.prevMonthText;

      // Approval Rate section
      if (res.submitted_apps !== 0) {
        this.totalApprovalRate = parseFloat(((res.approved_apps / res.submitted_apps) * 100).toFixed(2));
      } else {
        if (res.approved_apps !== 0) {
          this.totalApprovalRate = 100;
        }
      }
      if (res.submitted_thisMonth !== 0) {
        this.thisMonthAppRate = parseFloat(((res.approved_thisMonth / res.submitted_thisMonth) * 100).toFixed(2));
      } else {
        if (res.approved_thisMonth !== 0) {
          this.thisMonthAppRate = 100;
        }
      }
      if (res.submitted_lastMonth !== 0) {
        this.prevMonthAppRate = parseFloat(((res.approved_lastMonth / res.submitted_lastMonth) * 100).toFixed(2));
      } else {
        if (res.approved_lastMonth !== 0) {
          this.prevMonthAppRate = 100;
        }
      }
      if (res.settled_lastprev30days !== 0) {
        this.rollingSettledPercentage = ((res.settled_last30days / res.settled_lastprev30days) * 100) - 100;
      } else {
        if (res.settled_last30days !== 0) {
          this.rollingSettledPercentage = 100;
        }
      }

      // Settlements section
      let last30days_approvalRate = 0;
      if (res.submitted_last30days !== 0) {
        last30days_approvalRate = (res.approved_last30days / res.submitted_last30days) * 100;
      } else {
        if (res.approved_last30days !== 0) {
          last30days_approvalRate = 100;
        }
      }
      let lastprev30days_approvalRate = 0;
      if (res.submitted_lastprev30days !== 0) {
        lastprev30days_approvalRate = (res.approved_lastprev30days / res.submitted_lastprev30days) * 100;
      } else {
        if (res.approved_lastprev30days !== 0) {
          lastprev30days_approvalRate = 100;
        }
      }
      if (lastprev30days_approvalRate !== 0) {
        this.rolling30days_approval = ((last30days_approvalRate / lastprev30days_approvalRate) * 100) - 100;
      } else {
        if (last30days_approvalRate !== 0) {
          this.rolling30days_approval = 100;
        }
      }
    }));
  }

  getNews() {
    this.applicationService.getNews().subscribe((r: PayloadApiResponse<GetNewsResult>) => {
      const res: GetNewsResult = r.payload;
      this.newsData = res.articles.slice(0, 9);
    });
  }

  getTopWidgetDetails(index: 1 | 2 | 3 | 4) {
    switch (index) {
      case 1:
        return {
          title:'Applications' as WidgetTitles,
          col1: {
            title: 'Total',
            value: this.totalApplications ?? 0,
          },
          col2: {
            title: this.prevMonthApplicationMonthYearInText,
            value: this.prevMonthApplications ?? 0,
          },
          col3: {
            title: this.thisMonthApplicationsMonthYearInText,
            value: this.thisMonthApplications ?? 0
          },
          percentage: this.monthlyAppPercentage ?? 0,
          icon: 'view-grid-outline',
          iconColor: 'blue'
        } as WidgetDetails;
      case 2:
        return {
          title:'In Progress' as WidgetTitles,
          col1: {
              title: 'Total',
              value: this.totalInProgressApps ?? 0,
          },
          col2: {
              title: 'Approved',
              value: this.approvedApps ?? 0,
          },
          col3: {
              title: 'Under Review',
              value: this.underReviewApps ?? 0,
          },
          icon: 'progress-clock',
          iconColor: 'orange'
        } as WidgetDetails;
      case 3:
        return {
          title:'Settlements' as WidgetTitles,
          col1: {
            title: 'Total',
            value: this.totalSettled ?? 0,
          },
          col2: {
            title: this.settledLastMonthMonthYearInText,
            value: (this.settledLastMonth ?? 0),
          },
          col3: {
            title: this.settledThisMonthMonthYearInText,
            value: (this.settledThisMonth ?? 0),
          },
          percentage: this.rollingSettledPercentage ?? 0,
          icon: 'cash-multiple',
          iconColor: 'green'
        } as WidgetDetails;
      case 4:
        return {
          title:'Approval Rate' as WidgetTitles,
          col1: {
            title: 'Historical',
            value: this.totalApprovalRate ? this.totalApprovalRate+'%' : 0
          },
          col2: {
            title: this.settledLastMonthMonthYearInText,
            value: this.prevMonthAppRate ? this.prevMonthAppRate+'%' : 0
          },
          col3: {
            title: this.settledThisMonthMonthYearInText,
            value: this.thisMonthAppRate ? this.thisMonthAppRate+'%' : 0
          },
          percentage: this.rolling30days_approval ?? 0,
          icon: 'cash-multiple',
          iconColor: 'purple'
        } as WidgetDetails;
    }
  }
  displayApplicationVolumeChart() {
    if (this.displayGraphFlag != 'application-volume') {
      this.displayGraphFlag = 'application-volume';
      const chartApplicationVolume = document.getElementById('chart-application-volume') as any;
      if (chartApplicationVolume) {
        const gradientStop0 = hexToRgb(this.dynamoneyBlue, 0.1);
        const gradientStop1 = hexToRgb(this.dynamoneyBlue, 0);
        const ctx = chartApplicationVolume.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, gradientStop0);
        gradient.addColorStop(1, gradientStop1);
        // gradient.addColorStop(0, 'rgba(93, 64, 255,0.1)');
        // gradient.addColorStop(1, 'rgba(93, 64, 255,0)');
        this.applicationVolumeChart = new Chart(chartApplicationVolume, {
          type: 'line',
          options: this.chartOptions,
          data: {
            labels: this.dates,
            datasets: [
              {
                backgroundColor: gradient,
                label: 'Performance',
                fill: true,
                borderColor: this.dynamoneyBlue,
                cubicInterpolationMode: 'monotone',
                tension: 0.4,
                pointBackgroundColor: this.dynamoneyBlue,
                //pointRadius: 0,
                data: this.totalMonthlyApplications
              }
            ]
          }
        })
      }
    }
  }

  displayTotalSettlementsChart() {
    if (this.displayGraphFlag != 'total-settlement') {
      this.displayGraphFlag = 'total-settlement';
      const chartTotalSettlement = document.getElementById('chart-total-settlements') as any;
      if (this.loggedInUser.approval && this.loggedInUser.approval === 1 && chartTotalSettlement) {
        this.totalSettlementsChart = new Chart(chartTotalSettlement, {
          type: 'bar',
          options: this.chartOptions,
          data: {
            labels: this.dates,
            datasets: [
              {
                label: 'Sales',
                borderColor: this.dynamoneyBlue,
                backgroundColor: this.dynamoneyBlue,
                data: this.monthlyData
              }
            ]
          }
        });
      }
    }
  }

  getApplications() {
    const params = {
      page: { limit: 10, offset: 0 },
      sorts: [{ prop: 'CreateTime', dir: 'DESC' }] as ApplicationSort,
      filter: ''
    };

    this.subscriptions.push(this.applicationService.getApplicationsByUser(params).subscribe((res: PaginablePayloadApiResponse<SimplifiedApplication>) => {
      const records = res.payload;
      if (records && records?.length) {
        records.forEach((application ) => {
          const stageName = this.applicationService.getApplicationStage2(application.StageName);
          const status = this.applicationService.getApplicationStatus2(application.StageName, application.SalesforceId);
          this.allApplications.push({
            ApplicationID: application.ApplicationId,
            BrokerAppID: application.BrokerAppId,
            LoanAmount: application.PricingDetails?.LoanAmount,
            CompanyName: application.CompanyName,
            // InteflowStatus: application.InteflowStatus,
            StageName: stageName,
            Status: status
          })
        })
      }
    }))
  }

  updateOptions() {
    this.applicationVolumeChart.data.datasets[0].data = this.data;
    this.applicationVolumeChart.update();
  }

  async navigationHandler(event: ApplicationTypes) {
    await this.applicationService.applicationTilesComponentNavigationHandler(event);
  }

  onClickApplication(applicationId: string) {
    this.router.navigate(navigationUrlForApplication(applicationId,'app'));
  }

  get isDealer() {
    return this.loggedInUser?.priviledges?.includes('dealer');
  }
}
