<div class="modify-file-tags-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col mat-h4-custom title">
        <span class="mdi mdi-file-document-outline cursor-pointer"></span> {{fileName}}
      </div>
    </div>

    <div class="row separator-top">
      <div class="col selected-chips">
        <div class="title">Select those document types your file contains</div>
        <mat-card appearance="outlined" class="card">
          <mat-card-content>
            <mat-chip-listbox #chipList aria-label="Tag selection">
              @for (tag of tags; track tag) {
                <mat-chip-option
                  (removed)="remove(tag)">
                  {{tag.type}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-option>
              }
            </mat-chip-listbox>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    @if (showMaxTagErrorMessage) {
      <div class="row separator-top">
        <div class="col error-message">
          Maximum 10 tags are allowed for each document.
        </div>
      </div>
    }

    <div class="row separator-top">
      <div class="col all-chips">
        <mat-chip-listbox aria-label="Tag selection">
          @for (tag of allTags; track tag) {
            <mat-chip-option
              (click)="addTag(tag)">
              {{tag.type}}
            </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onSubmit(false)">Cancel</button>
        @if (tags) {
          <button mat-flat-button color="primary" [disabled]="!(tags.length)" (click)="onSubmit(true)">Save Changes</button>
        }
      </div>
    </div>
  </mat-dialog-content>
</div>
