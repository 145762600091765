import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  BusinessNumberSearchResult,
  Application,
  ApplicationDocumentsData,
  ApplicationDocumentsTableData,
  ApplicationMonthlyStatistics,
  ApplicationSortTypes,
  ApplicationStatistics,
  ApplicationTypes,
  AssetFinanceApplication,
  AzureStorageDocument,
  BankStatement,
  BankStatementsAnalysisData,
  BrokerOfUser,
  DocumentTag,
  DocumentWorklists,
  DocumentWorklistTableData,
  FetchABRdataByABNResult,
  filesToBase64Files,
  GeoLocation,
  getApplicationStage2,
  getApplicationStatus2,
  GetNewsResult,
  getOrgTypeByEntityText,
  GroupedDocument,
  InProgressApplicationStatistics,
  Metadata,
  Quote,
  RatecardDetails,
  SaveApplicationData,
  SaveApplicationResult,
  SaveQuoteResult,
  SearchCompanyByABNResult,
  SearchCompanyResult,
  ServiceabilityCalculationInput,
  ServiceabilityCalculationResult,
  SettledApplicationStatistics,
  SimplifiedApplication,
  stringifyJSON,
  SubmitApplicationResult,
  UpdateApplicationData,
  User,
  UserSelectionValue,
  GetRateCardDetailsFn,
  Disbursement,
  SyncSupplierBankDetailsBody,
  SyncDisbursementBody,
  BpayValidationData,
  BpayValidationResult,
  UpdateApplicationStageBody,
  SettleLoanBody,
  SyncPrivateSellerBankDetailsBody,
  DirectCreditInstitutionSearchResult,
  SyncDepositPaidToBody,
  LocalBankAccountDetailValue,
  FetchRbaRateResult,
  GenerateApprovalNoticeBody,
  GenerateContractResponse,
  SendApprovalNoticeEmailBody,
  DeleteContractBody,
  CopyApplicationBody,
  ApplicationOwner,
  SfAccountDetails,
  TaxInvoiceDetails,
  GetRbaRateFn,
  LvrCalculatorValue,
  LvrCalculatorInput,
  DscrCalculatorValue,
  DscrCalculatorInput,
  VerimotoAssetInspectionData,
  VerimotoCreateInspectionResponse,
  PpsrAsset,
  VerimotoInspectionTableDataWithInspectionDetails,
  UpdateApplicationFn,
  UpdateApplicationSfFn,
  AzureStorageContainer,
  GetBankStatementsAnalysisFn,
  PrivacyConsent,
  IndividualWithResult,
  PrivacyConsentStatus,
  UserOmitPriviledges,
  PaginationInfo,
  BpayGetBillerByBillerCodeResult,
  BpayValidatePaymentResult,
  SaveBsaCalculatorBody,
  BsaCalculator,
  GetBasiqCustomerMappingFn, BasiqCompanyDetailsBody, GetBasiqStatementDataForCompanyFn, BasiqUserCustomerMapping, GetBankStatementAndBasiqDataStatusFn, BankstatementAndBasiqDataStatus,
  ConsumerDscrCalculatorValue, ConsumerDscrCalculatorInput, UpdateLenderBody, BsaLender, AddLenderBody, SendAdminPrivacyConsentEmailBody, AdminPrivacyConsent, DscrCalculatorHistory,
  OverdraftProductApplicationStatistics,
  ConsumerFinanceDscrServiceabilityCalculationInput, ConsumerFinanceDscrServiceabilityCalculationResult, BasiqReferenceType, BankStatementData,
  DocumentReviewEmailBody,
  SendConsumerQuotationData,
  DownloadConsumerQuotationData,
} from '@portal-workspace/grow-shared-library';
import { ApiResponse, Base64File, OriginatorBusiness, PaginablePayloadApiResponse, PayloadApiResponse } from '@portal-workspace/grow-shared-library';
import { combineLatest, Observable, Observer, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  BusinessNumberSearchFn,
  AggregatorSearchFn,
  PortalHotToastService, ApplicationDialogService, httpOptionsForDirectSale,
  toPaginationInfo,
  paginationUrl
} from '@portal-workspace/grow-ui-library';
import { AggregatorSearchComponentValue, } from '@portal-workspace/grow-shared-library';
import { DEFAULT_FILTER, DEFAULT_LIMIT, DEFAULT_OFFSET, DEFAULT_SORTS } from '@portal-workspace/grow-shared-library';
import moment from 'moment'
import {
  httpOptions,
} from '@portal-workspace/grow-ui-library';
import { BusinessSearchFn } from '@portal-workspace/grow-ui-library';
import {
  navigationUrlForAssetFinance, navigationUrlForAssetProductSelector, navigationUrlForBusinessFinance, navigationUrlForBusinessOverdraft, navigationUrlForConsumerAssetFinance,
  navigationUrlForCorporateLoan, navigationUrlForDirectSaleAssetFinance, navigationUrlForDirectSaleBusinessLoans, navigationUrlForDirectSaleBusinessOverdraft, navigationUrlForInsurancePremium,

} from './navigation-urls';
import { Router } from '@angular/router';
import { Response } from 'request';

const URL_GET_APPLICATIONS = () => `${environment.api2Host}/api2/applications-list-by-user2`;

const URL_GET_APPLICATION = () => `${environment.api2Host}/api2/fetch-application`;
const URL_GET_APPLICATION_FOR_DIRECT_SALE = () => `${environment.api2Host}/api2/direct-sale/fetch-application`;
const URL_SEARCH_COMPANY_BY_NAME = (name: string) => `${environment.api2Host}/api2/search-company-by-name/${name}`;
const URL_SEARCH_BROKER_BY_NAME2 = (name: string) => `${environment.api2Host}/api2/originator_business/getOriginators2/${name}`;
const URL_GET_RATECARD_DETAILS = (userId: number, type: ApplicationTypes) => `${environment.api2Host}/api2/company-rate-card/${userId}/${type}`;
const URL_GET_DEFAULT_RATECARD_DETAILS = (type: ApplicationTypes) => `${environment.api2Host}/api2/default-rate-card/${type}`;
const URL_GET_IP_ADDRESS = () => `https://api.ipify.org/?format=json`;
const URL_GET_BROKER_FOR_USER = (userId: number) => `${environment.api2Host}/api2/getApplicationBrokerbyUserid/${userId}`;

const URL_SAVE_APPLICATION = () => `${environment.api2Host}/api2/store-application`; // post
const URL_SAVE_APPLICATION_FOR_DIRECT_SALE = () => `${environment.api2Host}/api2/direct-sale/store-application`; // post
const URL_UPDATE_APPLICATION = () => `${environment.api2Host}/api2/update-application2`; // put
const URL_UPDATE_APPLICATION_FOR_DIRECT_SALE = () => `${environment.api2Host}/api2/direct-sale/update-application2`; // put
const URL_UPDATE_APPLICATION_SF = () => `${environment.api2Host}/api2/update-application-sf`; // put
const URL_SUBMIT_APPLICATION = () => `${environment.api2Host}/api2/submit-to-inteflow2`; // post  (asset, business-loan, commercial)
const URL_SUBMIT_APPLICATION_FOR_DIRECT_SALE = () => `${environment.api2Host}/api2/direct-sale/submit-to-inteflow2`; // post  (asset, business-loan, commercial)
const URL_SUBMIT_TRADE_FINANCE_APPLICATION = () => `${environment.api2Host}/api2/TradeFinance2`; // post
const URL_SUBMIT_CORPORATE_LOAN_APPLICATION = () => `${environment.api2Host}/api2/CorporateLoan2`; // post
const URL_SUBMIT_INSURANCE_PREMIUM_APPLICATION = () => `${environment.api2Host}/api2/IPF2`; // post
const URL_SUBMIT_CONSUMER_APPLICATION = () => `${environment.api2Host}/api2/consumer2`; // post
// const URL_UPDATE_APPLICATION_DOCUMENT = (applicationId: number) => `${environment.api2Host}/api2/update-application-documents2/${applicationId}`; // post
const URL_UPDATE_APPLICATION_SUBMITTER = () => `${environment.api2Host}/api2/update-application-submitter`; // posts
const URL_UPDATE_APPLICATION_ADDITIONAL_CORRESPONDENT = () => `${environment.api2Host}/api2/update-application-additional-correspondent`; // post
const URL_SAVE_QUOTE = () => `${environment.api2Host}/api2/save-quote`; // post

const URL_UPDATE_APPLICATION_DOCUMENT_TO_AZURE_STORAGE = (applicationId: number) => `${environment.api2Host}/api2/document/update-application-document-to-azure-storage/${applicationId}`; // post
const URL_UPDATE_APPLICATION_DOCUMENT_TO_AZURE_STORAGE_FOR_DIRECT_SALE = (applicationId: number) => `${environment.api2Host}/api2/direct-sale/update-application-document-to-azure-storage/${applicationId}`; // post
const URL_LIST_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE = (applicationId: number) => `${environment.api2Host}/api2/document/list-application-document-from-azure-storage/${applicationId}`; // get
const URL_LIST_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE_DIRECT_SALE = (applicationId: number) => `${environment.api2Host}/api2/direct-sale/list-application-document-from-azure-storage/${applicationId}`; // get
const URL_DOWNLOAD_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE = (applicationId: number) => `${environment.api2Host}/api2/document/download-application-document-from-azure-storage/${applicationId}`; // get
const URL_DOWNLOAD_ALL_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE = (applicationId: number, customerName: string, userId: number) => `${environment.api2Host}/api2/document/download-all-application-document-from-azure-storage/${applicationId}/${customerName}/${userId}`; // get
const URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/update-document-metadata-in-azure-storage`; // post
const URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE_FOR_DIRECT_SALE = () => `${environment.api2Host}/api2/direct-sale/update-document-metadata-in-azure-storage`; // post
const URL_UPDATE_DOCUMENT_TAGS_IN_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/update-document-tags-in-azure-storage`; // post
const URL_LIST_APPLICATION_DOCUMENTS_BY_COMPANY_AND_CONSUMER = () => `${environment.api2Host}/api2/document/list-application-documents-by-company-and-consumer`; // post
const URL_GET_DOCUMENTS_FROM_CACHE = () => `${environment.api2Host}/api2/document/get-document-cache`; // get

const URL_DOWNLOAD_ALL_DOCUMENT_FOR_COMPANY_AND_CONSUMER = (type: 'consumer' | 'company') => `${environment.api2Host}/api2/document/download-all-document-for-company/${type}`; // get
const URL_DOWNLOAD_ALL_DOCUMENT = (type: 'consumer' | 'company') => `${environment.api2Host}/api2/document/download-all-document-in-zip/${type}`; // get
const URL_DELETE_DOCUMENT_FROM_AZURE_STORAGE = () => `${environment.api2Host}/api2/document/delete-document-from-azure-storage`; // post
const URL_UPDATE_APPLICATION_DOCUMENT_IN_DB = () => `${environment.api2Host}/api2/document/update-application-documents-in-db`; // post
const URL_SEND_APPLICATION_DOCUMENT_NOTIFICATION = () => `${environment.api2Host}/api2/document/send-application-document-notification`; // post
const URL_GET_DOCUMENT_WORKLIST = () => `${environment.api2Host}/api2/get-document-worklist`; // get
const URL_UPDATE_DOCUMENT_WORKLIST = () => `${environment.api2Host}/api2/update-document-worklist`; // post
const URL_CREATE_DOCUMENT_WORKLIST = () => `${environment.api2Host}/api2/create-document-worklist`; // post
const URL_COMPLETE_DOCUMENT_WORKLIST = (applicationId: number) => `${environment.api2Host}/api2/complete-document-worklist/${applicationId}`; // post

const URL_GET_ORIGINATOR_BUSINESS_BY_ID = (originatorBusinessId: number) => `${environment.api2Host}/api2/originator_business/${originatorBusinessId}`;
const URL_GET_APPLICATION_MONTHWISE_STATISTICS = () => `${environment.api2Host}/api2/get-approved-monthwise`;
const URL_GET_APPLICATION_STATISTICS = () => `${environment.api2Host}/api2/get-application-stats`;
const URL_GET_INPROGRESS_APPLICATION_STATISTICS = () => `${environment.api2Host}/api2/get-inprogress`;
const URL_GET_OVERDRAFT_PRODUCT_TOTAL_BALANCE_STATISTICS = () => `${environment.api2Host}/api2/get-overdraft-product-total-balance`;
const URL_GET_SETTLED_APPLICATION_STATISTICS = () => `${environment.api2Host}/api2/get-settled-application-stats`;
const URL_GET_NEWS = () => `${environment.api2Host}/api2/getNews`;
const URL_GET_BANKSTATEMENTS_ANALYSIS = () => `${environment.api2Host}/api2/bankstatement/analysis`;
const URL_GET_BANK_STATEMENT_AND_BASIQ_DATA_STATUS = (reference: string, type: BasiqReferenceType) => `${environment.api2Host}/api2/get-bank-statement-and-basiq-data-status/${reference}/${type}`;
const URL_GET_BASIQ_CUSTOMER_MAPPING_BY_ABN = (reference: string) => `${environment.api2Host}/api2/basiq-customer-mapping-by-reference/${reference}`;
const URL_GET_BASIQ_STATEMENT_DATA_FOR_COMPANY = () => `${environment.api2Host}/api2/basiq-statement-data-for-company`;

const URL_SERVICEABILITY_CALCULATION = () => `${environment.api2Host}/api2/serviceability-calculation`; // post
const URL_CREATE_NEW_DISBURSEMENT = () => `${environment.api2Host}/api2/create-new-disbursement`;
const URL_UPDATE_DISBURSEMENT = () => `${environment.api2Host}/api2/update-disbursement`;
const URL_GET_DISBURSEMENT_BY_APPLICATION_ID = (applicationId: number) => `${environment.api2Host}/api2/get-disbursement-by-application/${applicationId}`;
const URL_SYNC_DISBURSEMENT_TO_SF = () => `${environment.api2Host}/api2/sync-disbursement-to-sf`;
const URL_SYNC_BANK_DETAILS_TO_SF = () => `${environment.api2Host}/api2/sync-bank-details-to-sf`;
const URL_SYNC_PRIVATE_SELLER_BANK_DETAILS_TO_SF = () => `${environment.api2Host}/api2/sync-private-seller-bank-details-to-sf`;
const URL_BUSINESS_NUMBER_SEARCH = (acn: string) => `${environment.api2Host}/api2/business-number-search/${acn}`;  // get
const URL_GET_BPAY_BILLER_NAME_BY_BILLERCODE = (billerCode: string) => `${environment.api2Host}/api2/bpay/get-biller/${billerCode}`; //get
const URL_VALIDATE_BPAY = () => `${environment.api2Host}/api2/bpay/validate-payment`;
const URL_UPDATE_APPLICATION_STAGE_IN_SF = () => `${environment.api2Host}/api2/update-application-stage-in-sf`; // post
const URL_SETTLE_LOAN = () => `${environment.api2Host}/api2/settle-loan`; // post
const URL_GET_INSTITUTION_NAME = (bsb: string) => `${environment.api2Host}/api2/institution-name/${bsb}`; //get
const URL_SYNC_DEPOSIT_PAID_TO_SF = () => `${environment.api2Host}/api2/sync-disbursement-deposit-paid-to-sf`; // post
const URL_GET_BANK_DETAILS_FROM_OPPORTUNITY_SF = (salesforceId: string) => `${environment.api2Host}/api2/get-bank-details-from-opportunity-sf/${salesforceId}`; //get
const URL_GENERATE_APPROVAL_NOTICE = () => `${environment.api2Host}/api2/generate-approval-notice`; // post
const URL_SEND_APPROVAL_NOTICE_EMAIL = () => `${environment.api2Host}/api2/send-approval-notice-email`; // post
const URL_SEND_DOCUMENT_REVIEW_EMAIL = () => `${environment.api2Host}/api2/send-document-review-email`; // post
const URL_DELETE_APPROVAL_NOTICE_FILE = () => `${environment.api2Host}/api2/delete-approval-notice-file`; // post
const URL_EXTRACT_TAX_INVOICE = () => `${environment.api2Host}/api2/extract-tax-invoice`; // post
const URL_GET_LVR_CALCULATOR_VALUE = (applicationId: number) => `${environment.api2Host}/api2/application/${applicationId}/lvr`; // get
const URL_UPDATE_LVR_CALCULATOR_VALUE = (applicationId: number): string => `${environment.api2Host}/api2/application/${applicationId}/lvr/save`; // post
const URL_GET_DSCR_CALCULATOR_VALUE = (applicationId: number) => `${environment.api2Host}/api2/application/${applicationId}/dscr`; // get
const URL_GET_CONSUMER_DSCR_CALCULATOR_VALUE = (applicationId: number) => `${environment.api2Host}/api2/application/${applicationId}/consumer-dscr`; // get
const URL_UPDATE_DSCR_CALCULATOR_VALUE = (applicationId: number): string => `${environment.api2Host}/api2/application/${applicationId}/dscr/save`; // post
const URL_UPDATE_CONSUMER_DSCR_CALCULATOR_VALUE = (applicationId: number): string => `${environment.api2Host}/api2/application/${applicationId}/consumer-dscr/save`; // post
const URL_CONSUMER_FINANCE_DSCR_SERVICEABILITY_CALCULATION = (): string => `${environment.api2Host}/api2/consumer-finance-dscr-calculation`; // post
const URL_DOWNLOAD_VERIMOTO_REPORT = (inspectionId: number) => `${environment.api2Host}/api2/download-verimoto-report/${inspectionId}`; // get
const URL_DOWNLOAD_DOCUMENT_FROM_AZURE = () => `${environment.api2Host}/api2/download-document-from-azure`;
const URL_SEND_ID_VERIFY_LINK = () => `${environment.api2Host}/api2/rekognition/send-id-verify-link`;
const URL_BYPASS_FACE_COMPARE = (identityVerificationId: number) => `${environment.api2Host}/api2/rekognition/bypass-face-compare/${identityVerificationId}`;
const URL_DELETE_IDENTITY_VERIFICATION = (identityVerificationId: number) => `${environment.api2Host}/api2/rekognition/delete-identity-verification/${identityVerificationId}`;
const URL_GET_PRIVACY_CONSENT_BY_ID = (id: number) => `${environment.api2Host}/api2/privacy-consent/${id}`;
const URL_SEND_PRIVACY_CONSENT_EMAIL = () => `${environment.api2Host}/api2/send-privacy-consent-email`;
const URL_UPDATE_PRIVACY_CONSENT_STATUS = () => `${environment.api2Host}/api2/update-privacy-consent-status`;
const URL_GET_PRIVACY_CONSENT_DOC = () => `${environment.api2Host}/api2/privacy-consent-doc`;
const URL_GET_BSA_LENDER_LIST = () => `${environment.api2Host}/api2/bsa-lender-list`;
const URL_GET_BSA_EXCLUDED_LENDER_LIST = () => `${environment.api2Host}/api2/bsa-excluded-lender-list`;
const URL_UPDATE_BSA_EXCLUDED_LENDER = () => `${environment.api2Host}/api2/bsa-update-excluded-lender`;
const URL_UPDATE_BSA_LENDER = () => `${environment.api2Host}/api2/bsa-update-lender`;
const URL_DELETE_BSA_LENDER = (id: number) => `${environment.api2Host}/api2/bsa-delete-lender/${id}`;
const URL_DELETE_BSA_EXCLUDED_LENDER = (id: number) => `${environment.api2Host}/api2/bsa-delete-excluded-lender/${id}`
const URL_ADD_BSA_LENDER = () => `${environment.api2Host}/api2/bsa-add-lender`;
const URL_ADD_BSA_EXCLUDED_LENDER = () => `${environment.api2Host}/api2/bsa-add-excluded-lender`;
const URL_BSA_GET_CALCULATOR = (applicationId: number) => `${environment.api2Host}/api2/bsa-calculator/${applicationId}`;
const URL_BSA_SAVE_CALCULATOR = () => `${environment.api2Host}/api2/bsa-calculator`;
const URL_SEND_ADMIN_PRIVACY_CONSENT = () => `${environment.api2Host}/api2/send-admin-privacy-consent-email`;
const URL_UPDATE_ADMIN_PRIVACY_CONSENT_STATUS = () => `${environment.api2Host}/api2/update-admin-privacy-consent-status`;
const URL_GET_ADMIN_PRIVACY_CONSENT = (id: number) => `${environment.api2Host}/api2/admin-privacy-consent/${id}`;
const URL_BSA_GET_DSCR_CALCULATOR_HISTORY = (applicationId: number) => `${environment.api2Host}/api2/dscr-calculator-history/${applicationId}`;

// NOTE: not used
// const stringifyApplicationData = <T extends SaveApplicationData>(data: T): any => {
//   const dbData = {...data};
//   // dbData.IP = stringifyJSON(data.IP) as any;
//   dbData.ApplicantLocation = stringifyJSON(data.ApplicantLocation) as any;
//   dbData.AppInfo = stringifyJSON(data.AppInfo) as any;
//   dbData.AssetSpec = stringifyJSON(data.AssetSpec) as any;
//   dbData.CommercialEntities = stringifyJSON(data.CommercialEntities) as any;
//   dbData.Individuals = stringifyJSON(data.Individuals) as any;
//   dbData.Reference = stringifyJSON(data.Reference) as any;
//   dbData.PricingDetails = stringifyJSON(data.PricingDetails) as any;
//   dbData.Contacts = stringifyJSON(data.Contacts) as any;
//   dbData.CompanyDetails = stringifyJSON(data.CompanyDetails) as any;
//   dbData.Policies = stringifyJSON(data.Policies) as any;
//   dbData.AuthorisedSignatory = stringifyJSON(data.AuthorisedSignatory) as any;
//   dbData.Expense = stringifyJSON(data.Expense) as any;
//   dbData.DocumentTypes = stringifyJSON(data.DocumentTypes) as any;
//   return dbData;
// }
const URL_FETCH_RBA_RATES = () => `${environment.api2Host}/api2/getLatestRbaRate`; //get
const URL_COPY_APPLICATION = () => `${environment.api2Host}/api2/copy-application`; // post
const URL_GET_APPLICATION_OWNER = (salesforceId: string) => `${environment.api2Host}/api2/get-application-owner/${salesforceId}`; //get
const URL_GET_APPLICATION_BY_SALESFORCE_ID = (salesforceId: string) => `${environment.api2Host}/api2/get-application-by-salesforce-id/${salesforceId}`; //get
const URL_REFRESH_BANK_STATEMENT = (abn: string) => `${environment.api2Host}/api2/refresh-bank-statement/${abn}`; //get
const URL_GET_ACCOUNT_DETAILS_FROM_SF = (salesforceId: string) => `${environment.api2Host}/api2/get-account-details-from-sf/${salesforceId}`; // get
const URL_DELETE_APPLICATION = (applicationId: number) => `${environment.api2Host}/api2/application/delete/${applicationId}`; //delete
const URL_ALL_APPLICATION_SEARCH = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/search-applications`, paginationInfo); //post
const URL_CREATE_ASSET_INSPECTION = () => `${environment.api2Host}/api2/create-asset-inspection`; //post
const URL_GET_ASSET_INSPECTIONS_FOR_APPLICATION = (applicationId: number) => `${environment.api2Host}/api2/asset-inspections/${applicationId}`; //get
const URL_GET_ACTIVE_CREDIT_USERS = () => `${environment.api2Host}/api2/get-all-active-credit-users`; //get
const URL_SEND_DIRECT_SALE_DRAFT_NOTIFICATION = () => `${environment.api2Host}/api2/direct-sale/send-direct-sale-draft-notification`; // post
const URL_UPLOAD_BANK_STATEMENTS = (applicationId: number) => `${environment.api2Host}/api2/document/upload-bank-statements/${applicationId}`;
const URL_SEND_CONSUMER_QUOTATION = () => `${environment.api2Host}/api2/send-consumer-quotation`;
const URL_GENERATE_CONSUMER_QUOTATION = () => `${environment.api2Host}/api2/generate-consumer-quotation`;

@Injectable()
export class ApplicationService {
  // private extractData(res: any) {
  //   const body = res;
  //   return body || { };
  // }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dialogService: ApplicationDialogService,
    private portalHotToastService: PortalHotToastService) {
  }


  async applicationTilesComponentNavigationHandler(applicationTypes: ApplicationTypes) {
    const applicationSubmissionConfig = environment.applicationSubmission;
    const openDisableSubmissionDialog = () => {
      this.dialogService.openInformationDialog({
        message: `Information`,
        subMessage: applicationSubmissionConfig.disabledMessage,
      });
    }
    switch (applicationTypes) {
      case 'AssetFinance':
        if (applicationSubmissionConfig.AssetFinance) {
          // await this.router.navigate(navigationUrlForAssetFinance());
          await this.router.navigate(navigationUrlForAssetProductSelector());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'CorporateLoans':
        if (applicationSubmissionConfig.CorporateLoans) {
          this.router.navigate(navigationUrlForCorporateLoan());
        } else {
          openDisableSubmissionDialog();
        }
        break;

      case 'BusinessLoans':
        if (applicationSubmissionConfig.BusinessLoans) {
          this.router.navigate(navigationUrlForBusinessFinance());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'InsurancePremium':
        if (applicationSubmissionConfig.InsurancePremium) {
          this.router.navigate(navigationUrlForInsurancePremium());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'Consumer':
        if (applicationSubmissionConfig.Consumer) {
          this.router.navigate(navigationUrlForConsumerAssetFinance());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'BusinessOverdraft':
        if (applicationSubmissionConfig.BusinessOverdraft) {
          this.router.navigate(navigationUrlForBusinessOverdraft());
        } else {
          openDisableSubmissionDialog();
        }
        break;
    }
  }

  async applicationTilesComponentNavigationHandlerForDirectSale(applicationTypes: ApplicationTypes) {
    const directSaleSubmissionConfig = environment.directSaleSubmission;
    const openDisableSubmissionDialog = () => {
      this.dialogService.openInformationDialog({
        message: `Information`,
        subMessage: directSaleSubmissionConfig.disabledMessage,
      });
    }
    switch (applicationTypes) {
      case 'AssetFinance':
        if (directSaleSubmissionConfig.AssetFinance) {
          await this.router.navigate(navigationUrlForDirectSaleAssetFinance());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'BusinessLoans':
        if (directSaleSubmissionConfig.BusinessLoans) {
          this.router.navigate(navigationUrlForDirectSaleBusinessLoans());
        } else {
          openDisableSubmissionDialog();
        }
        break;
      case 'BusinessOverdraft':
        if (directSaleSubmissionConfig.BusinessOverdraft) {
          this.router.navigate(navigationUrlForDirectSaleBusinessOverdraft());
        } else {
          openDisableSubmissionDialog();
        }
        break;
    }
  }


  businessNumberSearchFn: BusinessNumberSearchFn = (businessNumber: string): Observable<BusinessNumberSearchResult> => {
    return this.businessNumberSearch(businessNumber).pipe(map(r => r.payload));
  }
  businessNumberSearch = (businessNumber: string): Observable<PayloadApiResponse<BusinessNumberSearchResult>> => {
    return this.httpClient.get<PayloadApiResponse<BusinessNumberSearchResult>>(URL_BUSINESS_NUMBER_SEARCH(businessNumber), httpOptions());
  }



  serviceabilityCalculationFn = (input: ServiceabilityCalculationInput) => {
    return this.doServiceabilityCalculation(input).pipe(map(r => r.payload));
  }

  doServiceabilityCalculation(input: ServiceabilityCalculationInput): Observable<PayloadApiResponse<ServiceabilityCalculationResult>> {
    return this.httpClient.post<PayloadApiResponse<ServiceabilityCalculationResult>>(URL_SERVICEABILITY_CALCULATION(), {
      ...input
    }, httpOptions()).pipe();
  }


  getOriginatorBusinessById(originatorBusinessId: number): Observable<PayloadApiResponse<OriginatorBusiness | null>> {
    return this.httpClient.get<PayloadApiResponse<OriginatorBusiness>>(URL_GET_ORIGINATOR_BUSINESS_BY_ID(originatorBusinessId), httpOptions()).pipe();
  }

  getOriginatorBusinessByIdFn = (originatorBusinessId: number): Observable<OriginatorBusiness | null> => {
    return this.getOriginatorBusinessById(originatorBusinessId).pipe(map(r => r.payload));
  }

  updateApplicationSubmitter(applicationId: number, submitterUserId: number): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_SUBMITTER(), {
      applicationId, submitterUserId,
    }, httpOptions()).pipe();
  }

  updateApplicationAdditionalCorrespondent(applicationId: number, data: UserSelectionValue): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_ADDITIONAL_CORRESPONDENT(), {
      applicationId, data
    }, httpOptions()).pipe();
  }

  // not used, get list from constants.ts
  // getInsuranceProduct(): Observable<PayloadApiResponse<InsuranceProduct[]>> {
  //   return this.httpClient.get<InsuranceProduct[]>(URL_INSURANCE_PRODUCT, httpOptions()).pipe(
  //     map(r => {
  //       return {
  //         status: 'Success',
  //         messages: ['loaded insurance product'],
  //         payload: r,
  //       }
  //     })
  //   );
  // }

  submitConsumerFinance(ApplicationId: number, data: SaveApplicationData): Observable<PayloadApiResponse<SubmitApplicationResult>> {
    const submittedData = { ...data };
    (submittedData as any).ApplicationId = ApplicationId;
    return this.httpClient.post<PayloadApiResponse<SubmitApplicationResult>>(URL_SUBMIT_CONSUMER_APPLICATION(), {
      ApplicationId,
      data: submittedData,
    }, httpOptions()).pipe();
  }

  submitInsurancePremium(ApplicationId: number, data: SaveApplicationData): Observable<PayloadApiResponse<SubmitApplicationResult>> {
    const submittedData = { ...data };
    // no stringify needed, the api only update AppInfo section
    (submittedData as any).ApplicationId = ApplicationId;
    return this.httpClient.post<PayloadApiResponse<SubmitApplicationResult>>(URL_SUBMIT_INSURANCE_PREMIUM_APPLICATION(), {
      ApplicationId,
      data: submittedData
    }, httpOptions()).pipe();
  }



  submitApplication(ApplicationId: number, data: SaveApplicationData): Observable<PayloadApiResponse<SubmitApplicationResult>> {
    const submittedData = { ...data };
    // no stringify needed, the api only update AppInfo section
    (submittedData as any).ApplicationId = ApplicationId;
    return this.httpClient.post<PayloadApiResponse<SubmitApplicationResult>>(URL_SUBMIT_APPLICATION(), {
      ApplicationId,
      data: submittedData,
    }, httpOptions()).pipe();
  }

  submitApplicationForDirectSale(ApplicationId: number, data: SaveApplicationData, token: string): Observable<PayloadApiResponse<SubmitApplicationResult>> {
    const submittedData = { ...data };
    // no stringify needed, the api only update AppInfo section
    (submittedData as any).ApplicationId = ApplicationId;
    return this.httpClient.post<PayloadApiResponse<SubmitApplicationResult>>(URL_SUBMIT_APPLICATION_FOR_DIRECT_SALE(), {
      ApplicationId,
      data: submittedData,
    }, httpOptionsForDirectSale(token)).pipe();
  }

  updateApplicationSf(ApplicationId: number, data: UpdateApplicationData): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_UPDATE_APPLICATION_SF(), {
      ApplicationId,
      data: data,
    }, httpOptions()).pipe();
  }

  updateApplicationSfFn: UpdateApplicationSfFn = (applicationId: number, data: UpdateApplicationData) => {
    return this.updateApplicationSf(applicationId, data);
  }

  updateApplication(ApplicationId: number, data: UpdateApplicationData): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_UPDATE_APPLICATION(), {
      ApplicationId,
      data: data,
    }, httpOptions()).pipe();
  }

  updateApplicationForDirectSale(ApplicationId: number, data: UpdateApplicationData, token: string): Observable<ApiResponse> {
    return this.httpClient.put<ApiResponse>(URL_UPDATE_APPLICATION_FOR_DIRECT_SALE(), {
      ApplicationId,
      data: data,
    }, httpOptionsForDirectSale(token)).pipe();
  }

  updateApplicationFn: UpdateApplicationFn = (ApplicationId: number, data: UpdateApplicationData) => {
    return this.updateApplication(ApplicationId, data);
  }

  saveApplication(data: SaveApplicationData): Observable<PayloadApiResponse<SaveApplicationResult>> {
    return this.httpClient.post<PayloadApiResponse<SaveApplicationResult>>(URL_SAVE_APPLICATION(), data, httpOptions()).pipe();
  }

  saveApplicationForDirectSale(data: SaveApplicationData): Observable<PayloadApiResponse<SaveApplicationResult>> {
    return this.httpClient.post<PayloadApiResponse<SaveApplicationResult>>(URL_SAVE_APPLICATION_FOR_DIRECT_SALE(), data, httpOptions()).pipe();
  }

  getBrokerOfUser(userId: number): Observable<PayloadApiResponse<BrokerOfUser | null>> {
    return this.httpClient.get<PayloadApiResponse<BrokerOfUser>>(URL_GET_BROKER_FOR_USER(userId), httpOptions()).pipe();
  }

  getIpAddress(): Observable<PayloadApiResponse<string>> {
    return this.httpClient.get<{ ip: string }>(URL_GET_IP_ADDRESS()).pipe(
      map(r => {
        return {
          status: true,
          message: '',
          payload: r.ip,
        }
      }),
      catchError(err => {
        console.log(`error getting ip address`, err);
        return of({
          status: true,
          message: '',
          payload: '',
        });
      })
    );
  }

  getGeoLocation(): Promise<GeoLocation> {
    const self = this;
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        (self as any).noLocationPermission = true;
        if (!position) {
          resolve({});
          console.log('can\'t get location from static method getGeoLocation');
        }
        return resolve({
          lat: String(position.coords.latitude),
          long: String(position.coords.longitude),
        });
      },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            reject(`Please allow location detection to go further`)
            alert(`Please allow location detection to go further`);
            (self as any).noLocationPermission = false;
          }
        });
    });
  }

  getGeoLocationFn = (): Promise<GeoLocation> => {
    return this.getGeoLocation();
  }

  getRatecardDetails(userId: number, type: ApplicationTypes): Observable<PayloadApiResponse<RatecardDetails>> {
    return this.httpClient.get<PayloadApiResponse<RatecardDetails>>(URL_GET_RATECARD_DETAILS(userId, type), httpOptions()).pipe();
  }

  getRatecardDetailsFn = (userId: number, type: ApplicationTypes) => {
    return this.getRatecardDetails(userId, type).pipe(map(r => r.payload));
  }
  getDefaultRatecardDetails(type: ApplicationTypes): Observable<PayloadApiResponse<RatecardDetails>> {
    return this.httpClient.get<PayloadApiResponse<RatecardDetails>>(URL_GET_DEFAULT_RATECARD_DETAILS(type), httpOptions()).pipe();
    //  map(_r => {
    //    if(_r!==null) {
    //     this.materializeRatecardDetails(_r.payload)
    //    }
    //    return _r as PayloadApiResponse<RatecardDetails>
    //  })
    //);
  }


  // we will remove this after migrating to api2 cause, all these will be done in the api2 then
  // this is done in api2 through toRatecardDetails(...) in share lib
  // materializeRatecardDetails(_r: RatecardDetails){
  //   let assetTypes: AssetTypeRateValue = parseJSON(_r.AssetType);
  //   if (assetTypes && Array.isArray(assetTypes)) {
  //     assetTypes = assetTypes.map(assetType => ({
  //       Cat: assetType.Cat,
  //       Type: assetType.Type.split('-')[1] ?? assetType.Type,
  //       Rate: assetType.Rate,
  //     }))
  //   }
  //   _r.ABNorGSTAge = parseJSON(_r.ABNorGSTAge);
  //   _r.ApplicationCategory = parseJSON(_r.ApplicationCategory);
  //   _r.AssetFinanceTier1 = parseJSON(_r.AssetFinanceTier1);
  //   _r.AssetFinanceTier2 = parseJSON(_r.AssetFinanceTier2);
  //   _r.AssetFinanceTier3 = parseJSON(_r.AssetFinanceTier3);
  //   _r.AssetType = assetTypes ?? [];
  //   _r.AssetCategory = parseJSON(_r.AssetCategory);
  //   _r.Brokerage = parseJSON(_r.Brokerage);
  //   _r.DocFee = parseJSON(_r.DocFee);
  //   _r.EOTAge = parseJSON(_r.EOTAge);
  //   _r.LoanAmount = parseJSON(_r.LoanAmount);
  //   _r.LoanTerms = parseJSON(_r.LoanTerms);
  //   _r.CarsAndCaravans = parseJSON(_r.CarsAndCaravans);
  //   _r.Motorbikes = parseJSON(_r.Motorbikes);
  //   _r.SolarSystems = parseJSON(_r.SolarSystems);
  // }

  getDefaultRatecardDetailsFn: GetRateCardDetailsFn = (ratecardId: number, type: ApplicationTypes) => {
    return this.getDefaultRatecardDetails(type).pipe(map(r => r.payload));
  }

  brokerSearchFn: AggregatorSearchFn = (term: string) => {
    return this.searchBroker2(term).pipe(map(r => r.payload));
  }
  searchBroker2(name: string): Observable<PayloadApiResponse<Exclude<AggregatorSearchComponentValue, null>[]>> {
    return this.httpClient.get<PayloadApiResponse<Exclude<AggregatorSearchComponentValue, null>[]>>(URL_SEARCH_BROKER_BY_NAME2(name), httpOptions());
    // return this.httpClient.get<{status: boolean, message: string, payload: {ABN: string, EntityName: string, SalesforceId: string}[]}>(URL_SEARCH_BROKER_BY_NAME2(name), httpOptions())
    //   .pipe(
    //     map((r: {status: boolean, message: string, payload: {ABN: string, EntityName: string, SalesforceId: string}[]}) => {
    //       const res = r.payload
    //       const rst =  res.map((rec) => {
    //         return {
    //           abn: rec.ABN,
    //           entityName: rec.EntityName,
    //           salesforceId: rec.SalesforceId,
    //         }
    //       });
    //       return {
    //         status: true,
    //         message: 'Brokers info retrieved',
    //         payload: rst,
    //       }
    //     }));
  }

  // searchBroker(name: string): Observable<PayloadApiResponse<Exclude<AggregatorSearchComponentValue, null>[]>> {
  //   return this.httpClient.get<{ABN: string, EntityName: string, SalesforceId: string}[]>(URL_SEARCH_BROKER_BY_NAME(name), httpOptions())
  //     .pipe(
  //       map((r: {ABN: string, EntityName: string, SalesforceId: string}[]) => {
  //         const rst =  r.map((rec) => {
  //           return {
  //             abn: rec.ABN,
  //             entityName: rec.EntityName,
  //             salesforceId: rec.SalesforceId,
  //           }
  //         });
  //         return {
  //           status: true,
  //           message: 'Brokers info retrieved',
  //           payload: rst,
  //         }
  //       }));
  // }

  // companySearchFn: CompaniesSearchFn = (name: string) => {
  //   return this.searchCompanyByName(name).pipe(
  //     map((r: PayloadApiResponse<SearchCompanyResult>) => {
  //       const p = r.payload;
  //       const rec = p?.records;
  //       return (rec ?? []).map( (re: {abn: number, organisationName: string}) => ({
  //         abn: String(re.abn),
  //         entityName: re.organisationName,
  //         salesforceId: '',
  //         originatorBusinessId: 0,
  //       }))
  //     }),
  //     catchError(error => {
  //       this.portalHotToastService.warn(`Unable to perform company search`);
  //       return of([]);
  //     })
  //   );
  // }

  businessSearchFn: BusinessSearchFn = (search: string) => {
    return this.searchCompanyByName(search).pipe(
      map(r => r.payload ?? { numberOfRecords: 0, records: [] }),
      catchError(err => {
        this.portalHotToastService.warn(`Unable to perform business search`);
        return of({ numberOfRecords: 0, records: [] });
      })
    );
  }

  searchCompanyByName(name: string): Observable<PayloadApiResponse<SearchCompanyResult | null>> {
    return this.httpClient.post<PayloadApiResponse<SearchCompanyResult | null>>(URL_SEARCH_COMPANY_BY_NAME(name), {}, httpOptions());
    // return this.httpClient.post<{status: boolean, message: string, payload: SearchCompanyResult}>(URL_SEARCH_COMPANY_BY_NAME(name), {}, httpOptions())
    //   .pipe(
    //     map((r) => {
    //       return r as PayloadApiResponse<SearchCompanyResult>;
    //     }),
    //   );
  }

  // not used
  // getApplicationsReport(): Observable<PayloadApiResponse<ApplicationsReport>> {
  //   return this.httpClient.get<ApplicationsReport>(URL_GET_APPLICATIONS_REPORT(), httpOptions()).pipe(
  //     map((r: ApplicationsReport) => {
  //       const p: PayloadApiResponse<ApplicationsReport> = {
  //         status: true,
  //         message: 'Applications report retrieved',
  //         payload: r
  //       }
  //       return p;
  //     })
  //   );
  // }

  fetchABRdataByABN(abn: string): Promise<FetchABRdataByABNResult> {
    return new Promise((resolve, reject) => {
      this.searchCompanyByName(abn)
        .toPromise()
        .then(res => {
          const r: SearchCompanyByABNResult | undefined = res?.payload as SearchCompanyByABNResult;
          resolve(this.mapOrgeData(r));
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  mapOrgeData(res: SearchCompanyByABNResult | undefined): FetchABRdataByABNResult {
    let report = res;
    let response: FetchABRdataByABNResult = {};
    if (report) {
      let acn = (report['ASICNumber'] != undefined && String(report['ASICNumber']) != '') ? String(report['ASICNumber']) : null;
      if (acn != null) {
        let charRequiredtoFill = 9 - acn.toString().length;
        if (charRequiredtoFill > 0) {
          acn = ('0').repeat(charRequiredtoFill) + acn;
        }
        response['acn'] = acn.toString();
      }
      if (report.ABN && Array.isArray(report.ABN)) {
        response['abn'] = report.ABN[0].identifierValue.toString();
      } else if (report.ABN) {
        response['abn'] = report.ABN.identifierValue.toString();
      }
      let abnAgeMonths = 0;
      if (report['entityStatus'] !== undefined) {
        let entityStatus;
        if (Array.isArray(report['entityStatus'])) {
          entityStatus = report['entityStatus'][0];
        } else {
          entityStatus = report['entityStatus'];
        }
        abnAgeMonths = moment().diff(entityStatus['effectiveFrom'], 'months');
        response['ABNAgeMonths'] = abnAgeMonths;
      }
      if (report['goodsAndServicesTax'] !== undefined) {
        let goodsAndServicesTax;
        if (Array.isArray(report['goodsAndServicesTax'])) {
          goodsAndServicesTax = report['goodsAndServicesTax'][0];
        } else {
          goodsAndServicesTax = report['goodsAndServicesTax'];
        }
        let months = moment().diff(goodsAndServicesTax['effectiveFrom'], 'months');
        let years = moment().diff(goodsAndServicesTax['effectiveFrom'], 'years');
        let yearsInBusiness = years + (months % 12 / 10);
        response.yearsInBusiness = yearsInBusiness;
        response['GSTAgeMonths'] = months;
      }
      if (report['entityType'] !== undefined) {
        let entityType = report['entityType'];
        if (entityType['entityTypeCode'] == 'IND') {
          response.organisationType = 'Sole Trader';
          let legalName;
          if (Array.isArray(report['legalName'])) {
            legalName = report['legalName'][0];
          } else {
            legalName = report['legalName'];
          }
          response['legalName'] = legalName['givenName'];
          response['legalName'] += legalName['otherGivenName'] != '' ? ' ' + legalName['otherGivenName'] : '';
          response['legalName'] += legalName['familyName'] != '' ? ' ' + legalName['familyName'] : '';
        } else {
          let entityTypeText = getOrgTypeByEntityText(entityType['entityDescription']);
          response.organisationType = entityTypeText;
        }
      } else {
        response.organisationType = 'Other';
      }
      let entityType: 'PTY' | 'TRST' | 'PTNR' | 'PTY' | 'SLTR' = 'PTY';
      let orgType = response.organisationType;
      if (orgType === 'Trust') {
        entityType = 'TRST';
      } else if (orgType === 'Partnership') {
        entityType = 'PTNR';
      } else if (orgType === 'Company') {
        entityType = 'PTY';
      } else if (orgType === 'Sole Trader') {
        entityType = 'SLTR';
      } else {
        entityType = 'PTY';
      }
      response.entityType = entityType;
    }
    return response;
  }


  getApplicationById(applicationId: number): Observable<PayloadApiResponse<Application>> {
    return this.httpClient.post<PayloadApiResponse<Application>>(URL_GET_APPLICATION(), {
      ApplicationId: applicationId
    }, httpOptions()).pipe();
    //   map((r: {status: boolean, payload: Application, message?: string}) => {
    //     if (r.status) {
    //       this.materialiseApplication(r.payload);
    //     }
    //     const p: PayloadApiResponse<Application> = {
    //       status: r.status,
    //       message: r.status ? 'Success' : r.message ? r.message : `Unable to retrieve application with id ${applicationId}`,
    //       payload: r.payload
    //     }
    //     return p;
    //   })
    // );
  }

  getApplicationByIdForDirectSale(applicationId: number, token: string): Observable<PayloadApiResponse<Application>> {
    return this.httpClient.post<PayloadApiResponse<Application>>(URL_GET_APPLICATION_FOR_DIRECT_SALE(), {
      ApplicationId: applicationId
    }, httpOptionsForDirectSale(token)).pipe();
    //   map((r: {status: boolean, payload: Application, message?: string}) => {
    //     if (r.status) {
    //       this.materialiseApplication(r.payload);
    //     }
    //     const p: PayloadApiResponse<Application> = {
    //       status: r.status,
    //       message: r.status ? 'Success' : r.message ? r.message : `Unable to retrieve application with id ${applicationId}`,
    //       payload: r.payload
    //     }
    //     return p;
    //   })
    // );
  }

  getApplicationByIdFn = (applicationId: number): Observable<Application> => {
    return this.getApplicationById(applicationId).pipe(map(res => res.payload));
  }

  // not used (replaced by local-applications-db.service)
  // getApplicationsByUserUnderReview(
  //   options: {
  //     page: {offset: number, limit: number},
  //     sorts?: ApplicationSort,
  //     filter: string,
  //   } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT}, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER } ): Observable<PaginablePayloadApiResponse<SimplifiedApplication>> {
  //   return this.httpClient.post(URL_GET_APPLICATIONS_UNDER_REVIEW(), options, httpOptions()).pipe(
  //     map(res => {

  //       (res as any).records.forEach((r: any) => {
  //         this.materialiseApplication(r);
  //       })

  //       const p: PaginablePayloadApiResponse<SimplifiedApplication> = {
  //         limit: options.page.limit,
  //         offset: options.page.offset,
  //         message: 'Application retrieved',
  //         status: true,
  //         payload: (res as any).records,
  //         total: (res as any).total_rows,
  //       };
  //       return p;
  //     })
  //   );
  // }

  // not used (replaced by local-applications-db.service)
  // getApplicationsByUserInSettlement(
  //   options: {
  //     page: {offset: number, limit: number},
  //     sorts?: ApplicationSort,
  //     filter: string,
  //   } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT}, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER } ): Observable<PaginablePayloadApiResponse<SimplifiedApplication>> {
  //   return this.httpClient.post(URL_GET_APPLICATIONS_IN_SETTLEMENT(), options, httpOptions()).pipe(
  //     map(res => {

  //       (res as any).records.forEach((r: any) => {
  //         this.materialiseApplication(r);
  //       })

  //       const p: PaginablePayloadApiResponse<SimplifiedApplication> = {
  //         limit: options.page.limit,
  //         offset: options.page.offset,
  //         message: 'Application retrieved',
  //         status: true,
  //         payload: (res as any).records,
  //         total: (res as any).total_rows,
  //       };
  //       return p;
  //     })
  //   );
  // }

  // not used (replaced by local-applications-db.service)
  // getApplicationsByUserClosedWon(
  //   options: {
  //     page: {offset: number, limit: number},
  //     sorts?: ApplicationSort,
  //     filter: string,
  //   } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT}, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER } ): Observable<PaginablePayloadApiResponse<SimplifiedApplication>> {
  //   return this.httpClient.post(URL_GET_APPLICATIONS_CLOSED_WON(), options, httpOptions()).pipe(
  //     map(res => {

  //       (res as any).records.forEach((r: any) => {
  //         this.materialiseApplication(r);
  //       })

  //       const p: PaginablePayloadApiResponse<SimplifiedApplication> = {
  //         limit: options.page.limit,
  //         offset: options.page.offset,
  //         message: 'Application retrieved',
  //         status: true,
  //         payload: (res as any).records,
  //         total: (res as any).total_rows,
  //       };
  //       return p;
  //     })
  //   );
  // }

  getApplicationsByUser(
    options: {
      page: { offset: number, limit: number },
      sorts?: ApplicationSortTypes,
      filter: string,
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT }, sorts: DEFAULT_SORTS, filter: DEFAULT_FILTER }): Observable<PaginablePayloadApiResponse<SimplifiedApplication>> {
    return this.httpClient.post<PaginablePayloadApiResponse<SimplifiedApplication>>(URL_GET_APPLICATIONS(), options, httpOptions()).pipe();
    // return this.httpClient.post(URL_GET_APPLICATIONS(), options, httpOptions()).pipe(
    //   map(res => {

    //     (res as any).records.forEach((r: any) => {
    //       this.materialiseApplication(r);
    //     })

    //     const p: PaginablePayloadApiResponse<SimplifiedApplication> = {
    //       limit: options.page.limit,
    //       offset: options.page.offset,
    //       message: 'Application retrieved',
    //       status: true,
    //       payload: (res as any).records,
    //       total: (res as any).total_rows,
    //     };
    //     return p;
    //   })
    // );
  }

  getApplicationStatus2 = getApplicationStatus2;

  getApplicationStage2 = getApplicationStage2;

  // getApplicationIndividualsWithDigitalIdVerificationResults(applicationId: number) {
  // }

  // NOTE: not being used
  // getApplicantName(app: Application): string {
  //   if (app.CommercialEntities && app.CommercialEntities.length) {
  //     return app.CommercialEntities[0].LegalName;
  //   } else if (app.ApplicationType === 'Consumer') {
  //     if (app.Individuals && app.Individuals.length) {
  //       return app.Individuals[0].GivenName + ' ' + app.Individuals[0].SurName;
  //     }
  //   }
  //   return '';
  // }

  // updateApplicationDocument(applicationId: number, newFiles: Base64File[], deletedFileNames: string[] = []): Observable<ApiResponse> {
  //   const nf = newFiles.map(f => ({
  //     base64: f.base64,
  //     name: f.name,
  //     size: f.size,
  //     type: f.type,
  //   }));
  //   const rf = deletedFileNames.map(f => ({ name: f}));
  //   return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_DOCUMENT(applicationId), {
  //       new: nf,
  //       remove: rf,
  //   }, httpOptions()).pipe();
  //   // return this.httpClient.post<{status: boolean, message: string, data: any}>(URL_UPDATE_APPLICATION_DOCUMENT(applicationId), {
  //   //   new: nf,
  //   //   remove: rf,
  //   // }, httpOptions()).pipe(
  //   //   map(r => {
  //   //     return {
  //   //       status: r.status,
  //   //       message: r.message ?? '',
  //   //     }
  //   //   })
  //   // );
  // }

  uploadApplicationDocumentToAzureStorage(applicationId: number, newFiles: Base64File[], deletedFileNames: string[] = []): Observable<ApiResponse> {
    const nf = newFiles.map(f => ({
      base64: f.base64,
      name: f.name.replace(/[^\t\x20-\x7e\x80-\xff]/, '_'),
      size: f.size,
      type: f.type,
      tags: f?.tags ?? [],
      metadata: f?.metadata ?? {}
    }));
    const rf = deletedFileNames.map(f => ({ name: f }));
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_DOCUMENT_TO_AZURE_STORAGE(applicationId), {
      new: nf,
      remove: rf,
    }, httpOptions());
  }

  uploadApplicationDocumentToAzureStorageForDirectSale(applicationId: number, newFiles: Base64File[], deletedFileNames: string[] = [], token: string): Observable<ApiResponse> {
    const nf = newFiles.map(f => ({
      base64: f.base64,
      name: f.name.replace(/[^\t\x20-\x7e\x80-\xff]/, '_'),
      size: f.size,
      type: f.type,
      tags: f?.tags ?? [],
      metadata: f?.metadata ?? {}
    }));
    const rf = deletedFileNames.map(f => ({ name: f }));
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_DOCUMENT_TO_AZURE_STORAGE_FOR_DIRECT_SALE(applicationId), {
      new: nf,
      remove: rf,
    }, httpOptionsForDirectSale(token));
  }

  // updateApplicationDocumentFn: UpdateApplicationDocumentFn = async (applicationId: number, files: File[] | Base64File[], deleteFiles: string[]) => {
  //   const base64Files = await filesToBase64Files(files);
  //   const r =  this.updateApplicationDocument(applicationId, base64Files, deleteFiles)
  //     .pipe(
  //       switchMap(r => {
  //         return this.getApplicationById(applicationId).pipe(map(r => r.payload));
  //       })
  //     );
  //   return r;
  // }

  uploadApplicationDocumentToAzureStorageFn = async (applicationId: number, files: File[] | Base64File[], deleteFiles: string[], isBase64File: boolean = false) => {
    const base64Files = isBase64File ? files as Base64File[] : await filesToBase64Files(files);
    const r = this.uploadApplicationDocumentToAzureStorage(applicationId, base64Files, deleteFiles)
      .pipe(
        switchMap(r => {
          return this.getApplicationById(applicationId).pipe(map(r => r.payload));
        })
      );
    return r;
  }

  listApplicationDocument = (applicationId: number): Observable<PayloadApiResponse<AzureStorageDocument[]>> => {
    return this.httpClient.get<PayloadApiResponse<AzureStorageDocument[]>>(URL_LIST_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE(applicationId), httpOptions());
  }

  listApplicationDocumentFn = (applicationId: number, includeDeletedFile = false): Observable<AzureStorageDocument[]> => {
    return this.listApplicationDocument(applicationId).pipe(
      map((r: PayloadApiResponse<AzureStorageDocument[]>) => r.payload),
      map((docs: AzureStorageDocument[]) => {
        if (!includeDeletedFile) {
          return docs.filter(doc => !(doc?.metadata?.isdeleted) || !(doc?.metadata?.isdeleted.toLowerCase() === 'true'));
        } else {
          return docs;
        }
      })
    );
  }

  listApplicationDocumentForDirectSale = (applicationId: number, token: string): Observable<PayloadApiResponse<AzureStorageDocument[]>> => {
    return this.httpClient.get<PayloadApiResponse<AzureStorageDocument[]>>(URL_LIST_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE_DIRECT_SALE(applicationId), httpOptionsForDirectSale(token));
  }

  listApplicationDocumentForDirectSaleFn = (applicationId: number, includeDeletedFile = false, token: string): Observable<AzureStorageDocument[]> => {
    return this.listApplicationDocumentForDirectSale(applicationId, token).pipe(
      map((r: PayloadApiResponse<AzureStorageDocument[]>) => r.payload),
      map((docs: AzureStorageDocument[]) => {
        if (!includeDeletedFile) {
          return docs.filter(doc => !(doc?.metadata?.isdeleted) || !(doc?.metadata?.isdeleted.toLowerCase() === 'true'));
        } else {
          return docs;
        }
      })
    );
  }

  listAllDocumentsByCompanyAndConsumer = (): Observable<ApplicationDocumentsTableData[]> => {
    return this.httpClient.post<PayloadApiResponse<ApplicationDocumentsTableData[]>>(URL_LIST_APPLICATION_DOCUMENTS_BY_COMPANY_AND_CONSUMER(), {}, httpOptions())
      .pipe(map((r: PayloadApiResponse<ApplicationDocumentsTableData[]>) => r.payload));
  }

  getDocumentsFromCache = (): Observable<ApplicationDocumentsTableData[]> => {
    return this.httpClient.get<PayloadApiResponse<ApplicationDocumentsTableData[]>>(URL_GET_DOCUMENTS_FROM_CACHE(), httpOptions())
      .pipe(map((r: PayloadApiResponse<ApplicationDocumentsTableData[]>) => r.payload));
  }

  downloadApplicationDocument = (path: string, fileName: string, applicationId: number): string => {
    const urlWithParams = new URL(URL_DOWNLOAD_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE(applicationId));
    urlWithParams.searchParams.append("path", path);
    urlWithParams.searchParams.append("fileName", fileName);

    return urlWithParams.href;
  }

  downloadApplicationDocumentUrlFn = (path: string, fileName: string, applicationId: number): Observable<Blob> => {
    // return this.downloadApplicationDocument(path, fileName, applicationId);

    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(this.downloadApplicationDocument(path, fileName, applicationId), options)
  }

  downloadAllApplicationDocument = (applicationId: number, customerName: string, userId: number) => {
    return URL_DOWNLOAD_ALL_APPLICATION_DOCUMENT_FROM_AZURE_STORAGE(applicationId, customerName, userId);
  }

  downloadAllApplicationDocumentUrlFn = (applicationId: number, customerName: string, userId: number): Observable<Blob> => {
    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(this.downloadAllApplicationDocument(applicationId, customerName, userId), options)
  }

  downloadAllCompanyDocument = (type: 'consumer' | 'company', legalName: string, abn: string, name: string, dob: string) => {
    const urlWithParams = new URL(URL_DOWNLOAD_ALL_DOCUMENT_FOR_COMPANY_AND_CONSUMER(type));
    urlWithParams.searchParams.append("legalName", legalName);
    urlWithParams.searchParams.append("abn", abn);
    urlWithParams.searchParams.append("name", name);
    urlWithParams.searchParams.append("dob", dob);

    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(urlWithParams.href, options)
  }

  downloadDocuments = (type: 'consumer' | 'company', legalName: string, abn: string, name: string, dob: string, tag: string) => {
    const urlWithParams = new URL(URL_DOWNLOAD_ALL_DOCUMENT(type));
    urlWithParams.searchParams.append("legalName", legalName);
    urlWithParams.searchParams.append("abn", abn);
    urlWithParams.searchParams.append("name", name);
    urlWithParams.searchParams.append("dob", dob);
    urlWithParams.searchParams.append("tag", tag);

    const options = { ...httpOptions(), observe: 'body' as const, responseType: 'blob' as 'json' }
    return this.httpClient.get<Blob>(urlWithParams.href, options)
  }

  updateDocumentMetadata = (path: string, fileName: string, metadata: Metadata): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE(), { path: path, fileName: fileName, metadata: metadata }, httpOptions());
  }

  updateDocumentMetadataForDirectSale = (path: string, fileName: string, metadata: Metadata, token: string): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_METADATA_IN_AZURE_STORAGE_FOR_DIRECT_SALE(), { path: path, fileName: fileName, metadata: metadata }, httpOptionsForDirectSale(token));
  }

  updateDocumentMetadataFn = (path: string, fileName: string, metadata: Metadata): Observable<ApiResponse> => {
    return this.updateDocumentMetadata(path, fileName, metadata);
  }

  updateDocumentMetadataForDirectSaleFn = (path: string, fileName: string, metadata: Metadata, token: string): Observable<ApiResponse> => {
    return this.updateDocumentMetadataForDirectSale(path, fileName, metadata, token);
  }

  approveApplicationDocumentFn = (path: string, fileName: string, tagName: string, userId: number | undefined): Observable<ApiResponse> => {
    return this.updateDocumentMetadataFn(path, fileName, { [tagName]: 'Accepted', statuschangedby: String(userId) });
  }

  declineApplicationDocumentFn = (path: string, fileName: string, tagName: string, userId: number | undefined): Observable<ApiResponse> => {
    return this.updateDocumentMetadataFn(path, fileName, { [tagName]: 'Rejected', statuschangedby: String(userId) });
  }

  undoApplicationDocumentFn = (path: string, fileName: string, tagName: string, userId: number | undefined): Observable<ApiResponse> => {
    return this.updateDocumentMetadataFn(path, fileName, { [tagName]: 'In Progress', statuschangedby: String(userId) });
  }

  updateCompanyDetailsForDocumentFn = (path: string, fileName: string, legalName: string, abn: string): Observable<ApiResponse> => {
    return this.updateDocumentMetadataFn(path, fileName, { legalname: legalName, abn: abn });
  }

  updateConsumerDetailsForDocumentFn = (path: string, fileName: string, name: string, dob: string): Observable<ApiResponse> => {
    return this.updateDocumentMetadataFn(path, fileName, { consumername: name, dob: dob });
  }

  deleteApplicationDocument = (path: string, fileName: string, tagName: string): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_DOCUMENT_FROM_AZURE_STORAGE(), { path: path, fileName: fileName, tag: tagName }, httpOptions());
  }

  deleteApplicationDocumentFn = (path: string, fileName: string, tagName: string): Observable<ApiResponse> => {
    return this.deleteApplicationDocument(path, fileName, tagName);
  }

  updateDocumentTags = (path: string, fileName: string, tags: string[]): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_TAGS_IN_AZURE_STORAGE(), { path: path, fileName: fileName, tags: tags }, httpOptions());
  }

  updateDocumentTagsFn = (path: string, fileName: string, tags: string[]): Observable<ApiResponse> => {
    return this.updateDocumentTags(path, fileName, tags);
  }

  updateApplicationRequiredDocuments = (applicationId: number, data: DocumentTag[]): Observable<ApiResponse> => {
    // const stringifiedData = stringifyJSON(data);
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_DOCUMENT_IN_DB(), { applicationId: applicationId, documents: data }, httpOptions());
  }

  updateApplicationRequiredDocumentsFn = (applicationId: number, data: DocumentTag[]): Observable<Application> => {
    return this.updateApplicationRequiredDocuments(applicationId, data).pipe(
      switchMap(r => {
        return this.getApplicationById(applicationId).pipe(map(r => r.payload));
      })
    );
  }

  sendApplicationDocumentNotification = (
    documents: GroupedDocument[],
    additionalInfo: string,
    brokerAppId: string,
    applicationId: number,
    brokerName: string,
    emailList: string[],
    borrowerName: string,
    userId: number
  ): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_APPLICATION_DOCUMENT_NOTIFICATION(), {
      documents: documents,
      additionalInfo: additionalInfo,
      applicationId: applicationId,
      brokerName: brokerName,
      brokerAppId: brokerAppId,
      emailList: emailList,
      borrowerName: borrowerName,
      userId,
    }, httpOptions());
  }

  sendApplicationDocumentNotificationFn = (
    documents: GroupedDocument[],
    additionalInfo: string,
    brokerAppId: string,
    applicationId: number,
    brokerName: string,
    emailList: string[],
    borrowerName: string,
    userId: number
  ): Observable<ApiResponse> => {
    return this.sendApplicationDocumentNotification(documents, additionalInfo, brokerAppId, applicationId, brokerName, emailList, borrowerName, userId);
  }

  saveQuote(data: Quote): Observable<PayloadApiResponse<SaveQuoteResult>> {
    return this.httpClient.post<PayloadApiResponse<SaveQuoteResult>>(URL_SAVE_QUOTE(), data, httpOptions()).pipe();
    //   map(r => {
    //     return {
    //       status: true,
    //       message: '',
    //       payload: r
    //     }
    //   })
    // );
  }


  // NOTE: use share library's toApplication(...) in utils.ts
  // we will remove this after migrating to api2 cause, all these will be done in the api2 then
  // private materialiseApplication(r: any) {
  //   (r as any).ApplicantLocation = parseJSON((r as any).ApplicantLocation);
  //   (r as any).CompanyDetails = parseJSON((r as any).CompanyDetails);
  //   (r as any).AppInfo = parseJSON((r as any).AppInfo);
  //   (r as any).Contacts = parseJSON((r as any).Contacts);
  //   (r as any).CommercialEntities = (parseJSON((r as any).CommercialEntities) ?? []);
  //   (r as any).Individuals = (parseJSON((r as any).Individuals) ?? []);
  //   (r as any).Reference = parseJSON((r as any).Reference);
  //   (r as any).AssetSpec = parseJSON((r as any).AssetSpec);
  //   (r as any).Expense = parseJSON((r as any).Expense);
  //   (r as any).Policies = parseJSON((r as any).Policies);
  //   (r as any).PricingDetails = parseJSON((r as any).PricingDetails);
  //   (r as any).Documents = parseJSON((r as any).Documents);
  //   (r as any).InteflowStatus = parseJSON((r as any).InteflowStatus);
  //   (r as any).DocumentResult = parseJSON((r as any).DocumentResult);
  //   (r as any).GrowRiskRatingData = parseJSON((r as any).GrowRiskRatingData);
  //   if(( r as any).DocumentResult) {
  //     for (const dRst of (r as any).DocumentResult) {
  //       if (dRst.result) {
  //         dRst.result = parseJSON(dRst.result);
  //       }
  //     }
  //   }
  //   (r as any).AuthorisedSignatory = parseJSON((r as any).AuthorisedSignatory);
  //   (r as any).Policies = parseJSON((r as any).Policies);
  //   return r;
  // }

  getApplicationMonthWiseStatistics(params: {
    userId: number,
    role: string,
    // aggregatorId: number,
    OriginatorBusinessId: number
  }): Observable<PayloadApiResponse<ApplicationMonthlyStatistics>> {
    return this.httpClient.post<PayloadApiResponse<ApplicationMonthlyStatistics>>(URL_GET_APPLICATION_MONTHWISE_STATISTICS(), params, httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<ApplicationMonthlyStatistics>
    //     })
    //   );
  }

  getApplicationStatistics(params: {
    userId: number,
    role: string,
    status: string,
    // aggregatorId: number,
    OriginatorBusinessId: number
  }): Observable<PayloadApiResponse<ApplicationStatistics>> {
    return this.httpClient.post<PayloadApiResponse<ApplicationStatistics>>(URL_GET_APPLICATION_STATISTICS(), params, httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<ApplicationStatistics>
    //     })
    //   );
  }

  getInProgressApplicationStatistics(params: {
    userId: number,
    role: string,
    status: string,
    // aggregatorId: number,
    OriginatorBusinessId: number
  }): Observable<PayloadApiResponse<InProgressApplicationStatistics>> {
    return this.httpClient.post<PayloadApiResponse<InProgressApplicationStatistics>>(URL_GET_INPROGRESS_APPLICATION_STATISTICS(), params, httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<InProgressApplicationStatistics>
    //     })
    //   );
  }

  getOverdraftProductTotalBalanceStatistics(): Observable<PayloadApiResponse<OverdraftProductApplicationStatistics>> {
    return this.httpClient.get<PayloadApiResponse<OverdraftProductApplicationStatistics>>(URL_GET_OVERDRAFT_PRODUCT_TOTAL_BALANCE_STATISTICS(), httpOptions()).pipe();

  }

  getSettledApplicationStatistics(params: {
    userId: number,
    role: string,
    status: string,
    // aggregatorId: number,
    OriginatorBusinessId: number
  }): Observable<PayloadApiResponse<SettledApplicationStatistics>> {
    return this.httpClient.post<PayloadApiResponse<SettledApplicationStatistics>>(URL_GET_SETTLED_APPLICATION_STATISTICS(), params, httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<SettledApplicationStatistics>
    //     })
    //   );
  }

  getNews(): Observable<PayloadApiResponse<GetNewsResult>> {
    return this.httpClient.get<PayloadApiResponse<GetNewsResult>>(URL_GET_NEWS(), httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<GetNewsResult>
    //     })
    //   );
  }

  getBankStatementsAnalysisFn: GetBankStatementsAnalysisFn = (params: {
    reference: string // abn
  }) => {
    return this.getBankStatementsAnalysis(params).pipe(map(r => r))
  }

  getBankStatementsAnalysis(params: {
    reference: string // abn
  }): Observable<PayloadApiResponse<BankStatementData | null>> {
    return this.httpClient.post<PayloadApiResponse<BankStatementData | null>>(URL_GET_BANKSTATEMENTS_ANALYSIS(), params, httpOptions()).pipe();
    //   .pipe(
    //     map(this.extractData),
    //     map(r => {
    //       return r as PayloadApiResponse<BankStatementsAnalysisData>
    //     })
    //   );
  }

  getBankStatementAndBasiqDataStatusFn: GetBankStatementAndBasiqDataStatusFn = (reference: string, type: BasiqReferenceType) => {
    return this.getBankStatementAndBasiqDataStatus(reference, type).pipe(map(r => r))
  }

  getBankStatementAndBasiqDataStatus(reference: string, type: BasiqReferenceType): Observable<PayloadApiResponse<BankstatementAndBasiqDataStatus | null>> {
    return this.httpClient.get<PayloadApiResponse<BankstatementAndBasiqDataStatus | null>>(URL_GET_BANK_STATEMENT_AND_BASIQ_DATA_STATUS(reference, type), httpOptions()).pipe();
  }

  getBasiqCustomerMappingByAbnFn: GetBasiqCustomerMappingFn = (reference: string) => {
    return this.getBasiqCustomerMappingByAbn(reference).pipe(map(r => r))
  }

  getBasiqCustomerMappingByAbn(reference: string): Observable<PayloadApiResponse<BasiqUserCustomerMapping | null>> {
    return this.httpClient.get<PayloadApiResponse<BasiqUserCustomerMapping | null>>(URL_GET_BASIQ_CUSTOMER_MAPPING_BY_ABN(reference), httpOptions()).pipe();
  }

  getBasiqStatementDataForCompanyFn: GetBasiqStatementDataForCompanyFn = (params: BasiqUserCustomerMapping) => {
    return this.getBasiqStatementDataForCompany(params).pipe(map(r => r))
  }

  getBasiqStatementDataForCompany(params: BasiqUserCustomerMapping): Observable<PayloadApiResponse<BankStatementData | null>> {
    return this.httpClient.post<PayloadApiResponse<BankStatementData | null>>(URL_GET_BASIQ_STATEMENT_DATA_FOR_COMPANY(), params, httpOptions()).pipe();
  }

  refreshBankStatement(abn: string): Observable<PayloadApiResponse<Response | { Error: string }>> {
    return this.httpClient.get<PayloadApiResponse<Response | { Error: string }>>(URL_REFRESH_BANK_STATEMENT(abn), httpOptions());
  }

  refreshBankStatementFn = (abn: string): Observable<PayloadApiResponse<Response | { Error: string }>> => {
    return this.refreshBankStatement(abn);
  }

  getApplicationUserId(application: Application | null, user: User): number {
    return (application && application.UserId ? application.UserId : user.UserId);
  }

  getApplicationCompanyId(application: Application | null, user: User): number | undefined {
    return (application && application.CompanyId ? application.CompanyId : user.OriginatorBusinessId);
  }

  getDocumentWorklist() {
    return this.httpClient.get<PayloadApiResponse<DocumentWorklists>>(URL_GET_DOCUMENT_WORKLIST(), httpOptions());
  }

  updateDocumentWorklist(worklist: DocumentWorklistTableData) {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DOCUMENT_WORKLIST(), worklist, httpOptions());
  }

  createDocumentWorklist(worklist: DocumentWorklistTableData) {
    return this.httpClient.post<PayloadApiResponse<boolean>>(URL_CREATE_DOCUMENT_WORKLIST(), worklist, httpOptions());
  }

  createDocumentWorklistFn = (worklist: DocumentWorklistTableData): Observable<PayloadApiResponse<boolean>> => {
    return this.createDocumentWorklist(worklist);
  }

  completeDocumentWorklist(applicationId: number) {
    return this.httpClient.post<ApiResponse>(URL_COMPLETE_DOCUMENT_WORKLIST(applicationId), {}, httpOptions());
  }

  completeDocumentWorklistFn = (applicationId: number): Observable<ApiResponse> => {
    return this.completeDocumentWorklist(applicationId);
  }

  createNewDisbursement(disbursement: Disbursement): Observable<number> {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_CREATE_NEW_DISBURSEMENT(), disbursement, httpOptions())
      .pipe(map(r => r.payload));
  }

  createNewDisbursementFn = (disbursement: Disbursement): Observable<number> => {
    return this.createNewDisbursement(disbursement);
  }

  updateDisbursement(disbursement: Disbursement): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DISBURSEMENT(), disbursement, httpOptions());
  }

  updateDisbursementFn = (disbursement: Disbursement): Observable<ApiResponse> => {
    return this.updateDisbursement(disbursement);
  }

  getDisbursementByApplicationId<D extends Disbursement>(applicationId: number): Observable<D[]> {
    return this.httpClient.get<PayloadApiResponse<D[]>>(URL_GET_DISBURSEMENT_BY_APPLICATION_ID(applicationId), httpOptions())
      .pipe(map(response => response.payload));
  }

  getDisbursementByApplicationIdFn = <D extends Disbursement>(applicationId: number): Observable<D[]> => {
    return this.getDisbursementByApplicationId(applicationId);
  }

  syncDisbursementToSf(body: SyncDisbursementBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_SYNC_DISBURSEMENT_TO_SF(), body, httpOptions());
  }

  syncDisbursementToSfFn = (body: SyncDisbursementBody): Observable<ApiResponse> => {
    return this.syncDisbursementToSf(body);
  }

  syncDepositPaidToSf = (body: SyncDepositPaidToBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SYNC_DEPOSIT_PAID_TO_SF(), body, httpOptions())
  }

  syncDepositPaidToSfFn = (body: SyncDepositPaidToBody): Observable<ApiResponse> => {
    return this.syncDepositPaidToSf(body);
  }

  settleLoan(body: SettleLoanBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_SETTLE_LOAN(), body, httpOptions());
  }

  settleLoanFn = (body: SettleLoanBody): Observable<ApiResponse> => {
    return this.settleLoan(body);
  }

  syncBankDetailsToSf(body: SyncSupplierBankDetailsBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_SYNC_BANK_DETAILS_TO_SF(), body, httpOptions());
  }

  syncBankDetailsToSfFn = (body: SyncSupplierBankDetailsBody): Observable<ApiResponse> => {
    return this.syncBankDetailsToSf(body);
  }

  syncPrivateSellerBankDetailsToSf(body: SyncPrivateSellerBankDetailsBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_SYNC_PRIVATE_SELLER_BANK_DETAILS_TO_SF(), body, httpOptions());
  }

  syncPrivateSellerBankDetailsToSfFn = (body: SyncPrivateSellerBankDetailsBody): Observable<ApiResponse> => {
    return this.syncPrivateSellerBankDetailsToSf(body);
  }

  getBillerName = (billerCode: string): Observable<PayloadApiResponse<BpayGetBillerByBillerCodeResult>> => {
    return this.httpClient.get<PayloadApiResponse<BpayGetBillerByBillerCodeResult>>(URL_GET_BPAY_BILLER_NAME_BY_BILLERCODE(billerCode), httpOptions());
  }

  getBillerNameFn = (billerCode: string): Observable<BpayGetBillerByBillerCodeResult> => {
    return this.getBillerName(billerCode).pipe(map(r => r.payload))
  }

  getInstitutionName = (bsb: string): Observable<PayloadApiResponse<DirectCreditInstitutionSearchResult | null>> => {
    return this.httpClient.get<PayloadApiResponse<DirectCreditInstitutionSearchResult>>(URL_GET_INSTITUTION_NAME(bsb), httpOptions())
  }

  getInstitutionNameFn = (bsb: string): Observable<DirectCreditInstitutionSearchResult | null> => {
    return this.getInstitutionName(bsb).pipe(map(r => r.payload))
  }

  validateBpay = (data: BpayValidationData): Observable<PayloadApiResponse<BpayValidatePaymentResult>> => {
    return this.httpClient.post<PayloadApiResponse<BpayValidatePaymentResult>>(URL_VALIDATE_BPAY(), data, httpOptions())
  }

  validateBpayFn = (data: BpayValidationData): Observable<BpayValidatePaymentResult> => {
    return this.validateBpay(data).pipe(map(r => r.payload))
  }

  updateApplicationStage(body: UpdateApplicationStageBody): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_APPLICATION_STAGE_IN_SF(), body, httpOptions());
  }

  updateApplicationStageFn = (body: UpdateApplicationStageBody): Observable<ApiResponse> => {
    return this.updateApplicationStage(body);
  }

  getBankDetailsFromOpportunitySf = (salesforceId: string): Observable<PayloadApiResponse<LocalBankAccountDetailValue | null>> => {
    return this.httpClient.get<PayloadApiResponse<LocalBankAccountDetailValue | null>>(URL_GET_BANK_DETAILS_FROM_OPPORTUNITY_SF(salesforceId), httpOptions())
  }

  getBankDetailsFromOpportunitySfFn = (salesforceId: string): Observable<LocalBankAccountDetailValue | null> => {
    return this.getBankDetailsFromOpportunitySf(salesforceId).pipe(map(r => r.payload));
  }
  generateApprovalNoticeFn = (body: GenerateApprovalNoticeBody): Observable<GenerateContractResponse> => {
    return this.httpClient.post<PayloadApiResponse<GenerateContractResponse>>(URL_GENERATE_APPROVAL_NOTICE(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<GenerateContractResponse>) => r.payload));
  }

  sendApprovalNoticeEmailFn = (body: SendApprovalNoticeEmailBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_APPROVAL_NOTICE_EMAIL(), body, httpOptions());
  }

  sendDocumentReviewEmailFn = (body: DocumentReviewEmailBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_DOCUMENT_REVIEW_EMAIL(), body, httpOptions());
  }

  deleteApprovalNoticeFileFn = (body: DeleteContractBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_APPROVAL_NOTICE_FILE(), body, httpOptions());
  }

  getRbaRateFn: GetRbaRateFn = () => {
    return this.getRbaRate().pipe(map(r => r.payload));
  }

  getRbaRate() {
    return this.httpClient.get<PayloadApiResponse<number>>(URL_FETCH_RBA_RATES(), httpOptions());
  }

  copyApplicationFn = (body: CopyApplicationBody): Observable<SaveApplicationResult> => {
    return this.httpClient.post<PayloadApiResponse<SaveApplicationResult>>(URL_COPY_APPLICATION(), body, httpOptions())
      .pipe(map((r: PayloadApiResponse<SaveApplicationResult>) => r.payload))
  }

  getApplicationOwnerFn = (salesforceId: string): Observable<ApplicationOwner | null> => {
    return this.httpClient.get<PayloadApiResponse<ApplicationOwner | null>>(URL_GET_APPLICATION_OWNER(salesforceId), httpOptions())
      .pipe(map((r: PayloadApiResponse<ApplicationOwner | null>) => r.payload));
  }

  getApplicationBySalesforceIdFn = (salesforceId: string): Observable<Application | null> => {
    return this.httpClient.get<PayloadApiResponse<Application | null>>(URL_GET_APPLICATION_BY_SALESFORCE_ID(salesforceId), httpOptions())
      .pipe(map((r: PayloadApiResponse<Application | null>) => r.payload));
  }

  deleteApplicationByAppId = (applicationId: number): Observable<ApiResponse> => {
    return this.httpClient.put<ApiResponse>(URL_DELETE_APPLICATION(applicationId), {}, httpOptions())
  }

  getAllApplicationSearch(opts: {
    page: { offset: number, limit: number, },
    filter: string,
    sorts?: ApplicationSortTypes,
  }): Observable<PaginablePayloadApiResponse<SimplifiedApplication>> {
    return this.httpClient.get<PaginablePayloadApiResponse<SimplifiedApplication>>(URL_ALL_APPLICATION_SEARCH(toPaginationInfo(opts)), httpOptions())
  }

  getAccountDetailsFromSfFn = (salesforceId: string): Observable<SfAccountDetails> => {
    return this.httpClient.get<PayloadApiResponse<SfAccountDetails>>(URL_GET_ACCOUNT_DETAILS_FROM_SF(salesforceId), httpOptions())
      .pipe(map(r => r.payload));
  }

  extractTaxInvoiceFn = async (file: File): Promise<Observable<TaxInvoiceDetails>> => {
    const base64Files = await filesToBase64Files([file]);
    console.log(base64Files);
    return this.httpClient.post<PayloadApiResponse<TaxInvoiceDetails>>(URL_EXTRACT_TAX_INVOICE(), {
      data: base64Files[0].base64.split(',')[1],
      type: file.type
    }, httpOptions())
      .pipe(map(r => r.payload));
  }

  getLvrCalculatorValue = (applicationId: number): Observable<PayloadApiResponse<LvrCalculatorValue>> => {
    return this.httpClient.get<PayloadApiResponse<LvrCalculatorValue>>(URL_GET_LVR_CALCULATOR_VALUE(applicationId), httpOptions());
  }

  getLvrCalculatorValueFn = (applicationId: number): Observable<LvrCalculatorValue> => {
    return this.getLvrCalculatorValue(applicationId).pipe(map(v => v.payload));
  }

  updateLvrCalculatorValueFn = (applicationId: number, input: LvrCalculatorInput): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_LVR_CALCULATOR_VALUE(applicationId), input, httpOptions());
  }

  getDscrCalculatorValue = (applicationId: number): Observable<PayloadApiResponse<DscrCalculatorValue>> => {
    return this.httpClient.get<PayloadApiResponse<DscrCalculatorValue>>(URL_GET_DSCR_CALCULATOR_VALUE(applicationId), httpOptions());
  }

  getDscrCalculatorValueFn = (applicationId: number): Observable<DscrCalculatorValue> => {
    return this.getDscrCalculatorValue(applicationId).pipe(map(v => v.payload));
  }

  getConsumerDscrCalculatorValue = (applicationId: number): Observable<PayloadApiResponse<ConsumerDscrCalculatorValue>> => {
    return this.httpClient.get<PayloadApiResponse<ConsumerDscrCalculatorValue>>(URL_GET_CONSUMER_DSCR_CALCULATOR_VALUE(applicationId), httpOptions());
  }

  getConsumerDscrCalculatorValueFn = (applicationId: number) => {
    return this.getConsumerDscrCalculatorValue(applicationId).pipe(map(v => v.payload));
  }

  updateDscrCalculatorValueFn = (applicationId: number, input: DscrCalculatorInput): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_DSCR_CALCULATOR_VALUE(applicationId), input, httpOptions());
  }

  updateConsumerDscrCalculatorValueFn = (applicationId: number, input: ConsumerDscrCalculatorInput): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_CONSUMER_DSCR_CALCULATOR_VALUE(applicationId), input, httpOptions());
  }

  consumerFinanceDscrServiceabilityCalculationFn = (input: ConsumerFinanceDscrServiceabilityCalculationInput): Observable<ConsumerFinanceDscrServiceabilityCalculationResult> => {
    return this.consumerFinanceDscrServiceabilityCalculation(input).pipe(map(r => r.payload));
  }

  consumerFinanceDscrServiceabilityCalculation = (input: ConsumerFinanceDscrServiceabilityCalculationInput): Observable<PayloadApiResponse<ConsumerFinanceDscrServiceabilityCalculationResult>> => {
    return this.httpClient.post<PayloadApiResponse<ConsumerFinanceDscrServiceabilityCalculationResult>>(URL_CONSUMER_FINANCE_DSCR_SERVICEABILITY_CALCULATION(), input, httpOptions());
  }

  createAssetInspectionFn = (assetId: number, applicationId: number, data: VerimotoAssetInspectionData): Observable<PayloadApiResponse<VerimotoCreateInspectionResponse>> => {
    return this.httpClient.post<PayloadApiResponse<VerimotoCreateInspectionResponse>>(URL_CREATE_ASSET_INSPECTION(), {
      data,
      assetId,
      applicationId
    }, httpOptions());
  }

  getAssetInspectionsForApplicationFn = (applicationId: number): Observable<VerimotoInspectionTableDataWithInspectionDetails[]> => {
    return this.httpClient.get<PayloadApiResponse<VerimotoInspectionTableDataWithInspectionDetails[]>>(URL_GET_ASSET_INSPECTIONS_FOR_APPLICATION(applicationId), httpOptions())
      .pipe(map((res: PayloadApiResponse<VerimotoInspectionTableDataWithInspectionDetails[]>) => res.payload));
  }

  downloadVerimotoReportFn = (inspectionId: number): Observable<any> => {
    return this.httpClient.get<PayloadApiResponse<any>>(URL_DOWNLOAD_VERIMOTO_REPORT(inspectionId), httpOptions())
      .pipe(map((res: PayloadApiResponse<any>) => res.payload))
  }

  downloadDocumentFromAzureFn = (file: string, container: AzureStorageContainer = 'app-documents') => {
    return this.httpClient.post<PayloadApiResponse<{ type: "Buffer"; data: Buffer }>>(URL_DOWNLOAD_DOCUMENT_FROM_AZURE(), { file, container }, httpOptions())
      .pipe(map((res: PayloadApiResponse<{ type: "Buffer"; data: Buffer }>) => res.payload));
  }

  sendIdVerifyLinkFn = (email: string, name: string, link: string) => {
    return this.httpClient.post<ApiResponse>(URL_SEND_ID_VERIFY_LINK(), {
      email,
      name,
      link,
    }, httpOptions());
  }

  bypassFaceCompareFn = (identityVerificationId: number) => {
    return this.httpClient.post<ApiResponse>(URL_BYPASS_FACE_COMPARE(identityVerificationId), {}, httpOptions());
  }

  deleteIdentityVerificationFn = (identityVerificationId: number) => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_IDENTITY_VERIFICATION(identityVerificationId), {}, httpOptions());
  }

  getActiveCreditUsers() {
    return this.httpClient.get<PayloadApiResponse<UserOmitPriviledges[]>>(URL_GET_ACTIVE_CREDIT_USERS(), httpOptions());
  }

  sendDirectSaleDraftNotificationFn(
    firstName: string,
    lastName: string,
    email: string,
    applicationId: number,
    applicationType: ApplicationTypes,
    businessName: string,
    token: string
  ) {
    return this.httpClient.post<ApiResponse>(URL_SEND_DIRECT_SALE_DRAFT_NOTIFICATION(), {
      firstName,
      lastName,
      email,
      applicationId,
      applicationType,
      businessName,
    }, httpOptionsForDirectSale(token));
  }

  getPrivacyConsentById(id: number): Observable<PrivacyConsent | null> {
    return this.httpClient.get<PayloadApiResponse<PrivacyConsent | null>>(URL_GET_PRIVACY_CONSENT_BY_ID(id), httpOptions())
      .pipe(map((response: PayloadApiResponse<PrivacyConsent | null>) => response.payload));
  }

  sendPrivacyConsentEmailFn = (
    individual: IndividualWithResult,
    applicationId: number,
    applicationName: string
  ): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_PRIVACY_CONSENT_EMAIL(), {
      individual,
      applicationId,
      applicationName,
    }, httpOptions());
  }

  updatePrivacyConsentStatusFn = (id: number, status: PrivacyConsentStatus, ip: string) => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_PRIVACY_CONSENT_STATUS(), {
      id,
      status,
      ip,
    }, httpOptions());
  }

  getPrivacyConsentDocFn = (): Observable<string> => {
    return this.httpClient.get<PayloadApiResponse<string>>(URL_GET_PRIVACY_CONSENT_DOC(), httpOptions())
      .pipe(map(response => response.payload));
  }

  getBsaLenderListFn = (): Observable<BsaLender[]> => {
    return this.httpClient.get<PayloadApiResponse<BsaLender[]>>(URL_GET_BSA_LENDER_LIST(), httpOptions())
      .pipe(map(response => response.payload));
  }

  getBsaExcludedLenderListFn = (): Observable<BsaLender[]> => {
    return this.httpClient.get<PayloadApiResponse<BsaLender[]>>(URL_GET_BSA_EXCLUDED_LENDER_LIST(), httpOptions())
      .pipe(map(response => response.payload));
  }

  updateBsaLenderFn = (data: UpdateLenderBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_BSA_LENDER(), data, httpOptions());
  }

  updateBsaExcludedLenderFn = (data: UpdateLenderBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_BSA_EXCLUDED_LENDER(), data, httpOptions());
  }

  saveBsaCalculatorFn = (data: SaveBsaCalculatorBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_BSA_SAVE_CALCULATOR(), data, httpOptions());
  }

  getBsaCalculatorFn = (applicationId: number): Observable<BsaCalculator | null> => {
    return this.httpClient.get<PayloadApiResponse<BsaCalculator | null>>(URL_BSA_GET_CALCULATOR(applicationId), httpOptions())
      .pipe(map(r => r.payload));
  }

  deleteBsaLenderFn = (id: number) => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_BSA_LENDER(id), {}, httpOptions());
  }

  deleteBsaExcludedLenderFn = (id: number) => {
    return this.httpClient.post<ApiResponse>(URL_DELETE_BSA_EXCLUDED_LENDER(id), {}, httpOptions());
  }

  addBsaLenderFn = (data: AddLenderBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_ADD_BSA_LENDER(), data, httpOptions());
  }

  addBsaExcludedLenderFn = (data: AddLenderBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_ADD_BSA_EXCLUDED_LENDER(), data, httpOptions());
  }

  sendAdminPrivacyConsentFn = (body: SendAdminPrivacyConsentEmailBody): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_ADMIN_PRIVACY_CONSENT(), body, httpOptions());
  }

  getAdminPrivacyConsentFn = (id: number): Observable<AdminPrivacyConsent | null> => {
    return this.httpClient.get<PayloadApiResponse<AdminPrivacyConsent | null>>(URL_GET_ADMIN_PRIVACY_CONSENT(id), httpOptions())
      .pipe(map(r => r.payload));
  }

  updateAdminPrivacyConsentStatusFn = (id: number, status: string, ip: string): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_UPDATE_ADMIN_PRIVACY_CONSENT_STATUS(), {
      id,
      status,
      ip,
    }, httpOptions());
  }

  uploadBankStatementsFn = async (files: File[], applicationId: number): Promise<Observable<ApiResponse>> => {
    const base64Files = [...await filesToBase64Files(files)];
    const nf = base64Files.map(f => {
      return {
        base64: f.base64,
        name: f.name.replace(/[^\t\x20-\x7e\x80-\xff]/, '_'),
        size: f.size,
        type: f.type,
        tags: f?.tags ? [...f.tags, 'bankstatements'] : ['bankstatements'],
        metadata: f?.metadata ?? {}
      }
    });
    return this.httpClient.post<ApiResponse>(URL_UPLOAD_BANK_STATEMENTS(applicationId), {
      new: nf,
    }, httpOptionsForDirectSale(''));
  }

  getDscrCalculatorHistoryFn = (applicationId: number): Observable<DscrCalculatorHistory[]> => {
    return this.httpClient.get<PayloadApiResponse<DscrCalculatorHistory[]>>(URL_BSA_GET_DSCR_CALCULATOR_HISTORY(applicationId), httpOptions())
      .pipe(map(response => response.payload));
  }

  sendConsumerQuotationFn = (body: SendConsumerQuotationData): Observable<ApiResponse> => {
    return this.httpClient.post<ApiResponse>(URL_SEND_CONSUMER_QUOTATION(), body, httpOptions());
  }

  generateConsumerQuotationFn = (body: DownloadConsumerQuotationData): Observable<number> => {
    return this.httpClient.post<PayloadApiResponse<number>>(URL_GENERATE_CONSUMER_QUOTATION(), body, httpOptions())
      .pipe(map(response => response.payload));
  }
}
