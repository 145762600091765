import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { DocumentTag, UploadFileDialogData, UploadFilesWithTagsDialogResult, constants } from '@portal-workspace/grow-shared-library';
import { Subscription, tap } from 'rxjs';
import { ApplicationDialogService } from './application-dialog.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './selectable-tags.dialog.html',
    styleUrls: ['./selectable-tags.dialog.scss'],
    standalone: true,
    imports: [ MatButtonModule, MatDialogModule, MatChipsModule, FlexModule, MatCardModule]
})

export class SelectableTagsDialog implements OnInit{

    selectableTags: DocumentTag[] = [];
    tags: DocumentTag[] = [];
    tagsSel: DocumentTag[] = [];
    allTags: DocumentTag[] = [];
    MAX_TAG_ALLOWED = 10;
    showMaxTagErrorMessage = false;
    enableCustomTag: boolean = false;
    dialogData: UploadFileDialogData
    subscriptions: Subscription[] = [];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<SelectableTagsDialog, UploadFilesWithTagsDialogResult>,
      private formBuilder: FormBuilder,
      private dialogService: ApplicationDialogService,) {
        // this.selectableTags = data.selectableTags;
        this.tagsSel = data.tagsSel;
        // this.allTags= data.allTags;
        // this.showMaxTagErrorMessage= data.showMaxTagErrorMessage;
   
        this.allTags= data.allTags;
        this.dialogData = data.dialogData;
    }

    ngOnInit(){
       
      //this.allTags = this.allTags ?? Object.values(constants.documentTypes) as DocumentTag[];
      this.allTags = this.allTags.sort((a, b) => a.type.localeCompare(b.type));
      this.enableCustomTag = !!this.dialogData?.enableCustomTag;

      if(this.tagsSel){
        this.tags.push(...this.tagsSel);
      }
      if (this.dialogData?.tags && this.dialogData?.tags?.length) {
        for (const tagValue of this.dialogData.tags) {
          const tagObj = this.allTags.find(tag => tag.value === tagValue);
          if (tagObj) {
            this.tags.push(tagObj);
          }
        }
      }

      this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
    }
  
    // onSubmit($event: Event) {
    //   const result: any = {}
    //   this.dialogRef.close(result);
    // }

    onCancel($event: MouseEvent) {
      this.matDialogRef.close({
        valid: false,
        files: [],
        selectedTags: this.tags
      })
    }

    onSubmit($event: MouseEvent) {
      this.matDialogRef.close({
        valid: true,
        files:  [],
        selectedTags: this.tags
      });
    }

    remove(tag: DocumentTag): void {
      const index = this.tags.indexOf(tag);
  
      if (index >= 0) {
        this.tags.splice(index, 1);
  
        // remove max tag error message
        if (this.tags.length < this.MAX_TAG_ALLOWED) {
          this.showMaxTagErrorMessage = false;
        }
      }
      this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
    }

    addTag(tag: DocumentTag) {
        // avoid duplicated tags
        const tagObj = this.tags.find(t => t.value === tag.value);
        if (!tagObj) {
          // do not allow more than 10 tags
          if (this.tags.length >= this.MAX_TAG_ALLOWED) {
            this.showMaxTagErrorMessage = true;
            return;
          }
    
          this.tags.push(tag);
        }
        this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
    }

    addCustomTag() {
      this.subscriptions.push(
        this.dialogService.openCustomDocumentTagDialog({ nonStandardCondition: false }).afterClosed().pipe(
          tap(r => {
            if (r && r.readyForSubmission) {
              this.allTags.push(r.tag);
              this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
            }
          })
        ).subscribe()
      );
    }

    // get fileData() {
    //   const tagValue = this.tags.map(tag => tag.value);
    //   return this.formControl?.value?.map((file) => {
    //     (file).tags = tagValue;
    //     if (this.dialogData.metadata) {
    //       (file).metadata = this.dialogData.metadata;
    //     }
    //     return file;
    //   })
    // }
}