<div class="edit-monthly-facility-fee-percentage-dialog">
  <mat-dialog-content>
    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1 header">Edit Monthly Facility Fee%</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <mat-form-field>
          <mat-label>Monthly Facility Fee Percentage</mat-label>
          <input matInput type="number" [formControl]="formControlMonthlyFacilityFeePercentage" required>
          <span class="mdi mdi-percent" matSuffix></span>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="text">Monthly Facility Fee: {{monthlyFacilityFee | looseCurrency}}</div>
      </div>
    </div>

    <div class="width-100" fxLayout="row" fxLayoutAlign="space-between">
      <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
      <button mat-flat-button color="primary" (click)="onSave()"  [disabled]="formControlMonthlyFacilityFeePercentage.invalid">Save</button>
    </div>
  </mat-dialog-content>
</div>
