import {Component, Input} from "@angular/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {ConsumerDscrCalculatorComponent} from "./consumer-dscr-calculator.component";
import {
  Application,
  ConsumerFinanceDsrServiceabliltyCalculationFn,
  isInternalUser
} from "@portal-workspace/grow-shared-library";
import { GetConsumerDscrCalculatorValueFn, UpdateConsumerDscrCalculatorValueFn } from "@portal-workspace/grow-shared-library";

@Component({
  selector: 'consumer-credit',
  templateUrl: './consumer-credit.component.html',
  styleUrls: ['./consumer-credit.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    ConsumerDscrCalculatorComponent,
  ],
})
export class ConsumerCreditComponent {

  @Input({required: true}) application!: Application;
  @Input({required: true}) getConsumerDscrCalculatorValueFn!: GetConsumerDscrCalculatorValueFn
  @Input({required: true}) updateConsumerDscrCalculatorValueFn!: UpdateConsumerDscrCalculatorValueFn;
  @Input({required: true}) consumerFinanceDscrServiceabilityCalculationFn!: ConsumerFinanceDsrServiceabliltyCalculationFn;

  isInternalUser = isInternalUser;

}
