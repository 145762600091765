import {Component, forwardRef, Input, OnInit} from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators, FormControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {delay, distinctUntilChanged, tap} from 'rxjs/operators';
import {AbstractControlValueAccessor} from '../abstract-control-value-accessor';
import { compareMatch, OriginatorListValue } from '@portal-workspace/grow-shared-library'
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { MatFormFieldModule } from '@angular/material/form-field';

export type OriginatorListValueGetFn = ()=>Observable<Exclude<OriginatorListValue, null>[]>;


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'originator-list',
    templateUrl: './originator-list.component.html',
    styleUrls: ['./originator-list.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OriginatorListComponent), multi: true },
    ],
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatOptionModule]
})
export class OriginatorListComponent extends AbstractControlValueAccessor<OriginatorListValue> implements OnInit {

  subscriptions: Subscription[] = [];

  @Input({required: true}) title?: string;

  formControl: FormControl<OriginatorListValue>;

  originators: Exclude<OriginatorListValue, null>[] = [];

  @Input({required: true}) getFn!: OriginatorListValueGetFn;

  constructor(private formBuilder: FormBuilder) {
    super();
    this.formControl = formBuilder.control(null, [Validators.required]);
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.getFn().pipe(
      tap(r => {
        this.originators = r ?? [];
      })
    ).subscribe();
    const sub = this.formControl.valueChanges.pipe(
      delay(0),
      distinctUntilChanged(compareMatch),
      tap(r => {
        if (this.formControl.valid) {
          this.propagateChange(r);
        } else {
          this.propagateChange(null);
        }
      })
    ).subscribe();

  }

  doWriteValue(v: OriginatorListValue | null | undefined): void | OriginatorListValue {
    if (v) {
      setTimeout(()=> {
        const o: OriginatorListValue | undefined = this.originators.find(opt => opt.originatorBusinessId == v.originatorBusinessId);
        if (o) {
          this.formControl.setValue(o);
        }
      }, 1000);
    }
    return undefined;
  }

}
