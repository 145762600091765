import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import { AsyncPipe, DecimalPipe, NgClass } from "@angular/common";
import {
  ApplicationDialogService,
  formControlErrorKeys,
  formControlErrorMessage,
  getUser,
  PortalHotToastService
} from "@portal-workspace/grow-ui-library";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTooltipModule} from "@angular/material/tooltip";
import {LooseCurrencyPipe} from '../../pipes/loose-currency.pipe';
import {MessageBoxComponent} from '../message-box/message-box.component';
import {CurrencyInputComponent} from '../currency-selection-component/currency-input.component';
import {
  CreateAccountInterestRateFn,
  CreateOverdraftAccountDialogResult,
  CreateOverdraftAccountFn,
  CreateOverdraftUserFn,
  CurrencyInputValue,
  CustomerUser,
  DigitalIdGetApplicationIndividualsFn,
  EditMonthlyFacilityFeePercentageDialogResult,
  FirstOverdraftUserDialogResult,
  GeneratePayoutDialogResult,
  GetAccountDetailsFromSfFn,
  GetAllPismoAccountStatusFn,
  GetCustomerFn,
  GetOpportunitiesForCustomerFn,
  GetPendingPaymentsFn,
  GetPismoAccountAndOpportunityDetailsFn,
  GetPismoAccountStatusReasonsFn,
  GetPismoCustomerForAccountFn,
  GetPismoProgramDueDate,
  getPismoProgramsFn,
  GetPismoTransactionFlowFn,
  GetPismoTransactionTypeByIdFn,
  GetRateCardDetailsFn,
  GetRbaRateFn,
  GetUserByEmailFn,
  isInternalUser,
  ManualDirectDebitFn,
  OverdraftAccountLimitIncreaseDialogResult,
  OverdraftAccountLimitIncreaseFn,
  OverdraftDirectDebitDialogResult,
  PismoAccountAndOpportunityResponse,
  PismoAccountMappingAndApplication,
  PismoCheckEmailExistsFn,
  PismoGeneratePayoutFn,
  PismoGetAccountInterestRateFn,
  PismoGetAccountResponse,
  PismoGetBuiltInTransactionTypesFn,
  PismoGetCardsForAccountFn, PismoGetPaidFacilityFeeFn, PismoGetPendingInterestFn, PismoGetTransactionTypesFn,
  PismoRollbackAccountStatusFn,
  PismoSendPayoutNotificationFn,
  PismoUpdateAccountLimitFn,
  PismoUpdateAccountStatusFn,
  PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult,
  PismoUpdateRequireMonthlyFixedInstallmentFlagFn,
  PortalLoginUser,
  PostManualTransactionFn,
  PostOverdraftAccountFeeFn,
  RatecardDetails,
  SyncBankDetailsToSfFn,
  TransferredBalanceDialogResult,
  UpdateMonthlyFacilityFeeFn,
  UpdatePismoAccountPaymentLimitFn,
  ValidEmailCheckFn
} from "@portal-workspace/grow-shared-library";
import {FormBuilder, FormControl, ReactiveFormsModule, Validators} from "@angular/forms";
import {UntilDestroy} from "@ngneat/until-destroy";
import {combineLatest, Subscription} from "rxjs";

import {MatExpansionModule} from "@angular/material/expansion";
import {loadingFor} from "@ngneat/loadoff";
import {tap} from "rxjs/operators";
import moment from 'moment';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import{CustomContentLoaderComponent} from "../custom-content-loader-component/custom-content-loader.component";
import {EditableComponent, EditableSaveDirective, EditModeDirective, ViewModeDirective} from "@ngneat/edit-in-place";
import {PercentagePipe} from '../../pipes/percentage.pipe';

export type PismoAccountAndOpporunityResponseWithFormControlPaymentLimit = PismoAccountAndOpportunityResponse & {formControlPaymentLimit: FormControl<CurrencyInputValue>, monthlyFacilityFee: number};

export type OverdraftAccountDetailsComponentEvent =  OverdraftAccountDetailsComponentDisplayTransactionEvent | OverdraftAccountDetailsComponentLoadAccountUsersEvent | OverdraftAccountDetailsComponentAfterPostFeeSuccessEvent | OverdraftAccountFlagChangeSuccessEvent;

export type OverdraftAccountDetailsComponentDisplayTransactionEvent = {
  type: 'display-transaction',
  customerId: number;
  pismoAccountNumber: number
}

export type OverdraftAccountDetailsComponentCreateUserEvent = {
  type: 'create-user',
}

export type OverdraftAccountDetailsComponentLoadAccountUsersEvent = {
  type: 'account-user-created',
}

export type OverdraftAccountDetailsComponentAfterPostFeeSuccessEvent = {
  type: 'retry-account-fees'
}

export type OverdraftAccountFlagChangeSuccessEvent = {
  type: 'flag-change'
}

export type PismoAccountWithInterestRate = {
  interestRate: number;
  pismoAccountNumber: number;
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'overdraft-account-details',
  templateUrl: './overdraft-account-details.component.html',
  styleUrls: ['./overdraft-account-details.component.scss'],
  standalone:  true,
  imports: [
    AsyncPipe,
    CurrencyInputComponent,
    DecimalPipe,
    FlexModule,
    LooseCurrencyPipe,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatTooltipModule,
    CustomContentLoaderComponent,
    MatExpansionModule,
    NgClass,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MessageBoxComponent,
    EditableSaveDirective,
    EditableComponent,
    EditModeDirective,
    ViewModeDirective,
    PercentagePipe
  ]
})
export class OverdraftAccountDetailsComponent implements OnInit, OnChanges {

  moment = moment;
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  formBuilder: FormBuilder;

  pismoAccountAndOpportunityDetails: PismoAccountAndOpporunityResponseWithFormControlPaymentLimit[] = [];
  pismoAccountWithInterestRate: PismoAccountWithInterestRate[] = [];

  loader = loadingFor('loadingProfile', 'loadingPassword', 'loadingMisc', 'savingFloorplan', 'loadingOverdraft', 'paymentLimitUpdated');

  subscriptions: Subscription[] = [];

  dialogService: ApplicationDialogService;
  portalHotToast: PortalHotToastService;
  formControlRequireMonthlyFixedInstallment!: FormControl<boolean | null>;

  user: PortalLoginUser | null = getUser();
  canAccess = this.user?.priviledges?.includes('admin') || this.user?.priviledges?.includes('operations') 
  interestRate!: number;
  @Input({required: true}) customer!: CustomerUser;
  @Input({required: true}) existingPismoAccountMappings: PismoAccountMappingAndApplication[] = [];
  @Input({required: false}) canEdit: boolean = true;
  @Input({required: true}) overdraftCustomerSfRecordId!: string;
  @Input({required: true}) getPismoAccountAndOpportunityDetailsFn!:GetPismoAccountAndOpportunityDetailsFn;
  @Input({required: true}) updatePismoAccountPaymentLimitFn!: UpdatePismoAccountPaymentLimitFn;
  @Input({required: true}) getOpportunitiesForCustomerFn!: GetOpportunitiesForCustomerFn;
  @Input({required: true}) validEmailCheckFn!: ValidEmailCheckFn;
  @Input({required: true}) getUserByEmailFn!: GetUserByEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) createOverdraftUserFn!: CreateOverdraftUserFn;
  @Input({required: true}) postOverdraftAccountFeeFn!: PostOverdraftAccountFeeFn;
  @Input({required: true}) createAccountInterestRateFn!: CreateAccountInterestRateFn;
  @Input({required: true}) getPismoTransactionTypeByIdFn!: GetPismoTransactionTypeByIdFn;
  @Input({required: true}) postManualTransactionFn!: PostManualTransactionFn;
  @Input({required: true}) getPismoTransactionFlowFn!: GetPismoTransactionFlowFn;
  @Input({required: true}) getPismoCardsForAccountFn!: PismoGetCardsForAccountFn;
  @Input({required: true}) getPismoTransactionTypesFn!: PismoGetTransactionTypesFn;
  @Input({required: true}) updatePismoAccountLimitFn!: PismoUpdateAccountLimitFn;
  @Input({required: true}) getAllPismoAccountStatusFn!: GetAllPismoAccountStatusFn;
  @Input({required: true}) getPismoAccountStatusReasonsFn!: GetPismoAccountStatusReasonsFn;
  @Input({required: true}) updatePismoAccountStatusFn!: PismoUpdateAccountStatusFn;
  @Input({required: true}) rollbackPismoAccountStatusFn!: PismoRollbackAccountStatusFn;
  @Input({required: true}) pismoCheckEmailExistsFn!: PismoCheckEmailExistsFn;
  @Input({required: true}) pismoGetBuiltInTransactionTypesFn!: PismoGetBuiltInTransactionTypesFn;
  @Input({required: true}) pismoUpdateRequireMonthlyFixedInstallmentFlagFn!: PismoUpdateRequireMonthlyFixedInstallmentFlagFn;
  @Input({required: true}) pismoGetPaidFacilityFeeFn!: PismoGetPaidFacilityFeeFn;
  @Input({required: true}) pismoGetPendingInterestFn!: PismoGetPendingInterestFn;
  @Input({required: true}) getPismoCustomerForAccountFn!: GetPismoCustomerForAccountFn;
  @Input({required: true}) pismoSendPayoutNotificationFn!: PismoSendPayoutNotificationFn;
  @Input({required: true}) pismoGeneratePayoutFn!: PismoGeneratePayoutFn;
  @Input({required: true}) getPendingPaymentsFn!: GetPendingPaymentsFn;
  @Input({required: true}) pismoGetAccountInterestRateFn!: PismoGetAccountInterestRateFn;
  @Input({required: true}) getDefaultRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input({required: true}) getRbaRateFn!: GetRbaRateFn;
  @Input({required: true}) getCustomerFn!: GetCustomerFn;
  @Input({required: true}) overdraftAccountLimitIncreaseFn!: OverdraftAccountLimitIncreaseFn;
  @Input({required: true}) manualDirectDebitFn!: ManualDirectDebitFn;
  @Input({required: true}) updateMonthlyFacilityFeeFn!: UpdateMonthlyFacilityFeeFn;

  @Input({required: false}) showDisplayTransactions: boolean = true;

  @Output() events: EventEmitter<OverdraftAccountDetailsComponentEvent> = new EventEmitter<OverdraftAccountDetailsComponentEvent>();

  constructor() {
    this.dialogService = inject(ApplicationDialogService);
    this.formBuilder = inject(FormBuilder);
    this.portalHotToast = inject(PortalHotToastService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['existingPismoAccountMappings'];
    if (change && change.currentValue && change.currentValue.length) {
      this.load();
    }
  }

  load() {
    const pismoAccountNumbers: number[] = (this.existingPismoAccountMappings ?? []).map(m => m.pismoAccountNumber);
    this.subscriptions.push(
      combineLatest([
        this.getPismoAccountAndOpportunityDetailsFn(pismoAccountNumbers),
        this.getDefaultRatecardDetailsFn(0, 'BusinessOverdraft')
      ])
        .pipe(
          this.loader.loadingOverdraft.track(),
        )
        .subscribe(
          ([response, rateCard]: [PismoAccountAndOpportunityResponse[], RatecardDetails]) => {
            console.log('=====pismo account details: ', response)
            this.pismoAccountAndOpportunityDetails = (response ?? []).map(pismoAccount => {
              const paymentLimit = pismoAccount.pismoAccountDetails.custom_fields.paymentLimit ?? 0;
              const formControlPaymentLimit = this.formBuilder.control(paymentLimit, [Validators.required, Validators.min(50), Validators.max(pismoAccount.pismoAccountDetails.total_credit_limit)]);
              const monthlyFacilityFeePercentage = pismoAccount.pismoAccountDetails.custom_fields.monthlyFacilityFeePercentage ?? 0;
              const monthlyFacilityFee = Math.round(100 * pismoAccount.pismoAccountDetails.max_credit_limit * monthlyFacilityFeePercentage / 1200) / 100;
              return {
                ...pismoAccount,
                formControlPaymentLimit,
                monthlyFacilityFee,
              }
            });
          })
    )

    const interestRateSubs = [];
    this.pismoAccountWithInterestRate = [];
    for (const pismoAccountNumber of pismoAccountNumbers) {
      interestRateSubs.push(
        this.pismoGetAccountInterestRateFn(pismoAccountNumber)
      )
    }

    this.subscriptions.push(
      combineLatest(interestRateSubs).subscribe((interestRates: number[]) => {
        for (let i = 0; i < interestRates.length; i++) {
          this.pismoAccountWithInterestRate.push({
            pismoAccountNumber: pismoAccountNumbers[i],
            interestRate: interestRates[i]
          })
        }

        console.log('====this.pismoAccountWithInterestRate: ', this.pismoAccountWithInterestRate)
      })
    )
  }


  onCreateNewOverdraftUser(pismoAccountId: number) {
    const pismoAccountMapping = this.existingPismoAccountMappings.find(acc => acc.pismoAccountNumber);
    this.subscriptions.push(
      this.dialogService.openFirstOverdraftUserDialog({
        application: pismoAccountMapping?.application ?? null,
        customer: this.customer as CustomerUser,
        validEmailCheckFn: this.validEmailCheckFn,
        getUserByEmailFn: this.getUserByEmailFn,
        getApplicationIndividualsFn: this.getApplicationIndividualsFn,
        createOverdraftUserFn: this.createOverdraftUserFn,
        pismoCheckEmailExistsFn: this.pismoCheckEmailExistsFn,
        pismoAccountNumbers: [pismoAccountId],
      }).afterClosed().subscribe((result: FirstOverdraftUserDialogResult | undefined) => {
        if (result) {
          // this.pismoAccountAndOpportunityDetails = []
          this.load();
          this.events.emit({
            type: 'account-user-created',
          });
        }
      })
    )
  }

  async displayPismoAccountTransaction(pismoAccountId: number) {
    this.events.emit({
      type: 'display-transaction',
      customerId: this.customer.CustomerId,
      pismoAccountNumber: pismoAccountId,
    });
  }


  postPismoManualTransaction(pismoAccountId: number) {
    this.subscriptions.push(
      this.dialogService.openPismoPostManualTransactionDialog({
        pismoAccountId: pismoAccountId,
        getPismoTransactionTypeByIdFn: this.getPismoTransactionTypeByIdFn,
        postManualTransactionFn: this.postManualTransactionFn,
        getPismoTransactionFlowFn: this.getPismoTransactionFlowFn,
        pismoGetCardsForAccountFn: this.getPismoCardsForAccountFn,
        pismoGetTransactionTypesFn: this.getPismoTransactionTypesFn,
        pismoGetBuiltInTransactionTypesFn: this.pismoGetBuiltInTransactionTypesFn,
      }).afterClosed().pipe(

      ).subscribe()
    )
  }


  editPismoAccount(accountDetails: PismoAccountAndOpportunityResponse) {
    this.dialogService.openPismoEditAccountDialog({
      accountDetails: accountDetails.pismoAccountDetails,
      pismoUpdateAccountFn: this.updatePismoAccountLimitFn,
      pismoGetAllStatusFn: this.getAllPismoAccountStatusFn,
      pismoGetAccountStatusReasonsFn: this.getPismoAccountStatusReasonsFn,
      pismoUpdateAccountStatusFn: this.updatePismoAccountStatusFn,
      pismoRollbackAccountStatusFn: this.rollbackPismoAccountStatusFn
    })
      .afterClosed()
      .subscribe((r)=>{
        if(r){
          this.load();
        }
      });
  }

  getPismoAccountMapping(pismoAccountNumber: number) {
    return this.existingPismoAccountMappings.find(mapping => mapping.pismoAccountNumber === pismoAccountNumber);
  }


  // when paymentLimit (edit in place) is entered / saved
  saveOverdraftPaymentLimit(event: Event, pismoAccountAndOpportunityDetail: PismoAccountAndOpporunityResponseWithFormControlPaymentLimit) {
    console.log('**** saveOverdraftPaymentLimit', event, pismoAccountAndOpportunityDetail);
    const newPaymentLimit = pismoAccountAndOpportunityDetail.formControlPaymentLimit.value;
    if (newPaymentLimit) {
      const accountId = pismoAccountAndOpportunityDetail.pismoAccountDetails.account_id;
      this.updatePismoAccountPaymentLimitFn(accountId, newPaymentLimit)
        .pipe(
          this.loader.paymentLimitUpdated.track(),
          this.portalHotToast.snackBarObservable(`Payment limit updated`),
          tap(r => {
            pismoAccountAndOpportunityDetail.pismoAccountDetails.custom_fields.paymentLimit = newPaymentLimit;
          })
        ).subscribe();
    }
  }


  retryFacilityEstablishmentFee(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (this.customer && pismoAccountMapping) {
      this.subscriptions.push(
        this.postOverdraftAccountFeeFn({
          fee: 'facility establishment fee',
          pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields },
          pismoAccountMapping: pismoAccountMapping,
          customer: this.customer,
        }).pipe(
          this.portalHotToast.spinnerObservable(),
          tap(r => {
            if(r.status) {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Post facility establishment fee success`
              }).afterClosed().subscribe(() => {
                this.events.emit({
                  type: 'retry-account-fees',
                });
              })
            } else {
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: r.message,
              });
            }
          })
        ).subscribe()
      )
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch customer info or pismo account mapping',
      });
    }
  }


  retryBrokerage(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (this.customer && pismoAccountMapping) {
      this.subscriptions.push(
        this.postOverdraftAccountFeeFn({
          fee: 'brokerage',
          pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
          pismoAccountMapping: pismoAccountMapping,
          customer: this.customer,
        }).pipe(
          this.portalHotToast.spinnerObservable(),
          tap(r => {
            if(r.status) {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Post brokerage success`
              }).afterClosed().subscribe(() => {
                this.events.emit({
                  type: 'retry-account-fees',
                });
              })
            } else {
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: r.message,
              });
            }
          })
        ).subscribe()
      )
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch customer info or pismo account mapping',
      });
    }
  }

  retryDocumentationFee(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (this.customer && pismoAccountMapping) {
      this.subscriptions.push(
        this.postOverdraftAccountFeeFn({
          fee: 'documentation fee',
          pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
          pismoAccountMapping: pismoAccountMapping,
          customer: this.customer,
        }).pipe(
          this.portalHotToast.spinnerObservable(),
          tap(r => {
            if(r.status) {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Post documentation fee success`
              }).afterClosed().subscribe(() => {
                this.events.emit({
                  type: 'retry-account-fees',
                });
              })
            } else {
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: r.message,
              });
            }
          })
        ).subscribe()
      )
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch customer info or pismo account mapping',
      });
    }
  }


  retryDocumentationFeeReversal(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (this.customer && pismoAccountMapping) {
      this.subscriptions.push(
        this.postOverdraftAccountFeeFn({
          fee: 'documentation fee reversal',
          pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
          pismoAccountMapping: pismoAccountMapping,
          customer: this.customer,
        }).pipe(
          this.portalHotToast.spinnerObservable(),
          tap(r => {
            if(r.status) {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Post documentation fee reversal success`
              }).afterClosed().subscribe(() => {
                this.events.emit({
                  type: 'retry-account-fees',
                });
              })
            } else {
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: r.message,
              });
            }
          })
        ).subscribe()
      )
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch customer info or pismo account mapping',
      });
    }
  }


  retryAccountInterestRate(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (pismoAccountMapping) {
      this.subscriptions.push(
        this.createAccountInterestRateFn(pismoAccountDetail.account_id)
          .pipe(this.portalHotToast.spinnerObservable())
          .subscribe(() => {
            this.events.emit({
              type: 'retry-account-fees',
            });
          })
      )
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch pismo account mapping',
      });
    }
  }


  retryTransferredBalance(pismoAccountDetail: PismoGetAccountResponse) {
    const pismoAccountMapping = this.getPismoAccountMapping(pismoAccountDetail.account_id);
    if (pismoAccountMapping) {
      this.dialogService.openTransferredBalanceDialog({}).afterClosed().subscribe(
        (data: TransferredBalanceDialogResult | undefined) => {
          if (this.customer && data) {
            this.postOverdraftAccountFeeFn({
              fee: 'transferred balance',
              pismoAccountDetails: {account_id: pismoAccountDetail.account_id, custom_fields: pismoAccountDetail.custom_fields},
              pismoAccountMapping: pismoAccountMapping,
              customer: this.customer,
              accountBalanceType: data.balanceType,
              accountBalance: data.balanceAmount,
            }).pipe(
              this.portalHotToast.spinnerObservable(),
              tap(r => {
                if(r.status) {
                  this.dialogService.successDialog({
                    message: 'Success',
                    subMessage: `Post account balance success`
                  }).afterClosed().subscribe(() => {
                    this.events.emit({
                      type: 'retry-account-fees',
                    });
                  })
                } else {
                  this.dialogService.openAlertDialog({
                    message: 'Error',
                    subMessage: r.message,
                  });
                }
              })
            ).subscribe()
          }
      })

    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Failed to fetch pismo account mapping',
      });
    }
  }

  updateRequireMonthlyFixedInstallmentFlag(accountId: number) {
    const pismoAccountMapping = this.getPismoAccountMapping(accountId);
    if (pismoAccountMapping) {
      this.dialogService.openPismoUpdateRequireFixedMonthlyInstallmentFlagDialog({
        currentRequireMonthlyFixedInstallment: pismoAccountMapping.requireMonthlyFixedInstallment,
        accountId: accountId
      }).afterClosed().subscribe((result: PismoUpdateRequireFixedMonthlyInstallmentFlagDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          this.pismoUpdateRequireMonthlyFixedInstallmentFlagFn(
            accountId,
            result.requireMonthlyFixedInstallment,
            result.reason
          ).pipe(
            this.portalHotToast.spinnerObservable()
          ).subscribe(() => {
            this.dialogService.successDialog({
              message: 'Success',
              subMessage: `Update RequireFixedMonthlyInstallmentFlag success`
            }).afterClosed().subscribe(() => {
              this.events.emit({
                type: 'flag-change',
              });
            })
          })
        }
      })
    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Unable to update the flag',
      });
    }

  }

  generatePayout(accountDetails: PismoAccountAndOpportunityResponse) {
    this.subscriptions.push(
      this.dialogService.openGeneratePayoutDialog({
        accountAndOpportunityDetails: accountDetails,
        pismoGetPaidFacilityFeeFn: this.pismoGetPaidFacilityFeeFn,
        pismoGetPendingInterestFn: this.pismoGetPendingInterestFn,
        getPismoCustomerForAccountFn: this.getPismoCustomerForAccountFn,
        pismoSendPayoutNotificationFn: this.pismoSendPayoutNotificationFn,
        pismoGeneratePayoutFn: this.pismoGeneratePayoutFn,
        getPendingPaymentsFn: this.getPendingPaymentsFn,
        getDefaultRatecardDetailsFn: this.getDefaultRatecardDetailsFn
      }).afterClosed().subscribe((result: GeneratePayoutDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          console.log(result)
        }
      })
    )
  }

  onCancelAccount(accountDetails: PismoAccountAndOpportunityResponse) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: "Account Closure",
        subMessage: "Do you want to close this account?"
      }).afterClosed().subscribe(result => {
          if (result && result.readyForSubmission) {
            this.updatePismoAccountStatusFn(accountDetails.pismoAccountDetails.account_id, {
              status: "CANCELLED",
              description: `Account cancelled through portal by user ${this.user?.UserId}`
            }).pipe(
              this.portalHotToast.spinnerObservable()
            ).subscribe((r)=>{
                if(r){
                  this.load();
                }
            })
          }
        }
      )
    )
  }

  limitIncrease(accountDetails: PismoAccountAndOpportunityResponse) {
    this.subscriptions.push(
      this.dialogService.openOverdraftAccountLimitIncreaseDialog({
        getOpportunitiesForCustomerFn: this.getOpportunitiesForCustomerFn,
        getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
        getRbaRateFn: this.getRbaRateFn,
        getCustomerFn: this.getCustomerFn,
        overdraftAccountLimitIncreaseFn: this.overdraftAccountLimitIncreaseFn,
        customerId: this.customer.CustomerId,
        overdraftCustomerSfRecordId: this.overdraftCustomerSfRecordId,
        overdraftSfOpportunityStage: 'Settlement',
        pismoAccountDetails: accountDetails.pismoAccountDetails,
      }).afterClosed().subscribe((result: OverdraftAccountLimitIncreaseDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          this.load();
        }
      })
    )
  }

  directDebit(accountDetails: PismoAccountAndOpportunityResponse) {
    this.subscriptions.push(
      this.dialogService.openOverdraftDirectDebitDailog({
        pismoAccountId: accountDetails.pismoAccountDetails.account_id,
        manualDirectDebitFn: this.manualDirectDebitFn,
      }).afterClosed().subscribe((result: OverdraftDirectDebitDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          this.load();
        }
      })
    )
  }

  updateMonthlyFacilityFeePercentage(accountDetails: PismoAccountAndOpportunityResponse) {
    const opportunityId = accountDetails.opportunityDetails?.Id;
    const creditLimit = accountDetails.pismoAccountDetails.total_credit_limit;
    const monthlyFacilityFeePercentage = accountDetails.pismoAccountDetails.custom_fields.monthlyFacilityFeePercentage ?? 0;
    if (opportunityId) {
      this.dialogService.openEditMonthlyFacilityFeePercentageDialog({
        creditLimit: creditLimit,
        monthlyFacilityFeePercentage: monthlyFacilityFeePercentage
      }).afterClosed().subscribe((result: EditMonthlyFacilityFeePercentageDialogResult | undefined) => {
        if (result && result.readyForSubmission) {
          this.updateMonthlyFacilityFeeFn({
            opportunityId: opportunityId,
            accountId: accountDetails.pismoAccountDetails.account_id,
            monthlyFacilityFeePercentage: result.monthlyFacilityFeePercentage
          }).pipe(
            this.portalHotToast.spinnerObservable()
          ).subscribe(() => {
              this.dialogService.successDialog({
                message: 'Success',
                subMessage: `Update monthly facility fee percentage success`
              }).afterClosed().subscribe(() => {
                this.load();
              })
          })
        }
      })

    } else {
      this.dialogService.openAlertDialog({
        message: 'Error',
        subMessage: 'Unable to update monthly facility fee percentage',
      });
    }
  }
}
