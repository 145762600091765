<div class="custom-content-loader">
  @if (type === 'custom') {
    <content-loader>
      <svg:rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
      <svg:rect x="20" y="20" rx="3" ry="3" width="220" height="10" />
      <svg:rect x="20" y="40" rx="3" ry="3" width="170" height="10" />
      <svg:rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
      <svg:rect x="20" y="80" rx="3" ry="3" width="200" height="10" />
      <svg:rect x="20" y="100" rx="3" ry="3" width="80" height="10" />
    </content-loader>
  }
  @if (type === 'bullet' ) {
    <bullet-list-content-loader  ></bullet-list-content-loader>
  }
  @if (type === 'facebook' ) {
    <facebook-content-loader></facebook-content-loader>
  }
  @if (type === 'list' ) {
    <list-content-loader></list-content-loader>
  }
</div>
