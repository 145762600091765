<div class="asset-finance-application-details-component">
  <div fxLayout="column" fxLayoutGap="5px">
    <div>
      <breadcrumb (events)="onBreadcurmbEvents($event)" [trails]="breadcrumbTrails"></breadcrumb>
    </div>
    <div class="separator-bottom row">
      <div class="col-lg-8 col-md-12 mb-1" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-flat-button class="mat-flat-button-custom w-lt-md-100 btn-black-border">
          <application-type-icon applicationType="AssetFinance" />
          Asset Finance
          @if (application.Source == 'external') {
            <span class="ml-2 mdi mdi-alpha-e-circle" matTooltip="External source application"></span>
          }
          @if (application.Source == 'direct') {
            <span class="ml-2 mdi mdi-alpha-d-circle" matTooltip="Direct source application"></span>
          }
        </button>
      </div>
      <div class="col-lg-4 col-md-12 mb-1">
        <application-stage-icon [stage]="getApplicationStage(application)"></application-stage-icon>
      </div>
    </div>
  </div>
  <mat-tab-group
    [mat-stretch-tabs]="false"
    mat-align-tabs="start"
    [(selectedIndex)]="currentSectionIndex"
    (selectedTabChange)="onSelectedTabChange($event)">
    @for (currentTab of tabs; track currentTab) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="mdi {{currentTab.iconClass}}"></span>
          {{currentTab.name}}
        </ng-template>
      </mat-tab>
    }
    <!--
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mdi mdi-view-grid-outline"></span>
        APPLICATION
      </ng-template>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <span class="mdi mdi-bank-outline"></span>
        BANK STATEMENTS
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mdi mdi-check-decagram-outline" ></span>
        KYC/AML
      </ng-template>
    </mat-tab>
    <mat-tab >
      <ng-template mat-tab-label>
        <span class="mdi mdi-folder-outline"></span>
        DOCUMENTS
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mdi mdi-handshake-outline"></span>
        CONTRACT
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isAdminOrCreditUserOrSalesAM(loggedInUser) || isInternalUser(loggedInUser)">
      <ng-template mat-tab-label>
        <span class="mdi mdi-currency-usd"></span>
        PRICING
      </ng-template>
    </mat-tab>
    -->
  </mat-tab-group>

  @if (currentSection === 'asset') {
    <div class="row">
      <div class="col-12 btn-space header"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayout.lt-md="column"
        fxLayoutAlign.lt-md="center start"
        >
        <button mat-flat-button class="w-lt-md-100" (click)="showAddAsset()" [disabled]="disableAddAsset">
          <span class="mdi mdi-plus" matPrefix></span>
          <span class="label"> Add Asset </span>
        </button>
        @if (currentSection === 'asset' && isInternalUser(loggedInUser)) {
          <button mat-flat-button class="w-lt-md-100" [matMenuTriggerFor]="menu" [disabled]="isAssetEmpty">
            <span class="label">  PPSR </span> <span class="mdi mdi-chevron-down" matPrefix></span>
          </button>
        }
        <mat-menu #menu="matMenu">
          <button class="menu-items" mat-menu-item (click)="onPPSRCheckAll($event)">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>Check All</div>
              <div><span class="mdi mdi-autorenew"></span></div>
            </div>
          </button>
          <mat-divider></mat-divider>
          <button class="menu-items" [disabled]="isAssetEmpty || notAllAssetAccepted" mat-menu-item (click)="onPPSRRegister()">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>Register</div>
              <div><span class="mdi mdi-progress-star"></span></div>
            </div>
          </button>
        </mat-menu>
        @if (allowContractGeneration) {
          <button mat-flat-button class="w-lt-md-100" [disabled]="isAssetEmpty || !applicationStageAllowGenerateContract(application)" (click)="openGenerateContractDialog()">
            <span class="mdi mdi-file-sign pr-2" matPrefix></span>
            <span class="label">Generate Contract</span>
          </button>
        }
        @if (activeContract && allowContractGeneration) {
          <button mat-flat-button class="w-lt-md-100" (click)="openContractStatusDialog()">
            <span class="mdi mdi-account-supervisor-outline pr-2" matPrefix></span>
            <span class="label">Signer Status</span>
          </button>
        }
        @if (currentSection === 'asset') {
          <button mat-flat-button class="w-lt-md-100"
            [disabled]="isAssetEmpty || (notAllAssetAccepted && isInternalUser(loggedInUser)) || !applicationStageAllowSettleLoan(application) || (missingBankDetails && isInternalUser(loggedInUser))"
            (click)="onSettleLoan()">
            <span class="mdi mdi-cash-multiple pr-2" matPrefix></span>
            <span class="label">Request Settlement</span>
          </button>
        }
      </div>
    </div>
  }
  <div>
    @if (currentSection === 'asset' && missingBankDetails && isInternalUser(loggedInUser)) {
      <message-box type="danger">
        Cannot request settlement as {{missingBankDetails}}'s bank details are missing.
      </message-box>
    }
    @if (currentSection === 'app') {
      <asset-app
        (events)="events.emit($event)"
        [getRatecardDetailsFn]="getRatecardDetailsFn"
        [getUsersInCompanyFn]="getUsersInCompanyFn"
        [application]="application"
        [amortizationScheduleData]="amortizationScheduleData"
        [repaymentEstimationData]="repaymentEstimationData"
        [paymentChartData]="paymentChartData"
        [copyApplicationFn]="copyApplicationFn"
        [updateApplicationFn]="updateApplicationFn"
        [ip]="ip"
        [getGeoLocationFn]="getGeoLocationFn"
        [amortizationChartData]="amortizationChartData"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [assetSearchFn]="assetSearchFn"
        (navigateToApplications)="navigateToApplications.emit()">
      </asset-app>
    }
    @if (currentSection === 'bank-statement') {
      <bank
        [getBankStatementsAnalysisFn]="getBankStatementsAnalysisFn"
        [getBankStatementAndBasiqDataStatusFn]="getBankStatementAndBasiqDataStatusFn"
        [refreshBankStatementFn]="refreshBankStatementFn"
        [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn"
        [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
        [saveBsaCalculatorFn]="saveBsaCalculatorFn"
        [getBsaCalculatorFn]="getBsaCalculatorFn"
        [apiUrl]="apiUrl"
        [bankStatementsUrl]="bankStatementsUrl"
        [getBsaLenderListFn]="getBsaLenderListFn"
        [getBsaExcludedLenderListFn]="getBsaExcludedLenderListFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [getDscrCalculatorHistoryFn]="getDscrCalculatorHistoryFn"
      [application]="application"
        [getBasiqCustomerMappingByAbnFn]="getBasiqCustomerMappingByAbnFn"
        [getBasiqStatementDataForCompanyFn]="getBasiqStatementDataForCompanyFn"
        [refreshBasiqConnectionsFn]="refreshBasiqConnectionsFn"
      ></bank>
    }
    @if (currentSection === 'asset') {
      <asset-settlement
        [getApplicationPpsrInfoFn]="getApplicationPpsrInfoFn"
        [removeApplicationAssetFn]="removeApplicationAssetFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [searchAssetFn]="searchAssetFn"
        [submitPpsrFn]="submitPpsrFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [sendAssetToSfFn]="sendAssetToSfFn"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [addPpsrDetailFn]="addPpsrDetailFn"
        [updatePpsrDetailFn]="updatePpsrDetailFn"
        [updateApplicationAssetsFn]="updateApplicationAssetsFn"
        [submitApplicationAssetsFn]="submitApplicationAssetsFn"
        [searchGrantorsFn]="searchGrantorsFn"
        [getPpsrDocumentsFn]="getPpsrDocumentsFn"
        [searchSupplierFn]="searchSupplierFn"
        [saveSupplierFn]="saveSupplierFn"
        [businessSearchFn]="businessSearchFn"
        [apiUrl]="apiUrl"
        [application]="application"
        [isPpsrChecked]="ppsrChecked"
        [isPpsrRegistered]="ppsrRegistered"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [sendToSF]="sendToSF"
        [assetSettlementSearchAssetsFn]="assetSettlementSearchAssetsFn"
        (onEditAssetEvent)="onEditAssetEvent($event)"
        (emptyAsset)="emptyAssetFn($event)"
        (settleLoan)="disableSettleLoan($event)"
        (ppsrStatus)="disableOnPPSRStatus($event)"
        (deleteAsset)="getApplicationAsset()"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [createNewDisbursementFn]="createNewDisbursementFn"
        [updateDisbursementFn]="updateDisbursementFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [syncDisbursementToSfFn]="syncDisbursementToSfFn"
        [paymentChartData]="paymentChartData"
        [syncBankDetailsToSfFn]="syncBankDetailsToSfFn"
        [syncPrivateSellerBankDetailsToSfFn]="syncPrivateSellerBankDetailsToSfFn"
        [syncDepositPaidToSfFn]="syncDepositPaidToSfFn"
        [getBillerNameFn]="getBillerNameFn"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [validateBpayFn]="validateBpayFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [createAssetInspectionFn]="createAssetInspectionFn"
        [getAssetInspectionsForApplicationFn]="getAssetInspectionsForApplicationFn"
        [downloadVerimotoReportFn]="downloadVerimotoReportFn"
        [assetInspectionTableData]="assetInspectionTableData"
        [verimotoLender]="verimotoLender"
        [verimotoAssetInspectionTypes]="verimotoAssetInspectionTypes"
        (newAssetInspection)="loadAssetInspections()"
        [addAuditLogFn]="addAuditLogFn"
        >
      </asset-settlement>
    }

    @if (currentSection === 'add-asset') {
      <add-asset
        [businessSearchFn]="businessSearchFn"
        [updateApplicationAssetsFn]="updateApplicationAssetsFn"
        [submitApplicationAssetsFn]="submitApplicationAssetsFn"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [updateDocumentMetadataFn]="updateDocumentMetadataFn"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [searchSupplierFn]="searchSupplierFn"
        [application]="application"
        [getPpsrDetailsFn]="getPpsrDetailsFn"
        [editAssetValue]="editAssetValue"
        [getInstitutionNameFn]="getInstitutionNameFn"
        [extractTaxInvoiceFn]="extractTaxInvoiceFn"
        [createAssetInspectionFn]="createAssetInspectionFn"
        [verimotoLender]="verimotoLender"
        [getContractStatusFn]="getContractStatusFn"
        [assetInspectionTableData]="assetInspectionTableData"
        (onBackClick)="getEvent()"
        (onAssetSave)="getAssetSave()"
        (onAssetInspectionCreated)="loadAssetInspections()">
      </add-asset>
    }

    @if (currentSection === 'kyc-aml') {
      <kyc-verification
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [getOriginatorByIdFn]="getOriginatorByIdFn"
        [addIndividualFn]="addIndividualFn"
        [businessSearchFn]="businessSearchFn"
        [verifyApplicationIndividualsFn]="verifyApplicationIndividualsFn"
        [verifyOneApplicationIndividualFn]="verifyOneApplicationIndividualFn"
        [sendAskForVerificationInfoEmailFn]="sendAskForVerificationInfoEmailFn"
        [getApplicationIndividualsFn]="getApplicationIndividualsFn"
        [updateIndividualFn]="updateIndividualFn"
        [updateApplicationIndividualDigitalIdMappingFn]="updateApplicationIndividualDigitalIdMappingFn"
        [updateApplicationIndividualInfoFn]="updateApplicationIndividualInfoFn"
        [printDigitalIdResultFn]="printDigitalIdResultFn"
        [setupApplicationIndividualMappingFn]="setupApplicationIndividualMappingFn"
        [businessNumberSearchFn]="businessNumberSearchFn"
        [downloadDocumentFromAzureFn]="downloadDocumentFromAzureFn"
        [idVerifyUrl]="idVerifyUrl"
        [sendIdVerifyLinkFn]="sendIdVerifyLinkFn"
        [bypassFaceCompareFn]="bypassFaceCompareFn"
        [deleteIdentityVerificationFn]="deleteIdentityVerificationFn"
        [sendPrivacyConsentEmailFn]="sendPrivacyConsentEmailFn"
        [application]="application">
      </kyc-verification>
    }

    @if (currentSection === 'credit') {
          <asset-credit
            [application]="application"
            [getDscrCalculatorValueFn]="getDscrCalculatorValueFn"
            [updateDscrCalculatorValueFn]="updateDscrCalculatorValueFn" />
    }

    @if (currentSection === 'documents') {
      <asset-documents
        [apiUrl]="apiUrl"
        [uploadApplicationDocumentFn]="uploadApplicationDocumentFn"
        [application]="application"
        [getApplicationByIdFn]="getApplicationByIdFn"
        [listApplicationDocumentFn]="listApplicationDocumentFn"
        [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
        [approveApplicationDocumentFn]="approveApplicationDocumentFn"
        [declineApplicationDocumentFn]="declineApplicationDocumentFn"
        [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
        [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
        [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
        [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
        [sendApplicationDocumentNotificationFn]="sendApplicationDocumentNotificationFn"
        [createDocumentWorklistFn]="createDocumentWorklistFn"
        [completeDocumentWorklistFn]="completeDocumentWorklistFn"
        [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
        [getApplicationAuditLogsFn]="getApplicationAuditLogsFn"
        [createApplicationNoteFn]="createApplicationNoteFn"
        [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
        [generateApprovalNoticeFn]="generateApprovalNoticeFn"
        [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
        [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
        [getApplicationOwnerFn]="getApplicationOwnerFn"
        [undoApplicationDocumentFn]="undoApplicationDocumentFn"
        [updateApplicationStageFn]="updateApplicationStageFn"
        [getUserFn]="getUserFn"
        [isAssetEmpty]="isAssetEmpty"
        [notAllAssetAccepted]="notAllAssetAccepted"
        [missingBankDetails]="missingBankDetails"
        [getApplicationAssetFn]="getApplicationAssetFn"
        [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
        [settleLoanFn]="settleLoanFn"
      ></asset-documents>
    }

    @if (currentSection === 'pricing') {
      <asset-pricing
        [application]="application"
        [assetSearchFn]="assetSearchFn"
        [currentRstValue]="rst"
        [updateApplicationFn]="updateApplicationFn"
        [updateApplicationSfFn]="updateApplicationSfFn"
        [getRateCardDetailsFn]="getRatecardDetailsFn"
        [getContractStatusFn]="getContractStatusFn"
        (events)="onAssetPricingEvent($event)"
        [getOriginatorBusinessByIdFn]="getOriginatorBusinessByIdFn"
        >
      </asset-pricing>
    }


  </div>
</div>
