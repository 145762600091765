import {Component, OnInit} from '@angular/core';
import {
  ApplicationDialogService, BreadcrumbComponentEvent, DeleteApplicationDocumentFn,
  DownloadApplicationDocumentUrlFn, ListApplicationDocumentFn, openWindowAndDownloadWithFilename, PortalHotToastService, ProgressSpinnerDialog,
  setupUntilDestroy, UpdateApplicationDocumentFn, UpdateApplicationDocumentTagsFn,
} from '@portal-workspace/grow-ui-library';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ApplicationService} from '../../service/application.service';
import {loadingFor} from '@ngneat/loadoff';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap, tap} from 'rxjs/operators';
import {
  ApplicationDocumentsTableData,
  AzureStorageDocument,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  GroupedDocument,
  formGroupedDocumentData, Metadata,
  getUniqueDocumentTags,
  displayDocumentTag, unionExistingTagsWithDefaultTags, GetApplicationByIdFn, Application
} from '@portal-workspace/grow-shared-library';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {PageEvent} from '@angular/material/paginator';
import {MatDialogRef} from '@angular/material/dialog';
import { CustomPaginatorComponent } from '@portal-workspace/grow-ui-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
 
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, DatePipe } from '@angular/common';
import { BreadcrumbComponent,CustomContentLoaderComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';

export class DocumentTableDataSource extends DataSource<ApplicationDocumentsTableData> {

  subject = new BehaviorSubject<ApplicationDocumentsTableData[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<ApplicationDocumentsTableData[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }

  update(docs: ApplicationDocumentsTableData[]) {
    this.subject.next(docs);
  }

}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './documents.page.html',
    styleUrls: ['./documents.page.scss'],
    standalone: true,
    imports: [FlexModule, BreadcrumbComponent, MatFormFieldModule, CustomContentLoaderComponent, MessageBoxComponent, MatInputModule, FormsModule, ReactiveFormsModule, MatTableModule, MatTooltipModule, CustomPaginatorComponent, AsyncPipe, DatePipe]
})
export class DocumentsPage implements OnInit {
  loader = loadingFor('tableLoading');

  formControlSearchStep1: FormControl<string | null>;
  formControlSearchApplicationIdStep1: FormControl<string | null>;
  formControlSearchStep2: FormControl<string | null>;
  total = 0;
  limit = DEFAULT_LIMIT;
  offset = 0;

  directoryDepth: 1 | 2 | 3 = 1;

  subscriptions: Subscription[] = [];
  allDocuments: ApplicationDocumentsTableData[] = [];
  filteredDocuments: ApplicationDocumentsTableData[] = [];
  displayedDocuments: ApplicationDocumentsTableData[] = [];
  tmpDocuments: ApplicationDocumentsTableData[] = [];
  dataSourceDepth1: ApplicationDocumentsTableData[] = [];
  dataSourceDepth2: (GroupedDocument | null)[] = [];
  dataSourceDepth2All: (GroupedDocument | null)[] = [];
  dataSourceRawDepth2!: ApplicationDocumentsTableData;
  dataSourceDepth3: (AzureStorageDocument | null)[] = [];
  filteredDataSourceDepth3: (GroupedDocument | null)[] = [];
  directoryNameDepth2: string = '';
  directoryNameDepth3: string = '';
  tagValueDepth3: string = '';
  breadcrumbTrails: string[] = ['Documents'];
  downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  listApplicationDocumentFn!: ListApplicationDocumentFn;
  getApplicationByIdFn!: GetApplicationByIdFn;
  formGroupedDocumentData = formGroupedDocumentData;
  displayDocumentTag = displayDocumentTag;
  getUniqueDocumentTags = getUniqueDocumentTags;
  unionExistingTagsWithDefaultTags = unionExistingTagsWithDefaultTags;
  ref: MatDialogRef<ProgressSpinnerDialog> | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private dialogService: ApplicationDialogService,
    private toastService: PortalHotToastService
  ) {
    this.formControlSearchStep1 = formBuilder.control(null);
    this.formControlSearchStep2 = formBuilder.control(null);
    this.formControlSearchApplicationIdStep1 = formBuilder.control(null);
    this.downloadApplicationDocumentUrlFn = this.applicationService.downloadApplicationDocumentUrlFn;
    this.uploadApplicationDocumentFn = this.applicationService.uploadApplicationDocumentToAzureStorageFn;
    this.deleteApplicationDocumentFn = this.applicationService.deleteApplicationDocumentFn;
    this.updateApplicationDocumentTagsFn = this.applicationService.updateDocumentTagsFn;
    this.listApplicationDocumentFn = this.applicationService.listApplicationDocumentFn;
    this.getApplicationByIdFn = this.applicationService.getApplicationByIdFn;
  }

  ngOnInit() {
    setupUntilDestroy(this);
    this.reload();

    this.subscriptions.push(
      this.formControlSearchStep1.valueChanges.pipe(
        filter(c => !!c),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r) => {
           this.onSearchStep1();
           this.initPagination();
        })
      ).subscribe()
    );

    this.subscriptions.push(
      this.formControlSearchStep2.valueChanges.pipe(
        filter(c => !!c),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r) => {
          this.onSearchStep2();
        })
      ).subscribe()
    );

    this.subscriptions.push(
      this.formControlSearchApplicationIdStep1.valueChanges.pipe(
        filter(c => !!c),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((r) => {
          this.onSearchApplicationIdStep1();
        })
      ).subscribe()
    )
  }

  reload() {
    this.subscriptions.push(
      this.applicationService.getDocumentsFromCache().pipe(
        this.toastService.spinnerObservable(),
        tap((r: ApplicationDocumentsTableData[]) => {
          console.log("cache data ", r);
          this.filteredDocuments = r;
          this.allDocuments = r;
          this.total = this.filteredDocuments.length;
          this.updateDisplayedData();
          this.directoryDepth = 1;
        })
      ).subscribe()
    )
    // this.subscriptions.push(
    //   this.applicationService.listAllDocumentsByCompanyAndConsumer().pipe(
    //     this.toastService.topMenuLoadingObservable(),
    //     tap((r: ApplicationDocumentsTableData[]) => {
    //       this.allDocuments = r;
    //       this.filteredDocuments = this.allDocuments;
    //       this.total = this.filteredDocuments.length;
    //       console.log(this.allDocuments);
    //       // update data source in depth1 first
    //       this.updateDisplayedData();
    //       // update data source in depth2 & 3
    //       this.updateDepthData();
    //       // if (this.ref) {
    //       //   this.ref.close();
    //       // }
    //     })
    //   ).subscribe()
    // )
  }

  initPagination() {
    this.total = this.filteredDocuments.length;
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
    this.updateDisplayedData();
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.updateDisplayedData();
  }

  updateDisplayedData() {
    this.displayedDocuments = this.filteredDocuments.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
    this.dataSourceDepth1 = this.displayedDocuments;
  }

  // update depth data for reload
  updateDepthData() {
    // update data in depth 2
    if (this.directoryDepth >= 2) {
      this.dataSourceRawDepth2 = this.dataSourceDepth1.find(data =>
        data.companyLegalName === this.dataSourceRawDepth2.companyLegalName &&
        data.companyAbn === this.dataSourceRawDepth2.companyAbn &&
        data.consumerName === this.dataSourceRawDepth2.consumerName &&
        data.consumerDob === this.dataSourceRawDepth2.consumerDob
      ) as ApplicationDocumentsTableData;
      if (!this.dataSourceRawDepth2) {
        this.onChangeDirectoryDepth(1);
        return;
      }
      this.dataSourceDepth2All = [null, ...this.formGroupedDocumentData(this.dataSourceRawDepth2?.docs)];
      this.onSearchStep2();
      console.log('depth2 data all', this.dataSourceDepth2All);
    }

    // update data in depth 3
    if (this.directoryDepth >= 3) {
      const groupedDoc = this.dataSourceDepth2All?.filter(data => !!data)?.find(data =>
        data?.value === this.tagValueDepth3) as GroupedDocument;
      if (!groupedDoc) {
        this.onChangeDirectoryDepth(2);
        return;
      }
      this.dataSourceDepth3 = [null, ...groupedDoc?.docs];
      console.log('depth3 data', this.dataSourceDepth3);
    }
  }

  onSearchStep1() {
    const inputs = this.formControlSearchStep1.value?.toLowerCase().replace(/[^\w\s]/gi, '');
    let results: ApplicationDocumentsTableData[] = [];

    if (inputs) {
      for (const object of this.allDocuments) {
        const objectIdentifier = (object.type === 'company' ?
          object.companyLegalName + ' ' + object.companyAbn :
          object.consumerName + ' ' + object.consumerDob).toLowerCase();
        if (objectIdentifier.includes(inputs)) {
          results = [...results, object];
        }
      }

      this.filteredDocuments = results;
    } else {
      this.filteredDocuments = this.allDocuments;
    }
    console.log('searched: ', this.filteredDocuments)
  }

  onSearchApplicationIdStep1() {
    let inputs = this.formControlSearchApplicationIdStep1.value?.toLowerCase().replace(/[^\w\s]/gi, '');
    if (inputs && inputs.length && inputs?.startsWith('g000')) {
      inputs = inputs.slice(4, inputs.length);
    }
    if (inputs && !isNaN(Number(inputs))) {
      this.subscriptions.push(    
        combineLatest([
          this.getApplicationByIdFn(Number(inputs)),
          this.listApplicationDocumentFn(Number(inputs))
        ]).pipe(
          this.toastService.spinnerObservable(),
          tap(([application, documents]: [Application, AzureStorageDocument[]]) => {
            let documentTableData: ApplicationDocumentsTableData = {
              type: 'company',
              companyLegalName: '',
              companyAbn: '',
              consumerName: '',
              consumerDob: '',
              latestApplicationId: Number(inputs),
              docs: documents
            }
            if (application.ApplicationType === 'Consumer') {
              const applicant = application?.Individuals.find(i => i.Role === 'Applicant');
              documentTableData.consumerName = applicant?.GivenName + ' ' + applicant?.SurName,
              documentTableData.consumerDob = applicant?.DoB as string
              documentTableData.type = 'consumer';
            } else {
              const entity = application?.CommercialEntities?.find(e => e.Type === 'Primary');
              if (entity) {
                documentTableData.companyLegalName = entity?.LegalName;
                documentTableData.companyAbn = entity?.ABN;
                documentTableData.type = 'company';
              }
            }
            
            this.filteredDocuments = [documentTableData];
            this.total = this.filteredDocuments.length;
            this.directoryDepth = 1;
            this.updateDisplayedData();
          })
        ).subscribe()
      )
    } else {
      this.reload();
    }
  }

  onSearchStep2() {
    let inputs = this.formControlSearchStep2.value?.toLowerCase().replace(/[^\w\s]/gi, '');
    if (inputs && inputs.length && inputs?.startsWith('g000')) {
      inputs = inputs.slice(4, inputs.length);
    }
    if (inputs) {
      this.dataSourceDepth2 = [];
      for (const d of this.dataSourceDepth2All) {
        if (d) {
          const docs = d.docs.filter(doc => {
            const path = doc?.metadata?.path;
            if (!path) {
              return false;
            } else {
              const applicationId = path.slice(12, path.length);
              return applicationId.includes(inputs!);
            }
          })
          if (docs?.length) {
            this.dataSourceDepth2.push({
              ...d,
              docs: docs
            })
          }
        } else {
          this.dataSourceDepth2.push(d);
        }
      }
    } else {
      this.dataSourceDepth2 = this.dataSourceDepth2All;
    }
    console.log(this.dataSourceDepth2);
  }

  moveToDepth2(data: ApplicationDocumentsTableData) {
    this.directoryNameDepth2 = data.type === 'company' ? data.companyLegalName : data.consumerName;
    this.dataSourceDepth2All = [null, ...this.formGroupedDocumentData(data.docs)];
    this.dataSourceRawDepth2 = data;
    console.log('depth2 data all', this.dataSourceDepth2All);
    this.onSearchStep2();
    this.onChangeDirectoryDepth(2);
  }

  moveToDepth3(data: GroupedDocument) {
    if (data) {
      this.directoryNameDepth3 = data.groupName;
      this.tagValueDepth3 = data.value;
      this.dataSourceDepth3 = [null, ... data.docs];
      console.log('depth3 data', this.dataSourceDepth3);
      this.onChangeDirectoryDepth(3);
    } else {
      this.onChangeDirectoryDepth(1);
    }
  }

  onChangeDirectoryDepth(depth: 1 | 2 | 3) {
    this.directoryDepth = depth;

    switch (depth) {
      case 1:
        this.breadcrumbTrails = ['Documents'];
        this.formControlSearchStep2.setValue('');
        break;
      case 2:
        this.breadcrumbTrails = ['Documents', this.directoryNameDepth2];
        this.formControlSearchStep2.setValue('');
        break;
      case 3:
        this.breadcrumbTrails = ['Documents', this.directoryNameDepth2, this.directoryNameDepth3];
    }
  }

  onBreadcurmbEvents(event: BreadcrumbComponentEvent) {
    if (event.type === 'back') {
      this.onChangeDirectoryDepth(Math.max(this.directoryDepth - 1, 1) as 1 | 2);
    }
  }

  async onUploadDocument(fileType?: string) {
    this.subscriptions.push(this.dialogService.openUploadFileWithTagsDialog({
      title: 'Upload document',
      tags: fileType ? [fileType] : [],
      metadata: this.companyMetadata,
      allTags: this.unionExistingTagsWithDefaultTags(this.dataSourceRawDepth2.docs),
      enableCustomTag: true,
    }).afterClosed().pipe(
      switchMap(async (r: any) => {
        if (r) {
          console.log('================on upload document: ', r);
          const files = r.files;
          if (files && files.length && r.valid) {
            (await this.uploadApplicationDocumentFn(0, files, []))
              .subscribe((a: any) => {
              //this.reload();
            });
          }
        }
      })
    ).subscribe());
  }

  get companyMetadata() {
    return this.dataSourceRawDepth2?.type === 'consumer' ? {
      consumername: this.dataSourceRawDepth2.consumerName,
      dob: this.dataSourceRawDepth2.consumerDob
    } as Metadata : {
      legalname: this.dataSourceRawDepth2.companyLegalName,
      abn: this.dataSourceRawDepth2.companyAbn
    } as Metadata;
  }

  onMoveBackToDepth2(doc: AzureStorageDocument) {
    if (!doc) {
      this.onChangeDirectoryDepth(2);
    }
  }

  isDocumentHasAppId(doc: AzureStorageDocument | null) {
    if (doc && doc?.metadata?.path) {
      const path = doc?.metadata?.path;
      const applicationId = path.slice(12, path?.length);
      return !isNaN(Number(applicationId));
    }
    return false;
  }

  getDocumentAppId(doc: AzureStorageDocument | null): string | null {
    if (doc && doc?.metadata?.path) {
      const path = doc?.metadata?.path;
      let applicationId = path.slice(12, path?.length);
      if (applicationId) {
        applicationId = 'G' + '0'.repeat((7 - applicationId.length)) + applicationId;
      }
      return applicationId;
    }
    return null;
  }

  onDownloadDoc(doc: AzureStorageDocument) {
    // const a = document.createElement('a') as any;
    // a.href = this.downloadApplicationDocumentUrlFn(doc.name, "", 0);
    // document.body.appendChild(a);
    // a.click();
    this.downloadApplicationDocumentUrlFn(doc.name, "", 0).pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename((doc.metadata as Metadata)?.name ?? doc.name, blob)
      })
    ).subscribe()

  }

  onDownloadCompany(element: ApplicationDocumentsTableData) {
    // const a = document.createElement('a') as any;
    // a.href = this.applicationService.downloadAllCompanyDocument(
    //   element.type,
    //   element.companyLegalName,
    //   element.companyAbn,
    //   element.consumerName,
    //   element.consumerDob
    // );
    // document.body.appendChild(a);
    // a.click();
    this.applicationService.downloadAllCompanyDocument(
      element.type,
      element.companyLegalName,
      element.companyAbn,
      element.consumerName,
      element.consumerDob
    ).pipe(
      this.toastService.loadingWithMessage("Downloading..."),
      tap(blob => {
        openWindowAndDownloadWithFilename(element.companyLegalName || element.consumerName, blob)
      })
    ).subscribe()
  }

  onDownloadDocumentType(element: GroupedDocument) {
    // const a = document.createElement('a') as any;
    // a.href = this.applicationService.downloadDocuments(
    //   this.dataSourceRawDepth2.type,
    //   this.dataSourceRawDepth2.companyLegalName,
    //   this.dataSourceRawDepth2.companyAbn,
    //   this.dataSourceRawDepth2.consumerName,
    //   this.dataSourceRawDepth2.consumerDob,
    //   element.value
    // );
    // document.body.appendChild(a);
    // a.click();
    this.applicationService.downloadDocuments(
      this.dataSourceRawDepth2.type,
      this.dataSourceRawDepth2.companyLegalName,
      this.dataSourceRawDepth2.companyAbn,
      this.dataSourceRawDepth2.consumerName,
      this.dataSourceRawDepth2.consumerDob,
      element.value
    ).pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename((this.dataSourceRawDepth2.companyLegalName || this.dataSourceRawDepth2.consumerName) + '-' + element.value, blob)
      })
    ).subscribe()
    // window.open(url);
  }

  onEditDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.dialogService.openModifyFileTagsDialog({
        fileName: (doc?.metadata as Metadata)['name'],
        selectedTags: Object.values(doc?.tags ?? {}),
        allTags: this.getUniqueDocumentTags(this.dataSourceRawDepth2.docs)
      }).afterClosed().pipe(
        tap((r) => {
          if (r && r.readyForSubmission) {
            console.log(r);
            this.updateApplicationDocumentTagsFn(doc.name, '', r.tags).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Tags Updated',
                errorMessage: 'Failed to update the tags',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onEditDoc(doc);
                }
              }),
              tap(r => {
                //this.reload();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }

  onDeleteDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.dialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to delete ' + ((doc?.metadata as Metadata)['name'] ?? 'this file') + '?'
      }).afterClosed().pipe(
        tap(async (r) => {
          if (r && r.readyForSubmission) {
            this.deleteApplicationDocumentFn(doc.name, "", this.tagValueDepth3).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Delete',
                errorMessage: 'Failed to delete the document',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onDeleteDoc(doc);
                }
              }),
              tap(r => {
                //this.reload();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }
}
