<div class="enable-2fa-dialog">
  <mat-dialog-content>
    <div class="card mb-4" style="padding: 16px 32px;">
      <div class="header row">
        <h3>Enable two-factor auth</h3>
        <div>
          <p style="font-weight: 500;
      font-size: 16px;">Make your account safer in 3 easy steps</p>
        </div>
      </div>

      <div>
        <div class="row mb-4">
          <div class="col-md-3">
            <img src="assets/images/google_authenticator.svg" style="width: 100px;height: 100px;">
          </div>
          <div class="col-md-9">
            <span style="font-weight: 500;font-size: 16px;">Download an authenticator app</span>
            <div class="row">
            <span style="font-weight: 400;font-size: 14px;margin-left: 15px;">Download and install Google Authenticator
              for your phone or tablet</span>
            </div>

            <div class="row mt-2" style="margin-left: 0px;">
              <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US&pli=1"
                 target="_blank"><img src="assets/images/Mobile play Store Badges.svg" class="mr-2"
                                      style="width: 100px;height: 30px;"> </a>
              <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><img
                src="assets/images/Mobile app Store Badges.svg" style="width: 100px;height: 30px;"></a>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3">
            <qrcode [qrdata]="verificationCode"
                    [allowEmptyString]="true"
                    [ariaLabel]="'QR Code image with the following content...'"
                    [cssClass]="'center'"
                    [colorDark]="'#000000ff'"
                    [colorLight]="'#ffffffff'"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'L'"
                    [margin]="4"
                    [scale]="1"
                    [title]="'Scan the code to get your six-digit code'"
                    [width]="100">
            </qrcode>
          </div>
          <div class="col-md-9" style="margin-top: 20px;">
            <span style="font-weight: 500;font-size: 16px;">Scan the QR code</span>
            <div class="row">
            <span style="font-weight: 400;font-size: 14px;margin-left: 15px;">Open the authenticator app and scan the
              image to the left using your phone’s camera</span>
            </div>
          </div>
        </div>


        <div class="row ">
          <div class="col-md-3">
            <img src="assets/images/2fa.svg" style="width: 100px;height: 100px;">
          </div>
          <div class="col-md-9" style="margin-top: 9px;">
            <span style="font-weight: 500;font-size: 16px;">Validate your code</span>
            <div class="row">
            <span style="font-weight: 400;font-size: 14px;margin-left: 15px;">Enter the 6-digit verification code
              generated</span>
            </div>
            <div class="row col-md-10">
              <mat-form-field>
                <input matInput [formControl]="formControlDigit" placeholder="000 000">
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>

      <!--
    <div>
      <mat-form-field fxFlex="1 1 auto">
        <input matInput [formControl]="passwordControl" placeholder="Password" [type]="hide ? 'password' : 'text'">
        <mat-icon matSuffix (click)="hide = !hide" class="mdi mdi-eye-outline"></mat-icon>
      </mat-form-field>
    </div> -->
    </div>
    <form [formGroup]="formGroup">
      <div class="footer">
        <button color="primary" fxFlexAlign="start" mat-stroked-button (click)="onCancel()">Cancel</button>
        <button color="primary" fxFlexAlign="end" type="submit" mat-flat-button (click)="onSubmit()">Continue</button>
      </div>

    </form>
  </mat-dialog-content>
</div>
