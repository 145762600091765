<div class="consumer-credit-component">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          DSCR
        </mat-panel-title>
      </mat-expansion-panel-header>
      <consumer-dscr-calculator
        [application]="application"
        [getConsumerDscrCalculatorValueFn]="getConsumerDscrCalculatorValueFn"
        [updateConsumerDscrCalculatorValueFn]="updateConsumerDscrCalculatorValueFn"
        [consumerFinanceDscrServiceabilityCalculationFn]="consumerFinanceDscrServiceabilityCalculationFn"
      />
    </mat-expansion-panel>
  </mat-accordion>
</div>
