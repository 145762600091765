import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  constants,
  DocumentTag,
  ModifyFileTagsDialogData,
  ModifyFileTagsDialogResult,
} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatIconModule } from '@angular/material/icon';

import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';

@Component({
    templateUrl: './modify-file-tags.dialog.html',
    styleUrls: ['./modify-file-tags.dialog.scss'],
    standalone: true,
    imports: [MatCardModule, MatChipsModule, MatIconModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class ModifyFileTagsDialog {

  allTags: DocumentTag[] = [];
  tags: DocumentTag[] = [];
  MAX_TAG_ALLOWED = 10;
  showMaxTagErrorMessage = false;

  fileName!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ModifyFileTagsDialogData,
    private matDialogRef: MatDialogRef<ModifyFileTagsDialog, ModifyFileTagsDialogResult>
  ) {
    this.allTags = this.dialogData.allTags;
    this.tags = this.dialogData.selectedTags
      .filter(t => this.allTags.find(obj => obj.value === t))
      .map(t => this.allTags.find(obj => obj.value === t)) as DocumentTag[];
    this.fileName = this.dialogData.fileName;
  }

  remove(tag: DocumentTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);

      // remove max tag error message
      if (this.tags.length < this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = false;
      }
    }
  }

  addTag(tag: DocumentTag) {
    // avoid duplicated tags
    const tagObj = this.tags.find(t => t.value === tag.value);
    if (!tagObj) {
      // do not allow more than 10 tags
      if (this.tags.length >= this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = true;
        return;
      }

      this.tags.push(tag);
    }
  }

  onSubmit(readyForSubmission: boolean) {
    this.matDialogRef.close(
      {
        readyForSubmission: readyForSubmission,
        tags: this.tags.map(t => t.value)
      }
    );
  }

}
