import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  Address2,
  Application,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  GetLvrCalculatorValueFn,
  UpdateLvrCalculatorValueFn
} from '@portal-workspace/grow-shared-library';
import {
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { DscrCalculatorComponent } from '../dscr-calculator.component';
import { LvrCalculatorComponent } from '../lvr-calculator.component';
import {
  isInternalUser,
} from '@portal-workspace/grow-shared-library';


import { Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatExpansionModule } from "@angular/material/expansion";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'business-credit',
    templateUrl: './business-credit.component.html',
    styleUrls: ['./business-credit.component.scss'],
    standalone: true,
    imports: [DscrCalculatorComponent,MatExpansionModule,LvrCalculatorComponent]
})

export class BusinessCreditComponent implements OnInit {

  subscriptions: Subscription[] = [];
  expandedIndex = 1;

  @Input({required: true}) application!: Application;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
  @Input({required: true}) getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
  @Input({required: true}) updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;

  isInternalUser = isInternalUser;

  isPanelExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  expandPanel(index: number) {
    this.expandedIndex = index;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }


}
