<div class="business-overdraft-documents-component">
  <document-details
    [application]="application"
    [apiUrl]="apiUrl"
    [listApplicationDocumentFn]="listApplicationDocumentFn"
    [downloadApplicationDocumentUrlFn]="downloadApplicationDocumentUrlFn"
    [approveApplicationDocumentFn]="approveApplicationDocumentFn"
    [declineApplicationDocumentFn]="declineApplicationDocumentFn"
    [deleteApplicationDocumentFn]="deleteApplicationDocumentFn"
    [downloadAllApplicationDocumentUrlFn]="downloadAllApplicationDocumentUrlFn"
    [updateApplicationDocumentTagsFn]="updateApplicationDocumentTagsFn"
    [updateApplicationRequiredDocumentsFn]="updateApplicationRequiredDocumentsFn"
    [sendApplicationDocumentNotificationFn]="sendApplicationDocumentNotificationFn"
    [createDocumentWorklistFn]="createDocumentWorklistFn"
    [completeDocumentWorklistFn]="completeDocumentWorklistFn"
    [generateApprovalNoticeFn]="generateApprovalNoticeFn"
    [sendApprovalNoticeEmailFn]="sendApprovalNoticeEmailFn"
    [deleteApprovalNoticeFileFn]="deleteApprovalNoticeFileFn"
    [getApplicationOwnerFn]="getApplicationOwnerFn"
    [businessLoanData]="data"
    [undoApplicationDocumentFn]="undoApplicationDocumentFn"
    [getUserFn]="getUserFn"
    (events)="onDocumentDetailsEvent($event)"
    [getDisbursementByApplicationIdFn]="getDisbursementByApplicationIdFn"
    [settleLoanFn]="settleLoanFn"
    [updateApplicationStageFn]="updateApplicationStageFn"
    [getApplicationAssetFn]="getApplicationAssetFn"
    [getApplicationByIdFn]="getApplicationByIdFn"
    [updateApplicationStageFn]="updateApplicationStageFn"
  ></document-details>

  <div class="notes">
    <asset-notes
      [onlyShowNotes]="true"
      [getNotesByApplicationIdFn]="getNotesByApplicationIdFn"
      [createApplicationNoteFn]="createApplicationNoteFn"
      [removeApplicationNoteByNoteIdFn]="removeApplicationNoteByNoteIdFn"
    ></asset-notes>
  </div>
</div>
