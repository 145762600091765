import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { setupUntilDestroy } from '@portal-workspace/grow-ui-library';
import { map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { OriginatorListValueGetFn as OriginatorGetFn } from '@portal-workspace/grow-ui-library';
import {OriginatorListValue} from '@portal-workspace/grow-shared-library'
import { UserListValueGetFn as UsersGetFn } from '@portal-workspace/grow-ui-library';
import { NotificationService } from '../../service/notification.service';
import { UserListValue } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '@portal-workspace/grow-ui-library';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { OriginatorListComponent } from '@portal-workspace/grow-ui-library';
import { UserListComponent } from '@portal-workspace/grow-ui-library';

import { FlexModule } from '@angular/flex-layout/flex';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';


@UntilDestroy({ arrayName: 'subscription' })
@Component({
    templateUrl: './notifications-management.page.html',
    styleUrls: ['./notifications-management.page.scss'],
    standalone: true,
    imports: [MatCardModule, MatRadioModule, FlexModule, FormsModule, ReactiveFormsModule, UserListComponent, OriginatorListComponent, MatDividerModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class NotificationsManagementPage implements OnInit {
  isLoading = false;
  subscriptions: Subscription[] = [];
  formGroup: FormGroup<{
    sendTo: FormControl<string | null>;
    company: FormControl<OriginatorListValue>;
    user: FormControl<UserListValue>;
    title: FormControl<string | null>;
    message: FormControl<string | null>;
  }>;
  formControlSendTo: FormControl<string | null>;
  formControlCompany: FormControl<OriginatorListValue>;
  formControlUser: FormControl<UserListValue>;
  formControlTitle: FormControl<string | null>;
  formControlMessage: FormControl<string | null>;
  originatorsGetFn!: OriginatorGetFn;
  usersGetFn!: UsersGetFn;
  userTitle = 'User';
  companyTitle = 'Company'

  constructor(private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private notificationService: NotificationService) {
    this.formControlSendTo = formBuilder.control('all', [Validators.required]);
    this.formControlCompany = formBuilder.control(null);
    this.formControlUser = formBuilder.control(null);
    this.formControlTitle = formBuilder.control(null, [Validators.required]);
    this.formControlMessage = formBuilder.control(null, [Validators.required]);
    this.formGroup = formBuilder.group({
      sendTo: this.formControlSendTo,
      company: this.formControlCompany,
      user: this.formControlUser,
      title: this.formControlTitle,
      message: this.formControlMessage,
    });
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.originatorsGetFn = () => {
      return this.notificationService.getNotificationOriginatorRecipients().pipe(map(r => {
        console.log('**** originator recipients', r)
        return r.payload.map(rr => ({
          originatorBusinessId: rr.OriginatorBusinessId,
          name: rr.EntityName,
          salesforceId: rr.SalesforceId
        }));
      }));
    };
    this.usersGetFn = () => {
      return this.notificationService.getNotificationUserRecipients().pipe(map(r => {
        console.log('**** user recipients', r)
        return r.payload.map(rr => ({
          userId: rr.UserId,
          name: rr.Name ?? `${rr.GivenName} ${rr.FamilyName}`,
          email: rr.Email
        }))
      }))
    };
    const sub = this.formControlSendTo.valueChanges.pipe(
      tap(r => {
        if (r) {
          switch (r) {
            case 'user': {
              this.formControlCompany.setValidators([]);
              this.formControlUser.setValidators([Validators.required]);
              break;
            }
            case 'company': {
              this.formControlCompany.setValidators([Validators.required]);
              this.formControlUser.setValidators([]);
              break;
            }
            case 'all': {
              this.formControlCompany.setValidators([]);
              this.formControlUser.setValidators([]);
              break;
            }
          }
          this.formControlCompany.updateValueAndValidity();
          this.formControlUser.updateValueAndValidity();
        }
      })
    ).subscribe();
    this.subscriptions.push(sub);
  }


  sendNotification($event: Event) {
    switch (this.formControlSendTo.value) {
      case 'all': {
        this.isLoading = true;
        const title = this.formControlTitle.value as string;
        const message = this.formControlMessage.value as string;
        this.notificationService.submitBroadcastNotification(title, message).pipe(
          this.toastService.retryableMessage({
            successMessage: 'Notification sent',
            errorMessage: 'Failed to send the notification',
            retryFn: ()=> {
              console.log('**** retry ', this);
              this.sendNotification($event);
            }
          }),
          // this.toastService.snackBarObservable('Notification sent')
        ).subscribe((res) => {
          if (res){
            this.isLoading = false;
            this.onCancel()
          }
        }, (err: any) => {
          if (err) {
            this.isLoading = false;
          }
        });
        break;
      }
      case 'user': {
        this.isLoading = true;
        const title = this.formControlTitle.value as string;
        const message = this.formControlMessage.value as string;
        const u: UserListValue = this.formControlUser.value;
        if (u) {
          this.notificationService.submitUserNotification(u.userId, title, message).pipe(
            this.toastService.retryableMessage({
              successMessage: 'Notification sent',
              errorMessage: 'Failed to send the notification',
              retryFn: ()=> {
                console.log('**** retry ', this);
                this.sendNotification($event);
              }
            }),
            // this.toastService.snackBarObservable('Notification sent')
          ).subscribe((res) => {
            if (res){
              this.isLoading = false;
              this.onCancel()
            }
          }, (err: any) => {
            if (err) {
              this.isLoading = false;
            }
          });
        }
        break;
      }
      case 'company': {
        this.isLoading = true;
        const title = this.formControlTitle.value as string;
        const message = this.formControlMessage.value as string;
        const c: OriginatorListValue = this.formControlCompany.value;
        if (c) {
          this.notificationService.submitCompanyNotification(c.originatorBusinessId, title, message).pipe(
            this.toastService.retryableMessage({
              successMessage: 'Notification sent',
              errorMessage: 'Failed to send the notification',
              retryFn: ()=> {
                console.log('**** retry ', this);
                this.sendNotification($event);
              }
            }),
            // this.toastService.snackBarObservable('Notification sent')
          ).subscribe((res) => {
            if (res){
              this.isLoading = false;
              this.onCancel()
            }
          }, (err: any) => {
            if (err) {
              this.isLoading = false;
            }
          });
        }
        break;
      }
    }
  }

  async onCancel() {
    this.formGroup.reset();
  }
}
