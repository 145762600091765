<div class="upload-file-with-tags-dialog">
  <mat-dialog-content class= 'max-height-100-vh'>
    <div class="row">
      <div class="col">
        <upload-file
          title="{{dialogData.title}}"
          [maxFilesAllowed]="100"
          [tags1]="tags"
          [selectableTags]="selectableTags"
          [allTags]="allTags"
          [showMaxTagErrorMessage]="showMaxTagErrorMessage"
          [subMessage]="''"
          [isShowEditButton]="true"
          (selectedFileTags)="onSelectedFileTags($event)"
          (events)="onSelectOrDeleteFile($event)"
          [formControl]="formControl"
          [allowMultiple]="true"></upload-file>
      </div>
    </div>
    

 <!--    @if (showMaxTagErrorMessage) {
      <div class="row separator-top">
        <div class="col error-message">
          Maximum 10 tags are allowed for each document.
        </div>
      </div>
    } -->

 
    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
        @if (tags) {
          <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit($event)">Save Documents</button>
        }
      </div>
    </div>
  </mat-dialog-content>
</div>
