import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Application,
  AzureStorageDocument,
  Metadata,
  defaultDocuments,
  unclassifiedDocuments,
  User, ModifyFileTagsDialogResult, ConfirmationDialogResult, DEFAULT_LIMIT, DEFAULT_OFFSET,
} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DownloadApplicationDocumentUrlFn, getUser, openWindowAndDownloadWithFilename, PortalHotToastService, UndoApplicationDocumentFn, UpdateApplicationDocumentTagsFn
} from '@portal-workspace/grow-ui-library';
import {isInternalUser} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexModule } from '@angular/flex-layout/flex';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'document-details-sub-table',
    templateUrl: './document-details-sub-table.component.html',
    styleUrls: ['./document-details-sub-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, TagBoxComponent, FlexModule, MatTooltipModule, DatePipe]
})
export class DocumentDetailsSubTableComponent implements OnInit {
  subscriptions: Subscription[] = [];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;

  @Input({required: false}) data: AzureStorageDocument[] = [];
  @Input({required: true}) application!: Application;
  @Input({required: true}) tagName!: string; // driversLicense ...
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Output() reload = new EventEmitter();
  defaultDocuments = defaultDocuments;
  isInternalUser = isInternalUser;
  unclassifiedDocuments = unclassifiedDocuments;

  displayedData: AzureStorageDocument[] = [];
  columnsToDisplay: string[] = ['icon', 'name', 'status', 'action'];
  user: User | null = null;
  constructor(
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService
  ) { }

  ngOnInit(): void {
    this.user = getUser();
    this.displayedData = this.data;
  }

  get isTrustApplication() {
    const primaryEntity = this.application.CommercialEntities.find(entity => entity.Type === 'Primary');
    return primaryEntity?.EntityType === 'TRST';
  }

  get isPartnershipApplication() {
    const primaryEntity = this.application.CommercialEntities.find(entity => entity.Type === 'Primary');
    return primaryEntity?.EntityType === 'PTNR';
  }

  updateDisplayedData() {
    this.displayedData = this.data.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'name': return 'Document Uploaded';
      case 'action': return 'Actions';
      case 'status': return 'Status';
      default: return '';
    }
  }

  onDownloadDoc(doc: AzureStorageDocument) {
    // const a = document.createElement('a') as any;
    // a.href = this.downloadApplicationDocumentUrlFn(doc.name, "", 0);
    // document.body.appendChild(a);
    // a.click();
    this.downloadApplicationDocumentUrlFn(doc.name, "", 0).pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename((doc.metadata as Metadata)?.name ?? doc.name, blob)
      })
    ).subscribe()

  }

  onApproveDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.approveApplicationDocumentFn(doc.name, "", this.tagName, this.user?.UserId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Document Approved',
          errorMessage: 'Failed to approve the document',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onApproveDoc(doc);
          }
        }),
        tap(r => {
          this.reload.emit();
        })
      ).subscribe()
    )
  }

  onDeleteDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to delete ' + ((doc?.metadata as Metadata)['name'] ?? 'this file') + '?'
      }).afterClosed().pipe(
        tap(async (r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            this.deleteApplicationDocumentFn(doc.name, "", this.tagName).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Delete',
                errorMessage: 'Failed to delete the document',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onDeleteDoc(doc);
                }
              }),
              tap(r => {
                this.reload.emit();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }

  onDeclineDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.declineApplicationDocumentFn(doc.name, "", this.tagName, this.user?.UserId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Documents Declined',
          errorMessage: 'Failed to decline the documents',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onDeclineDoc(doc);
          }
        }),
        tap(r => {
          this.reload.emit();
        })
      ).subscribe()
    )
  }

  onUndoDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.undoApplicationDocumentFn(doc.name, "", this.tagName, this.user?.UserId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Undo Document',
          errorMessage: 'Failed to undo the document',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onUndoDoc(doc);
          }
        }),
        tap(r => {
          this.reload.emit();
        })
      ).subscribe()
    )
  }

  onEditDoc(doc: AzureStorageDocument) {
    const applicant = this.application.Individuals.find(i => i.Role === "Applicant");
    const primaryEntity = this.application.CommercialEntities.find(e => e.Type === "Primary");
    this.subscriptions.push(
      this.applicationDialogService.openModifyFileTagsDialog({
        fileName: (doc?.metadata as Metadata)['name'],
        selectedTags: Object.values(doc?.tags ?? {}),
        allTags: this.application?.DocumentTypes?.length ? this.application?.DocumentTypes
          : [...this.defaultDocuments(
            this.application.ApplicationType,
            this.application.Individuals.filter(i => i.Role === "Guarantor").map(i => i.GivenName + ' ' + i.SurName),
            this.application.ApplicationType === "Consumer" ? applicant?.GivenName + " " + applicant?.SurName : (primaryEntity?.LegalName ?? ""),
            "",
            "",
            (this.application.PricingDetails as any).LoanAmount,
            (this.application.PricingDetails as any).Deposit,
            (this.application.PricingDetails as any).PrivateSale === "Yes",
            this.isTrustApplication,
            this.isPartnershipApplication
          ), this.unclassifiedDocuments()]
      }).afterClosed().pipe(
        tap((r: ModifyFileTagsDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            console.log(r);
            this.updateApplicationDocumentTagsFn(doc.name, '', r.tags).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Tags Updated',
                errorMessage: 'Failed to update the tags',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onEditDoc(doc);
                }
              }),
              tap(r => {
                this.reload.emit();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }
}
