import {Component, Input, OnInit} from '@angular/core';
import {Article} from '@portal-workspace/grow-shared-library';



@Component({
    selector: 'app-dashboard-news',
    templateUrl: './dashboard-news.component.html',
    styleUrls: ['./dashboard-news.component.scss'],
    standalone: true,
    imports: []
})
export class DashboardNewsComponent implements OnInit {
  @Input({required: true}) news!: Article;
  constructor() { }

  ngOnInit(): void {
  }

  removeTags(str: any) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
    }

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
  }
}
