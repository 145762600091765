<div class="document-notification-dialog">
  <mat-dialog-content class="max-height-100-vh">
    <div class="row">
      <div class="col-12">
        <div class="mat-h4-custom">Document requirements</div>
      </div>
    </div>

    <div class="narrow-separator">
      <mat-divider></mat-divider>
    </div>

    <div class="row">
      <div class="col-12 file">
        <span class="mdi mdi-progress-clock"></span>
        <span class="group-name">Pending Documents</span>
      </div>
      @for (group of pendingDocs; track group) {
        <div class="col-12 file" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <span class="mdi mdi-file-document-multiple-outline"></span>
          <span class="file-name">{{group.groupName}}</span>
        </div>
      }
      @for (group of inProgressDocs; track group) {
        @for (doc of group.docs; track doc) {
          <div class="col-12 file" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="mdi mdi-attachment"></span>
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="file-name">{{doc && doc.metadata ? doc.metadata['name'] : ''}}</span>
              <span class="date-text">{{group.groupName}}</span>
            </div>
          </div>
        }
      }
    </div>

    <div class="narrow-separator">
      <mat-divider></mat-divider>
    </div>

    <div class="row">
      <div class="col-12 file group-name">
        <span class="mdi mdi-progress-close"></span>
        <span class="group-name">Rejected Documents</span>
      </div>
      @for (group of rejectedDocs; track group) {
        @for (doc of group.docs; track doc) {
          <div class="col-12 file" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="mdi mdi-attachment"></span>
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="file-name">{{doc && doc.metadata ? doc.metadata['name'] : ''}}</span>
              <span class="date-text">{{group.groupName}}</span>
            </div>
          </div>
        }
      }
    </div>

    <div class="narrow-separator">
      <mat-divider></mat-divider>
    </div>

    <div class="row">
      <div class="col-12 file group-name">
        <span class="mdi mdi-progress-check"></span>
        <span class="group-name">Accepted Documents</span>
      </div>
      @for (group of acceptedDocs; track group) {
        @if (!group.wholeGroup) {
          @for (doc of group.docs; track doc) {
            <div class="col-12 file" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <span class="mdi mdi-attachment"></span>
              <div fxLayout="column" fxLayoutAlign="start start">
                <span class="file-name">{{doc && doc.metadata ? doc.metadata['name'] : ''}}</span>
                <span class="date-text">{{group.groupName}}</span>
              </div>
            </div>
          }
        }
        @if (group.wholeGroup) {
          <div class="col-12 file" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <span class="mdi mdi-file-document-multiple-outline"></span>
            <span>{{group.groupName}}</span>
          </div>
        }
      }
    </div>

    <div class="separator"></div>

    <div class="row">
      <div class="col-12 email-list">
        <mat-form-field class="cc-email-chip-list">
          <mat-label>Send the notification to*</mat-label>
          <mat-chip-grid #chipList aria-label="email list">
            @for (email of emailList; track email) {
              <mat-chip-row (removed)="remove(email)">
                {{email}}
                <button matChipRemove>
                  <span class="mdi mdi-close"></span>
                </button>
              </mat-chip-row>
            }
            <input [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              [formControl]="formControlEmail"
              (matChipInputTokenEnd)="add($event)">
            </mat-chip-grid>
          </mat-form-field>
          @if (emailError) {
            <span class="error">
              Please enter a valid email
            </span>
          }
        </div>
      </div>

      <div class="separator"></div>

      <div class="row">
        <div class="col">
          <!-- <mat-form-field>
          <mat-label>Info (optional)</mat-label>
          <textarea matInput rows="5" wrap="hard" [formControl]="formControlAdditionalInformation" placeholder="Extra additional info..."></textarea>
        </mat-form-field> -->

        <custom-angular-editor [formControl]="formControlAdditionalInformation"
        [placeholder]="placeholder" [height]="250"></custom-angular-editor>

      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">
        <mat-checkbox [formControl]="formControlRemoveFromWorklist" color="primary">Remove from worklist</mat-checkbox>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
        @if (emailList) {
          <button mat-flat-button color="primary"
            [disabled]="formGroup.invalid || emailError || !(emailList.length)"
          (click)="onSubmit()">Send</button>
        }
      </div>
    </div>
  </mat-dialog-content>
</div>
