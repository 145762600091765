<div class="create-overdraft-account-dialog">
  <div class="generate-contract-dialog">
    <mat-dialog-content class= 'max-height-100-vh'>
      <application-stepper2 [linear]="false"
        [showHeader]="false"
        #applicationStepper="applicationStepper2">
        <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep4" ><ng-content *ngTemplateOutlet="step4" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
        <cdk-step [stepControl]="formGroupstep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
      </application-stepper2>
    </mat-dialog-content>
  </div>
</div>


<!--
##########################################
#  STEP 1: Select Opportunity
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Select Opportunity</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Opportunity</mat-label>
            <mat-select placeholder="Select Opportunity..." [formControl]="formControlStep1Opportunity">
              @if (!opportunitySelections.length) {
                <mat-option [value]="null">No existing overdraft opportunity</mat-option>
              }
              @for (option of opportunitySelections; track option) {
                <mat-option [value]="option">{{option.Name}} (Amount: {{option.Amount | looseCurrency}} Opportunity Id: {{option.SalesforceId}})</mat-option>
              }
            </mat-select>
            @for (errorKey of errorKeys(formControlStep1Opportunity); track errorKey) {
              <mat-error>
                @if (formControlStep1Opportunity.touched && formControlStep1Opportunity.hasError(errorKey)) {
                  {{errorMessage(formControlStep1Opportunity, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <!--For program-->
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Program</mat-label>
            <mat-select placeholder="Select Program..." [formControl]="formControlStep1Program">
              @if (!programSelections.length) {
                <mat-option [value]="null">No existing program found</mat-option>
              }
              @for (option of programSelections; track option) {
                <mat-option [value]="option">{{option.programId}} Name: {{option.name}}</mat-option>
              }
            </mat-select>
            @for (errorKey of errorKeys(formControlStep1Program); track errorKey) {
              <mat-error>
                @if (formControlStep1Program.touched && formControlStep1Program.hasError(errorKey)) {
                  {{errorMessage(formControlStep1Program, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <!--For duedate-->
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Due Date</mat-label>
            <mat-select placeholder="Select Due Date..." [formControl]="formControlStep1DueDate">
              @if (!programSelections.length) {
                <mat-option [value]="null">No existing duedate found</mat-option>
              }
              @for (option of pismoDueDate; track option) {
                <mat-option [value]="option">
                {{option.day}}</mat-option>
              }
            </mat-select>
            @for (errorKey of errorKeys(formControlStep1DueDate); track errorKey) {
              <mat-error>
                @if (formControlStep1DueDate.touched && formControlStep1DueDate.hasError(errorKey)) {
                  {{errorMessage(formControlStep1Program, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>


</ng-template>

<!--
##########################################
#  STEP 2: Overdraft Info
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Overdraft Account Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Overdraft Limit</div>
            <div class="postfix-icon-padding">
              {{formControlStep2OverdraftLimit.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Pismo Program Id</div>
            <div class="postfix-icon-padding">
              {{formControlStep2PismoProgramId.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Rate</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Rate.value | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Margin</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Margin.value | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Documentation Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep2DocFee.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Doc Fee Financed</div>
            <div class="postfix-icon-padding">
              {{formControlStep2DocFeeFinanced.value ? 'Yes' : 'No'}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Facility Establishment Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep2FacilityEstablishmentFee.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Term</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Term.value}} Months
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Brokerage</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Brokerage.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Due Date</div>
            <div class="postfix-icon-padding">
              {{formControlStep2DueDate.value ? formControlStep2DueDate.value.day : ''}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Monthly Facility Fee</div>
            <div class="postfix-icon-padding">
              {{formControlStep2MonthlyFacilityFee.value | looseCurrency}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Monthly Facility Fee Percentage</div>
            <div class="postfix-icon-padding">
              {{formControlStep2MonthlyFacilityFeePercentage.value | number : '1.2-2' | percentage}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Address</div>
            <div class="postfix-icon-padding">
              {{formControlStep2Address.value}}
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Customer Bank Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerAccountName.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Financial Institution Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerFinancialInstitutionName.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>BSB</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerBsb.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Number</div>
            <div class="postfix-icon-padding">
              {{formControlStep2CustomerAccountNumber.value}}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Broker Bank Details</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerAccountName.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Financial Institution Name</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerFinancialInstitutionName.value}}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>BSB</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerBsb.value}}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
            <div>Account Number</div>
            <div class="postfix-icon-padding">
              {{formControlStep2BrokerAccountNumber.value}}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 3: Account Balance
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Account Balance</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Does this account have any debit/credit balance?</mat-label>
            <mat-select placeholder="Does this account have any debit/credit balance?" [formControl]="formControlStep3AccountBalanceExistence">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            @for (errorKey of errorKeys(formControlStep3AccountBalanceExistence); track errorKey) {
              <mat-error>
                @if (formControlStep3AccountBalanceExistence.touched && formControlStep3AccountBalanceExistence.hasError(errorKey)) {
                  {{errorMessage(formControlStep3AccountBalanceExistence, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      @if (formControlStep3AccountBalanceExistence.value) {
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <mat-label>What type of balance?</mat-label>
              <mat-select placeholder="Balance type" [formControl]="formControlStep3AccountBalanceType">
                <mat-option [value]="'debit'">Debit</mat-option>
                <mat-option [value]="'credit'">Credit</mat-option>
              </mat-select>
              @for (errorKey of errorKeys(formControlStep3AccountBalanceType); track errorKey) {
                <mat-error>
                  @if (formControlStep3AccountBalanceType.touched && formControlStep3AccountBalanceType.hasError(errorKey)) {
                    {{errorMessage(formControlStep3AccountBalanceType, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input [formControl]="formControlStep3AccountBalance" type="number" matInput currencyMask placeholder="0.00"/>
              @for (errorKey of errorKeys(formControlStep3AccountBalance); track errorKey) {
                <mat-error>
                  @if (formControlStep3AccountBalance.touched && formControlStep3AccountBalance.hasError(errorKey)) {
                    {{errorMessage(formControlStep3AccountBalance, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 4: Security & Valuation Fee
##########################################
-->
<ng-template #step4>
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Security & Legal Fee</div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Does this account have any security & legal fee?</mat-label>
            <mat-select placeholder="Does this account have any security & legal fee?" [formControl]="formControlStep4SecurityValuationFeeExistence">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
            @for (errorKey of errorKeys(formControlStep4SecurityValuationFeeExistence); track errorKey) {
              <mat-error>
                @if (formControlStep4SecurityValuationFeeExistence.touched && formControlStep4SecurityValuationFeeExistence.hasError(errorKey)) {
                  {{errorMessage(formControlStep4SecurityValuationFeeExistence, errorKey)}}
                }
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      @if (formControlStep4SecurityValuationFeeExistence.value) {
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <mat-label>Amount</mat-label>
              <input [formControl]="formControlStep4SecurityValuationFee" type="number" matInput currencyMask placeholder="0.00"/>
              @for (errorKey of errorKeys(formControlStep4SecurityValuationFee); track errorKey) {
                <mat-error>
                  @if (formControlStep4SecurityValuationFee.touched && formControlStep4SecurityValuationFee.hasError(errorKey)) {
                    {{errorMessage(formControlStep4SecurityValuationFee, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 5: Payout Inputs
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <form [formGroup]="formGroupStep5">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Payout</div>
        </div>
      </div>

      <ng-container>
        <div class="row">
          <div class="col-8">
            <mat-form-field>
              <mat-label>Do you have any payout for this account?</mat-label>
              <mat-select placeholder="Do you have any payout for this account" [formControl]="formControlStep5PayoutExistence">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
              @for (errorKey of errorKeys(formControlStep5PayoutExistence); track errorKey) {
                <mat-error>
                  @if (formControlStep5PayoutExistence.touched && formControlStep5PayoutExistence.hasError(errorKey)) {
                    {{errorMessage(formControlStep5PayoutExistence, errorKey)}}
                  }
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      @if (formControlStep5PayoutExistence.value) {
        @for (payoutFormGroup of payoutFormGroups(); track payoutFormGroup; let index = $index) {
          <div class="row">
            <div class="col">
              <div class="mat-h3">Payout {{index + 1}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label>Payment Type</mat-label>
                <mat-select placeholder="Payment Type" [formControl]="payoutFormGroupFormControl(payoutFormGroup, 'type')">
                  <mat-option [value]="'bank-transfer'">Bank Transfer</mat-option>
                  <mat-option [value]="'bpay'">BPay</mat-option>
                </mat-select>
                @for (errorKey of errorKeys(payoutFormGroupFormControl(payoutFormGroup, 'type')); track errorKey) {
                  <mat-error>
                    @if (payoutFormGroupFormControl(payoutFormGroup, 'type').touched && payoutFormGroupFormControl(payoutFormGroup, 'type').hasError(errorKey)) {
                      {{errorMessage(payoutFormGroupFormControl(payoutFormGroup, 'type'), errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field>
                <mat-label>Amount</mat-label>
                <input type="number" matInput [formControl]="payoutFormGroupFormControl(payoutFormGroup, 'amount')" placeholder="Enter payout amount...">
                @for (errorKey of errorKeys(payoutFormGroupFormControl(payoutFormGroup, 'amount')); track errorKey) {
                  <mat-error>
                    @if (payoutFormGroupFormControl(payoutFormGroup, 'amount').touched && payoutFormGroupFormControl(payoutFormGroup, 'amount').hasError(errorKey)) {
                      {{errorMessage(payoutFormGroupFormControl(payoutFormGroup, 'amount'), errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
          @if (payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bank-transfer') {
            <div class="row">
              <div class="col-12">
                <local-bank-account-detail [getInstitutionNameFn]="getInstitutionNameFn" [formControl]="payoutFormGroupFormControl(payoutFormGroup, 'bankTransfer')" [hideInstitutionName]="true"></local-bank-account-detail>
              </div>
            </div>
          }
          @if (payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bpay') {
            <div class="row">
              <div class="col-12">
                <bpay-bank-details [getBillerNameFn]="getBillerNameFn" [formControl]="payoutFormGroupFormControl(payoutFormGroup, 'bpay')" [hideAmount]="true"></bpay-bank-details>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-6">
              <mat-form-field>
                <mat-label>Reference</mat-label>
                <input type="text" matInput [formControl]="payoutFormGroupFormControl(payoutFormGroup, 'reference')" placeholder="Enter payout reference...">
                @for (errorKey of errorKeys(payoutFormGroupFormControl(payoutFormGroup, 'reference')); track errorKey) {
                  <mat-error>
                    @if (payoutFormGroupFormControl(payoutFormGroup, 'reference').touched && payoutFormGroupFormControl(payoutFormGroup, 'reference').hasError(errorKey)) {
                      {{errorMessage(payoutFormGroupFormControl(payoutFormGroup, 'reference'), errorKey)}}
                    }
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
          <div class="row separator-bottom">
            <div class="col">
              <button mat-stroked-button color="warn" [disabled]="formControlStep5Payout.length <= 1" (click)="removePayout(payoutFormGroup)">Remove payout</button>
            </div>
          </div>
        }
        <div class="row separator-bottom">
          <div class="col">
            <button mat-stroked-button color="primary" (click)="addPayout()"><span class="mdi mdi-plus" matPrefix></span>Add payout</button>
          </div>
        </div>
      }


    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 6: Payout Confirmation
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <form [formGroup]="formGroupStep6">
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1 header">Payout Confirmation</div>
        </div>
      </div>

      @for (payoutFormGroup of payoutFormGroups(); track payoutFormGroup; let index = $index) {
        <div class="row">
          <div class="col-6 subheader">
            Payout {{index + 1}}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
              <div>Payout Type</div>
              <div class="postfix-icon-padding">
                {{payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bank-transfer' ? 'Bank Transfer' :
                payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bpay' ? 'BPay' :
                payoutFormGroupFormControl(payoutFormGroup, 'type').value}}
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
              <div>Amount</div>
              <div class="postfix-icon-padding">
                {{payoutFormGroupFormControl(payoutFormGroup, 'amount').value | looseCurrency}}
              </div>
            </div>
          </div>
        </div>
        @if (payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bank-transfer') {
          <div class="row">
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Reference</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'reference').value ?? ''}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Account Name</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bankTransfer').value?.AccountName ?? ''}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>BSB</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bankTransfer').value?.BSB ?? ''}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Account Number</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bankTransfer').value?.AccountNumber ?? ''}}
                </div>
              </div>
            </div>
          </div>
        }
        @if (payoutFormGroupFormControl(payoutFormGroup, 'type').value === 'bpay') {
          <div class="row">
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Biller Code</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bpay').value?.BillerCode ?? ''}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Biller Name</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bpay').value?.BillerName ?? ''}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>CRN</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'bpay').value?.CRN ?? ''}}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="account-item" fxLayoutGap="10px" fxLayoutAlign="space-between center">
                <div>Reference</div>
                <div class="postfix-icon-padding">
                  {{payoutFormGroupFormControl(payoutFormGroup, 'reference').value ?? ''}}
                </div>
              </div>
            </div>
          </div>
        }
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 6: Account Create Success
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupstep7">
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="success-icon">
            <span class="mdi mdi-file-sign"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div class="mat-subtitle-1">Overdraft account has been created successfully</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          @if (!feePostingStatusStep2) {
            <span>However, some fees are posted failed. Please go to the account details to see more information.</span>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

