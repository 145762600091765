<div class="finance-type-selection-component">
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Finance type</mat-label>
      <mat-select [formControl]="formControl" [compareWith]="compareWithFn">
        @for (option of options; track option) {
          <mat-option [value]="option">{{option.name}}</mat-option>
        }
      <!--mat-option *ngFor="let option of options" [value]="option" [disabled]="applicationType=='AssetFinance'&&option.name==='Finance Lease'">{{option.name}}</mat-option-->
    </mat-select>
    @for (errorKey of errorKeys(formControl); track errorKey) {
      <mat-error>
        @if (formControl.touched && formControl.hasError(errorKey)) {
          {{errorMessage(formControl, errorKey)}}
        }
      </mat-error>
    }
    <!-- <mat-error *ngIf="formControl.touched && formControl.hasError('required')">This field is required.</mat-error> -->
  </mat-form-field>
</form>
</div>
