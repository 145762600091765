import {Component, inject} from "@angular/core";
import {FlexModule} from "@angular/flex-layout/flex";
import {MatButtonModule} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {
  InterestRateCalculationLogDialogData,
  InterestRateCalculationLogDialogResult
} from "@portal-workspace/grow-shared-library";
import {MatTableModule} from "@angular/material/table";
import { AsyncPipe, DecimalPipe, JsonPipe, PercentPipe } from "@angular/common";
import {MessageBoxComponent} from "../message-box/message-box.component";
import {PercentagePipe} from "../../pipes/percentage.pipe";


@Component({
  templateUrl: './interest-rate-calculation-log.dialog.html',
  styleUrls: ['./interest-rate-calculation-log.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    JsonPipe,
    AsyncPipe,
    DecimalPipe,
    MessageBoxComponent,
    PercentagePipe
]
})
export class InterestRateCalculationLogDialog {

  matDialogRef: MatDialogRef<InterestRateCalculationLogDialog, InterestRateCalculationLogDialogResult> =
    inject(MatDialogRef<InterestRateCalculationLogDialog, InterestRateCalculationLogDialogResult>);
  data: InterestRateCalculationLogDialogData = inject(MAT_DIALOG_DATA);

  displayedColumns = ['log', 'rate'];

  onClose() {
    this.matDialogRef.close({});
  }
}
