import { Component, Inject, OnInit} from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CurrencyInputValue, EditMonthlyFacilityFeePercentageDialogData, EditMonthlyFacilityFeePercentageDialogResult } from '@portal-workspace/grow-shared-library';
import { PortalHotToastService } from '../portal-hot-toast-component/hot-toast.service';
import { SlideToggleComponent } from "../slide-toggle-component/slide-toggle.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';
import { CurrencyInputComponent } from '../currency-selection-component/currency-input.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import _ from 'lodash';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { createTwoDecimalInputMask } from '../component-utils';
import { InputMaskModule } from '@ngneat/input-mask';

@Component({
  templateUrl: './edit-monthly-facility-fee-percentage.dialog.html',
  styleUrls: ['./edit-monthly-facility-fee-percentage.dialog.scss'],
  standalone: true,
  imports: [
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    SlideToggleComponent,
    MatSlideToggleModule,
    MatCardModule,
    CurrencyInputComponent,
    MatOptionModule,
    MatSelectModule,
    NgClass,
    MatFormFieldModule,
    LooseCurrencyPipe,
    InputMaskModule,
]
})
export class EditMonthlyFacilityFeePercentageDialog implements OnInit {
  createTwoDecimalInputMask = createTwoDecimalInputMask();

  formControlMonthlyFacilityFeePercentage!: FormControl<CurrencyInputValue>;
  creditLimit: number = 0;
  monthlyFacilityFee: number = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditMonthlyFacilityFeePercentageDialogData,
    private dialogRef: MatDialogRef<EditMonthlyFacilityFeePercentageDialog, EditMonthlyFacilityFeePercentageDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService) {
    this.creditLimit = data.creditLimit;
    this.formControlMonthlyFacilityFeePercentage = formBuilder.control(data.monthlyFacilityFeePercentage ?? 0, [Validators.required]);
    this.calculateMonthlyFacilityFee();
  }

  ngOnInit() {
    this.formControlMonthlyFacilityFeePercentage.valueChanges.subscribe((value) => {
      this.calculateMonthlyFacilityFee();
    });
  }

  calculateMonthlyFacilityFee() {
    const monthlyFacilityFeePercentage = this.formControlMonthlyFacilityFeePercentage.value ?? 0;
    this.monthlyFacilityFee = _.round(this.creditLimit * monthlyFacilityFeePercentage / 1200, 2);
  }

  onSave() {
    if (this.formControlMonthlyFacilityFeePercentage.valid) {
      this.dialogRef.close({
        readyForSubmission: true,
        monthlyFacilityFeePercentage: this.formControlMonthlyFacilityFeePercentage.value ?? 0,
      });
    }
  }

  onCancel(event: Event) {
    this.dialogRef.close();
  }
}
