import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {openWindowWithLink, setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { navigationUrlForAssetFinance } from '../../service/navigation-urls';
import { environment } from 'apps/portal2/src/environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import {AssetFinanceApplication, AssetFinanceProductType} from '@portal-workspace/grow-shared-library';

// NOTE: Page to land when you are in DRAFT (new unsubmitted) asset application
@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './asset-Product-selector.page.html',
    styleUrls: ['./asset-Product-selector.page.scss'],
    standalone: true,
    imports: [MatCardModule,MatDividerModule,CommonModule,MatSlideToggleModule,MatButtonModule],
})
export class AssetProductSelectorPage implements OnInit {
  selectedProductType: AssetFinanceProductType = 'FAST DOC';
  isSwitchToEasyDoc: boolean =false;
  existingApplication: AssetFinanceApplication | null = null; // editing an existing application
  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  async ngOnInit() {
    setupUntilDestroy(this);
    this.existingApplication = (this.route.snapshot.data as any).application;
    this. populate();
  }

  populate() {
      if (this.existingApplication && this.existingApplication.AppInfo.productType) {
        this.isSwitchToEasyDoc = this.existingApplication.AppInfo.isSwitchToEasyDoc
        this.selectedProductType = this.existingApplication.AppInfo.productType
      }else{
        this.selectedProductType ='FAST DOC';
        this.isSwitchToEasyDoc = true;
      }
  }

  selectProductType(productType: AssetFinanceProductType): void {
    if(productType === 'FAST DOC'){
      this.isSwitchToEasyDoc = true;
      this.selectedProductType = productType;
    }else{
      this.selectedProductType = productType;
      this.isSwitchToEasyDoc = false;
    }
  }

  isProductTypeSelected(productType: AssetFinanceProductType): boolean {
    return this.selectedProductType === productType;
  }

  onSwitchToEasyDoc(switchToEasyDoc: boolean) {
    this.isSwitchToEasyDoc = switchToEasyDoc;
  }

  async onNext() {
    if(this.existingApplication){
      const urlTree = navigationUrlForAssetFinance(this.router, this.selectedProductType, this.isSwitchToEasyDoc, this.existingApplication.ApplicationId);
      await this.router.navigateByUrl(urlTree);
      // this.router.navigate(url, { queryParams });
    }else{
      const urlTree = navigationUrlForAssetFinance(this.router, this.selectedProductType, this.isSwitchToEasyDoc, undefined);
      await this.router.navigateByUrl(urlTree);
      // this.router.navigate(url, { queryParams });
      // this.router.navigate(navigationUrlForAssetFinance());
    }
  }

  downloadFile(){
    // window.open(`${environment.assetProductGuideUrl}`, '_blank');
    openWindowWithLink(environment.assetProductGuideUrl);
  }
}

