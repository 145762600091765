<div class="reporting-page container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="mat-h3">Reporting</div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <table mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows>
        <ng-container matColumnDef="dir">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            @if (isTableRowExpanded(element)) {
              <span class="mdi mdi-chevron-up"></span>
            }
            @if (!isTableRowExpanded(element)) {
              <span class="mdi mdi-chevron-down"></span>
            }
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">
            {{element.description}}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <span class="mdi mdi-database-export-outline cursor-pointer" (click)="generateReport($event, element)" matTooltip="Generate report"></span>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="element-detail" [@detailExpand]="isTableRowExpanded(element) ? 'expanded' : 'collapsed'">
              <!-- @if (element.type == 'reporting-trade-invoices-previousday') {
                <ng-container *ngTemplateOutlet="reportingTradeInvoicesPreviousDay; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-all-unpaid-trade-invoices') {
                <ng-container *ngTemplateOutlet="reportingAllUnpaidTradeInvoices; context: {element}"></ng-container>
              } -->
              <!-- @if (element.type == 'reporting-full-trade-invoice-transaction-listing') {
                <ng-container *ngTemplateOutlet="reportingFullTradeInvoiceTransactionListing; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-all-trade-payments-received-last-month') {
                <ng-container *ngTemplateOutlet="reportingAllTradePaymentsReceivedLastMonth; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-trade-invoices-due-3-days-after') {
                <ng-container *ngTemplateOutlet="reportingTradeInvoicesDue3DaysAfter; context: {element}"></ng-container>
              } -->
              <!-- @if (element.type == 'reporting-trade-invoices-due-next-7-days') {
                <ng-container *ngTemplateOutlet="reportingTradeInvoicesDueNext7Days; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-payway-transactions') {
                <ng-container *ngTemplateOutlet="reportingPaywayTransactions; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-trade-customers') {
                <ng-container *ngTemplateOutlet="reportingTradeCustomers; context: {element}"></ng-container>
              } -->
              @if (element.type == 'reporting-banking-transactions') {
                <ng-container *ngTemplateOutlet="reportingBankingTransactions; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-brokerage-transactions') {
                <ng-container *ngTemplateOutlet="reportingBrokerageTransactions; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-accounting-events') {
                <ng-container *ngTemplateOutlet="reportingPismoAccountingEvents; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-accounts-balances') {
                <ng-container *ngTemplateOutlet="reportingPismoAccountsBalances; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-accounts-limits') {
                <ng-container *ngTemplateOutlet="reportingPismoAccountsLimits; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-transactions-balance') {
                <ng-container *ngTemplateOutlet="reportingPismoTransactionsBalances; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-transactions-denied') {
                <ng-container *ngTemplateOutlet="reportingPismoTransactionsDenied; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-portfolio-balance') {
                <ng-container *ngTemplateOutlet="reportingPismoPortfolioBalance; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-accounting-raw') {
                <ng-container *ngTemplateOutlet="reportingPismoRawAccounting; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-accounting-aggregated') {
                <ng-container *ngTemplateOutlet="reportingPismoAggregatedAccounting; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-internal-accounts') {
                <ng-container *ngTemplateOutlet="reportingPismoInternalAccounts; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-pismo-arrears') {
                <ng-container *ngTemplateOutlet="reportingPismoArrears; context: {element}"></ng-container>
              }
              @if (element.type == 'reporting-authorization-clearing') {
                <ng-container *ngTemplateOutlet="reportingAuthorizationClearing; context: {element}"></ng-container>
              }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay"
          [class.expanded-row]="isTableRowExpanded(element)"
          (click)="expandTableRow(element)"
          class="cursor-pointer">
        </tr>
        <tr class="detail-row"
          mat-row
          *matRowDef="let element; columns: ['expandedDetail']"
          [ngClass]="{'detail-row-expanded': isTableRowExpanded(element)}"
          [ngStyle]="{'display': isTableRowExpanded(element) ? null : 'none'} ">
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- Reporting: trade invoice transaction listing of yesterday-->
<!-- <ng-template #reportingTradeInvoicesPreviousDay let-element="element">
  <p>{{element.description}}</p>
  <p>This report: </p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>List of all the Trade Invoices that with invoice date between given date range.</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template> -->

<!-- Reporting: trade invoices due next 7 days -->
<!-- <ng-template #reportingTradeInvoicesDueNext7Days let-element="element">
  <p>{{element.description}}</p>
  <p>This report: </p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all invoices due over the next week</li>
    <li>Will send an email with report attached to credit team every Friday morning</li>
    <li>Can be run manually when necessary, but will only generate the downloadable list when run manually</li>
  </ul>
</ng-template> -->

<!-- Reporting: all trade payments received last month -->
<!-- <ng-template #reportingAllTradePaymentsReceivedLastMonth let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all trade invoices that was paid last month</li>
    <li>Will send an email with report attached to credit team every 1st day of the month</li>
    <li>Can be run manually when necessary, but will only generate the downloadable list when run manually</li>
  </ul>
</ng-template> -->

<!-- Reporting: All Unpaid Trade Invoices -->
<!-- <ng-template #reportingAllUnpaidTradeInvoices let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all unpaid trade invoices with a due date before the today's date </li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template> -->

<!-- Reporting: trade invoices due 3 days after -->
<!-- <ng-template #reportingTradeInvoicesDue3DaysAfter let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>List all invoices due in 3 days time</li>
    <li>Will run daily in the morning send a reminder to customers within the list</li>
    <li>Can be run manually when necessary, but will only generate the downloadable list when run manually</li>
  </ul>
</ng-template> -->

<!-- Reporting: full trade invoice transaction listing -->
<!-- <ng-template #reportingFullTradeInvoiceTransactionListing let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all trade invoices (Approved, paid /unpaid, including their payway transaction allocated)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template> -->

<!-- Reporting: trade payway transaction report -->
<!-- <ng-template #reportingPaywayTransactions let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all payway transactions</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template> -->

<!-- Reporting: trade customer report -->
<!-- <ng-template #reportingTradeCustomers let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file</li>
    <li>list all customers (CustomerId, SalesforceId, userId, CustomerType, ABN, ACN, EntityName, EntityType etc.)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template> -->

<!-- Reporting: banking transactions report -->
<ng-template #reportingBankingTransactions let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all banking transactions (Transaction Type, Amount, Date, Status, Pismo Account No, Pismo Customer No, Last Updated Time etc.)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: brokerage transactions report -->
<ng-template #reportingBrokerageTransactions let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all brokerage transactions (Transaction Type, Amount, Date, Status, Pismo Account No, Pismo Customer No, Last Updated Time etc.)</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounting events report -->
<ng-template #reportingPismoAccountingEvents let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounting events from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounts balances report -->
<ng-template #reportingPismoAccountsBalances let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounts balances from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo accounts limits report -->
<ng-template #reportingPismoAccountsLimits let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all accounts limits from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo transactions balance report -->
<ng-template #reportingPismoTransactionsBalances let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all transactions balance from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo transactions denied report -->
<ng-template #reportingPismoTransactionsDenied let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all transactions denied from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo portfolio balance report -->
<ng-template #reportingPismoPortfolioBalance let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all portfolio balance from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo raw accounting report -->
<ng-template #reportingPismoRawAccounting let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all raw accounting report from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo aggregated accounting report -->
<ng-template #reportingPismoAggregatedAccounting let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all aggregated accounting report from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: pismo internal accounts -->
<ng-template #reportingPismoInternalAccounts let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all internal accounts transactions from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>


<!-- Reporting: pismo arrears report -->
<ng-template #reportingPismoArrears let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all arrears from Pismo</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>

<!-- Reporting: Mastercard transaction report -->
<ng-template #reportingAuthorizationClearing let-element="element">
  <p>{{element.description}}</p>
  <p>This report:</p>
  <ul>
    <li>Generates report in a csv file for the selected period</li>
    <li>list all Mastercard transactions</li>
    <li>Can be run manually when necessary</li>
  </ul>
</ng-template>
