import {Component, inject, OnInit} from "@angular/core";
import {UntilDestroy} from "@ngneat/until-destroy";
import {
  AccessLevelModule,
  CustomContentLoaderComponent,
  CustomPaginatorComponent,
  setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {FlexLayoutModule} from "@angular/flex-layout";
import {loadingFor} from "@ngneat/loadoff";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {DEFAULT_LIMIT, DEFAULT_OFFSET, User, WhitelabelEntity} from "@portal-workspace/grow-shared-library";
import {AsyncPipe, JsonPipe, NgClass} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {
  navigationUrlForAddWhitelabelSettingDetails, navigationUrlForEditWhitelabelSettingDetails,
  navigationUrlForPendingApprovalUserDetail
} from "../../service/navigation-urls";
import {Sort} from "@angular/material/sort";
import {PageEvent} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {WhitelabelService} from "../../service/whitelabel.service";
import {debounceTime, distinctUntilChanged, tap} from "rxjs/operators";
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormBuilder, FormControl} from "@angular/forms";

export class WhitelabelSettingsInternalDataSource extends DataSource<WhitelabelEntity> {

  subject = new BehaviorSubject<WhitelabelEntity[]>([]);

  connect(collectionViewer: CollectionViewer): Observable<readonly WhitelabelEntity[]> {
    return this.subject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.subject.complete();
  }
  update(entries: WhitelabelEntity[]) {
    this.subject.next(entries);
  }
}

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './whitelabel-settings.page.html',
  styleUrls: ['./whitelabel-settings.page.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatTableModule,
    FlexLayoutModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgClass,
    CustomContentLoaderComponent,
    JsonPipe,
    AsyncPipe,
    CustomPaginatorComponent,
    AccessLevelModule,
    MatFormField,
    MatInput,
    MatPrefix,
  ],
})
export class WhitelabelSettingsPage implements OnInit {

  displayColumns: string[] = ['originatorBusinessId', 'entityName', 'action'];

  subscriptions: Subscription[] = [];

  router = inject(Router);
  whitelabelService = inject(WhitelabelService);
  loader = loadingFor('tableLoading');

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;
  filter = '';
  sorts: {prop: string, dir: 'asc' | 'desc'} | null = null;

  formBuilder = inject(FormBuilder);
  formControlSearch: FormControl<string | null> = this.formBuilder.control(null);

  datasource = new WhitelabelSettingsInternalDataSource();

  ngOnInit() {
    setupUntilDestroy(this);
    this.reload();
    this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(r => {
        this.filter = r ?? '';
        this.reset();
        this.reload();
      })
    ).subscribe());
  }

  onSortData($event: Sort) {
    if ($event.direction && $event.active) {
      this.sorts = {prop: $event.active, dir: $event.direction};
    } else {
      this.sorts = null;
    }
    this.reset();
    this.reload();
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.reload();
  }

  private reset() {
    this.limit = DEFAULT_LIMIT;
    this.offset = DEFAULT_OFFSET;
  }

  private reload() {
    this.subscriptions.push(this.whitelabelService.getAllWhitelabels({
      page: {
        limit: this.limit, offset: this.offset,
      },
      filter: this.filter,
      sorts: this.sorts ? [this.sorts]: []
    }).pipe(
      this.loader.tableLoading.track(),
      tap(r => {
        this.total = r.total;
        const entries = r.payload;
        this.datasource.update(entries);
      })
    ).subscribe());
  }

  async onAdd() {
    await this.router.navigate(navigationUrlForAddWhitelabelSettingDetails());
  }

  async onEdit(element: WhitelabelEntity) {
    await this.router.navigate(navigationUrlForEditWhitelabelSettingDetails(element.originatorBusinessId));
  }

  async onRowClicked($event: Event, element: WhitelabelEntity) {
    await this.router.navigate(navigationUrlForEditWhitelabelSettingDetails(element.originatorBusinessId));
  }
}
