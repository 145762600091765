<div class="required-document-list-dialog">
  <mat-dialog-content class='max-height-100-vh'>
    <div class="row">
      <div class="col selected-chips">
        <div class="title">Select the required documents for this application</div>
        <mat-card appearance="outlined" class="card">
          <mat-card-content>
            <mat-chip-listbox #chipList aria-label="Tag selection">
              @for (tag of requiredTags; track tag) {
                <mat-chip-option
                  (removed)="remove(tag)">
                  {{tag.type}}
                  <button matChipRemove>
                    <span class="mdi mdi-close-circle-outline"></span>
                  </button>
                </mat-chip-option>
              }
            </mat-chip-listbox>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col  all-chips">
        <mat-chip-listbox aria-label="Tag selection">
          @for (tag of selectableTags; track tag) {
            <mat-chip-option
              (click)="addTag(tag)">
              {{tag.type}}
            </mat-chip-option>
          }
          <mat-chip-option class="custom-chip" (click)="addCustomTag(false)">
            + Custom Tag
          </mat-chip-option>
          <mat-chip-option class="custom-chip" (click)="addCustomTag(true)">
            + Non-standard condition
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onSubmit(false)">Cancel</button>
        <button mat-flat-button color="primary" (click)="onSubmit(true)">Save Changes</button>
      </div>
    </div>
  </mat-dialog-content>
</div>
