import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
  SimpleChanges
} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe, PercentPipe } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MessageBoxComponent } from "../../message-box/message-box.component";
import { MatCardModule } from "@angular/material/card";
import {
  AppCalculator,
  Application,
  FacilityEstablishmentValue,
  FormDataForBusinessOverdraft,
  GetRateCardDetailsFn,
  LoanTermValue,
  LoanTermValueOptions,
  OverdraftPaymentBreakupDialogData2,
  PaymentFrequencyValue,
  PaymentFrequencyValueOptions,
  RatecardDetails,
  SaveApplicationPricingDetailsData,
  SliderComponentValue,
  TermRate,
  TermRateForBusinessOverdraft,
  UpdateApplicationData,
  UpdateApplicationFn,
  UpdateApplicationSfFn,
  YesNoValue,
  booleanToYesNo,
  getDocFee,
  getInterestRate,
  getRbaRate,
  isAdminOrCreditUserOrSalesAM,
  isInternalUser,
  isNotAdminOrCreditOrSalesAMInternalUser,
  CorporateLoanPaymentBreakupDialogData,
  calculateBusinessOverdraftEstimation,
  LoanTermType,
  GetContractStatusFn,
  getBrokerage,
  isAdminOrCreditUser,
  isAdmin,
  isAdminOrCreditUserOrSalesAmOrSalesBDM,
  LtvSelectionValue,
  SecurityTypeSelectionValue,
  LtvSelectionValueOptions,
  SecurityTypeSelectionValueOptions,
  calculateCorporateLoanEstimation
} from "@portal-workspace/grow-shared-library";
import { Subscription, tap } from "rxjs";
import { DisableControlDirective } from "../../../directives/disable-control.directive";
import { InputMaskModule } from "@ngneat/input-mask";
import { YesNoComponent } from "../../yes-no-component/yes-no.component";
import { MatTableModule } from "@angular/material/table";
import { PercentageInputComponent } from "../../percentage-input-component/percentage-input.component";
import { PaymentFrequencySelectionComponent } from "../../payment-frequency-selection-component/payment-frequency-selection.component";
import { CurrencyInputComponent } from "../../currency-selection-component/currency-input.component";
import { FacilityEstablishmentComponent } from "../../facility-establishment-component/facility-establishment.component";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ApplicationDialogService } from "../../application-dialog-component/application-dialog.service";
import { PortalHotToastService } from "../../portal-hot-toast-component/hot-toast.service";
import { loadingFor } from "@ngneat/loadoff";
import {
  applicationToAdverseOnFile,
  applicationToBrokerageAmount,
  applicationToCrediRateAdjustment,
  applicationToDirectorScore,
  applicationToEquifaxScoreAboveThreshold,
  applicationToFacilityEstablishmentFee,
  applicationToFacilityEstablishmentFeePercent,
  applicationToLastUpdatedByUser,
  applicationToLoanAmount,
  applicationToLoanTerms, applicationToLtv,
  applicationToMargin,
  applicationToPaymentFrequency,
  applicationToPreviousLoan,
  applicationToPropertyOwner, applicationToSecurityType,
  getUser
} from "../../component-utils";
import { Moment } from "moment";
import moment from "moment";
import _ from "lodash";
import {LooseCurrencyPipe} from '../../../pipes/loose-currency.pipe';
import {LtvSelectionComponent} from '../../ltv-component/ltv-selection.component';
import {SecurityTypeSelectionComponent} from '../../security-type-component/security-type-selection.component';
import {PercentagePipe} from "../../../pipes/percentage.pipe";

export type CorporateLoansPricingComponentEvent = CorporateLoansPricingComponentSavedEvent;

export interface CorporateLoansPricingComponentSavedEvent {
  type: 'corporate-loan-pricing-saved',
  rst: CorporateLoanPaymentBreakupDialogData
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'corporate-loans-pricing',
  templateUrl: './corporate-loans-pricing.component.html',
  styleUrls: ['./corporate-loans-pricing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatFormFieldModule,
    AsyncPipe,
    JsonPipe,
    MatCardModule,
    MessageBoxComponent,
    DisableControlDirective,
    InputMaskModule,
    YesNoComponent,
    MatTableModule,
    PercentageInputComponent,
    PaymentFrequencySelectionComponent,
    CurrencyInputComponent,
    FacilityEstablishmentComponent,
    LooseCurrencyPipe,
    LtvSelectionComponent,
    SecurityTypeSelectionComponent,
    PercentagePipe
],
})
export class CorporateLoansPricingComponent implements OnInit {

  @Input({ required: true }) getRateCardDetailsFn!: GetRateCardDetailsFn;
  @Input({ required: true }) updateApplicationFn!: UpdateApplicationFn;
  @Input({ required: true }) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({ required: true }) application!: Application;
  @Input({ required: true }) currentRstValue!: CorporateLoanPaymentBreakupDialogData;
  @Output() events = new EventEmitter<CorporateLoansPricingComponentEvent>();
  @ViewChild('readMode') readModeTemplate!: TemplateRef<any>;
  @ViewChild('editMode') editModeTemplate!: TemplateRef<any>;

  subscriptions: Subscription[] = [];

  formBuilder: FormBuilder = inject(FormBuilder);
  applicationDialogService: ApplicationDialogService = inject(ApplicationDialogService);
  toastService: PortalHotToastService = inject(PortalHotToastService);

  loader = loadingFor('saveRate');

  user = getUser();
  isInternalUser = isInternalUser(this.user)
  isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.user);
  isAdminOrCreditUserOrSalesAMOrSalesBDM = isAdminOrCreditUserOrSalesAmOrSalesBDM(this.user);
  isNotAdminOrCreditUser = !isAdminOrCreditUser(this.user);
  isAdmin = isAdmin(this.user);

  isContractPending: boolean = true; // if a contract is pending for this application
  isApplicationStagePostQa = true;

  dataSource: (
    {name: string, click?: (value: string | null | undefined)=> void,  highlight?: (value: string | null | undefined) => boolean, type: 'text', value: string | null | undefined} |
    {name: string, click?: (value: string | null | undefined)=> void,  highlight?: (value: number | string | null | undefined) => boolean, type: 'currency', value: number | string | null | undefined} |
    {name: string, click?: (value: string | null | undefined)=> void,  highlight?: (value: number | string | null | undefined) => boolean, type: 'percentage', value: number | string | null | undefined}
    )[] = [];
  currentTemplate: TemplateRef<any> | null = null;

  step2MonthlyRepayment: string = '';
  step2EstimatedDrawdownDate: Moment = moment();
  MIN_LIMIT = 0.01
  step2MinLimit: number = this.MIN_LIMIT;
  step2MaxLimit: number | null = null;
  isDisable = true;
  MIN_CREDIT_RATE = -1;
  MAX_CREDIT_RATE = 5;
  step2MaxFacilityEstablishmentFee: number = 5;

  rateCard: RatecardDetails | null = null;
  // terms: TermRate | null = null;              // available after chart popups (after onGenerateQuote())
  calculator: AppCalculator | null = null;    // available after chart popups (after onGenerateQuote())
  rst: CorporateLoanPaymentBreakupDialogData | null = null;    // calculate estimation result (available after chart popups (after onGenerateQuote())
  data: Partial<UpdateApplicationData> | null = null;  // available after chart popups (after onGenerateQuote())
  brokerageDollar?: number;
  interestRate?: number;
  rbaRate?: number;
  facilityEstablishmentFee?: number;
  docFee?: number;
  brokerage?: number;
  monthlyFacilityFeePercentage?: number;
  lastEditedByUser ?: string | null

  formControlStep2LoanTerms!: FormControl<LoanTermValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2FacilityEstablishmentFeePercent!: FormControl<FacilityEstablishmentValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlStep2LoanAmount!: FormControl<SliderComponentValue>;
  formControlStep2DirectorScore!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formControlCreditRateAdjustment!: FormControl<number | null>;
  formControlStep2LtvSelection!: FormControl<LtvSelectionValue>;
  formControlStep2SecurityTypeSelection!: FormControl<SecurityTypeSelectionValue>;

  formGroupStep2!: FormGroup<{
    loanAmount: FormControl<SliderComponentValue>,
    facilityEstablishmentFeePercent: FormControl<FacilityEstablishmentValue>,
    loanTerms: FormControl<LoanTermValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScore: FormControl<YesNoValue>,
    directorScore: FormControl<YesNoValue>,
    previousLoan: FormControl<YesNoValue>,
    creditRateAdjustment: FormControl<number | null>,
    ltv: FormControl<LtvSelectionValue>,
    securityType: FormControl<SecurityTypeSelectionValue>,
  }>;

  ngOnInit() {
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2FacilityEstablishmentFeePercent = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(LoanTermValueOptions[2], [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control(PaymentFrequencyValueOptions[2], [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2DirectorScore = this.formBuilder.control(true, [Validators.required])
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);
    this.formControlCreditRateAdjustment = this.formBuilder.control(0.0, [Validators.required]);
    this.formControlStep2LtvSelection = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2SecurityTypeSelection = this.formBuilder.control(null, [Validators.required]);

    this.formGroupStep2 = this.formBuilder.group({
      // broker: this.formControlStep2Broker,
      loanAmount: this.formControlStep2LoanAmount,
      facilityEstablishmentFeePercent: this.formControlStep2FacilityEstablishmentFeePercent,
      loanTerms: this.formControlStep2LoanTerms,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      equifaxScore: this.formControlStep2EquifaxScoreAboveThreshold,
      directorScore: this.formControlStep2DirectorScore,
      previousLoan: this.formControlStep2PreviousLoan,
      creditRateAdjustment: this.formControlCreditRateAdjustment,
      ltv: this.formControlStep2LtvSelection,
      securityType: this.formControlStep2SecurityTypeSelection,
    });

    //populate
    this.populate();

    this.createReadonlyTableDataSource();


    if (this.isNotAdminOrCreditUser) { // not credit or admin
      this.formControlStep2PropertyOwner.disable();
      this.formControlStep2AdverseOnFile.disable();
      this.formControlStep2EquifaxScoreAboveThreshold.disable();
      this.formControlStep2DirectorScore.disable();
      this.formControlStep2PreviousLoan.disable();

      const loanAmount = applicationToLoanAmount(this.application) ?? 0;
      const adjustmentToLoanAmount = (loanAmount * 0.1);
      const maxLoanAmount = _.round(loanAmount + adjustmentToLoanAmount);
      this.step2MaxLimit = maxLoanAmount;

    } else {  // credit or admin
      if (this.isAdmin) {
        this.MIN_CREDIT_RATE = -10000;
        this.MAX_CREDIT_RATE = 10000;
      } else {
        this.MIN_CREDIT_RATE = -5;
        this.MAX_CREDIT_RATE = 5;
      }
    }

    // update max limit
    // this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
    //   tap(r => {
    //     this.step2UpdateMaxLimit();
    //   })
    // ).subscribe());

    this.subscriptions.push(this.formControlStep2EquifaxScoreAboveThreshold.valueChanges.pipe(
      tap(r => {
        if(r){
          this.formControlStep2DirectorScore.setValue(false)
        }else{
          this.formControlStep2DirectorScore.setValue(true)
        }
      })
    ).subscribe());

    // this.subscriptions.push(this.formControlStep2DirectorScore.valueChanges.pipe(
    //   tap(r => {
    //     this.step2UpdateMaxLimit();
    //   })
    // ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe())

    this.subscriptions.push(this.getRateCardDetailsFn(this.application.UserId, 'CorporateLoans').pipe(
      tap(rateCardDetails => {
        this.rateCard = rateCardDetails;
        this.step2MaxFacilityEstablishmentFee = this.rateCard?.MaxFacilityEstablishmentFee ?? 5;
      })
    ).subscribe());

    // get contract status for application
    this.subscriptions.push(this.getContractStatusFn(this.application.ApplicationId).pipe(
      tap(r => {
        const {contractPending} = r.signers.reduce((acc, signer) => {
          const contractPending = ["created", "sent", "delivered", "signed", "completed", "faxpending"].includes(signer.status);
          acc.contractPending = acc.contractPending || contractPending;
          return acc;
        }, { contractPending: false});
        this.isContractPending = contractPending;
      })
    ).subscribe());

    this.isApplicationStagePostQa = ['QA', 'Settlement', 'Closed Lost', 'Closed Lost'].includes(this.application.InteflowStatusStageName ?? '');
  }

  populate() {
    this.formControlStep2LoanTerms.setValue(applicationToLoanTerms(this.application));
    this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.application));
    this.formControlStep2FacilityEstablishmentFeePercent.setValue(applicationToFacilityEstablishmentFeePercent(this.application));
    // this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application));
    this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application) ??
      PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
    this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.application));
    this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.application));
    this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.application));
    this.formControlStep2DirectorScore.setValue(applicationToDirectorScore(this.application));
    this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.application));
    this.formControlCreditRateAdjustment.setValue(applicationToCrediRateAdjustment(this.application));
    const ltvType = applicationToLtv(this.application);
    this.formControlStep2LtvSelection.setValue(LtvSelectionValueOptions.find(opt => opt.type == ltvType) ?? null);
    const securityType = applicationToSecurityType(this.application);
    this.formControlStep2SecurityTypeSelection.setValue(SecurityTypeSelectionValueOptions.find(opt => opt.type == securityType) ?? null);
  }

  createReadonlyTableDataSource() {
    this.lastEditedByUser = applicationToLastUpdatedByUser(this.application)?.name
    const ltvType = applicationToLtv(this.application);
    const ltvTypeInText = LtvSelectionValueOptions.find(opt => opt.type == ltvType)?.name ?? '';
    const securityType = applicationToSecurityType(this.application);
    const securityTypeInText = SecurityTypeSelectionValueOptions.find(opt => opt.type == securityType)?.name ?? '';

    this.dataSource = [
      { name: 'Facility Limit', type: 'currency', value: applicationToLoanAmount(this.application) },
      { name: 'Facility Establishment Fee (%)', type: 'percentage', value: applicationToFacilityEstablishmentFeePercent(this.application)?.type },
      { name: 'Facility Establishment Fee ($)', type: 'currency', value: applicationToFacilityEstablishmentFee(this.application) },
      { name: 'Loan Terms', type: 'text', value: `${applicationToLoanTerms(this.application)?.type ?? ''} months` },
      { name: 'RBA Rate (%)', type: 'percentage', value: getRbaRate(this.application) },
      { name: 'Monthly Facility Fees ($)', type: 'currency', value: this.currentRstValue?.term.monthlyFacilityFee},

      { name: 'Brokerage (%)', type: 'percentage', value: getBrokerage(this.application) },
      { name: 'Brokerage(ex GST)', type: 'currency', value: applicationToBrokerageAmount(this.application) },

      { name: 'Property Owner', type: 'text', value: booleanToYesNo(applicationToPropertyOwner(this.application)) },
      { name: 'Adverse on file', type: 'text', value: booleanToYesNo(applicationToAdverseOnFile(this.application)) },
      { name: 'Director Equifax Score > 600', type: 'text', value: booleanToYesNo(applicationToEquifaxScoreAboveThreshold(this.application)) },
      { name: 'Director Score 500-550', type: 'text', value: booleanToYesNo(applicationToDirectorScore(this.application)) },

      { name: 'LTV', type: 'text', value: ltvTypeInText},
      { name: 'Security Type', type: 'text', value: securityTypeInText},

      { name: 'Doc/Annual Fee ($)', type: 'currency', value: getDocFee(this.application) },
      { name: 'Margin (%)', type: 'percentage', value: applicationToMargin(this.application) },

      { name: 'Interest Rate (%)', type: 'percentage', value: getInterestRate(this.application),
        // click: (v) => {
        //   if (this.currentRstValue?.term?.calculationLog) {
        //     this.applicationDialogService.openInterestRateCalculationLogDialog({ logs: this.currentRstValue.term.calculationLog});
        //   }
        // },
      },
      { name: 'Comparable Credit last 3 years', type: 'text', value: booleanToYesNo(applicationToPreviousLoan(this.application)) },

      { highlight: (v) => Number(v) != 0, name: 'Credit Rate Adjustment', type: 'percentage', value: applicationToCrediRateAdjustment(this.application) },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    const applicationChange = changes['application'];
    const currentRstValueChange = changes['currentRstValue'];
    if (applicationChange || currentRstValueChange) {
      this.createReadonlyTableDataSource();
    }
  }

  ngAfterViewInit() {
    this.currentTemplate = this.readModeTemplate;
  }

  switchMode() {
    if (this.currentTemplate == this.editModeTemplate) {
      this.currentTemplate = this.readModeTemplate;
    } else {
      this.populate();
      this.currentTemplate = this.editModeTemplate;
    }
  }

  // private step2UpdateMaxLimit() {
  //   const isPropertyOwner = this.formControlStep2PropertyOwner.value;
  //   if (isPropertyOwner) {
  //     this.step2MaxLimit = 300000;
  //   } else {
  //     this.step2MaxLimit = 50000;
  //   }
  // }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  calculateEstimation(): CorporateLoanPaymentBreakupDialogData {
    const date = moment().add(1, 'day');
    const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;
    const loanTermType: LoanTermType | null = loanTermValue?.type ?? null;
    // const loanAmount = 0;
    const propertyOwner = this.formControlStep2PropertyOwner.value ?? false;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value ?? false;
    const lowEquifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value ?? false;
    const directorScore = this.formControlStep2DirectorScore.value ?? false;
    const facilityLimit = this.formControlStep2LoanAmount.value ?? 0;
    const facilityEstablishmentFeePercent = this.formControlStep2FacilityEstablishmentFeePercent.value ? Number(this.formControlStep2FacilityEstablishmentFeePercent.value.type) : 0;
    const creditRateAdjustment = this.formControlCreditRateAdjustment.value ?? 0;
    const ltvType = this.formControlStep2LtvSelection.value;
    const securityType = this.formControlStep2SecurityTypeSelection.value;

    const r = calculateCorporateLoanEstimation(date, {
      type: 'CorporateLoan',
      loanTermType,
      // loanAmount,
      propertyOwner,
      adverseOnFile,
      lowEquifaxScore,
      directorScore,
      rateCard: this.rateCard,
      facilityLimit,
      facilityEstablishmentFeePercent,
      creditRateAdjustment,
      ltvType: ltvType?.type,
      securityType: securityType?.type,
    });
    this.calculator = r.calculator;
    this.brokerageDollar = r.term.brokerageDollar;
    this.brokerage = r.term.brokerage;
    this.interestRate = r.term.interestRate;
    this.rbaRate = this.rateCard?.rbaRate;
    this.facilityEstablishmentFee = r.term.facilityEstablishmentFee;
    this.docFee = r.term.docFee;
    this.monthlyFacilityFeePercentage = r.term.monthlyFacilityFeePercentage;
    this.data = this.createApplicationData();
    const rst: CorporateLoanPaymentBreakupDialogData= {
      term: r.term
    };
    this.rst = rst;
    return rst;
  }

  // calculateEstimationBak(): OverdraftPaymentBreakupDialogData {
  //   const paymentFrequencyValue = 'Monthly';
  //   const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;

  //   const calculator = new AppCalculator();
  //   // const businessSearchValue: BusinessSearchValue = this.formControlStep1Business.value;

  //   const lowEquifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value ?? false;
  //   const adverseOnFile = this.formControlStep2AdverseOnFile.value ?? false;
  //   const propertyOwner = this.formControlStep2PropertyOwner.value ?? false;
  //   const facilityEstablishmentFee = this.formControlStep2LoanAmount.value ?? 0;
  //   const directorScore = this.formControlStep2DirectorScore.value ?? false;
  //   const facilityEstablishmentFeePercent = this.formControlStep2FacilityEstablishmentFeePercent.value ? Number(this.formControlStep2FacilityEstablishmentFeePercent.value.type) : 0;
  //   const creditRateAdjustment = this.formControlCreditRateAdjustment.value;

  //   const formData: FormDataForBusinessOverdraft = {
  //     facilityEstablishmentFee,
  //     facilityEstablishmentFeePercent,
  //     loanTerms: Number(loanTermValue?.type ?? 0),
  //     propertyOwner,
  //     adverseOnFile,
  //     lowEquifaxScore: lowEquifaxScore,
  //     directorScore: directorScore,
  //     // extra field that affects estimation
  //     creditRateAdjustment: creditRateAdjustment ?? 0, // handle creditRateAdjustment
  //   };
  //   calculator.setRepaymentFrequency(paymentFrequencyValue);
  //   calculator.setloanValue(Number(loanTermValue?.type ?? '0'));
  //   calculator.refreshUI();
  //   this.calculator = calculator;
  //   const term = calculator.getCalcualteBusinessOverdraft(this.rateCard!, formData)
  //   this.brokerageDollar = term.brokerageDollar;
  //   this.brokerage = term.brokerage;
  //   this.interestRate = term.interestRate;
  //   this.rbaRate = this.rateCard?.rbaRate;
  //   this.facilityEstablishmentFee = term.facilityEstablishmentFee;
  //   this.docFee = term.docFee;
  //   this.data = this.createApplicationData();
  //   const rst = {
  //     term: term,
  //   };
  //   this.rst = rst;
  //   return rst;
  // }

  createApplicationData() {
    // pricing details
    const loanTerm: LoanTermValue = this.formControlStep2LoanTerms.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    // const brokerage: BrokerageSelectionValue = this.formControlStep2Brokerage.value;
    const paymentPeriod = 'Monthly';
    const docFeeFinanced = false;
    const docFee = this.docFee;
    // const brokerOriginationFee = this.terms?.brokerOriginationFee ?? 0;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    const rate = this.interestRate;
    const rbaRate = this.rbaRate;
    const loanPurpose = 'Cash Flow';
    const brokerage = this.brokerage;
    const facilityEstablishmentFeePercent = this.formControlStep2FacilityEstablishmentFeePercent.value?.type ?? 0;
    const facilityEstablishmentFee = this.facilityEstablishmentFee
    const directorScoreRate = this.formControlStep2DirectorScore.value
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const margin = (rate == undefined || this.rbaRate == undefined) ? undefined : Math.round((rate - this.rbaRate) * 100) / 100; // to prevent from substraction overflow
    const ltv = this.formControlStep2LtvSelection.value?.type ?? null;
    const securityType = this.formControlStep2SecurityTypeSelection.value?.type ?? null;
    let apiBodyPricingDetails: SaveApplicationPricingDetailsData = {
      LoanTerm: loanTerm?.type ? Number(loanTerm.type) : undefined,
      // Brokerage: brokerage ? numeral(brokerage.type).value() ?? undefined : undefined
      // BrokerOriginationFee: brokerOriginationFee,
      LoanAmount: loanAmount,
      AmountFinance: loanAmount,
      PaymentPeriod: paymentPeriod,
      DocFee: (docFee != null ? Number(docFee) : undefined),
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      PropertyOwner: booleanToYesNo(propertyOwner),
      BankStatementSubmitted: 'No' as const,
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      Rate: rate,
      RbaRate: rbaRate,
      Brokerage: brokerage,
      BrokerageDollar: this.brokerageDollar,
      BrokerageAmount: this.brokerageDollar,
      FacilityEstablishmentFeePercent: Number(facilityEstablishmentFeePercent),
      FacilityEstablishmentFee: facilityEstablishmentFee,
      DirectorScoreRate: booleanToYesNo(directorScoreRate),
      Margin: margin,
      CreditRateAdjustment: Number(this.formControlCreditRateAdjustment.value),
      MonthlyFacilityFeePercentage: this.monthlyFacilityFeePercentage,
      ltv: ltv,
      securityType: securityType,
    }

    if (!propertyOwner) {
      apiBodyPricingDetails = {
        ...apiBodyPricingDetails,
        PreviousLoan: booleanToYesNo(previousLoan)
      }
    }

    const apiBody: Partial<UpdateApplicationData> = {
      UpdateApplicationIgnoreStatus: true,
      PricingDetails: apiBodyPricingDetails as any,
    }
    return apiBody

  }

  save() {
    if (this.data) {
      this.subscriptions.push(this.updateApplicationSfFn(this.application.ApplicationId, this.data as UpdateApplicationData).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`Rate updated`),
        tap(r => {
          if (r.status) {
            this.events.emit({
              type: "corporate-loan-pricing-saved",
              rst: this.rst!,
            });
            this.currentTemplate = this.readModeTemplate;
          }
        })
      ).subscribe());
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: `Cannot saved without performing calculation first`,
      });
    }
  }


  onGenerateQuote() {
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');
    const subscription = this.applicationDialogService.openCorporateLoanPricingComparisonDialog({
      currentValue: this.currentRstValue,
      newValue: this.calculateEstimation()
    }).afterClosed()
      .pipe(
        tap(r => {
          if (r && r.acceptedQuotation) {
            // update the doc fee
            this.docFee = r.docFee;
            // patch current rst docfee, save will emit events that business-overdraft-application-details component subscribe
            this.rst!.term.docFee = r.docFee;
            // recreate application data for submission
            this.data = this.createApplicationData();
            this.save();
          }
        })
      ).subscribe();
    this.subscriptions.push(subscription);
  }

  canGenerateQuote(): boolean {
    return (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM && this.formGroupStep2.valid  &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
  }


  canEditPricing(): boolean {
    return (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
  }
}

