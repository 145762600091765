import {UntilDestroy} from "@ngneat/until-destroy";
import {BehaviorSubject, Subscription} from "rxjs";
import {inject, Injectable, OnDestroy} from "@angular/core";
import {
  DEFAULT_FILTER,
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  OriginatorListValue,
  PaginablePayloadApiResponse,
  PaginationInfo,
  PayloadApiResponse,
  SaveWhitelabel,
  SortTypes,
  WhitelabelEntity,
  WhitelabelEntityDetails,
  WhitelabelServiceEvent,
  WhitelabelStyles
} from "@portal-workspace/grow-shared-library";
import {environment} from "../../environments/environment";
import {
  generateMaterialShades, generateWhitelabelStyles,
  getWhiteLabelFromStorage,
  httpOptions,
  paginationUrl, setupUntilDestroy,
  storeWhitelabelIntoStorage, toContrastColor,
  toPaginationInfo
} from "@portal-workspace/grow-ui-library";
import {HttpClient} from "@angular/common/http";
import {map, tap} from "rxjs/operators";


const URL_GET_ALL_WHITELABELS  = (paginationInfo: PaginationInfo) => paginationUrl(`${environment.api2Host}/api2/whitelabel/all`, paginationInfo);  // GET
const URL_GET_WHITELABEL_BY_ORIGINATOR_BUSINESS_ID = (originatorBusinessId: number) => `${environment.api2Host}/api2/whitelabel/originator/${originatorBusinessId}`; // GET
const URL_GET_WHITELABEL_BY_USER_ID = (userId: number) => `${environment.api2Host}/api2/whitelabel/user/${userId}`; // GET
const URL_SAVE_WHITELABEL = (originatorBusinessId: number) => `${environment.api2Host}/api2/whitelabel/save/${originatorBusinessId}`; // POST
const URL_ORIGINATORS_WITHOUT_WHITELABEL = () => `${environment.api2Host}/api2/whitelabel/available-originators`; // GET

export const ALL_WHITELABEL_CSS_VAR_NAMES = [
  'portal2-sg-dynamoney-blue',
  'grow-primary-palette-50',
  'grow-primary-palette-100',
  'grow-primary-palette-200',
  'grow-primary-palette-300',
  'grow-primary-palette-400',
  'grow-primary-palette-500',
  'grow-primary-palette-600',
  'grow-primary-palette-700',
  'grow-primary-palette-800',
  'grow-primary-palette-900',
  'grow-primary-palette-A100',
  'grow-primary-palette-A200',
  'grow-primary-palette-A400',
  'grow-primary-palette-A700',
  'grow-primary-palette-contrast-50',
  'grow-primary-palette-contrast-100',
  'grow-primary-palette-contrast-200',
  'grow-primary-palette-contrast-300',
  'grow-primary-palette-contrast-400',
  'grow-primary-palette-contrast-500',
  'grow-primary-palette-contrast-600',
  'grow-primary-palette-contrast-700',
  'grow-primary-palette-contrast-800',
  'grow-primary-palette-contrast-900',
  'grow-primary-palette-contrast-A100',
  'grow-primary-palette-contrast-A200',
  'grow-primary-palette-contrast-A400',
  'grow-primary-palette-contrast-A700',
  'grow-accent-palette-50',
  'grow-accent-palette-100',
  'grow-accent-palette-200',
  'grow-accent-palette-300',
  'grow-accent-palette-400',
  'grow-accent-palette-500',
  'grow-accent-palette-600',
  'grow-accent-palette-700',
  'grow-accent-palette-800',
  'grow-accent-palette-900',
  'grow-accent-palette-A100',
  'grow-accent-palette-A200',
  'grow-accent-palette-A400',
  'grow-accent-palette-A700',
  'grow-accent-palette-contrast-50',
  'grow-accent-palette-contrast-100',
  'grow-accent-palette-contrast-200',
  'grow-accent-palette-contrast-300',
  'grow-accent-palette-contrast-400',
  'grow-accent-palette-contrast-500',
  'grow-accent-palette-contrast-600',
  'grow-accent-palette-contrast-700',
  'grow-accent-palette-contrast-800',
  'grow-accent-palette-contrast-900',
  'grow-accent-palette-contrast-A100',
  'grow-accent-palette-contrast-A200',
  'grow-accent-palette-contrast-A400',
  'grow-accent-palette-contrast-A700',
  'grow-warn-palette-50',
  'grow-warn-palette-100',
  'grow-warn-palette-200',
  'grow-warn-palette-300',
  'grow-warn-palette-400',
  'grow-warn-palette-500',
  'grow-warn-palette-600',
  'grow-warn-palette-700',
  'grow-warn-palette-800',
  'grow-warn-palette-900',
  'grow-warn-palette-A100',
  'grow-warn-palette-A200',
  'grow-warn-palette-A400',
  'grow-warn-palette-A700',
  'grow-warn-palette-contrast-50',
  'grow-warn-palette-contrast-100',
  'grow-warn-palette-contrast-200',
  'grow-warn-palette-contrast-300',
  'grow-warn-palette-contrast-400',
  'grow-warn-palette-contrast-500',
  'grow-warn-palette-contrast-600',
  'grow-warn-palette-contrast-700',
  'grow-warn-palette-contrast-800',
  'grow-warn-palette-contrast-900',
  'grow-warn-palette-contrast-A100',
  'grow-warn-palette-contrast-A200',
  'grow-warn-palette-contrast-A400',
  'grow-warn-palette-contrast-A700',
];

export const PURPLE_STYLES: WhitelabelStyles =  generateWhitelabelStyles({
  primary: '#211747',
  accent: '#5D40FF',
  warn: '#e54234',
});

export const GREEN_STYLES: WhitelabelStyles = generateWhitelabelStyles({
  primary: '#0b5d26',
  accent: '#a1ced6',
  warn: '#ffb4ab',
});

export const BROWN_STYLES: WhitelabelStyles = generateWhitelabelStyles({
  primary: '#ef7e6b',
  accent: '#6f5c2e',
  warn: '#ba1a1a',
});

export const BLUE_STYLES: WhitelabelStyles = generateWhitelabelStyles({
  primary: '#2f0e95',
  accent: '#ecb8cd',
  warn: '#ffb4ab',
})

export const PINK_STYLES: WhitelabelStyles = generateWhitelabelStyles({
  primary: '#9b1037',
  accent: '#785930',
  warn: '#ba1a1a',
});

export const ALL_STYLES =  [
  {name: 'Purple Pumpkin', style: PURPLE_STYLES},
  {name: 'Green Guava', style: GREEN_STYLES},
  {name: 'Brown Banana', style: BROWN_STYLES},
  {name: 'Blue Bael', style: BLUE_STYLES},
  {name: 'Pink Pear', style: PINK_STYLES}
].sort((a, b) => a.name.localeCompare(b.name));



@UntilDestroy({arrayName: 'subscriptions'})
@Injectable()
export class WhitelabelService implements OnDestroy {

  subject = new BehaviorSubject<WhitelabelServiceEvent>(null);

  subscriptions: Subscription[] = [];
  httpClient = inject(HttpClient);

  constructor() {
    setupUntilDestroy(this);
  }

  ngOnDestroy() {
    this.subject.complete();
  }

  getOriginatorsWithoutWhitelabelFn = () => {
    return this.getOriginatorsWithoutWhitelabel().pipe(map(r => r.payload));
  }

  getOriginatorsWithoutWhitelabel() {
    return this.httpClient.get<PayloadApiResponse<Exclude<OriginatorListValue, null>[]>>(URL_ORIGINATORS_WITHOUT_WHITELABEL(), httpOptions());
  }

  getAllWhitelabels(
    options: {
      page: {offset: number, limit: number},
      filter: string,
      sorts?: SortTypes,
    } = { page: { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT}, filter: DEFAULT_FILTER}
  ) {
    const paginationInfo = toPaginationInfo(options);
    return this.httpClient.get<PaginablePayloadApiResponse<WhitelabelEntity>>(URL_GET_ALL_WHITELABELS(paginationInfo), httpOptions());
  }

  saveWhitelabel(originatorBusinessId: number, data: SaveWhitelabel) {
    return this.httpClient.post(URL_SAVE_WHITELABEL(originatorBusinessId), data, httpOptions());
  }

  getWhitelabelByOriginatorBusinessId(originatorBusinessId: number) {
    return this.httpClient.get<PayloadApiResponse<WhitelabelEntityDetails>>(URL_GET_WHITELABEL_BY_ORIGINATOR_BUSINESS_ID(originatorBusinessId), httpOptions());
  }

  // when user login
  loadFromRemoteAndTriggerWhitelabelEvent(userId: number) {
    return this.httpClient
      .get<PayloadApiResponse<WhitelabelEntityDetails>>(URL_GET_WHITELABEL_BY_USER_ID(userId), httpOptions())
      .pipe(
        tap(r => {
          if (r.payload) {
            storeWhitelabelIntoStorage(r.payload);
            this.triggerUserWhitelabelChange({
              type: 'whitelabel-change',
              payload: {
                logoLink: r.payload.logoLink,
                styles: r.payload.styles,
              },
            });
          } else {
            storeWhitelabelIntoStorage(null);
            this.triggerUserWhitelabelChange({
              type: 'whitelabel-change',
              payload: {
                logoLink: null,
                styles: null,
              },
            });
          }
        })
      );
  }

  // when application loads, or when admin-whitelabel try to reset whitelabel
  loadFromLocalStorageAndTriggerWhitelabelEvent() {
    const whitelabel = getWhiteLabelFromStorage();
    this.triggerUserWhitelabelChange({
      type: 'whitelabel-change',
      payload: {
        logoLink: whitelabel?.logoLink ?? null,
        styles: whitelabel?.styles ?? null,
      },
    });
  }

  // from admin-whitelabel page
  triggerApplyWhitelabelEvent(we: { logoLink: string, styles: WhitelabelStyles }) {
    this.triggerUserWhitelabelChange({
      type: 'apply-whitelabel',
      payload: we,
    });
  }

  private triggerUserWhitelabelChange(event: WhitelabelServiceEvent) {
    this.subject.next(event);
  }

  updateStyles(obj: WhitelabelStyles) {
    window.requestAnimationFrame(() => {
      for (const key in obj) {
        document.documentElement.style.setProperty(`--${key}`, `${(obj as any)[key]}`);
      }
      document.documentElement.style.setProperty(`--portal2-sg-dynamoney-blue`, `var(--grow-primary-palette-500) `);
    });
  }

  clearStyles() {
    window.requestAnimationFrame(() => {
      for (const key of ALL_WHITELABEL_CSS_VAR_NAMES) {
        document.documentElement.style.removeProperty(`--${key}`);
      }
    });
  }

}


