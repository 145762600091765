import {Component, inject, Input, OnInit} from "@angular/core";
import {
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  CurrencyInputValue,
  DscrIndustry,
  DscrIndustryEbitda,
  calcApplicationDscrCalculatorValue,
  Application,
  getPaymentPeriod,
} from "@portal-workspace/grow-shared-library";
import {UntilDestroy} from "@ngneat/until-destroy";
import {debounceTime, skip, Subscription, tap} from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {CurrencyInputComponent} from "../currency-selection-component/currency-input.component";
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout/flex";
import {LooseCurrencyPipe} from "../../pipes/loose-currency.pipe";
import {
  createThreeDecimalInputMask,
  formControlErrorKeys,
  formControlErrorMessage,
  setupUntilDestroy
} from "../component-utils";
import { PortalHotToastService } from "../portal-hot-toast-component/hot-toast.service";
import { DscrIndustrySelectionComponent } from "../dscr-industry-selection-component/dscr-industry-selection.component";
import { DscrIndustryEbitdaSelectionComponent } from "../dscr-industry-selection-component/dscr-industry-ebitda-selection.component";
import {InputMaskModule} from "@ngneat/input-mask";



@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'dscr-calculator',
    templateUrl: './dscr-calculator.component.html',
    styleUrls: ['./dscr-calculator.component.scss'],
    standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyInputComponent,
    MatCardModule,
    FlexModule,
    LooseCurrencyPipe,
    DscrIndustrySelectionComponent,
    DscrIndustryEbitdaSelectionComponent,
    InputMaskModule
],
})
export class DscrCalculatorComponent implements OnInit {

    createThreeDecimalInputMask = createThreeDecimalInputMask();
    errorKeys = formControlErrorKeys;
    errorMessage = formControlErrorMessage;

    @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
    @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;
    @Input({required: true}) application!: Application;

    subscriptions: Subscription[] = [];
    hotToastService!: PortalHotToastService;
    // paymentPeriod!: string

    formBuilder!: FormBuilder;
    formGroup!: FormGroup<{
      industry: FormControl<DscrIndustry | null>,
      industryEbitda: FormControl<DscrIndustryEbitda | null>,
      adjustmentPercent: FormControl<CurrencyInputValue>,
      monthlySalesAverage3Months: FormControl<CurrencyInputValue>,
      monthlySalesAverage6Months: FormControl<CurrencyInputValue>,
      monthlySalesAverage12Months: FormControl<CurrencyInputValue>,
      averageMonthlySalesAdopted: FormControl<CurrencyInputValue>,
      salesAdjustment: FormControl<CurrencyInputValue>,
      averageMonthlySalesAdoptedAfterAdjustment: FormControl<CurrencyInputValue>,
      existingDebtCommitmentsMonthly: FormControl<CurrencyInputValue>,
      atoPaymentPlanMonthly: FormControl<CurrencyInputValue>,
      additionalCommitments: FormControl<CurrencyInputValue>,
  }>;
    formControlCustomerName!: FormControl<string | null>; //application
    formControlLoanAmount!: FormControl<CurrencyInputValue>; //application
    formControlIndustry!: FormControl<DscrIndustry | null>;
    formControlIndustryEbitda!: FormControl<DscrIndustryEbitda | null>;
    formControlAdjustmentPercent!: FormControl<CurrencyInputValue>;
    formControlMarginAdoptedPrecent!: FormControl<CurrencyInputValue>; //calculated
    formControlMonthlySalesAverage3Months!: FormControl<CurrencyInputValue>;
    formControlMonthlySalesAverage6Months!: FormControl<CurrencyInputValue>;
    formControlMonthlySalesAverage12Months!: FormControl<CurrencyInputValue>;
    formControlAverageMonthlySalesAdopted!: FormControl<CurrencyInputValue>;
    formControlSalesAdjustment!: FormControl<CurrencyInputValue>;
    formControlAverageMonthlySalesAdoptedAfterAdjustment!: FormControl<CurrencyInputValue>;
    formControlCashAvailableForDebtServiceMonthly!: FormControl<CurrencyInputValue>; //calculated
    formControlExistingDebtCommitmentsMonthly!: FormControl<CurrencyInputValue>;
    formControlAtoPaymentPlanMonthly!: FormControl<CurrencyInputValue>;
    formControlAdditionalCommitments!: FormControl<CurrencyInputValue>;
    formControlProposedCommitmentsMonthly!: FormControl<CurrencyInputValue>; //application
    formControlTotalFinancingCommitmentsMonthly!: FormControl<CurrencyInputValue>; //calculated
    formControlDebtServiceCoverRatio!: FormControl<CurrencyInputValue>; //calculated
    formControlProposedLoanAmount!: FormControl<CurrencyInputValue>; //calculated


    constructor() {
      this.formBuilder = inject(FormBuilder);
      this.hotToastService = inject(PortalHotToastService);
      this.formControlCustomerName = this.formBuilder.control(null);
      this.formControlCustomerName.disable();
      this.formControlLoanAmount = this.formBuilder.control(null);
      this.formControlLoanAmount.disable();
      this.formControlIndustry = this.formBuilder.control(null);
      this.formControlIndustryEbitda = this.formBuilder.control(null);
      this.formControlAdjustmentPercent = this.formBuilder.control(null);
      this.formControlMarginAdoptedPrecent = this.formBuilder.control(null);
      this.formControlMarginAdoptedPrecent.disable();
      this.formControlMonthlySalesAverage3Months = this.formBuilder.control(null);
      this.formControlMonthlySalesAverage6Months = this.formBuilder.control(null);
      this.formControlMonthlySalesAverage12Months = this.formBuilder.control(null);
      this.formControlAverageMonthlySalesAdopted = this.formBuilder.control(null);
      this.formControlSalesAdjustment = this.formBuilder.control(null);
      this.formControlAverageMonthlySalesAdoptedAfterAdjustment = this.formBuilder.control(null);
      this.formControlCashAvailableForDebtServiceMonthly = this.formBuilder.control(null);
      this.formControlCashAvailableForDebtServiceMonthly.disable();
      this.formControlExistingDebtCommitmentsMonthly = this.formBuilder.control(null);
      this.formControlAtoPaymentPlanMonthly = this.formBuilder.control(null);
      this.formControlAdditionalCommitments = this.formBuilder.control(null);
      this.formControlProposedCommitmentsMonthly = this.formBuilder.control(null, [Validators.required]);
      this.formControlProposedCommitmentsMonthly.disable();
      this.formControlTotalFinancingCommitmentsMonthly = this.formBuilder.control(null);
      this.formControlTotalFinancingCommitmentsMonthly.disable();
      this.formControlDebtServiceCoverRatio = this.formBuilder.control(null);
      this.formControlDebtServiceCoverRatio.disable();
      this.formControlProposedLoanAmount = this.formBuilder.control(null);
      this.formControlProposedLoanAmount.disable();

      this.formGroup = this.formBuilder.group({
        industry: this.formControlIndustry,
        industryEbitda: this.formControlIndustryEbitda,
        adjustmentPercent: this.formControlAdjustmentPercent,
        monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months,
        monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months,
        monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months,
        averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted,
        salesAdjustment: this.formControlSalesAdjustment,
        averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment,
        existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly,
        atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly,
        additionalCommitments: this.formControlAdditionalCommitments,
    });
    }

    ngOnInit(): void {
      setupUntilDestroy(this);
      // this.paymentPeriod = getPaymentPeriod(this.application)
      this.subscriptions.push(this.getDscrCalculatorValueFn(this.application.ApplicationId).pipe(
      this.hotToastService.spinnerObservable(),
          tap(r => {
            if(r){
            this.formControlCustomerName.setValue(r.customerName);
            this.formControlLoanAmount.setValue(r.loanAmount);
            this.formControlIndustry.setValue(r.industry);
            this.formControlIndustryEbitda.setValue(r.industryEbitda);
            this.formControlAdjustmentPercent.setValue(r.adjustmentPercent);
            this.formControlMarginAdoptedPrecent.setValue(r.marginAdoptedPercent);
            this.formControlMonthlySalesAverage3Months.setValue(r.monthlySalesAverage3Months);
            this.formControlMonthlySalesAverage6Months.setValue(r.monthlySalesAverage6Months);
            this.formControlMonthlySalesAverage12Months.setValue(r.monthlySalesAverage12Months);
            this.formControlAverageMonthlySalesAdopted.setValue(r.averageMonthlySalesAdopted);
            this.formControlSalesAdjustment.setValue(r.salesAdjustment);
            this.formControlAverageMonthlySalesAdoptedAfterAdjustment.setValue(r.averageMonthlySalesAdoptedAfterAdjustment);
            this.formControlCashAvailableForDebtServiceMonthly.setValue(r.cashAvailableForDebtServiceMonthly);
            this.formControlExistingDebtCommitmentsMonthly.setValue(r.existingDebtCommitmentsMonthly);
            this.formControlAtoPaymentPlanMonthly.setValue(r.atoPaymentPlanMonthly);
            this.formControlAdditionalCommitments.setValue(r.additionalCommitments);
            this.formControlProposedCommitmentsMonthly.setValue(r.proposedCommitmentsMonthly);
            this.formControlTotalFinancingCommitmentsMonthly.setValue(r.totalFinancingCommitmentsMonthly),
            this.formControlDebtServiceCoverRatio.setValue(r.debtServiceCoverRatio);
            this.formControlProposedLoanAmount.setValue(r.proposedLoanAmount);
            }

            this.subscriptions.push(this.formGroup.valueChanges.pipe(
              tap(r => {
                // console.log('*** form group value changes', this.formGroup, this.formGroup.valid);
                if (this.formGroup.valid) {
                  this.calc();
                }
              }),
              debounceTime(2000),
              skip(1), // skip the first trigger caused by ngInit() populating formControls
              tap(r => {
                if (this.formGroup.valid) {
                  this.updateDscrCalculatorValueFn(this.application.ApplicationId, {
                    customerName: this.formControlCustomerName.value ?? '',
                    loanAmount: this.formControlLoanAmount.value ?? 0, // this is application's Loan Amount
                    industry: this.formControlIndustry.value!,
                    industryEbitda: this.formControlIndustryEbitda.value!,
                    adjustmentPercent: this.formControlAdjustmentPercent.value ?? 0,
                    monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months.value ?? 0,
                    monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months.value ?? 0,
                    monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months.value ?? 0,
                    averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted.value ?? 0,
                    salesAdjustment: this.formControlSalesAdjustment.value ?? 0,
                    averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment.value ?? 0,
                    existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly.value ?? 0,
                    atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly.value ?? 0,
                    additionalCommitments: this.formControlAdditionalCommitments.value ?? 0,
                    proposedCommitmentsMonthly: this.formControlProposedCommitmentsMonthly.value ?? 0,
                  }).pipe(
                    this.hotToastService.snackBarObservable(`Auto saved!`),
                  ).subscribe();
                }
              })
            ).subscribe());
          }),
      ).subscribe());
  }

   // calculate the computed values based on manual inputs
   calc() {
    const {
      marginAdoptedPercent,
      cashAvailableForDebtServiceMonthly,
      proposedCommitmentsMonthly,
      totalFinancingCommitmentsMonthly,
      debtServiceCoverRatio,
      proposedLoanAmount
    } = calcApplicationDscrCalculatorValue({
      customerName: this.formControlCustomerName.value ?? '',
      loanAmount: this.formControlLoanAmount.value ?? 0, // this is application's Loan Amount
      industry: this.formControlIndustry.value ?? null,
      industryEbitda: this.formControlIndustryEbitda.value ?? null,
      adjustmentPercent: this.formControlAdjustmentPercent.value ?? 0,
      monthlySalesAverage3Months: this.formControlMonthlySalesAverage3Months.value ?? 0,
      monthlySalesAverage6Months: this.formControlMonthlySalesAverage6Months.value ?? 0,
      monthlySalesAverage12Months: this.formControlMonthlySalesAverage12Months.value ?? 0,
      averageMonthlySalesAdopted: this.formControlAverageMonthlySalesAdopted.value ?? 0,
      salesAdjustment: this.formControlSalesAdjustment.value ?? 0,
      averageMonthlySalesAdoptedAfterAdjustment: this.formControlAverageMonthlySalesAdoptedAfterAdjustment.value ?? 0,
      existingDebtCommitmentsMonthly: this.formControlExistingDebtCommitmentsMonthly.value ?? 0,
      atoPaymentPlanMonthly: this.formControlAtoPaymentPlanMonthly.value ?? 0,
      additionalCommitments: this.formControlAdditionalCommitments.value ?? 0,
      proposedCommitmentsMonthly: this.formControlProposedCommitmentsMonthly.value ?? 0,
    });

    const opt = {onlySelf: true, emitEvent: false, emitViewToModelChange: false }

    this.formControlMarginAdoptedPrecent.setValue(marginAdoptedPercent, opt);
    this.formControlCashAvailableForDebtServiceMonthly.setValue(cashAvailableForDebtServiceMonthly, opt);
    this.formControlProposedCommitmentsMonthly.setValue(proposedCommitmentsMonthly, opt);
    this.formControlTotalFinancingCommitmentsMonthly.setValue(totalFinancingCommitmentsMonthly, opt),
    this.formControlDebtServiceCoverRatio.setValue(debtServiceCoverRatio, opt);
    this.formControlProposedLoanAmount.setValue(proposedLoanAmount, opt);
  }

}
