<div class="accreditation-details-page container-fluid">
  @if (!accreditation) {
    <div class="row">
      <div class="col-6">
        <message-box type="danger" title="Not found">
          Unable to location application
        </message-box>
      </div>
    </div>
  }

  @if (accreditation) {
    <accreditation-details [accreditation]="accreditation"
      [onBreadcrumbEventsFn]="onBreadcrumbEventFn"
      [approveAccreditationDocumentFn]="approveAccreditationDocumentFn"
      [declineAccreditationDocumentFn]="declineAccreditationDocumentFn"
      [deleteAccreditationDocumentFn]="deleteAccreditationDocumentFn"
      [uploadAccreditationDocumentFn]="uploadAccreditationDocumentFn"
      [listAccreditationDocumentFn]="listAccreditationDocumentFn"
      [downloadAccreditationDocumentUrlFn]="downloadAccreditationDocumentUrlFn"
      [updateAccreditationDocumentTagsFn]="updateAccreditationDocumentTagsFn"
      [downloadAllAccreditationDocumentUrlFn]="downloadAllAccreditationDocumentUrlFn"
    ></accreditation-details>
  }
</div>
