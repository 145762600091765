import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  Application,
  DocumentNotificationDialogData,
  DocumentNotificationDialogResult, getNotificationDocuments,
  GetUserFn,
  GroupedDocument,
  Metadata, NotificationGroupedDocuments, UserandPriviledges
} from '@portal-workspace/grow-shared-library';
import {Subscription} from 'rxjs';
import {setupUntilDestroy, validateEmail} from '@portal-workspace/grow-ui-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomAngularEditorComponent } from '../custom-angular-editor-component/custom-angular-editor.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatDividerModule } from '@angular/material/divider';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
    templateUrl: './document-notification.dialog.html',
    styleUrls: ['./document-notification.dialog.scss'],
    standalone: true,
    imports: [MatDividerModule, FlexModule, MatFormFieldModule, MatChipsModule, FormsModule, ReactiveFormsModule, CustomAngularEditorComponent, MatCheckboxModule, MatButtonModule, MatDialogModule]
})
export class DocumentNotificationDialog implements OnInit{

  formControlAdditionalInformation!: FormControl<string | null>;
  formControlRemoveFromWorklist!: FormControl<boolean | null>;
  formGroup!: FormGroup;
  docs: GroupedDocument[] = [];
  pendingDocs: NotificationGroupedDocuments[] = [];
  inProgressDocs: NotificationGroupedDocuments[] = [];
  rejectedDocs: NotificationGroupedDocuments[] = [];
  acceptedDocs: NotificationGroupedDocuments[] = [];
  emailList: string[] = [];
  formControlEmail!: FormControl<string | null>;
  emailError = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  application!: Application;

  subscriptions: Subscription[] = [];
  placeholder = 'Extra additional info...';
  getUserFn!: GetUserFn;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DocumentNotificationDialogData,
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<DocumentNotificationDialog, DocumentNotificationDialogResult>
  ) {
    this.formControlAdditionalInformation = this.fb.control('');
    this.formControlRemoveFromWorklist = this.fb.control(false);
    this.formControlEmail = this.fb.control(null);
    this.formGroup = this.fb.group({
      additionalInformation: this.formControlAdditionalInformation,
      removeFromWorklist: this.formControlRemoveFromWorklist,
    });
    this.getUserFn = data.getUserFn;
  }

  ngOnInit() {
    setupUntilDestroy(this);

    // docs
    this.docs = this.data.docs.filter(g => g.required);
    const notificationDocuments = getNotificationDocuments(this.docs);
    this.pendingDocs = notificationDocuments.pendingDocs;
    this.rejectedDocs = notificationDocuments.rejectedDocs;
    this.inProgressDocs = notificationDocuments.inProgressDocs;
    this.acceptedDocs = notificationDocuments.acceptedDocs;

    // email list
    this.application = this.data.application;

    this.subscriptions.push(
      this.getUserFn(this.application.UserId,false).subscribe(
        (user: UserandPriviledges | null) => {
          if (user) {
            this.emailList.push(user.Email);
          }
        }
      )
    )

    this.subscriptions.push(
      this.formControlEmail.valueChanges.subscribe((value: string | null) => {
        if (!value) {
          this.emailError = false;
        }
      })
    )
  }

  onCancel() {
    this.matDialogRef.close({
      readyForSubmission: false,
      additionalInformation: this.formControlAdditionalInformation.value ?? '',
      removeFromList: this.formControlRemoveFromWorklist.value ?? false,
      emailList: this.emailList,
    })
  }

  onSubmit() {
    this.matDialogRef.close({
      readyForSubmission: true,
      additionalInformation: this.formControlAdditionalInformation.value ?? '',
      removeFromList: this.formControlRemoveFromWorklist.value ?? false,
      emailList: this.emailList,
    })
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (validateEmail(value)) {
      // Add chip
      if (value) {
        this.emailList.push(value);
      }
      // Clear the input value
      event.chipInput!.clear();
      this.emailError = false;
    } else if (value) {
      this.emailError = true;
    }
  }

  remove(email: string): void {
    const index = this.emailList.indexOf(email);

    if (index >= 0) {
      this.emailList.splice(index, 1);
    }
  }
}
