import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PaginatorData} from '@portal-workspace/grow-shared-library';
import { MatOptionModule } from '@angular/material/core';
import { NgClass } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {FlexModule} from "@angular/flex-layout";
import {pxToRem} from "../../component-utils";


@Component({
    selector: 'app-custom-paginator',
    templateUrl: './custom-paginator.component.html',
    styleUrls: ['./custom-paginator.component.scss'],
    standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgClass, FlexModule]
})
export class CustomPaginatorComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input({required: false}) length = 0;
  @Input({required: false}) pageIndex = 0;
  @Input({required: false}) pageSize = 0;
  @Input({required: false}) pageSizeOptions: number[] = [];
  @Output() page = new EventEmitter<PaginatorData>();

  displayedPages = 4;
  Math = Math;
  pageNumbers: (number | '...')[] = [];

  pxToRem = pxToRem;

  form!: FormGroup<{
    pageSize: FormControl<number | null>
  }>;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.fb.group({
      pageSize: [this.pageSize ? this.pageSize : (this.pageSizeOptions.length ? this.pageSizeOptions[0] : 10)]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePageNumbers();
  }

  ngOnInit(): void {
    (this.form.controls as any).pageSize.value = this.pageSize;
    this.updatePageNumbers();

    this.form.valueChanges.subscribe(changes => {
      this.onChangePageSize();
    })
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get pageSizeControlValue() {
    return (this.form.controls as any).pageSize.value;
  }

  get maxPageIndex() {
    return Math.ceil(this.length / this.pageSizeControlValue);
  }

  updatePageNumbers() {
    const maxPageIndex = this.maxPageIndex;
    const currentPageIndex = this.pageIndex + 1;
    if (maxPageIndex - currentPageIndex < this.displayedPages) {
      const results = []
      for (let i = maxPageIndex - 4; i <= maxPageIndex; i++) {
        if (i > 0) {
          results.push(i);
        }
      }
      this.pageNumbers = results;
    } else {
      this.pageNumbers = [currentPageIndex, currentPageIndex + 1, currentPageIndex + 2, currentPageIndex + 3, '...', maxPageIndex];
    }
  }

  updatePageNumbersAndEmit() {
    this.updatePageNumbers();
    this.page.emit({
      length: this.length,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      previousPageIndex: this.pageIndex ? this.pageIndex - 1 : 0
    } as PaginatorData)
  }

  onChangePageSize() {
    this.pageSize = this.pageSizeControlValue;
    if (this.pageIndex * this.pageSize + 1 > this.length) {
      this.pageIndex = this.maxPageIndex - 1;
    }
    this.updatePageNumbersAndEmit();
  }

  onClickNextPage() {
    const maxPageIndex = this.maxPageIndex;
    const currentPageIndex = this.pageIndex + 1;
    if (currentPageIndex < maxPageIndex ) {
      this.pageIndex += 1;
      this.updatePageNumbersAndEmit();
    }
  }

  onClickPreviousPage() {
    if (this.pageIndex > 0 ) {
      this.pageIndex -= 1;
      this.updatePageNumbersAndEmit();
    }
  }

  onClickPage(pageNumber: number | '...') {
    if (pageNumber !== '...') {
      this.pageIndex = pageNumber - 1;
      this.page.emit({
        length: this.length,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        previousPageIndex: this.pageIndex ? this.pageIndex - 1 : 0
      } as PaginatorData);
    } else {
      this.pageIndex = (this.pageNumbers[3] as number);
      this.updatePageNumbersAndEmit();
    }
  }
}
