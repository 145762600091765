import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Application,
  AzureStorageDocument,
  Metadata,
  defaultDocuments,
  unclassifiedDocuments,
  User, ModifyFileTagsDialogResult, ConfirmationDialogResult, accreditationDocumentTags, DEFAULT_LIMIT, DEFAULT_OFFSET,
} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn, DownloadAccreditationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  getUser,
  openWindowAndDownloadWithFilename,
  PortalHotToastService,
  UpdateApplicationDocumentTagsFn,
  UploadAccreditationDocumentFn
} from '@portal-workspace/grow-ui-library';
import {isInternalUser} from '@portal-workspace/grow-shared-library';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexModule } from '@angular/flex-layout/flex';
import { TagBoxComponent } from '../message-box/tag-box.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'accreditation-documents-sub-table',
    templateUrl: './accreditation-documents-sub-table.component.html',
    styleUrls: ['./accreditation-documents-sub-table.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, ExtendedModule, TagBoxComponent, FlexModule, MatTooltipModule, DatePipe]
})
export class AccreditationDocumentsSubTableComponent implements OnInit {
  @Input({required: true}) approveAccreditationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineAccreditationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteAccreditationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) uploadAccreditationDocumentFn!: UploadAccreditationDocumentFn;
  @Input({required: true}) downloadAccreditationDocumentUrlFn!: DownloadAccreditationDocumentUrlFn;
  @Input({required: true}) updateAccreditationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;

  subscriptions: Subscription[] = [];

  total = 0;
  limit = DEFAULT_LIMIT;
  offset = DEFAULT_OFFSET;

  @Input({required: false}) data: AzureStorageDocument[] = [];
  @Input({required: true}) tagName!: string; // driversLicense ...
  // @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  // @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  // @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  // @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  // @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Output() reload = new EventEmitter();
  isInternalUser = isInternalUser;
  unclassifiedDocuments = unclassifiedDocuments;

  displayedData: AzureStorageDocument[] = [];
  columnsToDisplay: string[] = ['icon', 'name', 'status', 'action'];
  user: User | null = null;
  constructor(
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService
  ) { }

  ngOnInit(): void {
    this.user = getUser();
    this.displayedData = this.data;
  }

  updateDisplayedData() {
    this.displayedData = this.data.slice(this.offset * this.limit, (this.offset + 1) * this.limit);
  }

  onPagination($event: PageEvent) {
    this.offset = $event.pageIndex;
    this.limit = $event.pageSize;
    this.updateDisplayedData();
  }

  getColumnTitles(column: string): string {
    switch (column) {
      case 'name': return 'Document Uploaded';
      case 'action': return 'Actions';
      case 'status': return 'Status';
      default: return '';
    }
  }

  onDownloadDoc(doc: AzureStorageDocument) {
    this.downloadAccreditationDocumentUrlFn(doc.name, "").pipe(
      this.toastService.loadingWithMessage('Downloading...'),
      tap(blob => {
        openWindowAndDownloadWithFilename((doc.metadata as Metadata)?.name ?? doc.name, blob)
      })
    ).subscribe()
  }

  onApproveDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.approveAccreditationDocumentFn(doc.name, "", this.tagName, this.user?.UserId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Document Approved',
          errorMessage: 'Failed to approve the document',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onApproveDoc(doc);
          }
        }),
        tap(r => {
          this.reload.emit();
        })
      ).subscribe()
    )
  }

  onDeleteDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.applicationDialogService.openConfirmationDialog({
        message: 'Please confirm',
        subMessage: 'Are you sure you want to delete ' + ((doc?.metadata as Metadata)['name'] ?? 'this file') + '?'
      }).afterClosed().pipe(
        tap(async (r: ConfirmationDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            this.deleteAccreditationDocumentFn(doc.name, "", this.tagName).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Delete',
                errorMessage: 'Failed to delete the document',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onDeleteDoc(doc);
                }
              }),
              tap(r => {
                this.reload.emit();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }

  onDeclineDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.declineAccreditationDocumentFn(doc.name, "", this.tagName, this.user?.UserId).pipe(
        this.toastService.retryableMessage({
          successMessage: 'Documents Declined',
          errorMessage: 'Failed to decline the documents',
          retryFn: ()=> {
            console.log('**** retry ', this);
            this.onDeclineDoc(doc);
          }
        }),
        tap(r => {
          this.reload.emit();
        })
      ).subscribe()
    )
  }

  onEditDoc(doc: AzureStorageDocument) {
    this.subscriptions.push(
      this.applicationDialogService.openModifyFileTagsDialog({
        fileName: (doc?.metadata as Metadata)['name'],
        selectedTags: Object.values(doc?.tags ?? {}),
        allTags: accreditationDocumentTags()
      }).afterClosed().pipe(
        tap((r: ModifyFileTagsDialogResult | undefined) => {
          if (r && r.readyForSubmission) {
            this.updateAccreditationDocumentTagsFn(doc.name, '', r.tags).pipe(
              this.toastService.retryableMessage({
                successMessage: 'Document Tags Updated',
                errorMessage: 'Failed to update the tags',
                retryFn: ()=> {
                  console.log('**** retry ', this);
                  this.onEditDoc(doc);
                }
              }),
              tap(r => {
                this.reload.emit();
              })
            ).subscribe()
          }
        })
      ).subscribe()
    );
  }
}
