import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
  Address2,
  Application,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
} from '@portal-workspace/grow-shared-library';
import {
  setupUntilDestroy,
} from '@portal-workspace/grow-ui-library';
import { DscrCalculatorComponent } from '../dscr-calculator.component';

import {
  isInternalUser,
} from '@portal-workspace/grow-shared-library';
import { UntypedFormBuilder} from '@angular/forms';
 
import { Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PortalHotToastService} from '../../portal-hot-toast-component/hot-toast.service';
import {ApplicationDialogService} from '../../application-dialog-component/application-dialog.service';
import { MatExpansionModule } from "@angular/material/expansion";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    selector: 'asset-credit',
    templateUrl: './asset-credit.component.html',
    styleUrls: ['./asset-credit.component.scss'],
    standalone: true,
    imports: [DscrCalculatorComponent,MatExpansionModule]
})

export class AssetCreditComponent implements OnInit {
 
  subscriptions: Subscription[] = [];
  expandedIndex = 0;
  
  @Input() application!: Application;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;

  isInternalUser = isInternalUser;

  constructor(private formBuilder: UntypedFormBuilder,
              private toastService: PortalHotToastService,
              private dialogService: ApplicationDialogService,
              ) {
  
  }
  
  isPanelExpanded(index: number): boolean {
    return this.expandedIndex === index;
  }
  expandPanel(index: number) {
    this.expandedIndex = index;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);
  }

  
}
