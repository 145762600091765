<div class="asset-pricing-component container-fluid">
  <ng-container *ngTemplateOutlet="currentTemplate">
  </ng-container>
</div>

<ng-template #readMode>
  <div class="readMode">
    <mat-card appearance="outlined">
      <mat-card-content>
        @if (isApplicationStagePostQa || isContractPending) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box type="info"
                title="Unable to edit">
                Can only edit pricing for application that is in stages before QA and
                do not have active or pending contracts generated.
              </message-box>
            </div>
          </div>
        }

        @if (!isInternalUser) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Not available for external brokers
              </message-box>
            </div>
          </div>
        }
        @if (!isAdminOrCreditUserOrSalesAMOrSalesBDM) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Only Admin, Credit, SalesAM or SalesBDM Internal Users can edit this.
              </message-box>
            </div>
          </div>
        }

        @if (lastEditedByUser) {
          <div class="row separator-bottom">
            <div class="col-lg-6 col-md-12">
              <message-box title="Info" type="info">
                Last edited by: {{lastEditedByUser ? lastEditedByUser : 'N/A'}}
              </message-box>
            </div>
          </div>
        }

        @if (isInternalUser) {
          <div class="row">
            <div class="col-12">
              <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let data">
                    <span [ngClass]="{highlight: (data.highlight && data.highlight(data.value))}">
                      {{data.name}}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef>Value</th>
                  <td mat-cell *matCellDef="let data">
                    @if (data.type == 'text') {
                      <span [ngClass]="{highlight: (data.highlight && data.highlight(data.value))}">{{data.value ?? ''}}</span>
                    }
                    @if (data.type == 'currency') {
                      <span [ngClass]="{highlight: (data.highlight && data.highlight(data.value))}">{{data.value | looseCurrency}}</span>
                    }
                    @if (data.type == 'percentage') {
                      <span [ngClass]="{highlight: (data.highlight && data.highlight(data.value))}">{{(data.value ?? '') | percentage:'1.0-2' }}</span>
                    }
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
              </table>
            </div>
          </div>
        }
      </mat-card-content>
      <mat-card-actions>
        @if (isInternalUser) {
          <div
            class="w-100"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="5px"
            fxLayout.lt-md="column"
            fxLayoutAlign.lt-md="center start">
            <div></div>
            <button mat-flat-button
              [disabled]="!canEditPricing()"
              class="w-lt-md-100"
              color="primary"
              (click)="switchMode()">
              Edit
            </button>
          </div>
        }
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>

<ng-template #editMode>
  <div class="editMode">
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <div class="mat-h3">
              Asset finance amount
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <currency-input
              [title]="formControlStep2FinanceType.value?.type === 'rental' ? 'Invoice amount (ex GST)' : 'Invoice amount (incl GST)'"
              [mark]="markSubject"
              [min]="step2InvoiceAmountMin"
              [max]="step2InvoiceAmountMax"
              [readonly]="true"
              [formControl]="formControlStep2InvoiceAmount">
            </currency-input>
          </div>
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Deposit amount (incl GST)"
              [mark]="markSubject"
              [min]="step2DepositMin"
              [max]="step2DepositMax"
              [readonly]="true"
              [formControl]="formControlStep2DepositAmount">
            </currency-input>
          </div>
          <div class="col-lg-3 col-md-12">
            <button mat-icon-button (click)="onEditInvoiceAmount()">
              <span class="mdi mdi-pencil-outline"></span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Loan amount"
              [mark]="markSubject"
              [required]="false"
              [readonly]="true"
              [formControl]="formControlStep2LoanAmount">
            </currency-input>
            </div>
        </div>

        <div class="row separator-bottom">
          <div class="col-12">
            <div class="mat-h3">Asset details</div>
            <div class="mat-body">Please provide details on the asset you would like to finance.</div>
          </div>
        </div>
        <div class="row separator-bottom">
          <div class="col-12">
            <asset-selection
              [mark]="markSubject"
              [categoryIndexFilter]="step2AssetCategoryIndexFilter"
              [searchFn]="assetSearchFn"
              [formControl]="formControlStep2Asset"
              (events)="onAssetSelectionEvent($event)"
              >
            </asset-selection>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <asset-condition
              [mark]="markSubject"
              [formControl]="formControlStep2AssetCondition">
            </asset-condition>
          </div>
          <div class="col-lg-3 col-md-12">
            <transaction-type-selection
              [mark]="markSubject"
              [formControl]="formControlStep2TransactionType">
            </transaction-type-selection>
          </div>
        </div>

        <div class="row separator-bottom">
          <div class="col-12">
            <div class="mat-h3">Finance details</div>
            <div class="mat-body separator-bottom">Let us know your preferred repayment terms</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <loan-terms-selection-with-input
              title="Loan term (in months)"
              [mark]="markSubject"
              [min]="1"
              [max]="84"
              [formControl]="formControlStep2LoanTerms"
            />
          </div>
          <div class="col-lg-3 col-md-12">
            <payment-frequency-selection
              [mark]="markSubject"
              [formControl]="formControlStep2PaymentFrequency">
            </payment-frequency-selection>
          </div>
        </div>


        <div class="row mt-3">
          <div class="col-lg-3 col-md-12">
            <finance-type-selection
              [mark]="markSubject"
              [formControl]="formControlStep2FinanceType"
              [optionsFilter]="ASSET_FINANCE_OPTIONS_FILTER">
            </finance-type-selection>
          </div>
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Doc Fee financed"
              [mark]="markSubject"
              [formControl]="formControlStep2DocFeeFinanced">
            </yes-no>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Balloon payment (Residual)"
              [mark]="markSubject"
              [required]="false"
              [min]="0"
              [max]="step2MaxBalloon"
              [formControl]="formControlStep2BalloonPayment">
            </currency-input>
          </div>
        </div>


        <div class="row separator-bottom">
          <div class="col-12">
            <div class="mat-h3">Brokerage details</div>
            <div class="mat-body separator-bottom">Enter your origination fee (dollar amount up to $900 ex GST) and brokerage of amount financed (up to 8%)</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Broker origination fee (ex GST)"
              [mark]="markSubject"
              [min]="step2MinBrokerOriginationFee"
              [max]="step2MaxBrokerOriginationFee"
              [formControl]="formControlStep2BrokerOriginationFee">
            </currency-input>
          </div>
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Brokerage amount"
              [mark]="markSubject"
              [min]="step2MinBrokerageAmount"
              [max]="step2MaxBrokerageAmount"
              [readonly]="true"
              [formControl]="formControlStep2BrokerageAmount">
            </currency-input>
          </div>
          <div class="col-lg-3 col-md-12">
            <span *ngIf="matchBrokerageAmount">( = {{brokeragePercentage| percentage}}) </span>
            <span *ngIf="!matchBrokerageAmount">( ~ {{brokeragePercentage | percentage}}) </span>
            <button mat-icon-button (click)="onEditBrokerage()">
              <span class="mdi mdi-pencil-outline"></span>
            </button>
          </div>
        </div>


        <div class="row separator-bottom">
          <div class="col-12">
            <div class="mat-h3">Risk profile</div>
            <div class="mat-body separator-bottom">Please provide us with further details so we can provide you with a more accurate quote</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Property owner"
              [mark]="markSubject"
              [formControl]="formControlStep2PropertyOwner">
            </yes-no>
          </div>
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Adverse on file"
              [mark]="markSubject"
              [formControl]="formControlStep2AdverseOnFile">
            </yes-no>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Director Equifax score > 500"
              [mark]="markSubject"
              [formControl]="formControlStep2EquifaxScoreAboveThreshold">
            </yes-no>
          </div>
          @if (!formControlStep2PropertyOwner.value) {
            <div class="col-lg-3 col-md-12">
              <yes-no
                title="Has the business taken another commercial loan in the past 3 years?"
                [mark]="markSubject"
                [formControl]="formControlStep2PreviousLoan"
                [notselected]="true">
              </yes-no>
            </div>
          }
        </div>

        <!-- <div class="row separator-bottom">
          <div class="col-12">
            <div class="mat-h3">Asset finance amount</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Deposit amount (incl GST)"
              [mark]="markSubject"
              [min]="step2DepositMin"
              [max]="step2DepositMax"
              [formControl]="formControlStep2DepositAmount">
            </currency-input>
          </div>
          <div class="col-lg-3 col-md-12">
            <currency-input
              title="Loan amount"
              [mark]="markSubject"
              [required]="false"
              [readonly]="true"
              [formControl]="formControlStep2LoanAmount">
            </currency-input>
          </div>
        </div> -->

        <div class="adjustment-section">
          <div class="row separator-bottom">
            <div class="col-12">
              <div class="mat-h3">Adjustment</div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <yes-no
                title="ABN age > 24 months"
                [mark]="markSubject"
                [formControl]="formControlAbnAgeAboveThreshold"
                [disableControl]="isNotAdminOrCreditUser"></yes-no>
            </div>
            <div class="col-lg-3 col-md-12">
              <percentage-input
                title="Credit Rate adjustment"
                [mark]="markSubject"
                [min]="MIN_CREDIT_RATE"
                [max]="MAX_CREDIT_RATE"
                [formControl]="formControlCreditRateAdjustment">
              </percentage-input>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="w-100 mt-4 mx-2"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="5px"
          fxLayout.lt-md="column"
          fxLayoutAlign.lt-md="center start">
          <button  mat-stroked-button color="primary"
            [disabled]="!assetSelectionReady"
            (click)="switchMode()">
            Cancel
          </button>
          <button mat-flat-button
            color="primary"
            class="w-lt-md-100"
            [disabled]="!canGenerateQuote()"
            (click)="onGenerateQuote()">
            Generate Quote
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>
