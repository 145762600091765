import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  applicationStageAllowGenerateContract,
  BusinessLoansApplication,
  ContractDetails,
  CreateApplicationNoteFn,
  CreateNewDisbursementFn,
  DeclineContractFn,
  DeleteContractFileFn,
  GenerateContractFn,
  GetActiveContractForApplicationFn,
  getBrokerageAmount,
  GetContractStatusFn,
  GetDisbursementByApplicationIdFn,
  SendContractEmailsFn,
  SyncBankDetailsToSfFn,
  SyncContractDetailsToSfFn,
  SyncDisbursementToSfFn,
  UpdateDisbursementFn,
  RemoveApplicationNoteByNoteIdFn,
  User,
  getPaymentType,
  GetBillerNameFn,
  ValidateBPAYFn,
  UpdateApplicationStageInSfFn,
  GetApplicationByIdFn,
  AzureStorageDocument,
  Application,
  GroupedDocument,
  ApiResponse,
  formGroupedDocumentData,
  unclassifiedDocuments,
  applicationStageAllowSettleLoan,
  Disbursement,
  validateDisbursementData,
  SettleLoanFn,
  GetInstitutionNameFn,
  GetBankDetailsFromOpportunitySfFn,
  GenerateApprovalNoticeFn,
  SendApprovalNoticeEmailFn,
  DeleteApprovalNoticeFileFn,
  CopyApplicationFn,
  GetApplicationOwnerFn,
  GetGeoLocationFn,
  RefreshBankStatementFn,
  GetUserFn,
  GetAccountDetailsFromSfFn,
  GetLvrCalculatorValueFn,
  UpdateLvrCalculatorValueFn,
  isAdminOrCreditUserOrSalesAM,
  UpdateApplicationFn,
  GetDscrCalculatorValueFn,
  UpdateDscrCalculatorValueFn,
  groupStandardCondition,
  constants,
  getCreditRateAdjustment,
  UpdateApplicationSfFn,
  TotalPaymentBreakupDialogData,
  calculateBusinessLoanEstimation,
  PaymentFrequencyType,
  LoanTermType,
  BrokerageSelectionType,
  BusinessSearchValue,
  RatecardDetails,
  SearchCompanyByABNResult,
  LoanTermValue,
  BusinessSearchResultValue,
  isAdminOrCreditUser,
  DownloadDocumentFromAzureFn,
  GetBankStatementsAnalysisFn,
  SendIdVerifyLinkFn,
  BypassFaceCompareFn,
  DeleteIdentityVerificationFn,
  SendPrivacyConsentEmailFn,
  AssetSettlementGetApplicationAssetFn,
  GetBsaLenderListFn,
  GetBsaExcludedLenderListFn,
  SaveBsaCalculatorFn,
  GetBsaCalculatorFn,
  GetDscrCalculatorHistoryFn,
  GetBasiqCustomerMappingFn,
  GetBasiqStatementDataForCompanyFn,
  RefreshBasiqConnectionsFn,
  GetBankStatementAndBasiqDataStatusFn,
  GetOriginatorBusinessByIdFn,
  AddAuditLogFn,
  getRepaymentType,
} from '@portal-workspace/grow-shared-library';
import { BreadcrumbComponentEvent, BreadcrumbComponent } from '../../breadcrumb-component/breadcrumb.component';
import {RepaymentEstimationEntry} from '@portal-workspace/grow-shared-library';
import {AmortisationScheduleEntry} from '@portal-workspace/grow-shared-library';
import {PaymentChartData} from '@portal-workspace/grow-shared-library';
import {AmortizationChartData} from '@portal-workspace/grow-shared-library';
import {
  ApplicationDialogService,
  ApproveApplicationDocumentFn,
  BusinessSearchFn,
  CompleteDocumentWorklistFn,
  CreateDocumentWorklistFn,
  DeclineApplicationDocumentFn,
  DeleteApplicationDocumentFn,
  DigitalIdAddIndividualFn,
  DigitalIdAuthenticateFn,
  DigitalIdGetClientIdFn,
  DigitalIdPrintDigitalIdResultFn,
  DigitalIdSendAskForVerificationInfoEmailFn,
  DigitalIdSetupApplicationIndividualMappingFn,
  DigitalIdUpdateApplicationIndividualDigitalIdMappingFn,
  DigitalIdUpdateApplicationIndividualInfoFn,
  DigitalIdUpdateIndividualFn,
  DigitalIdVerifyApplicationIndividualsFn,
  DigitalIdVerifyOneApplicationIndividualFn,
  DownloadAllApplicationDocumentUrlFn,
  DownloadApplicationDocumentUrlFn,
  GetOriginatorByIdFn,
  getUser,
  ListApplicationDocumentFn,
  PortalHotToastService,
  SendApplicationDocumentNotificationFn,
  UpdateApplicationDocumentTagsFn,
  UpdateApplicationRequiredDocumentsFn,
  GetNotesByApplicationIdFn,
  AssetSettlementSendAssetToSfFn,
  SearchSupplierFn,
  GetApplicationAuditLogsFn,
  BusinessNumberSearchFn,
  checkContractIndividuals,
  applicationDefaultDocuments,
  UndoApplicationDocumentFn,
  AssetSettlementSearchGrantorsFn,
  AssetSettlementGetPpsrDetailsFn,
  applicationToPaymentFrequency,
  applicationToLoanTerms,
  applicationToBrokerage,
  applicationToBusinessSearchValue,
  applicationToLoanAmount,
  applicationToPropertyOwner,
  applicationToBrokerageAmount,
  applicationToAdverseOnFile,
  applicationToEquifaxScoreAboveThreshold,
  applicationToDirectorScore, applicationToDocFeeFinanced, applicationToDocFee, AssetDocumentsComponent,
  DscrCalculatorComponent,
} from '@portal-workspace/grow-ui-library';
import {ApplicationSelectionType, primaryCommercialEntity, getDirectorScoreRate} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  getAbn,
  getAcn,
  getAdverseOnFile,
  getApplicationNotes, getAssetAvgRetail,
  getAssetCategory,
  getAssetCondition,
  getAssetDescription,
  getAssetFamily,
  getAssetMake, getAssetNewPrice,
  getAssetType, getAssetVehicle,
  getAssetYear, getBalloonPayment,
  getBrokerage,
  getBrokerApplicationId,
  getBrokerName, getBrokerOriginationFee,
  getBrokerSalesforceId,
  getBusinessLandline,
  getCompanyName,
  getDeposit,
  getDocFeeFinanced,
  getEquifaxScoreAboveThresold,
  getFinanceType,
  getIndustrySector,
  getInterestRate,
  getInvoiceAmount,
  getLoanAmount,
  getLoanTerms,
  getOperateInCommercialPremises,
  getOrganisationType, getPrimaryBusinessAddress,
  getPrimaryIndustry,
  getPrivateSales,
  getPropertyOwner,
  getRepaymentFrequency,
  getRevenue,
  DigitalIdGetApplicationIndividualsFn,
  GetRateCardDetailsFn,
  getApplicationStage,
} from '@portal-workspace/grow-shared-library';
import {switchMap, tap} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest, of, Subscription} from 'rxjs';
import {AppCalculator, FormDataForBusinessFinance, isInternalUser} from '@portal-workspace/grow-shared-library';
import {UpdateApplicationDocumentFn} from '../application-details.module';
import {GetUsersFunc, UserSelectionComponentEvent} from '@portal-workspace/grow-ui-library';
import { BusinessDocumentsComponent } from './business-documents.component';
import { BusinessCreditComponent } from './business-credit.component';
import { KycVerificationComponent } from '../kyc-verification.component';
import { BusinessSettlementComponent } from './business-settlement.component';
import { BusinessAppComponent } from './business-app.component';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import { ApplicationStageIconComponent } from '../application-stage-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { LvrCalculatorComponent } from '../lvr-calculator.component';
import {BusinessPricingComponent, BusinessPricingComponentEvent} from "./business-pricing.component";
import { BankComponent } from '../bank.component';
import { MatExpansionModule } from "@angular/material/expansion";
import {ApplicationTypeIconComponent} from '../../application-type-icon/application-type-icon.component';

export type BusinessTab = {selectionTypes: ApplicationSelectionType[], iconClass: string, name: string, index: number};

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'business-finance-application-details',
  templateUrl: './business-finance-application-details.component.html',
  styleUrls: ['./business-finance-application-details.component.scss'],
  standalone: true,

  imports: [
    FlexModule,
    BreadcrumbComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    ApplicationStageIconComponent,
    MatTabsModule,
    BusinessAppComponent,
    BusinessSettlementComponent,
    KycVerificationComponent,
    BusinessDocumentsComponent,
    LvrCalculatorComponent,
    BusinessPricingComponent,
    BankComponent,
    AssetDocumentsComponent,
    MatExpansionModule,
    DscrCalculatorComponent,
    BusinessCreditComponent,
    ApplicationTypeIconComponent,
],

})
export class BusinessFinanceApplicationDetailsComponent {

  tabs: BusinessTab[] = [];

  ratecard: RatecardDetails | null = null;

  // possible selections that can be access directly through URL
  possibleSelections: ApplicationSelectionType[] = ['app', 'quote', 'bank-statement', 'asset', 'credit','documents', 'kyc-aml', 'pricing'];

  subscriptions: Subscription[] = [];

  @Input({required: true}) createNewDisbursementFn!: CreateNewDisbursementFn;
  @Input({required: true}) updateDisbursementFn!: UpdateDisbursementFn;
  @Input({required: true}) getDisbursementByApplicationIdFn!: GetDisbursementByApplicationIdFn;
  @Input({required: true}) syncDisbursementToSfFn!: SyncDisbursementToSfFn;
  @Input({required: true}) businessNumberSearchFn!: BusinessNumberSearchFn;
  @Input({required: true}) syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  @Input({required: true}) sendAssetToSfFn!: AssetSettlementSendAssetToSfFn;
  @Input({required: true}) getApplicationAuditLogsFn!: GetApplicationAuditLogsFn;
  @Input({required: true}) getLvrCalculatorValueFn!: GetLvrCalculatorValueFn;
  @Input({required: true}) updateLvrCalculatorValueFn!: UpdateLvrCalculatorValueFn;
  @Input({required: true}) getDscrCalculatorValueFn!: GetDscrCalculatorValueFn;
  @Input({required: true}) updateDscrCalculatorValueFn!: UpdateDscrCalculatorValueFn;

  @Input({required: true}) getBankStatementsAnalysisFn!: GetBankStatementsAnalysisFn;
  @Input({required: true}) getOriginatorByIdFn!: GetOriginatorByIdFn;
  @Input({required: true}) addIndividualFn!: DigitalIdAddIndividualFn;
  @Input({required: true}) verifyApplicationIndividualsFn!: DigitalIdVerifyApplicationIndividualsFn;
  @Input({required: true}) verifyOneApplicationIndividualFn!: DigitalIdVerifyOneApplicationIndividualFn;
  @Input({required: true}) sendAskForVerificationInfoEmailFn!: DigitalIdSendAskForVerificationInfoEmailFn;
  @Input({required: true}) getApplicationIndividualsFn!: DigitalIdGetApplicationIndividualsFn;
  @Input({required: true}) updateIndividualFn!: DigitalIdUpdateIndividualFn;
  @Input({required: true}) businessSearchFn!: BusinessSearchFn;
  @Input({required: true}) updateApplicationIndividualDigitalIdMappingFn!: DigitalIdUpdateApplicationIndividualDigitalIdMappingFn;
  @Input({required: true}) updateApplicationIndividualInfoFn!: DigitalIdUpdateApplicationIndividualInfoFn;
  @Input({required: true}) printDigitalIdResultFn!: DigitalIdPrintDigitalIdResultFn;
  @Input({required: true}) setupApplicationIndividualMappingFn!: DigitalIdSetupApplicationIndividualMappingFn;
  @Input({required: true}) listApplicationDocumentFn!: ListApplicationDocumentFn;
  @Input({required: true}) downloadApplicationDocumentUrlFn!: DownloadApplicationDocumentUrlFn;
  @Input({required: true}) approveApplicationDocumentFn!: ApproveApplicationDocumentFn;
  @Input({required: true}) declineApplicationDocumentFn!: DeclineApplicationDocumentFn;
  @Input({required: true}) deleteApplicationDocumentFn!: DeleteApplicationDocumentFn;
  @Input({required: true}) downloadAllApplicationDocumentUrlFn!: DownloadAllApplicationDocumentUrlFn;
  @Input({required: true}) updateApplicationDocumentTagsFn!: UpdateApplicationDocumentTagsFn;
  @Input({required: true}) updateApplicationRequiredDocumentsFn!: UpdateApplicationRequiredDocumentsFn;
  @Input({required: true}) sendApplicationDocumentNotificationFn!: SendApplicationDocumentNotificationFn;
  @Input({required: true}) createDocumentWorklistFn!: CreateDocumentWorklistFn;
  @Input({required: true}) completeDocumentWorklistFn!: CompleteDocumentWorklistFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({required: true}) generateContractFn!: GenerateContractFn;
  @Input({required: true}) deleteContractFileFn!: DeleteContractFileFn;
  @Input({required: true}) getActiveContractForApplicationFn!: GetActiveContractForApplicationFn;
  @Input({required: true}) declineContractFn!: DeclineContractFn;
  @Input({required: true}) sendContractEmailsFn!: SendContractEmailsFn;
  @Input({required: true}) createApplicationNoteFn!: CreateApplicationNoteFn;
  @Input({required: true}) removeApplicationNoteByNoteIdFn!: RemoveApplicationNoteByNoteIdFn;
  @Input({required: true}) getNotesByApplicationIdFn!: GetNotesByApplicationIdFn;
  @Input({required: true}) syncContractDetailsToSfFn!: SyncContractDetailsToSfFn;
  @Input({required: true}) getBillerNameFn!: GetBillerNameFn;
  @Input({required: true}) validateBpayFn!: ValidateBPAYFn
  @Input({required: true}) updateApplicationStageFn!: UpdateApplicationStageInSfFn;
  @Input({required: true}) getApplicationByIdFn!: GetApplicationByIdFn;
  @Input({required: true}) getInstitutionNameFn!: GetInstitutionNameFn;
  @Input({required: true}) searchSupplierFn!: SearchSupplierFn;
  @Input({required: true}) generateApprovalNoticeFn!: GenerateApprovalNoticeFn;
  @Input({required: true}) sendApprovalNoticeEmailFn!: SendApprovalNoticeEmailFn;
  @Input({required: true}) deleteApprovalNoticeFileFn!: DeleteApprovalNoticeFileFn;
  @Input({required: true}) undoApplicationDocumentFn!: UndoApplicationDocumentFn;
  @Input({required: true}) getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  @Input({required: true}) apiUrl!: string;
  @Input({required: true}) bankStatementsUrl!: string;
  @Input({required: true}) idVerifyUrl!: string;
  @Input({required: true}) application!: BusinessLoansApplication;
  @Input({required: true}) copyApplicationFn!: CopyApplicationFn;
  @Input({required: true}) getGeoLocationFn!: GetGeoLocationFn;
  @Input({required: false}) ip: string = '';
  @Input({required: true}) getApplicationOwnerFn!: GetApplicationOwnerFn;
  @Input({required: true}) refreshBankStatementFn!: RefreshBankStatementFn;
  @Input({required: true}) getUserFn!: GetUserFn;
  @Input({required: true}) searchGrantorsFn!: AssetSettlementSearchGrantorsFn;
  @Input({required: true}) getPpsrDetailsFn!: AssetSettlementGetPpsrDetailsFn;

  breadcrumbTrails: string[] = ['Applications'];
  activeContract: ContractDetails | null = null;

  @Input({required: true}) getClientFn!: DigitalIdGetClientIdFn;
  @Input({required: true}) authenticateFn!: DigitalIdAuthenticateFn;
  @Input({required: false}) productionMode!: boolean;
  @Input({required: true}) onBreadcrumbEventsFn: (evt: BreadcrumbComponentEvent)=>void = (evt)=>{};
  @Input({required: true}) allowContractGeneration!: boolean;
  @Input({required: true}) updateApplicationFn!: UpdateApplicationFn;
  @Input({required: true}) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) downloadDocumentFromAzureFn!: DownloadDocumentFromAzureFn;
  @Input({required: true}) sendIdVerifyLinkFn!: SendIdVerifyLinkFn;
  @Input({required: true}) bypassFaceCompareFn!: BypassFaceCompareFn;
  @Input({required: true}) deleteIdentityVerificationFn!: DeleteIdentityVerificationFn;
  @Input({required: true}) sendPrivacyConsentEmailFn!: SendPrivacyConsentEmailFn;
  @Input({required: true}) getApplicationAssetFn!: AssetSettlementGetApplicationAssetFn;
  @Input({required: true}) getBsaLenderListFn!: GetBsaLenderListFn;
  @Input({required: true}) getBsaExcludedLenderListFn!: GetBsaExcludedLenderListFn;
  @Input({required: true}) saveBsaCalculatorFn!: SaveBsaCalculatorFn;
  @Input({required: true}) getBsaCalculatorFn!: GetBsaCalculatorFn;
  @Input({required: true}) getDscrCalculatorHistoryFn!: GetDscrCalculatorHistoryFn;
  @Input({required: true}) getBankStatementAndBasiqDataStatusFn!: GetBankStatementAndBasiqDataStatusFn;
  @Input({required: true}) getBasiqCustomerMappingByAbnFn!: GetBasiqCustomerMappingFn;
  @Input({required: true}) getBasiqStatementDataForCompanyFn!: GetBasiqStatementDataForCompanyFn;
  @Input({required: true}) refreshBasiqConnectionsFn!: RefreshBasiqConnectionsFn;
  @Input({required: true}) getOriginatorBusinessByIdFn!: GetOriginatorBusinessByIdFn;
  @Input({required: true}) addAuditLogFn!: AddAuditLogFn;

  @Output() events = new EventEmitter<UserSelectionComponentEvent & {type: 'submitter' | 'additional-correspondent'}>();
  @Output() businessPricingEvents = new EventEmitter<BusinessPricingComponentEvent>();

  rst!: TotalPaymentBreakupDialogData
  repaymentEstimationData: RepaymentEstimationEntry[] = [];
  amortizationScheduleData: AmortisationScheduleEntry[] = [];
  paymentChartData: PaymentChartData = {
    amountFinanced: 0,
    totalInterest: 0,
    emiAmt: 0,
    paymentFrequency: 'Monthly',
    principalAmt: 0,
    interestAmt: 0,
    totalAmt: 0,
    loanTerm: 0,
    lvr: 0,
    rv: 0,
    brokerageAmount: 0,
    docFee: 0,
    brokerOriginationFee: 0,
    applicationType:'BusinessLoans'
  };
  amortizationChartData: AmortizationChartData = {
    estimatedDrawdownDate: moment(),
    annualData: [],
    quarterlyData: [],
  };

  currentSection: ApplicationSelectionType = 'app';
  currentSectionIndex: number = 0;
  getCompanyName = getCompanyName;
  getBrokerApplicationId = getBrokerApplicationId;
  getBrokerSalesforceId = getBrokerSalesforceId;
  getBrokerName = getBrokerName;
  getOrganisationType = getOrganisationType;
  getInterestRate = getInterestRate;
  getLoanAmount = getLoanAmount;
  getBrokerage = getBrokerage;
  getAbn = getAbn;
  getAcn = getAcn;
  getFinanceType = getFinanceType;
  getAssetCategory = getAssetCategory;
  getAssetType = getAssetType;
  getAssetMake = getAssetMake;
  getAssetFamily = getAssetFamily;
  getAssetYear = getAssetYear;
  getAssetDescription = getAssetDescription;
  getAssetCondition = getAssetCondition;
  getInvoiceAmount = getInvoiceAmount;
  getLoanTerms = getLoanTerms;
  getPrivateSales = getPrivateSales;
  getEquifaxScoreAboveThreshold = getEquifaxScoreAboveThresold;
  getAdverseOnFile = getAdverseOnFile;
  getPropertyOwner = getPropertyOwner;
  getDocFeeFinanced = getDocFeeFinanced;
  getRepaymentFrequency = getRepaymentFrequency;
  getDeposit = getDeposit;
  getRevenue = getRevenue;
  getOperateInCommercialPremises = getOperateInCommercialPremises;
  getPrimaryIndustry = getPrimaryIndustry;
  getIndustrySector = getIndustrySector;
  getBusinessLandline = getBusinessLandline;
  getApplicationNotes = getApplicationNotes;
  getPrimaryBusinessAddress = getPrimaryBusinessAddress;
  isInternalUser = isInternalUser;
  getApplicationStage = getApplicationStage;
  applicationStageAllowGenerateContract = applicationStageAllowGenerateContract;
  formGroupedDocumentData = formGroupedDocumentData;
  unclassifiedDocuments = unclassifiedDocuments;
  applicationStageAllowSettleLoan = applicationStageAllowSettleLoan;
  groupStandardCondition = groupStandardCondition;
  loggedInUser: User | null = getUser();

  isAdminOrCreditUser = false;
  isAdminOrCreditUserOrSalesAM = false;

  @Input({required: true}) uploadApplicationDocumentFn!: UpdateApplicationDocumentFn;
  @Input({required: true}) getRatecardDetailsFn!: GetRateCardDetailsFn;
  @Input({required: true}) getUsersInCompanyFn!: GetUsersFunc;
  @Input({required: false}) initialSelection: ApplicationSelectionType | null = null;
  @Input({required: true}) settleLoanFn!: SettleLoanFn;
  @Input({required: true}) getBankDetailsFromOpportunitySfFn!: GetBankDetailsFromOpportunitySfFn;
  @Output() selectionEvent: EventEmitter<ApplicationSelectionType> = new EventEmitter<ApplicationSelectionType>();
  @Output() navigateToApplications = new EventEmitter();

  constructor(
    private toastService: PortalHotToastService,
    private dialogService: ApplicationDialogService
  ){}

  initTabs() {
    this.tabs = [];
    this.tabs.push({selectionTypes: ['app'], iconClass: 'mdi-view-grid-outline', name: 'APPLICATION', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['bank-statement'], iconClass: 'mdi-bank-outline', name: 'BANK STATEMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['kyc-aml'], iconClass: 'mdi-check-decagram-outline', name: 'CUSTOMER DETAILS(KYC/AML)', index: (this.tabs.length)});
    if (isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['credit'], iconClass: 'mdi-calculator', name: 'CREDIT', index: (this.tabs.length)});
    }

    this.tabs.push({selectionTypes: ['documents'], iconClass: 'mdi-folder-outline', name: 'DOCUMENTS', index: (this.tabs.length)});
    this.tabs.push({selectionTypes: ['asset', 'add-asset'], iconClass: 'mdi-handshake-outline', name: 'CONTRACT', index: (this.tabs.length)});

    if (this.isAdminOrCreditUserOrSalesAM || isInternalUser(this.loggedInUser)) {
      this.tabs.push({selectionTypes: ['pricing'], iconClass: 'mdi-currency-usd', name: 'PRICING', index: (this.tabs.length)});
    }

  }

  // NOTE: not used
  // expandedIndex = 1; // Default expansion index
  // isPanelExpanded(index: number): boolean {
  //   return this.expandedIndex === index;
  // }
  // expandPanel(index: number) {
  //   this.expandedIndex = index;
  // }

  // getApplicationTabIndex(tab: ApplicationSelectionType) {
  //   const tabFound = this.tabs.find((t) => t.selectionTypes.includes(tab));
  //   return tabFound ? tabFound.index : 0 /* default tab index if not found */;
  //   // switch (tab) {
  //   //   case 'app':
  //   //     return 0;
  //   //   case 'bank-statement':
  //   //     return 1;
  //   //   case 'kyc-aml':
  //   //     return 2
  //   //   case 'documents':
  //   //     return 3
  //   //   case 'asset':
  //   //   case 'add-asset':
  //   //     return 4
  //   //   case 'pricing':
  //   //     return 5;
  //   //   case 'lvr-calculator':
  //   //     return 6;
  //   //   default:
  //   //     return 0;
  //   // }
  // }

  async onSelectionTabChange(event: MatTabChangeEvent) {
    // const isInternalUser = this.isInternalUser(this.loggedInUser);
    // let selection: ApplicationSelectionType = 'app';
    // switch (tabIndex) {
    //   case 0:
    //     selection = 'app';
    //     break;
    //   case 1:
    //     selection = 'bank-statement';
    //     break;
    //   case 2:
    //     selection = 'kyc-aml';
    //     break;
    //   case 3:
    //     selection = 'documents';
    //     break;
    //   case 4:
    //     selection = 'asset';
    //     break;
    //   case 5:
    //     selection = 'pricing';
    //     break;
    //   case 6:
    //     selection = 'lvr-calculator';
    //     break;
    // }
    const tabFound = this.tabs.find(t => t.index == event.index);
    const selection = tabFound ? tabFound.selectionTypes[0] : 'app' /* default selection if not found */ ;
    this.currentSection = selection;
    this.selectionEvent.emit(this.currentSection);
  }


  ngOnInit() {
    this.isAdminOrCreditUser = isAdminOrCreditUser(this.loggedInUser);
    this.isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.loggedInUser);
    this.initTabs();
    const routeTab = this.initialSelection;
    if (routeTab && this.possibleSelections.includes(routeTab)) {
      const _tab = this.tabs.find(tab => tab.selectionTypes.includes(routeTab));
      if (_tab) {
        this.currentSection = routeTab;
        this.currentSectionIndex = _tab.index;
      }
    }
    setupUntilDestroy(this);
    this.getActiveContract()
    const commercialEntity = primaryCommercialEntity(this.application);
    if (commercialEntity) {
      this.breadcrumbTrails.push(commercialEntity.LegalName);
    }
    const sub = this.getRatecardDetailsFn(this.application.UserId, 'BusinessLoans').pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
        this.ratecard = r;
        this.recalculateEstimation();

        // const ratecard = r;
        // const date = moment().add(1, 'day');

        // {
        //   const paymentFrequencyValue = applicationToPaymentFrequency(this.application);
        //   const paymentFrequencyType = paymentFrequencyValue?.type ?? null;
        //   const loanAmount = applicationToLoanAmount(this.application) ?? 0;
        //   const loanTermValue: LoanTermValue = applicationToLoanTerms(this.application);
        //   const loanTermType = loanTermValue?.type ?? null;
        //   const brokerageValue = applicationToBrokerage(this.application);
        //   const brokerageType = brokerageValue?.type ?? null;
        //   const brokerageAmount = applicationToBrokerageAmount(this.application);
        //   const businessSearchValue = applicationToBusinessSearchValue(this.application);
        //   const propertyOwner =  applicationToPropertyOwner(this.application);
        //   const adverseOnFile = applicationToAdverseOnFile(this.application);
        //   const lowEquifaxScore = applicationToEquifaxScoreAboveThreshold(this.application);
        //   const directorScore = applicationToDirectorScore(this.application);
        //   const bureauReport = (businessSearchValue as BusinessSearchResultValue /* HACK */).result ?? null;
        //   const docFeeFinanced = applicationToDocFeeFinanced(this.application);
        //   const docFeeSf = getDocFeeSf(this.application);
        //   const rateSf = getRateSf(this.application);
        //   const brokerOriginationFeeSf = getBrokerOriginationFeeSf(this.application);
        //   const paymentType = getPaymentType(this.application);
        //   const creditRateAdjustment = getCreditRateAdjustment(this.application);

        //   const rst = calculateBusinessLoanEstimation(date, {
        //     paymentFrequencyType: paymentFrequencyType,
        //     loanTermType: loanTermType,
        //     brokerageType: brokerageType,
        //     loanAmount: loanAmount,
        //     businessSearchValue: businessSearchValue,
        //     propertyOwner: propertyOwner,
        //     brokerage: brokerageAmount,
        //     adverseOnFile: adverseOnFile,
        //     lowEquifaxScore: lowEquifaxScore,
        //     directorScore: directorScore,
        //     rateCard: ratecard,
        //     bureauReport: bureauReport,
        //     brokerageAmount: brokerageAmount,
        //     docFeeFinanced: docFeeFinanced,
        //     docFeeSf: docFeeSf,
        //     rateSf: rateSf,
        //     brokerOriginationFeeSf: brokerOriginationFeeSf,
        //     paymentType: paymentType ?? undefined,
        //     creditRateAdjustment: creditRateAdjustment,
        //   });

        //   this.rst = rst.totalPaymentBreakupDialogData;
        //   this.repaymentEstimationData = this.rst.repaymentEstimationData;
        //   this.amortizationScheduleData = this.rst.amortizationScheduleData;
        //   this.paymentChartData = this.rst.paymentChartData;
        //   this.amortizationChartData = this.rst.amortizationChartData;
        // }



        // const paymentFrequencyValue = getRepaymentFrequency(this.application);
        // const loanTermValue = getLoanTerms(this.application);
        // const brokerageValue = getBrokerage(this.application);
        // const loanAmount = getLoanAmount(this.application);
        // const bureauReport = this.application.CompanyDetails;
        // const lowEquifaxScore = yesNoToBoolean(getEquifaxScoreAboveThresold(this.application));
        // const adverseOnFile = yesNoToBoolean(getAdverseOnFile(this.application));
        // const propertyOwner = yesNoToBoolean(getPropertyOwner(this.application));
        // const directorScore = yesNoToBoolean(getDirectorScoreRate(this.application));
        // const brokerageAmount = getBrokerageAmount(this.application);
        // const docFeeFinanced = yesNoToBoolean(getDocFeeFinanced(this.application));
        // const docFeeSf = getDocFeeSf(this.application);
        // const rateSf = getRateSf(this.application);
        // const brokerOriginationFeeSf = getBrokerOriginationFeeSf(this.application);
        // const creditRateAdjustment = getCreditRateAdjustment(this.application);

        // const calculator = new AppCalculator();
        // const formData: FormDataForBusinessFinance = {
        //   loanAmount,
        //   lowEquifaxScore: lowEquifaxScore,
        //   adverseOnFile,
        //   brokerage: brokerageValue,
        //   propertyOwner,
        //   loanTerms: Number(loanTermValue ?? 0),
        //   directorScore: directorScore,
        //   brokerageAmount,
        //   docFeeSf,
        //   brokerOriginationFeeSf,
        //   docFeeFinanced,
        //   creditRateAdjustment: creditRateAdjustment ?? 0,
        // };
        // const terms = calculator.getTotalInterestRateForBusinessFinance(
        //   ratecard!,
        //   formData,
        //   bureauReport,
        // );
        // calculator.setRepaymentType(String(getPaymentType(this.application)) ? String(getPaymentType(this.application)).toLowerCase() : "arrears");
        // calculator.setRepaymentFrequency(paymentFrequencyValue as any ?? 'Monthly');
        // calculator.setPrinicipalValue(terms.prinicipalAmount);
        // calculator.setInterestValue(terms.totalInterest);
        // calculator.setloanValue(Number(loanTermValue ?? '0'));
        // calculator.setResidualAmount(terms.RV);
        // calculator.setBrokeragePercent(Number(brokerageValue ?? '0'));
        // calculator.setBrokerageAmount(terms.brokerageAmount);
        // calculator.setDateValue(date.format('DD/MM/YYYY'));
        // calculator.refreshUI();

        // const rst1 = calculator.getDonutChartData();
        // const rst2 = calculator.getBarChartYearlyData();
        // const rst3 = calculator.getBarChartQuaterlyData();
        // const rst4 = calculator.getRepaymentEstimationData2(
        //   terms,
        //   paymentFrequencyValue as any ?? 'Monthly',
        //   'advance',
        // );
        // const rst5 = calculator.getAmortisationData();

        // this.repaymentEstimationData = rst4.map((rst ) =>({
        //   amountFinance: String(rst.amountFinance),
        //   month24: String(rst.data.find((d ) => d.loanTerm == 24)?.repayment ?? 0),
        //   month36: String(rst.data.find((d ) => d.loanTerm == 36)?.repayment ?? 0),
        //   month48: String(rst.data.find((d ) => d.loanTerm == 48)?.repayment ?? 0),
        //   month60: String(rst.data.find((d ) => d.loanTerm == 60)?.repayment ?? 0),
        // }));
        // this.amortizationScheduleData = rst5.map(rst => ({
        //   year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
        //   details: rst.childRows.map(d =>({
        //     monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
        //     principal: String(d.pricipalPaid), balance: String(d.endingBalance)
        //   }))
        // }));
        // this.paymentChartData = {
        //   amountFinanced: rst1.pricipalAmt,
        //     totalInterest: terms.totalInterest,
        //     emiAmt: calculator.emiAmt,
        //     paymentFrequency: calculator.repaymentFrequency,
        //     principalAmt: calculator.principalAmt,
        //     interestAmt: calculator.interestAmt,
        //     totalAmt: calculator.totalAmt,
        //     loanTerm: calculator.loanValue, // loanValue from calculator
        //     lvr: terms.LVR,
        //     rv: terms.RV,
        //     brokerageAmount: terms.brokerageAmount,
        //     docFee: terms.docFee,
        //     brokerOriginationFee: terms.brokerOriginationFee,
        //     applicationType:'BusinessLoans'
        // };
        // this.amortizationChartData = {
        //   estimatedDrawdownDate: moment(calculator.dateValue),
        //     annualData: rst2.map(rst => ({
        //     year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
        //   })),
        //     quarterlyData: rst3.map(rst => ({
        //     quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
        //   }))
        // };

        // this.rst = {
        //   amortizationChartData: this.amortizationChartData,
        //   amortizationScheduleData: this.amortizationScheduleData,
        //   paymentChartData: this.paymentChartData,
        //   repaymentEstimationData: this.repaymentEstimationData,
        //   isBusinessLoan: true
        // }


        // console.log('*********** calculate ', rst1, rst2, rst3, rst4, rst5);
      })).subscribe();

      this.subscriptions.push(this.businessPricingEvents.subscribe((evt) => {
        switch(evt.type) {
          case "business-pricing-saved": {
            this.rst = evt.rst;
            this.repaymentEstimationData = evt.rst.repaymentEstimationData;
            this.amortizationChartData = evt.rst.amortizationChartData;
            this.paymentChartData = evt.rst.paymentChartData;
            this.amortizationChartData = evt.rst.amortizationChartData;
            break;
          }
        }
      }));
   this.subscriptions.push(sub);
  }

  recalculateEstimation() {
    if (this.ratecard) {
      const ratecard = this.ratecard;
      const date = moment().add(1, 'day');

      {
        const paymentFrequencyValue = applicationToPaymentFrequency(this.application);
        const paymentFrequencyType = paymentFrequencyValue?.type ?? null;
        const loanAmount = applicationToLoanAmount(this.application) ?? 0;
        const loanTermValue: LoanTermValue = applicationToLoanTerms(this.application);
        const loanTermType = loanTermValue?.type ?? null;
        const brokerageValue = applicationToBrokerage(this.application);
        const brokerageType = brokerageValue?.type ?? null;
        const brokerageAmount = applicationToBrokerageAmount(this.application) ?? undefined;
        const businessSearchValue = applicationToBusinessSearchValue(this.application);
        const businessSearchValueWithSearchResult = businessSearchValue?.type == 'search-result' ? businessSearchValue : null;
        const propertyOwner =  applicationToPropertyOwner(this.application);
        const adverseOnFile = applicationToAdverseOnFile(this.application);
        const lowEquifaxScore = applicationToEquifaxScoreAboveThreshold(this.application);
        const directorScore = applicationToDirectorScore(this.application);
        const bureauReport = (businessSearchValue as BusinessSearchResultValue /* HACK */).result ?? null;
        const docFeeFinanced = applicationToDocFeeFinanced(this.application);
        const docFee = applicationToDocFee(this.application) as number;
        const paymentType = getPaymentType(this.application);
        const creditRateAdjustment = getCreditRateAdjustment(this.application);

        const rst = calculateBusinessLoanEstimation(date, {
          type: 'BusinessLoans',
          paymentFrequencyType: paymentFrequencyType,
          loanTermType: loanTermType,
          brokerageType: brokerageType,
          brokerage: brokerageAmount,
          loanAmount: loanAmount,
          businessSearchValue: businessSearchValueWithSearchResult,
          propertyOwner: propertyOwner,
          adverseOnFile: adverseOnFile,
          lowEquifaxScore: lowEquifaxScore,
          directorScore: directorScore,
          rateCard: ratecard,
          bureauReport: bureauReport,
          brokerageAmount: brokerageAmount,
          docFeeFinanced: docFeeFinanced,
          docFee: docFee,
          paymentType: paymentType ?? undefined,
          creditRateAdjustment: creditRateAdjustment,
          repaymentType : getRepaymentType(this.application) ?? '',
        });

        this.rst = rst.totalPaymentBreakupDialogData;
        this.repaymentEstimationData = this.rst.repaymentEstimationData;
        this.amortizationScheduleData = this.rst.amortizationScheduleData;
        this.paymentChartData = this.rst.paymentChartData;
        this.amortizationChartData = this.rst.amortizationChartData;
      }
    }
  }

  onBreadcurmbEvents($event: BreadcrumbComponentEvent) {
    this.onBreadcrumbEventsFn($event);
  }

  getActiveContract() {
    this.subscriptions.push(
      this.getActiveContractForApplicationFn(this.application.ApplicationId)
        .subscribe((r: ContractDetails | null) => {
          this.activeContract = r;
        })
    )
  }

  openGenerateContractDialog() {
    const ok = checkContractIndividuals(this.application, this.dialogService);
    if (ok) {
      this.subscriptions.push(
        this.dialogService.openGenerateContractDialog({
          sendContractEmailsFn: this.sendContractEmailsFn,
          getRatecardDetailsFn: this.getRatecardDetailsFn,
          getApplicationIndividualsFn: this.getApplicationIndividualsFn,
          generateContractFn: this.generateContractFn,
          deleteContractFileFn: this.deleteContractFileFn,
          application: this.application,
          syncContractDetailsToSfFn: this.syncContractDetailsToSfFn,
          getApplicationOwnerFn: this.getApplicationOwnerFn,
          getAccountDetailsFromSfFn: this.getAccountDetailsFromSfFn,
          updateApplicationFn: this.updateApplicationFn,
          getOriginatorBusinessByIdFn: this.getOriginatorBusinessByIdFn,
          apiUrl: this.apiUrl,
        }).afterClosed().subscribe(() => {
          this.getActiveContract();
        })
      )
    }
  }

  onSettleLoan(){
    if (isInternalUser(this.loggedInUser)) {
      this.dialogService.openSettleLoanDialog()
        .afterClosed().pipe(
        tap(async (r) => {
          if (r) {
           this.doSendDisbursementToSf();
          }
        })
      ).subscribe()
    } else {
      // check documents first
      this.subscriptions.push(
        combineLatest([
          this.listApplicationDocumentFn(this.application.ApplicationId),
          this.getApplicationByIdFn(this.application.ApplicationId)
        ]).pipe(
          this.toastService.spinnerObservable()
        ).subscribe(([r, app]: [AzureStorageDocument[], Application]) => {
          if (r) {
            let docs: GroupedDocument[] = this.formGroupedDocumentData(r,
              app?.DocumentTypes?.length ? app?.DocumentTypes
              : [...applicationDefaultDocuments(this.application), this.unclassifiedDocuments()],
              '',
            );
            docs = this.groupStandardCondition(docs, this.application);
            docs = this.displayRequiredDocumentsOnly(docs);
            const standardDocs = docs.filter(d => !d?.nonStandardCondition && !d?.standardCondition);
            const nonStandardConditionDocs = docs.filter(d => d?.nonStandardCondition);
            const standardConditionDocs = docs.filter(d => d?.standardCondition);
            const pendingGroups = [
              ...standardDocs.filter(group => group.status === 'Pending'),
              ...nonStandardConditionDocs.filter(group => (group.docs.length ? group.status : group.nonStandardConditionStatus ?? 'Pending') === 'Pending'),
              ...standardConditionDocs.filter(group => (group.docs.length ? group.status : group.standardConditionStatus ?? 'Pending') === 'Pending')
            ];
            console.log(pendingGroups);
            if (pendingGroups.length) {
              // do not allow settle loan
              this.dialogService.openAlertDialog({
                message: 'Error',
                subMessage: 'You cannot settle loan as you still have pending documents',
              }).afterClosed().subscribe();
            } else {
              this.doUpdateApplicationStage();
            }
          }
        })
      )
    }
  }

  private displayRequiredDocumentsOnly(docs: GroupedDocument[]) {
    return docs.filter(group => {
      if (
        group.value === constants.documentTypes.internalDocuments.value ||
        group.required || group.docs?.length
      ) {
        return true;
      }
      return false;
    })
  }

  doUpdateApplicationStage() {
    this.subscriptions.push(
      this.updateApplicationStageFn({
        salesforceId: this.application.AppInfoSalesforceID ?? "",
        stageName: 'QA'
      }).pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response.status) {
          this.dialogService.successDialog({
            message: 'Success',
            subMessage: "Application sent to QA queue",
          }).afterClosed().subscribe();
        } else {
          this.dialogService.openAlertDialog({
            message: 'Error',
            subMessage: response.message,
          }).afterClosed().subscribe();
        }
      })
    )
  }

  openContractStatusDialog() {
    this.subscriptions.push(
      this.dialogService.openContractStatusDialog({
        getContractStatusFn: this.getContractStatusFn,
        applicationId: this.application.ApplicationId,
        declineContractFn: this.declineContractFn,
        envelopeId: this.activeContract?.envelopeId ?? ""
      }).afterClosed().subscribe(() => {
        this.getActiveContract();
      })
    )
  }

  doSendDisbursementToSf() {
    this.subscriptions.push(
      this.getDisbursementByApplicationIdFn(this.application.ApplicationId).pipe(
        switchMap((disbursements: Disbursement[]) => {
          console.log('================disbursements', disbursements)
          // validate disbursements
          if (!validateDisbursementData(disbursements)) {
            return of({
              status: false,
              message: "Please make sure you enter payment details for all disbursements."
            })
          }
          return this.settleLoanFn({
            assets: [],
            disbursements: disbursements,
            salesforceId: this.application.AppInfoSalesforceID ?? ""
          })
        }),
        this.toastService.spinnerObservable(),
      ).subscribe((response: ApiResponse) => {
        if (response?.status) {
          this.dialogService.successDialog({
            message: "Success",
            subMessage: "Asset and disbursement details sent to Salesforce",
          }).afterClosed().subscribe()
        } else {
          this.dialogService.openAlertDialog({
            message: "Error",
            subMessage: response.message,
          }).afterClosed().subscribe()
        }
      })
    )
  }

  // doSendAssetToSalesforce() {
  //   this.subscriptions.push(
  //     this.getApplicationAssetFn(this.application.ApplicationId).pipe(
  //       this.toastService.spinnerObservable(),
  //       this.toastService.snackBarObservable('Asset Sent to Salesforce'),
  //       switchMap((assets: PpsrAsset[]) => {
  //         const allSfData: SendAssetToSfRecord[] = [];
  //         for (const asset of assets) {
  //           let sfData: SendAssetToSfRecord = {
  //             attributes: {
  //               type: "Asset",
  //               referenceId: String(asset?.AssetId ?? ""),
  //             },
  //             Name: (asset.SettlementAssetDetails?.description ?? "").substring(0, 255),
  //             Asset_Category__c: asset.SettlementAssetDetails?.assetCategory ?? "",
  //             Asset_Class__c: `${asset.SettlementAssetDetails?.assetCategory ?? ""}-${asset.SettlementAssetDetails?.assetType ?? ""}`,
  //             Opportunity__c: this.application.AppInfo.SalesforceId ?? "",
  //             Description: asset.SettlementAssetDetails?.description ?? "",
  //             Invoice_Price_incl_GST__c: asset.SettlementAssetDetails?.InvoicePrice ?? 0,
  //             GST__c: asset.SettlementAssetDetails?.GST ?? 0,
  //             SerialNumber: asset.SettlementAssetDetails?.serialNumber ?? "",
  //             Invoice_Number__c: asset.invoiceNumber ?? "",
  //             Manufacture_Year__c: String(asset.SettlementAssetDetails?.year ?? "").slice(0, 4),
  //             Supplier__c: "",
  //           }
  //           // supplier
  //           if (asset.SettlementAssetDetails?.supplier) {
  //             sfData.Supplier__c = (asset.SettlementAssetDetails?.supplier as any)?.SalesForceId
  //           } else { // private seller
  //           }

  //           // vehicle details
  //           if (asset.SettlementAssetDetails?.VehicleDetails) {
  //             sfData.Make__c = asset.SettlementAssetDetails?.VehicleDetails?.Make ?? "";
  //             sfData.Model__c = asset.SettlementAssetDetails?.VehicleDetails?.Model ?? "";
  //           }

  //           allSfData.push(sfData);
  //         }
  //         console.log("salesforce data: ", allSfData);
  //         return this.sendAssetToSfFn({
  //           records: allSfData
  //         })
  //       }),
  //     ).subscribe()
  //   )
  // }

  onBusinessPricingEvents($event: BusinessPricingComponentEvent) {
    this.businessPricingEvents.emit($event);
  }
}
