import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {clearAllStorage } from '@portal-workspace/grow-ui-library';
import {setupUntilDestroy} from '@portal-workspace/grow-ui-library';
import {ActivatedRoute, Router} from '@angular/router';
import {navigationUrlForLogin} from '../../service/navigation-urls';
import {RegistrationService} from '../../service/registration.service';
import {PortalHotToastService} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MinimalLayoutService} from '../../layout/minimal-layout/minimal-layout.service';
import {ConfirmPasswordAdminValue} from '@portal-workspace/grow-shared-library';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmPasswordAdminComponent } from '@portal-workspace/grow-ui-library';
import { FlexModule } from '@angular/flex-layout/flex';
import { MessageBoxComponent } from '@portal-workspace/grow-ui-library';
import { NgTemplateOutlet } from '@angular/common';


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './reset-password.page.html',
    styleUrls: ['./reset-password.page.scss'],
    standalone: true,
    imports: [MessageBoxComponent, NgTemplateOutlet, FormsModule, FlexModule, ReactiveFormsModule, ConfirmPasswordAdminComponent, MatButtonModule]
})
export class ResetPasswordPage implements OnInit, AfterViewInit {

  subscriptions: Subscription[] = [];

  valid = true;
  error = ``;
  source!: string;

  email?: string;

  currentTemplate: TemplateRef<any> | null = null;

  @ViewChild('resetPasswordTemplate') resetPaswordTemplate!: TemplateRef<any>;
  @ViewChild('confirmationTemplate') confirmationTemplate!: TemplateRef<any>;
  @ViewChild('portalConfirmationTemplate') portalConfirmationTemplate!: TemplateRef<any>;
  @ViewChild('mobileConfirmationTemplate') mobileConfirmationTemplate!: TemplateRef<any>;


  formGroup: FormGroup<{
    confirmPassword: FormControl<ConfirmPasswordAdminValue>
  }>;
  formControlConfirmPassword: FormControl<string | null>;

  constructor(private formBuilder: FormBuilder,
              private registrationService: RegistrationService,
              private activatedRoute: ActivatedRoute,
              private toastService: PortalHotToastService,
              private minimalLayoutService: MinimalLayoutService,
              private router: Router) {
    this.formControlConfirmPassword = this.formBuilder.control(null, [Validators.required]);
    this.formGroup = this.formBuilder.group({
      confirmPassword: this.formControlConfirmPassword,
    });
  }

  ngOnInit(): void {
     setupUntilDestroy(this);
     this.source = this.activatedRoute.snapshot.queryParams.source;
     const code = this.activatedRoute.snapshot.params.code;
     if (!!!code) {
       this.valid = false;
       this.error = `Invalid code`;
     }
     this.minimalLayoutService.settings({
       showPrev: false,
       showCross: true,
       showAccreditationInContact: false,
       showContact: false,
     });
     const sub = this.minimalLayoutService.headerEventChanges().pipe(
       tap(async r => {
         if (r) {
           switch(r.type) {
             case 'cross-clicked': {
               await this.router.navigate(navigationUrlForLogin());
               break;
             }
           }
         }
       })
     ).subscribe();
     this.subscriptions.push(sub);
     this.registrationService.verifyResetPasswordCode(code).pipe(
      //  this.toastService.toastObserver2('verifying code'),
       tap(r => {
          this.valid = r.status;
          this.error = r.message ?? `Invalid code`;
          if(this.valid) {
            const uncoded = atob(code);
            this.email = uncoded.split('/')[1];
            console.log(uncoded, this.email);
            if (!this.email) {
              this.valid = false;
              this.error = `Invalid code`;
            }
          }
       })
     ).subscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentTemplate = this.resetPaswordTemplate;
    });
  }

  onSavePassword($event: Event) {
    this.registrationService.resetPassword({
      Email: this.email!,
      Password: this.formControlConfirmPassword.value ?? '',
    }).pipe(
      // this.toastService.toastObserver('Reseting passsword'),
      tap(r => {
        if (r.status) {
          clearAllStorage();
          setTimeout(() => {
            if(this.source == 'portal') {
              this.currentTemplate = this.portalConfirmationTemplate;
            }
            else if (this.source == 'mobile') {
              this.currentTemplate = this.mobileConfirmationTemplate;
            }
            else {
              this.currentTemplate = this.confirmationTemplate;
            }
          });
        }
      })
    ).subscribe();
  }


  async onLoginAgain($event: MouseEvent) {
    clearAllStorage();
    await this.router.navigate(navigationUrlForLogin())
  }
}
