<div class="corporate-loan-payment-breakup-component" fxLayout="column" fxLayoutGap="20px">
  <mat-dialog-content class='max-height-100-vh' >
    <div fxLayout="row" fxLayoutAlign="end center">
      <span class="mdi mdi-close cursor-pointer" (click)="onClose($event)"></span>
    </div>
    <div>

      <div class="mat-h2">Break-up of Finance arrangement</div>

      <div class="mat-body separator">
        This estimate is not an offer or approval of finance. The minimum monthly repayment is calculated based on interest accumulated on daily basis.
        All applications are subject to credit assessment and eligibility criteria. Terms, conditions, fees and charges
        apply.
      </div>

      @if (data) {
        <div gdArea="data" class="data">
          <!-- row 1 -->
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Facility limit</div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center"> {{data.term.facilitylimit | currency}}</div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Interest Rate </div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">
              <span>{{data.term.interestRate | percentage }}</span>
              <span class="mdi mdi-information-outline" (click)="onInterestRateClick()"></span>
            </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">RBA Rate </div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.term.rbaRate | percentage}} </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <!-- row 2 -->
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Facility Establishment Fee</div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center">{{data.term.facilityEstablishmentFee | currency}} </div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <!-- row 3-->
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Doc fee</div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center"> {{data.term.docFee | currency}}</div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <!-- row 4-->
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Monthly Facility Fee</div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center"> {{data.term.monthlyFacilityFee | currency}}</div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
          <!-- row 5-->
          @if (!hideBrokerage) {
            <div fxLayout="row" class="separator">
              <div class="no-logo-text mat-body width-50">Brokerage(ex GST)</div>
              <div class="mat-body width-50 mr-4" fxLayoutAlign="end center"> {{data.term.brokerageDollar | currency}}</div>
            </div>
          }
          @if (!hideBrokerage) {
            <mat-divider [inset]="true"></mat-divider>
          }
          <!-- row 6-->
          <div fxLayout="row" class="separator">
            <div class="no-logo-text mat-body width-50">Amount available</div>
            <div class="mat-body width-50 mr-4" fxLayoutAlign="end center"> {{data.term.amountAvailable | currency}}</div>
          </div>
          <mat-divider [inset]="true"></mat-divider>
        </div>
      }
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="pt-4">
      <button mat-stroked-button color="primary" (click)="onClose($event)"> Cancel </button>
      <button mat-flat-button color="primary" (click)="onAcceptQuotation($event)">{{ isQuote ? 'Close' : 'Accept Quotation' }}</button>
    </div>
  </mat-dialog-content>
</div>
