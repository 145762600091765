<div class="digital-id-verify-dialog">
  <mat-dialog-content>
    <h3 class="header text-align-center">Digital Id Verify</h3>
    <!-- <table class="verification-result-table">
      <tr>
        <th>Last update</th>
        <td>{{kycResult.lastUpdate | date: 'medium'}}</td>
      </tr>
      <tr>
        <th>Request status</th>
        <td>{{kycResult.status}}</td>
      </tr>
      <tr>
        <th>Verification status</th>
        <td><tag-box [state]="verificationStatusDisplay(kycResult.digitalIdResponse?.verification_status)"></tag-box></td>
      </tr>
      <tr>
        <th>Verification session token</th>
        <td><span class="text-wrap text-break">{{defaultString(kycResult.digitalIdResponse?.verification_session_token) | truncate: [30] }}</span></td>
      </tr>
      <tr>
        <th>Data source</th>
        <td>
          @if (kycResult.digitalIdResponse?.data_source_events?.length) {
            <ul>
              @for (event of kycResult.digitalIdResponse?.data_source_events; track event) {
                <li>
                  {{datasourceEventDisplay(event)}}
                </li>
              }
            </ul>
          }
          @if (!kycResult.digitalIdResponse?.data_source_events?.length) {
            <span>Not available</span>
          }
        </td>
      </tr>
      <tr>
        <th>Transaction ID</th>
        <td>
          {{defaultString(kycResult.digitalIdResponse?.transaction_id)}}
        </td>
      </tr>
      <tr>
        <th>Watch list</th>
        <td>
          @if (!kycResult.digitalIdResponse?.watchlist) {
            Not Available
          }
          @if (kycResult.digitalIdResponse?.watchlist) {
            <div>Check Performed: {{capitalize(kycResult.digitalIdResponse.watchlist.check_performed)}}</div>
            <div>Check Performed Date: {{kycResult.digitalIdResponse.watchlist.check_performed_date | date: 'MMM d, y, h:mm:ss a'}}</div>
            <div>Found: {{capitalize(kycResult.digitalIdResponse.watchlist.found)}}</div>
            <div>Sources Category: {{sourcesCategoryDisplay(kycResult.digitalIdResponse.watchlist.sources_category)}}</div>
            @if (kycResult.digitalIdResponse.watchlist.found_sources && kycResult.digitalIdResponse.watchlist.found_sources.length) {
              <div>
                <div>Found Categories and sources (category - found sources) as follows :-</div>
                <ul>
                  @for (source of kycResult.digitalIdResponse.watchlist.found_sources; track source) {
                    <li>
                      {{source.category}} - {{source.name}}
                    </li>
                  }
                </ul>
              </div>
            }
          }
        </td>
      </tr>
    </table> -->
    <div class="row">
      <div class="col-12 mb-2">
        <div class="text-align-center">
          Name: {{individual.GivenName}} {{individual.MiddleName ? individual.MiddleName : ''}} {{individual.SurName}}
        </div>
      </div>
      <div class="col-12">
        <div class="text-align-center">
          Please ensure that the name matches exactly as it appears on your photo ID. If it matches with your ID, then continue with the verification process.
          If your name does not match with your ID, please contact us at 1300 001 420.
        </div>
      </div>
    </div>
    <div class="mt-3 text-align-center">
      <app-digital-id
        [type]="'basic'"
        [getClientFn]="getClientFn"
        [authenticateFn]="authenticateFn"
        [verificationSessionToken]="getVerificationSessionToken(kycResult)"
        [onCompleteCallback]="onComplete"
        [onClick]="onClick"
        (events)="onDigitalIdEvent($event)">
      </app-digital-id>
    </div>
  </mat-dialog-content>
</div>
