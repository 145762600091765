import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { AsyncPipe, CommonModule, JsonPipe, PercentPipe } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { FlexModule } from "@angular/flex-layout/flex";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCardModule } from "@angular/material/card";
import {
  AppCalculator,
  Application,
  BrokerageSelectionValue,
  FormDataForBusinessFinance,
  GetRateCardDetailsFn,
  LoanPurposeValue,
  LoanTermValue,
  PaymentFrequencyValue,
  PaymentFrequencyValueOptions,
  RatecardDetails,
  SearchCompanyByABNResult,
  SliderComponentValue,
  TermRate,
  TotalPaymentBreakupDialogData,
  UpdateApplicationData,
  UpdateApplicationFn,
  UpdateApplicationSfFn,
  YesNoValue,
  booleanToYesNo,
  getBrokerage,
  getInterestRate,
  getPreviousBusiness,
  isAdminOrCreditUserOrSalesAM,
  isInternalUser,
  isNotAdminOrCreditOrSalesAMInternalUser,
  calculateBusinessLoanEstimation,
  PaymentFrequencyType,
  LoanTermType,
  yesNoToBoolean,
  BrokerageSelectionType,
  BusinessSearchResultValue,
  GetContractStatusFn,
  isAdminOrCreditUser,
  isAdmin,
  isAdminOrCreditUserOrSalesAmOrSalesBDM,
  CurrencyInputValue,
  LoanTermsSelectionWithInputValue,
  getRepaymentType,
} from "@portal-workspace/grow-shared-library";
import { MessageBoxComponent } from '../../message-box/message-box.component';
import {Subject, Subscription, tap} from "rxjs";
import { ApplicationDialogService } from "../../application-dialog-component/application-dialog.service";
import { PortalHotToastService } from "../../portal-hot-toast-component/hot-toast.service";
import { loadingFor } from "@ngneat/loadoff";
import {
  applicationToAbnAgeAboveThreshold,
  applicationToAdverseOnFile,
  applicationToBrokerage,
  applicationToBrokerageAmount,
  applicationToBusinessSearchValue,
  applicationToCrediRateAdjustment,
  applicationToDirectorScore,
  applicationToDocFee,
  applicationToDocFeeFinanced,
  applicationToEquifaxScoreAboveThreshold, applicationToInvoiceAmount,
  applicationToLastUpdatedByUser,
  applicationToLoanAmount,
  applicationToLoanPurpose,
  applicationToLoanTerms,
  applicationToLoanTermsValue,
  applicationToPaymentFrequency,
  applicationToPreviousLoan,
  applicationToPropertyOwner,
  getUser,
} from "@portal-workspace/grow-ui-library";
import { DisableControlDirective } from '../../../directives/disable-control.directive';
import { InputMaskModule } from "@ngneat/input-mask";
import { BrokerageSelectionComponent } from '../../brokerage-component/brokerage-selection.component';
import { YesNoComponent } from '../../yes-no-component/yes-no.component';
import { PaymentFrequencySelectionComponent } from '../../payment-frequency-selection-component/payment-frequency-selection.component';
import { BusinessLoanTermsSelectionComponent } from '../../loan-terms-selection-component/business-loan-terms-selection.component';
import { CurrencyInputComponent } from '../../currency-selection-component/currency-input.component';
import { MatInputModule } from "@angular/material/input";
import { LoanPurposeSelectionComponent } from '../../loan-purpose-selection-component/loan-purpose-selection.component';
import moment, { Moment } from "moment";
import numeral from "numeral";
import { UntilDestroy } from "@ngneat/until-destroy";
import { MatTableModule } from "@angular/material/table";
import { PercentageInputComponent } from '../../percentage-input-component/percentage-input.component';
import _ from "lodash";
import {LooseCurrencyPipe} from '../../../pipes/loose-currency.pipe';
import {PercentagePipe} from '../../../pipes/percentage.pipe';
import {MarkDirective} from '../../../directives/mark-as-dirty.directive';
import { LoanTermsSelectionWithInputComponent } from '../../loan-terms-selection-component/loan-terms-selection-with-input.component';

export type BusinessPricingComponentEvent = BusinessPricingComponentSavedEvent;

export interface BusinessPricingComponentSavedEvent {
  type: 'business-pricing-saved',
  rst: TotalPaymentBreakupDialogData
}

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'business-pricing',
  templateUrl: './business-pricing.component.html',
  styleUrls: ['./business-pricing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatFormFieldModule,
    AsyncPipe,
    JsonPipe,
    MatCardModule,
    MessageBoxComponent,
    DisableControlDirective,
    InputMaskModule,
    YesNoComponent,
    BrokerageSelectionComponent,
    PaymentFrequencySelectionComponent,
    BusinessLoanTermsSelectionComponent,
    CurrencyInputComponent,
    MatInputModule,
    LoanPurposeSelectionComponent,
    MatTableModule,
    PercentageInputComponent,
    LooseCurrencyPipe,
    MarkDirective,
    PercentagePipe,
    LoanTermsSelectionWithInputComponent,
 ]
})
export class BusinessPricingComponent implements OnInit, OnChanges {

  markSubject = new Subject<boolean>();

  @Input({ required: true }) getRateCardDetailsFn!: GetRateCardDetailsFn;
  @Input({ required: true }) updateApplicationFn!: UpdateApplicationFn;
  @Input({ required: true }) updateApplicationSfFn!: UpdateApplicationSfFn;
  @Input({required: true}) getContractStatusFn!: GetContractStatusFn;
  @Input({ required: true }) application!: Application;
  @Input({required:true}) currentRstValue!: TotalPaymentBreakupDialogData;
  @Output() events = new EventEmitter<BusinessPricingComponentEvent>();
  @ViewChild('readMode') readModeTemplate!: TemplateRef<any>;
  @ViewChild('editMode') editModeTemplate!: TemplateRef<any>;

  subscriptions: Subscription[] = [];

  formBuilder: FormBuilder = inject(FormBuilder);
  applicationDialogService: ApplicationDialogService = inject(ApplicationDialogService);
  toastService: PortalHotToastService = inject(PortalHotToastService);

  loader = loadingFor('saveRate');

  MIN_CREDIT_RATE = -1;
  MAX_CREDIT_RATE = 5;
  MIN_LOAN_AMOUNT = 0.01;
  step2MinLimit: number | null = this.MIN_LOAN_AMOUNT;
  step2MaxLimit: number | null = null;
  step2MinBrokerageAmount: number = 0;
  step2MaxBrokerageAmount: number = 999999;
  step2Repayment: string = '';
  step2EstimatedDrawdownDate: Moment = moment();

  AmountFinance!: number;
  rateCard: RatecardDetails | null = null;
  terms: TermRate | null = null;              // available after chart popups (after onGenerateQuote())
  calculator: AppCalculator | null = null;    // available after chart popups (after onGenerateQuote())
  rst: TotalPaymentBreakupDialogData | null = null;    // calculate estimation result (available after chart popups (after onGenerateQuote())
  data: Partial<UpdateApplicationData> | null = null;  // available after chart popups (after onGenerateQuote())
  lastEditedByUser ?: string | null

  user = getUser();
  isInternalUser = isInternalUser(this.user)
  isAdminOrCreditUserOrSalesAM = isAdminOrCreditUserOrSalesAM(this.user);
  isAdminOrCreditUserOrSalesAMOrSalesBDM = isAdminOrCreditUserOrSalesAmOrSalesBDM(this.user);
  isNotAdminOrCreditUser = !isAdminOrCreditUser(this.user);
  isAdmin = isAdmin(this.user);

  isContractPending: boolean = true; // if a contract is pending for this application
  isApplicationStagePostQa = true;

  dataSource: (
    {name: string, highlight?: (value: string | null | undefined) => boolean, type: 'text', value: string | null | undefined} |
    {name: string, highlight?: (value: number | string | null | undefined) => boolean, type: 'currency', value: number | string | null | undefined} |
    {name: string, highlight?: (value: number | string | null | undefined) => boolean, type: 'percentage', value: number | string | null | undefined}
  )[] = [];
  currentTemplate: TemplateRef<any> | null = null;

  formGroupStep2!: FormGroup<{
    loanPurpose: FormControl<LoanPurposeValue>,
    loanTerms: FormControl<LoanTermsSelectionWithInputValue>,
    loanAmount: FormControl<SliderComponentValue>,
    paymentFrequency: FormControl<PaymentFrequencyValue>,
    brokerageAmount: FormControl<CurrencyInputValue>,
    propertyOwner: FormControl<YesNoValue>,
    adverseOnFile: FormControl<YesNoValue>,
    equifaxScore: FormControl<YesNoValue>,
    directorScore: FormControl<YesNoValue>,
    previousLoan: FormControl<YesNoValue>,
    abnGstAgeAboveThreshold: FormControl<YesNoValue>,
    creditRateAdjustment: FormControl<number | null>,
    docFeeFinanced: FormControl<YesNoValue>,
  }>;
  formControlStep2LoanPurpose!: FormControl<LoanPurposeValue>;
  formControlStep2LoanAmount!: FormControl<SliderComponentValue>;
  formControlStep2LoanTerms!: FormControl<LoanTermsSelectionWithInputValue>;
  formControlStep2BrokerageAmount!: FormControl<CurrencyInputValue>;
  formControlStep2PaymentFrequency!: FormControl<PaymentFrequencyValue>;
  formControlStep2PropertyOwner!: FormControl<YesNoValue>;
  formControlStep2AdverseOnFile!: FormControl<YesNoValue>;
  formControlStep2EquifaxScoreAboveThreshold!: FormControl<YesNoValue>;
  formControlStep2DirectorScore!: FormControl<YesNoValue>;
  formControlStep2PreviousLoan!: FormControl<YesNoValue>;
  formControlAbnAgeAboveThreshold!: FormControl<YesNoValue>;
  formControlCreditRateAdjustment!: FormControl<number | null>;
  formControlStep2DocFeeFinanced!: FormControl<YesNoValue>;

  ngOnInit() {
    this.formControlStep2LoanPurpose = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2LoanTerms = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerageAmount = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PaymentFrequency = this.formBuilder.control({ type: 'Weekly', name: 'Weekly' }, [Validators.required]);
    this.formControlStep2PropertyOwner = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2AdverseOnFile = this.formBuilder.control(false, [Validators.required]);
    this.formControlStep2EquifaxScoreAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2DirectorScore = this.formBuilder.control(true, [Validators.required]);
    this.formControlStep2PreviousLoan = this.formBuilder.control(null);
    this.formControlAbnAgeAboveThreshold = this.formBuilder.control(true, [Validators.required]);
    this.formControlCreditRateAdjustment = this.formBuilder.control(0.0, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(true, [Validators.required]);

    this.formGroupStep2 = this.formBuilder.group({
      loanPurpose: this.formControlStep2LoanPurpose,
      loanTerms: this.formControlStep2LoanTerms,
      loanAmount: this.formControlStep2LoanAmount,
      brokerageAmount: this.formControlStep2BrokerageAmount,
      paymentFrequency: this.formControlStep2PaymentFrequency,
      propertyOwner: this.formControlStep2PropertyOwner,
      adverseOnFile: this.formControlStep2AdverseOnFile,
      equifaxScore: this.formControlStep2EquifaxScoreAboveThreshold,
      directorScore: this.formControlStep2DirectorScore,
      previousLoan: this.formControlStep2PreviousLoan,
      abnGstAgeAboveThreshold: this.formControlAbnAgeAboveThreshold,
      creditRateAdjustment: this.formControlCreditRateAdjustment,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
    });

    //populate
    this.populate();

    this.createReadonlyTableDataSource();


    if (this.isNotAdminOrCreditUser) { // not credit or admin
      this.formControlStep2PropertyOwner.disable();
      this.formControlStep2AdverseOnFile.disable();
      this.formControlStep2EquifaxScoreAboveThreshold.disable();
      this.formControlStep2DirectorScore.disable();
      this.formControlStep2PreviousLoan.disable();

      this.formControlAbnAgeAboveThreshold.disable();

      const loanAmount = applicationToLoanAmount(this.application) ?? 0;
      const adjustmentToLoanAmount = (loanAmount * 0.1);
      const maxLoanAmount = _.round(loanAmount + adjustmentToLoanAmount);
      this.step2MaxLimit = maxLoanAmount;

    } else { // credit or admin
      if (this.isAdmin) {
        this.MIN_CREDIT_RATE = -10000;
        this.MAX_CREDIT_RATE = 10000;
      } else {
        this.MIN_CREDIT_RATE = -5;
        this.MAX_CREDIT_RATE = 5;
      }
    }




    // update max limit
    // this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
    //   tap(r => {
    //     this.step2UpdateMaxLimit();
    //   })
    // ).subscribe());

     this.subscriptions.push(this.formControlStep2EquifaxScoreAboveThreshold.valueChanges.pipe(
       tap(r => {
        if(r){
          this.formControlStep2DirectorScore.setValue(false)
        }else{
          this.formControlStep2DirectorScore.setValue(true)
        }
       })
     ).subscribe());

    // this.subscriptions.push(this.formControlStep2DirectorScore.valueChanges.pipe(
    //   tap(r => {
    //     this.step2UpdateMaxLimit();
    //   })
    // ).subscribe());

    this.subscriptions.push(this.formControlStep2LoanAmount.valueChanges.pipe(
      tap(loanAmount => {
        this.step2MaxBrokerageAmount = Math.round((loanAmount ?? 0) * 4) / 100;
      })
    ).subscribe());

    // previous loan validation changes
    this.subscriptions.push(this.formControlStep2PropertyOwner.valueChanges.pipe(
      tap(r => {
        this.step2UpdatePreviousLoanValidation();
      })
    ).subscribe())

    this.subscriptions.push(this.getRateCardDetailsFn(this.application.UserId, 'BusinessLoans').pipe(
      tap(rateCardDetails => {
        this.rateCard = rateCardDetails;
      })
    ).subscribe());


    // get contract status for application
    this.subscriptions.push(this.getContractStatusFn(this.application.ApplicationId).pipe(
      tap(r => {
        const {contractPending} = r.signers.reduce((acc, signer) => {
          const contractPending = ["created", "sent", "delivered", "signed", "completed", "faxpending"].includes(signer.status);
          acc.contractPending = acc.contractPending || contractPending;
          return acc;
        }, { contractPending: false});
        this.isContractPending = contractPending;
      })
    ).subscribe());

    this.isApplicationStagePostQa = ['QA', 'Settlement', 'Closed Lost', 'Closed Lost'].includes(this.application.InteflowStatusStageName ?? '');
  }

  populate() {
    this.formControlStep2LoanPurpose.setValue(applicationToLoanPurpose(this.application));
    this.formControlStep2LoanAmount.setValue(applicationToLoanAmount(this.application));
    this.formControlStep2LoanTerms.setValue(applicationToLoanTermsValue(this.application));
    // this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application));
    this.formControlStep2PaymentFrequency.setValue(applicationToPaymentFrequency(this.application) ??
      PaymentFrequencyValueOptions.find(o => o.type === 'Monthly') ?? null);
    this.formControlStep2PropertyOwner.setValue(applicationToPropertyOwner(this.application));
    this.formControlStep2AdverseOnFile.setValue(applicationToAdverseOnFile(this.application));
    this.formControlStep2EquifaxScoreAboveThreshold.setValue(applicationToEquifaxScoreAboveThreshold(this.application));
    this.formControlStep2DirectorScore.setValue(applicationToDirectorScore(this.application));
    this.formControlStep2PreviousLoan.setValue(applicationToPreviousLoan(this.application));
    this.formControlCreditRateAdjustment.setValue(applicationToCrediRateAdjustment(this.application));
    this.formControlAbnAgeAboveThreshold.setValue(applicationToAbnAgeAboveThreshold(this.application));
    this.formControlStep2DocFeeFinanced.setValue(applicationToDocFeeFinanced(this.application));
    const calculator = new AppCalculator();
    const brokerageAmount = applicationToBrokerageAmount(this.application) == null ?
    calculator.calculateBrokerageAmount(applicationToLoanAmount(this.application) ?? 0, getBrokerage(this.application)):
    applicationToBrokerageAmount(this.application);
    this.formControlStep2BrokerageAmount.setValue(brokerageAmount);
  }

  createReadonlyTableDataSource() {
    this.lastEditedByUser = applicationToLastUpdatedByUser(this.application)?.name;
    const calculator = new AppCalculator();
    const brokerageAmount = applicationToBrokerageAmount(this.application) == null ?
      calculator.calculateBrokerageAmount(applicationToLoanAmount(this.application) ?? 0, getBrokerage(this.application)) :
      applicationToBrokerageAmount(this.application);

    this.dataSource = [
      { name: 'Loan Purpose', type: 'text', value: applicationToLoanPurpose(this.application)?.name},
      { name: 'Loan Amount ($)', type: 'currency', value: applicationToLoanAmount(this.application) },
      { name: 'Loan Terms', type: 'text', value: `${applicationToLoanTerms(this.application)?.type ?? ''} months` },
      { name: 'Payment Frequency', type: 'text', value: applicationToPaymentFrequency(this.application)?.type},
      { name: 'Brokerage ($)', type: 'currency', value: brokerageAmount },

      { name: 'Property Owner', type: 'text', value: booleanToYesNo(applicationToPropertyOwner(this.application)) },
      { name: 'Adverse on file', type: 'text', value: booleanToYesNo(applicationToAdverseOnFile(this.application)) },
      { name: 'Director Equifax Score > 600', type: 'text', value: booleanToYesNo(applicationToEquifaxScoreAboveThreshold(this.application)) },
      { name: 'Director Score 500-550', type: 'text', value: booleanToYesNo(applicationToDirectorScore(this.application)) },
      { name: 'Doc Fee Financed', type: 'text', value: booleanToYesNo(applicationToDocFeeFinanced(this.application))},
      { name: 'Doc Fee ($)', type: 'currency', value: applicationToDocFee(this.application) },

      { name: 'Interest Rate (%)', type: 'percentage', value: getInterestRate(this.application) },
      { name: 'Previous Loan', type: 'text', value: booleanToYesNo(applicationToPreviousLoan(this.application)) },
      { name: 'ABN age > 24 months', type: 'text', value: booleanToYesNo(applicationToAbnAgeAboveThreshold(this.application)) },

      { highlight: (v) => Number(v) != 0, name: 'Credit Rate Adjustment (%)', type: 'percentage', value: applicationToCrediRateAdjustment(this.application) },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes['application'];
    if (change) {
      this.createReadonlyTableDataSource();
    }
  }

  ngAfterViewInit() {
    this.currentTemplate = this.readModeTemplate;
  }

  switchMode() {
    if (this.currentTemplate == this.editModeTemplate) {
      this.currentTemplate = this.readModeTemplate;
    } else {
      this.populate();
      this.currentTemplate = this.editModeTemplate;
    }
  }

  step2UpdatePreviousLoanValidation() {
    const propertyOwner: YesNoValue = this.formControlStep2PropertyOwner.value;
    if (propertyOwner) {
      this.formControlStep2PreviousLoan.clearValidators();
    } else {
      this.formControlStep2PreviousLoan.setValidators([Validators.required]);
    }
    this.formControlStep2PreviousLoan.updateValueAndValidity();
  }

  // private step2UpdateMaxLimit() {
  //   const isPropertyOwner = this.formControlStep2PropertyOwner.value;
  //   const scoreAbove600 = this.formControlStep2EquifaxScoreAboveThreshold.value;
  //   const scoreBetween500And600 = this.formControlStep2DirectorScore.value;
  //   if (isPropertyOwner && scoreAbove600) {
  //     this.step2MaxLimit = 500000;
  //   } else if (!isPropertyOwner && scoreAbove600) {
  //     this.step2MaxLimit = 100000;
  //   } else if (isPropertyOwner && !scoreAbove600 && scoreBetween500And600) {
  //     this.step2MaxLimit = 250000;
  //   } else if (!isPropertyOwner && !scoreAbove600 && scoreBetween500And600) {
  //     this.step2MaxLimit = 50000;
  //   }
  // }

  calculateEstimation(date: Moment): TotalPaymentBreakupDialogData {
    const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const paymentFrequencyType: PaymentFrequencyType = paymentFrequencyValue?.type ?? 'Monthly';
    const loanTermValue: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const loanTermType: LoanTermType | null = loanTermValue ? String(loanTermValue) as LoanTermType : null;
    const loanAmount = numeral(this.formControlStep2LoanAmount.value).value() ?? 0;
    const businessSearchValue = applicationToBusinessSearchValue(this.application);
    const propertyOwner = this.formControlStep2PropertyOwner.value ?? false;
    const brokerageAmount = this.formControlStep2BrokerageAmount.value ?? 0;
    const brokeragePercentage = Math.round((brokerageAmount ?? 0) * 10000 / loanAmount) / 100;
    const brokerageValue: BrokerageSelectionValue = {type: `${brokeragePercentage}` as BrokerageSelectionType, name: `${brokeragePercentage}%`};
    const brokerageType: BrokerageSelectionType = brokerageValue?.type ?? '0';
    const brokerage = Number(brokerageValue?.type ?? 0);
    const adverseOnFile = this.formControlStep2AdverseOnFile.value ?? false;
    const lowEquifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value ?? false;
    const directorScore = this.formControlStep2DirectorScore.value ?? false;
    const abnGstAge = this.createAbnGstAge();
    const creditRateAdjustment = this.formControlCreditRateAdjustment.value ?? 0;
    const docFeeFinanced = this.formControlStep2DocFeeFinanced.value ?? false;


    const r = calculateBusinessLoanEstimation(date, {
      type: 'BusinessLoans',
      paymentFrequencyType,
      loanTermType,
      brokerageType,
      loanAmount,
      businessSearchValue: businessSearchValue ? { // handle ABN Age > 24 months
        ...businessSearchValue,
        result: {
          ABNAgeMonths: abnGstAge,
          GSTAgeMonths: abnGstAge,
        },
      }: {} as any /* HACK */,
      propertyOwner,
      brokerage,
      brokerageAmount,
      adverseOnFile,
      lowEquifaxScore,
      directorScore,
      rateCard: this.rateCard,
      bureauReport: this.application.CompanyDetails ? {
        ...this.application.CompanyDetails,
        ABNAgeMonths: abnGstAge,
        GSTAgeMonths: abnGstAge,
      }: {} as any /* HACK */,
      creditRateAdjustment,
      docFeeFinanced,
      repaymentType : getRepaymentType(this.application) ?? '',
    });
    this.calculator = r.calculator;
    this.step2Repayment = String(r.repayment);
    this.terms = r.terms
    this.data = this.createApplicationData();
    this.rst = r.totalPaymentBreakupDialogData
    return r.totalPaymentBreakupDialogData;
  }

  // calculateEstimationBak(date: Moment): TotalPaymentBreakupDialogData {
  //   const paymentFrequencyValue: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
  //   const loanTermValue: LoanTermValue = this.formControlStep2LoanTerms.value;
  //   const brokerageValue: BrokerageSelectionValue = this.formControlStep2Brokerage.value;
  //   const calculator = new AppCalculator();
  //   const loanAmount = numeral(this.formControlStep2LoanAmount.value).value() ?? 0;
  //   const businessSearchValue = applicationToBusinessSearchValue(this.application);
  //   const abnGstAge = this.createAbnGstAge();
  //   const lowEquifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
  //   const adverseOnFile = this.formControlStep2AdverseOnFile.value;
  //   const propertyOwner = this.formControlStep2PropertyOwner.value;
  //   const directorScore = this.formControlStep2DirectorScore.value;
  //   const creditRateAdjustment = this.formControlCreditRateAdjustment.value;
  //   const bureauReport = businessSearchValue ? {   // handle ABN Age > 24 months
  //     ...businessSearchValue,
  //     result: {
  //       ABNAgeMonths: abnGstAge,
  //       GSTAgeMonths: abnGstAge,
  //     }
  //   } : {} as any /* HACK */

  //   const formData: FormDataForBusinessFinance = {
  //     loanAmount,
  //     lowEquifaxScore: lowEquifaxScore!,
  //     adverseOnFile: adverseOnFile!,
  //     brokerage: Number(brokerageValue?.type ?? 0),
  //     propertyOwner: propertyOwner!,
  //     loanTerms: Number(loanTermValue?.type ?? 0),
  //     directorScore: directorScore!,

  //     // extra field that affects estimation
  //     creditRateAdjustment: creditRateAdjustment ?? 0, // handle creditRateAdjustment
  //   };

  //   this.terms = calculator.getTotalInterestRateForBusinessFinance(
  //     this.rateCard!,
  //     formData,
  //     bureauReport,
  //   );
  //   calculator.setRepaymentType('arrears');
  //   calculator.setRepaymentFrequency(paymentFrequencyValue?.type ?? 'Monthly');
  //   calculator.setPrinicipalValue(this.terms.prinicipalAmount);
  //   calculator.setInterestValue(this.terms.totalInterest);
  //   calculator.setloanValue(Number(loanTermValue?.type ?? '0'));
  //   calculator.setResidualAmount(this.terms.RV);
  //   calculator.setBrokeragePercent(Number(brokerageValue?.type ?? '0'));
  //   calculator.setBrokerageAmount(this.terms.brokerageAmount)
  //   calculator.setDateValue(date.format('DD/MM/YYYY'));
  //   calculator.refreshUI();
  //   this.calculator = calculator;

  //   this.step2Repayment = String(calculator.emiAmt);
  //   const rst1 = calculator.getDonutChartData();
  //   const rst2 = calculator.getBarChartYearlyData();
  //   const rst3 = calculator.getBarChartQuaterlyData();
  //   const rst4 = calculator.getRepaymentEstimationData2(
  //     this.terms,
  //     paymentFrequencyValue?.type ?? 'Monthly',
  //     'advance',
  //   );
  //   const rst5 = calculator.getAmortisationData();
  //   const x: TotalPaymentBreakupDialogData = {
  //     repaymentEstimationData: rst4.map((rst) => ({
  //       amountFinance: String(rst.amountFinance),
  //       month24: String(rst.data.find((d) => d.loanTerm == 24)?.repayment ?? 0),
  //       month36: String(rst.data.find((d) => d.loanTerm == 36)?.repayment ?? 0),
  //       month48: String(rst.data.find((d) => d.loanTerm == 48)?.repayment ?? 0),
  //       month60: String(rst.data.find((d) => d.loanTerm == 60)?.repayment ?? 0),
  //     })),
  //     amortizationScheduleData: rst5.map(rst => ({
  //       year: String(rst.year), payment: String(rst.yearTotal), interest: String(rst.yearInterest), principal: String(rst.yearPrincipal), balance: String(rst.endingBalance),
  //       details: rst.childRows.map(d => ({
  //         monthYear: `${d.month} ${d.year}`, payment: String(d.emi), interest: String(d.interest),
  //         principal: String(d.pricipalPaid), balance: String(d.endingBalance)
  //       }))
  //     })),
  //     paymentChartData: {
  //       amountFinanced: rst1.pricipalAmt,
  //       totalInterest: this.terms.totalInterest,
  //       emiAmt: calculator.emiAmt,
  //       paymentFrequency: calculator.repaymentFrequency,
  //       principalAmt: calculator.principalAmt,
  //       interestAmt: calculator.interestAmt,
  //       totalAmt: calculator.totalAmt,
  //       loanTerm: calculator.loanValue, // loanValue from calculator
  //       lvr: this.terms.LVR,
  //       rv: this.terms.RV,
  //       brokerageAmount: calculator.brokerageAmount,
  //       docFee: this.terms.docFee,
  //       brokerOriginationFee: this.terms.brokerOriginationFee,
  //       applicationType: 'BusinessLoans'
  //     },
  //     amortizationChartData: {
  //       estimatedDrawdownDate: moment(calculator.dateValue),
  //       annualData: rst2.map(rst => ({
  //         year: String(rst.year), interest: rst.yearInterest, principal: rst.yearPrincipal, balance: rst.endingBalance
  //       })),
  //       quarterlyData: rst3.map(rst => ({
  //         quarter: `Q${rst.quarter} ${rst.year}`, balance: rst.endingBalance, principal: rst.yearPrincipal, interest: rst.yearInterest
  //       }))
  //     },
  //     isBusinessLoan: true
  //   };
  //   this.data = this.createApplicationData();
  //   this.rst = x;
  //   return x;
  // }

  createAbnGstAge(): number {
    const abnGstAboveThreashold = this.formControlAbnAgeAboveThreshold.value;
    return abnGstAboveThreashold ? 999 : 1;
  }


  createApplicationData() {
    // NOTE: changes done here needs to be done in business-finance.page.ts as well
    const abnGstAge = this.createAbnGstAge();
    const companyDetails =
      {
        ...this.application.CompanyDetails,
        ABNAgeMonths: abnGstAge,
        GSTAgeMonths: abnGstAge,
      };

    // pricing details
    const loanTerm: LoanTermsSelectionWithInputValue = this.formControlStep2LoanTerms.value;
    const loanAmount = _.round(this.formControlStep2LoanAmount.value ?? 0, 2);
    const brokerageAmount = this.formControlStep2BrokerageAmount.value;
    const brokeragePercentage = Math.round((brokerageAmount ?? 0) * 10000 / loanAmount) / 100;
    const brokerage: BrokerageSelectionValue = {type: `${brokeragePercentage}` as BrokerageSelectionType, name: `${brokeragePercentage}%`};
    const paymentPeriod: PaymentFrequencyValue = this.formControlStep2PaymentFrequency.value;
    const docFee = this.terms?.docFee ?? undefined;
    const brokerOriginationFee = this.terms?.brokerOriginationFee ?? 0;
    const propertyOwner = this.formControlStep2PropertyOwner.value;
    const adverseOnFile = this.formControlStep2AdverseOnFile.value;
    const equifaxScore = this.formControlStep2EquifaxScoreAboveThreshold.value;
    // const rate = this.terms?.totalInterest ?? undefined;
    const loanPurpose: LoanPurposeValue = this.formControlStep2LoanPurpose.value;
    const repayment = this.calculator?.emiAmt ?? undefined;
    const directorScoreRate = this.formControlStep2DirectorScore.value
    const rate = this.terms ? this.terms?.totalInterest : getInterestRate(this.application);
    const previousLoan = this.formControlStep2PreviousLoan.value;
    const docFeeFinanced = !!this.formControlStep2DocFeeFinanced.value;

    let apiBodyPricingDetails = {
      ...this.application.PricingDetails,
      LoanTerm: loanTerm ?? undefined,
      Brokerage: brokerage ? numeral(brokerage.type).value() ?? undefined : undefined,
      BrokerageAmount: brokerageAmount,
      BrokerOriginationFee: brokerOriginationFee,
      LoanAmount: loanAmount,
      AmountFinance: loanAmount,
      PaymentPeriod: paymentPeriod ? paymentPeriod.name : undefined,
      DocFee: (docFee != null ? Number(docFee) : undefined),
      DocFeeFinanced: booleanToYesNo(docFeeFinanced),
      EquifaxScoreAbove600: booleanToYesNo(equifaxScore),
      DirectorScoreRate: booleanToYesNo(directorScoreRate),
      Repayment: repayment,
      PropertyOwner: booleanToYesNo(propertyOwner),
      AdverseOnFile: booleanToYesNo(adverseOnFile),
      Rate: rate,
      CreditRateAdjustment: Number(this.formControlCreditRateAdjustment.value)
    }

    if (!propertyOwner) {
      apiBodyPricingDetails = {
        ...apiBodyPricingDetails,
        PreviousLoan: booleanToYesNo(previousLoan)
      }
    }

    const apiBodyAppInfo = {
      ...this.application.AppInfo,
      LoanPurpose: loanPurpose?.name ?? undefined,
    }

    const apiBody: Partial<UpdateApplicationData> = {
      UpdateApplicationIgnoreStatus: true,
      PricingDetails: apiBodyPricingDetails as any,
      CompanyDetails: companyDetails as any,
      AppInfo: apiBodyAppInfo,
    }
    return apiBody;
  }

  save() {
    if (this.terms) {
      this.subscriptions.push(this.updateApplicationSfFn(this.application.ApplicationId, this.data as UpdateApplicationData).pipe(
        this.toastService.spinnerObservable(),
        this.toastService.snackBarObservable(`Rate updated`),
        tap(r => {
          if (r.status) {
            this.events.emit({
              type: "business-pricing-saved",
              rst: this.rst!,
            });
            this.currentTemplate = this.readModeTemplate;
          }
        })
      ).subscribe());
    } else {
      this.applicationDialogService.openAlertDialog({
        message: 'Error',
        subMessage: `Cannot saved without performing calculation first`,
      });
    }
  }

  onGenerateQuote() {
    this.step2Repayment = '12000';
    this.step2EstimatedDrawdownDate = moment().add(1, 'day');
    const date = moment();
    const subscription = this.applicationDialogService.openBusinessPricingComparisonDialog({
      currentValue: this.currentRstValue,
      newValue: this.calculateEstimation(date)
    }).afterClosed()
      .pipe(
        tap(r => {
          if (r && r.acceptedQuotation) {
            // update the doc fee
            this.terms!.docFee = r.docFee;
            // patch current rst docfee, save will emit events that business-finance-application-details component subscribe
            this.rst!.paymentChartData.docFee = r.docFee;
            // recreate application data for submission
            this.data = this.createApplicationData();
            this.save();
          }
        })
      ).subscribe();
    this.subscriptions.push(subscription);
  }

  canGenerateQuote(): boolean {
    const canGenerate = (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM && this.formGroupStep2.valid &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
    if (!canGenerate) {
      this.markSubject.next(true);
    }
    return canGenerate;
  }


  canEditPricing(): boolean {
    return (
      this.isAdminOrCreditUserOrSalesAMOrSalesBDM &&
      (!this.isApplicationStagePostQa) && (!this.isContractPending)
    );
  }

}
