<div class="paginator" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-md="column" [fxLayoutGap.lt-md]="pxToRem(5)">
  <div class="page-size" fxLayout="row" fxLayoutAlign="center center">
    <div>Items per page</div>

    <form class="select" [formGroup]="form">
      <mat-form-field>
        <mat-select formControlName="pageSize">
          @for (size of pageSizeOptions; track size) {
            <mat-option [value]="size">
              {{size}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>

    <div> {{pageIndex * pageSize + 1}} - {{Math.min((pageIndex + 1) * pageSize, length)}}  of {{length}} </div>
  </div>

  <div class="page-numbers" fxLayout="row" fxLayoutAlign="center center">
    <div class="block" (click)="onClickPage(1)"><span class="mdi mdi-page-first"></span></div>
    <div class="block" (click)="onClickPreviousPage()"><span class="mdi mdi-chevron-left"></span></div>
    @for (pageNumber of pageNumbers; track pageNumber) {
      <div class="block block-page-number" (click)="onClickPage(pageNumber)" [ngClass]="{'block-highlight': pageIndex + 1 === pageNumber}">{{pageNumber}}</div>
    }
    <div class="block" (click)="onClickNextPage()"><span class="mdi mdi-chevron-right"></span></div>
    <div class="block" (click)="onClickPage(maxPageIndex)"><span class="mdi mdi-page-last"></span></div>
  </div>
</div>
