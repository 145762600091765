import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {
  CreateOverdraftAccountDialogData,
  CreateOverdraftAccountDialogResult,
  CreateOverdraftAccountFn,
  CustomerOpportunity,
  CustomerUser,
  GetAccountDetailsFromSfFn,
  GetOpportunitiesForCustomerFn,
  PismoCreateAccountResponse,
  SfAccountDetails,
  PismoAccountMapping,
  getPismoProgramsFn,
  PismoProgram,
  PismoProgramDueDateResponse,
  GetPismoProgramDueDate,
  PismoProgramDueDate,
  encodePismoAddress,
  PayloadApiResponse,
  Address2ComponentValue,
  NotNullable,
  SyncBankDetailsToSfFn,
  OverdraftAccountBalanceType,
  GetRbaRateFn,
  LocalBankAccountDetailValue,
  BpayComponentValue,
  GetBillerNameFn,
  GetInstitutionNameFn,
  Payout,
} from '@portal-workspace/grow-shared-library';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule, FormArray } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatChipsModule } from '@angular/material/chips';
import { combineLatest, debounceTime, Subscription, tap } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LooseCurrencyPipe } from '../../pipes/loose-currency.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatTableModule } from '@angular/material/table';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { NgTemplateOutlet, DecimalPipe } from '@angular/common';
import { ApplicationStepper2Component } from '../application-stepper-component/application-stepper2.component';
import {
  setupUntilDestroy,
  PortalHotToastService,
  ApplicationDialogService,
  setNextButtonText,
  setPrevNextButtonVisibilityFn, setSecondaryButtonFn, setSecondaryButtonText,
  setSubmitStepFn,
  formControlErrorKeys, formControlErrorMessage,
  setStepper2StepConfig
} from '@portal-workspace/grow-ui-library';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BpayBankDetailsComponent } from '../bpay-bank-details-component/bpay-bank-details.component';
import { LocalBankAccountDetailComponent } from '../local-bank-account-detail-component/local-bank-account-detail.component';
import {PercentagePipe} from '../../pipes/percentage.pipe';

export type PayoutFormGroup = FormGroup<{
  type: FormControl<'bpay' | 'bank-transfer' | null>,
  amount: FormControl<number | null>,
  reference: FormControl<string | null>,
  bankTransfer: FormControl<LocalBankAccountDetailValue | null>,
  bpay: FormControl<BpayComponentValue | null>
  // accountName: FormGroup<string | null>,
  // bsb: FormGroup<string | null>,
  // accountNumber: FormGroup<string | null>,
  // crn: FormGroup<string | null>,
  // billerCode: FormGroup<string | null>,
  // billerName: FormGroup<string | null>,
}>

@UntilDestroy()
@Component({
    templateUrl: './create-overdraft-account.dialog.html',
    styleUrls: ['./create-overdraft-account.dialog.scss'],
    standalone: true,
    imports: [ApplicationStepper2Component, CdkStepperModule, MatOptionModule, MatSelectModule, NgTemplateOutlet, FormsModule, MatButtonModule, ReactiveFormsModule, FlexModule, MatTooltipModule, MatFormFieldModule, MatInputModule, InputMaskModule, MatTableModule, MatCheckboxModule, MatChipsModule, PdfViewerModule, DecimalPipe, LooseCurrencyPipe, MatDialogModule, BpayBankDetailsComponent, LocalBankAccountDetailComponent,PercentagePipe]
})
export class CreateOverdraftAccountDialog implements OnInit{

  getOpportunitiesForCustomerFn!: GetOpportunitiesForCustomerFn;
  getAccountDetailsFromSfFn!: GetAccountDetailsFromSfFn;
  // getPismoAccountDetailsFn!: GetPismoAccountDetailsFn;
  createOverdraftAccountFn!: CreateOverdraftAccountFn;
  getPismoProgramsFn!: getPismoProgramsFn;
  getPismoProgramDueDate!: GetPismoProgramDueDate;
  syncBankDetailsToSfFn!: SyncBankDetailsToSfFn;
  getRbaRateFn!: GetRbaRateFn;
  getBillerNameFn!: GetBillerNameFn;
  getInstitutionNameFn!: GetInstitutionNameFn;
  customer: CustomerUser | null = null;
  opportunitySelections: CustomerOpportunity[] = [];
  programSelections : PismoProgram[] = [];
  subscriptions: Subscription[] = [];
  existingPismoAccountMappings: PismoAccountMapping[] = [];
  customerSfDetails!: SfAccountDetails;
  brokerSfDetails!: SfAccountDetails;
   
  
  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;
  // pismoAccountNumber!: number;
  pismoDueDate: PismoProgramDueDate[] = []

  // step 1
  formGroupStep1!: FormGroup;
  formControlStep1Opportunity!: FormControl<CustomerOpportunity | null>;
  formControlStep1Program!: FormControl<PismoProgram | null>;
  formControlStep1DueDate!: FormControl<PismoProgramDueDate | null>


  // step 2
  formGroupStep2!: FormGroup;
  formControlStep2Address!: FormControl<string | null>;
  formControlStep2OverdraftLimit!: FormControl<number | null>;
  formControlStep2PismoProgramId!: FormControl<number | null>;
  formControlStep2DueDate!: FormControl<PismoProgramDueDate | null>;
  formControlStep2Rate!: FormControl<number | null>;
  formControlStep2Margin!: FormControl<number | null>;
  formControlStep2DocFee!: FormControl<number| null>;
  formControlStep2DocFeeFinanced!: FormControl<boolean | null>;
  formControlStep2FacilityEstablishmentFee!: FormControl<number| null>;
  formControlStep2Term!: FormControl<number| null>;
  formControlStep2Brokerage!: FormControl<number| null>;
  formControlStep2MonthlyFacilityFee!: FormControl<number| null>;
  formControlStep2MonthlyFacilityFeePercentage!: FormControl<number| null>;
  formControlStep2CustomerName!: FormControl<string | null>;
  formControlStep2CustomerAccountName!: FormControl<string | null>;
  formControlStep2CustomerBsb!: FormControl<string | null>;
  formControlStep2CustomerAccountNumber!: FormControl<string | null>;
  formControlStep2CustomerFinancialInstitutionName!: FormControl<string | null>;
  formControlStep2BrokerName!: FormControl<string | null>;
  formControlStep2BrokerAccountName!: FormControl<string | null>;
  formControlStep2BrokerBsb!: FormControl<string | null>;
  formControlStep2BrokerAccountNumber!: FormControl<string | null>;
  formControlStep2BrokerFinancialInstitutionName!: FormControl<string | null>;
  feePostingStatusStep2 = false;

  // step 3
  formGroupStep3!: FormGroup;
  formControlStep3AccountBalanceExistence!: FormControl<boolean | null>;
  formControlStep3AccountBalanceType!: FormControl<OverdraftAccountBalanceType | null>;
  formControlStep3AccountBalance!: FormControl<number | null>;

  // step 4
  formGroupStep5!: FormGroup;
  formControlStep5PayoutExistence!: FormControl<boolean | null>;
  formControlStep5Payout!: FormArray<PayoutFormGroup>;

  // step 5
  formGroupStep6!: FormGroup;

  // step 6
  formGroupStep4!: FormGroup;
  formControlStep4SecurityValuationFeeExistence!: FormControl<boolean | null>;
  formControlStep4SecurityValuationFee!: FormControl<number | null>;

  // step 7
  formGroupstep7!: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreateOverdraftAccountDialogData,
    private dialogRef: MatDialogRef<CreateOverdraftAccountDialog, CreateOverdraftAccountDialogResult>,
    private formBuilder: FormBuilder,
    private toastService: PortalHotToastService,
    private applicationDialogService: ApplicationDialogService,
  ) {
      // this.customer = data.customer;
      this.existingPismoAccountMappings = data.existingPismoAccountMappings;
      this.getOpportunitiesForCustomerFn = data.getOpportunitiesForCustomerFn;
      this.getAccountDetailsFromSfFn = data.getAccountDetailsFromSfFn;
      this.createOverdraftAccountFn = data.createOverdraftAccountFn;
      this.getPismoProgramsFn = data.getPismoProgramsFn;
      this.getPismoProgramDueDate = data.getPismoProgramDueDate;
      this.syncBankDetailsToSfFn = data.syncBankDetailsToSfFn;
      // this.getPismoAccountDetailsFn = data.getPismoAccountDetailsFn;
      this.getRbaRateFn = data.getRbaRateFn;
      this.getBillerNameFn = data.getBillerNameFn;
      this.getInstitutionNameFn = data.getInstitutionNameFn;
  }

  ngOnInit(): void {
    setupUntilDestroy(this);

    this.initStep1();
    this.initStep2();
    this.initStep3();
    this.initStep4();
    this.initStep5();
    this.initStep6();
    this.initstep7();
    this.populate();
    this.populateProgram();

  }

  populateProgram() {
    this.subscriptions.push(
      this.getPismoProgramsFn().pipe(
        this.toastService.spinnerObservable(),
      ).subscribe((program: PismoProgram[] ) => {
        this.programSelections = program
      })
    )
  }

  populate() {
    this.subscriptions.push(this.data.getCustomerFn(this.data.customerId).pipe(
      this.toastService.spinnerObservable(),
      tap(r => {
       this.customer = r;
       if (this.customer && this.customer.SalesforceId) {
           this.subscriptions.push(
             this.getOpportunitiesForCustomerFn(this.customer.SalesforceId).pipe(
                 this.toastService.spinnerObservable(),
             ).subscribe((opportunities: CustomerOpportunity[]) => {
               this.opportunitySelections = opportunities.filter(o => o.RecordTypeId === this.data.overdraftCustomerSfRecordId && o.Stage === this.data.overdraftSfOpportunityStage);
               console.log('this.opportunitySelections: ', this.opportunitySelections);
             })
           )
       }
     })
    ).subscribe());
  }

  initStep1() {
    this.formControlStep1Opportunity = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1Program = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep1DueDate = this.formBuilder.control(null, [Validators.required]);
    this.formGroupStep1 = this.formBuilder.group({
      opportunity: this.formControlStep1Opportunity,
      program: this.formControlStep1Program,
      dueDate: this.formControlStep1DueDate
    });

    const sub = this.formControlStep1Program.valueChanges.pipe(
      tap(r => {
        this.getPismoProgramDueDate(this.formControlStep1Program.value?.programId!).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((program: PismoProgramDueDateResponse ) => {
          this.pismoDueDate = program?.pagination?.items
        })
      })
    ).subscribe();
    this.subscriptions.push(sub);

    setStepper2StepConfig(this.formGroupStep1, {
      nextStepClickedFn: async stepper => {
        if (this.formGroupStep1.valid) {
          const opportunity = this.formControlStep1Opportunity.value as CustomerOpportunity;
          const program = this.formControlStep1Program.value as PismoProgram;
          const dueDate = this.formControlStep1DueDate.value as PismoProgramDueDate;
  
  
          this.subscriptions.push(
            combineLatest([
              this.getAccountDetailsFromSfFn(this.customer?.SalesforceId ?? ''),
              this.getAccountDetailsFromSfFn(this.formControlStep1Opportunity.value?.BrokerSalesforceId ?? ''),
              this.getRbaRateFn(),
            ]).pipe(
              this.toastService.spinnerObservable(),
            ).subscribe(([customerDetails, brokerDetails, rbaRate]: [SfAccountDetails, SfAccountDetails, number]) => {
              console.log('======customersfDetails: ', customerDetails);
              console.log('======brokerDetails: ', brokerDetails);
              console.log('======rbaRate: ', rbaRate);
              this.customerSfDetails = customerDetails;
              this.brokerSfDetails = brokerDetails;
              this.formControlStep2Address.setValue(this.customer?.Address?.RawAddress ?? null);
              this.formControlStep2CustomerName.setValue(customerDetails.Name);
              // use bank details on the opportunity level
              if (opportunity.BankAccountNumber) {
                this.formControlStep2CustomerAccountName.setValue(opportunity.BankAccountName);
                this.formControlStep2CustomerBsb.setValue(opportunity.BankAccountBsb);
                this.formControlStep2CustomerAccountNumber.setValue(opportunity.BankAccountNumber);
                this.formControlStep2CustomerFinancialInstitutionName.setValue(opportunity.FinancialInstitutionName);
              } else { // if no bank details on the opportunity level, we use the bank details on account level
                this.formControlStep2CustomerAccountName.setValue(customerDetails.AccountName);
                this.formControlStep2CustomerBsb.setValue(customerDetails.BSB);
                this.formControlStep2CustomerAccountNumber.setValue(customerDetails.AccountNumber);
                this.formControlStep2CustomerFinancialInstitutionName.setValue(customerDetails.InstitutionName);
              }
              // if bank details are on account level, sync it to customer level
              if (opportunity.BankAccountNumber && customerDetails.Id) {
                this.syncBankDetailsToSfFn({
                  salesforceId: customerDetails.Id,
                  financialInstitution: opportunity.FinancialInstitutionName,
                  bankAccountName: opportunity.BankAccountName,
                  bankAccountBsb: opportunity.BankAccountBsb,
                  bankAccountNumber: opportunity.BankAccountNumber,
                }).subscribe()
              }
  
              this.formControlStep2BrokerName.setValue(brokerDetails.Name);
              this.formControlStep2BrokerAccountName.setValue(brokerDetails.AccountName);
              this.formControlStep2BrokerBsb.setValue(brokerDetails.BSB);
              this.formControlStep2BrokerAccountNumber.setValue(brokerDetails.AccountNumber);
              this.formControlStep2BrokerFinancialInstitutionName.setValue(brokerDetails.InstitutionName);
  
              this.formControlStep2OverdraftLimit.setValue(opportunity.Amount);
              this.formControlStep2PismoProgramId.setValue(program.programId);
              this.formControlStep2DueDate.setValue(dueDate)
              this.formControlStep2Margin.setValue(opportunity.Margin);
              this.formControlStep2Rate.setValue((opportunity.Margin ?? 0) + rbaRate);
              this.formControlStep2DocFee.setValue(opportunity.DocFee);
              this.formControlStep2DocFeeFinanced.setValue(opportunity.DocFeeFinanced);
              this.formControlStep2FacilityEstablishmentFee.setValue(opportunity.FacilityEstablishmentFee);
              this.formControlStep2Term.setValue(opportunity.Term);
              this.formControlStep2Brokerage.setValue(opportunity.Brokerage);
              this.formControlStep2MonthlyFacilityFee.setValue(opportunity.MonthlyFacilityFee);
              this.formControlStep2MonthlyFacilityFeePercentage.setValue(opportunity.MonthlyFacilityFeePercentage);
              stepper.next();
            })
          )
        }
      },
      previousStepButtonText:"Close",
      previousStepClickedFn: async (stepper) => {
        this.onClickClose()
      }
    });

 
  
  }

  initStep2() {
    this.formControlStep2OverdraftLimit = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2PismoProgramId = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DueDate = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Rate = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Margin = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DocFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2DocFeeFinanced = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2FacilityEstablishmentFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Term = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2Brokerage = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2MonthlyFacilityFee = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2MonthlyFacilityFeePercentage = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerAccountName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerBsb = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerAccountNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2CustomerFinancialInstitutionName = this.formBuilder.control(null);
    this.formControlStep2BrokerName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerAccountName = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerBsb = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerAccountNumber = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep2BrokerFinancialInstitutionName = this.formBuilder.control(null);
    this.formControlStep2Address = this.formBuilder.control(null, [Validators.required]);

    this.formGroupStep2 = this.formBuilder.group({
      //opportunity: this.formControlStep1Opportunity,
      rawAddress: this.formControlStep2Address,
      overdraftLimit: this.formControlStep2OverdraftLimit,
      pismoProgramId: this.formControlStep2PismoProgramId,
      pismoDueDate: this.formControlStep2DueDate,
      rate: this.formControlStep2Rate,
      margin: this.formControlStep2Margin,
      docFee: this.formControlStep2DocFee,
      docFeeFinanced: this.formControlStep2DocFeeFinanced,
      facilityEstablishmentFee: this.formControlStep2FacilityEstablishmentFee,
      term: this.formControlStep2Term,
      brokerage: this.formControlStep2Brokerage,
      monthlyFacilityFee: this.formControlStep2MonthlyFacilityFee,
      monthlyFacilityFeePercentage: this.formControlStep2MonthlyFacilityFeePercentage,
      //customerName: this.formControlStep2CustomerName,
      customerAccountName: this.formControlStep2CustomerAccountName,
      customerBsb: this.formControlStep2CustomerBsb,
      customerAccountNumber: this.formControlStep2CustomerAccountNumber,
      customerFinancialInstitutionName: this.formControlStep2CustomerFinancialInstitutionName,
      brokerName: this.formControlStep2BrokerName,
      brokerAccountName: this.formControlStep2BrokerAccountName,
      brokerBsb: this.formControlStep2BrokerBsb,
      brokerAccountNumber: this.formControlStep2BrokerAccountNumber,
      brokerFinancialInstitutionName: this.formControlStep2BrokerFinancialInstitutionName,
    });

    setStepper2StepConfig(this.formGroupStep2, {
      nextStepClickedFn: async stepper => {
        console.log(this.formGroupStep2.value);
        console.log(this.formControlStep1Opportunity.value)
        const opportunityId = this.formControlStep1Opportunity.value?.SalesforceId ?? '';
  
        if (!this.customer) {
          this.applicationDialogService.openAlertDialog({
            message: `Warning`,
            subMessage: `Failed to load customer details.`,
          })
          return;
        }
  
        const existingMapping = this.existingPismoAccountMappings.find(mapping => mapping.opportunityId == opportunityId);
        if (existingMapping) {
          this.applicationDialogService.openAlertDialog({
            message: `Warning`,
            subMessage: `You have created an overdraft account for this opportunity.`,
          })
          return;
        }
  
        stepper.next();
      },
    });

     
  }

  initStep3() {
    this.formControlStep3AccountBalanceExistence = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep3AccountBalanceType = this.formBuilder.control(null);
    this.formControlStep3AccountBalance = this.formBuilder.control(null);
    

    this.formGroupStep3 = this.formBuilder.group({
      accountBalanceExistence: this.formControlStep3AccountBalanceExistence,
      accountBalanceType: this.formControlStep3AccountBalanceType,
      accountBalance: this.formControlStep3AccountBalance,
    });

    this.subscriptions.push(
      this.formControlStep3AccountBalanceExistence.valueChanges.subscribe((value: boolean | null) => {
        if (value) {
          this.formControlStep3AccountBalanceType.setValidators([Validators.required]);
          this.formControlStep3AccountBalance.setValidators([Validators.required, Validators.min(0.01)]);
          this.formControlStep3AccountBalanceType.updateValueAndValidity();
          this.formControlStep3AccountBalance.updateValueAndValidity();
        } else {
          this.formControlStep3AccountBalanceType.setValidators(null);
          this.formControlStep3AccountBalance.setValidators(null);
          this.formControlStep3AccountBalanceType.updateValueAndValidity();
          this.formControlStep3AccountBalance.updateValueAndValidity();
        }
      })
    )

    // this.subscriptions.push(
    //   combineLatest([
    //     this.formControlStep3AccountBalanceExistence.valueChanges,
    //     this.formControlStep3AccountBalanceType.valueChanges
    //   ]).subscribe(([accountBalanceExistence, accountBalanceType]: [boolean | null, OverdraftAccountBalanceType | null]) => {
    //     if (accountBalanceExistence && accountBalanceType === 'debit') {
    //       this.setNextButtonText(this.formGroupStep3, 'Next');
    //     } else {
    //       this.setNextButtonText(this.formGroupStep3, 'Create Account');
    //     }
    //   })
    // )

    setStepper2StepConfig(this.formGroupStep3, {
      nextStepButtonText:"Next",
      nextStepClickedFn: async stepper => {
        stepper.next();
      },
    });

  }

  initStep4() {
    this.formControlStep4SecurityValuationFeeExistence = this.formBuilder.control(null, [Validators.required]);
    this.formControlStep4SecurityValuationFee = this.formBuilder.control(null);    

    this.formGroupStep4 = this.formBuilder.group({
      securityValuationFeeExistence: this.formControlStep4SecurityValuationFeeExistence,
      securityValuationFee: this.formControlStep4SecurityValuationFee,
    });

    this.subscriptions.push(
      this.formControlStep4SecurityValuationFeeExistence.valueChanges.subscribe((value: boolean | null) => {
        if (value) {
          this.formControlStep4SecurityValuationFee.setValidators([Validators.required]);
          this.formControlStep4SecurityValuationFee.updateValueAndValidity();
        } else {
          this.formControlStep4SecurityValuationFee.setValidators(null);
          this.formControlStep4SecurityValuationFee.updateValueAndValidity();
        }
      })
    )

    setStepper2StepConfig(this.formGroupStep4, {
      nextStepButtonText:"Next",
      nextStepClickedFn: async stepper => {
        stepper.next();
      },
    });
  }

  initStep5() {
    let setNextButtonText = "Next"
    this.formControlStep5Payout = this.formBuilder.array<PayoutFormGroup>([]);
    this.formControlStep5PayoutExistence = this.formBuilder.control(null, [Validators.required]);

    this.formGroupStep5 = this.formBuilder.group({
      payout: this.formControlStep5Payout,
      payoutExistence: this.formControlStep5PayoutExistence,
    });

    this.subscriptions.push(
      this.formControlStep5PayoutExistence.valueChanges.subscribe((value: boolean | null) => {
        if (value) {
          this.addPayout();
        
        } else {
          this.clearPayout();
          setNextButtonText = 'Next';
        }
      })
    )


    setStepper2StepConfig(this.formGroupStep5, {
      nextStepButtonText:setNextButtonText,
      nextStepClickedFn: async stepper => {
        if (this.formControlStep5PayoutExistence.value) {
          if (this.formControlStep5Payout.valid) {
            console.log(this.formControlStep5Payout.value)
            const limit = this.formControlStep2OverdraftLimit.value ?? 0;
            const debit = this.formControlStep3AccountBalanceExistence.value && this.formControlStep3AccountBalanceType.value === 'debit' ? (this.formControlStep3AccountBalance.value ?? 0) : 0;
            const securityAndLegalFee = this.formControlStep4SecurityValuationFeeExistence.value ? (this.formControlStep4SecurityValuationFee.value ?? 0) : 0;
            const totalPayout = this.formControlStep5Payout.value.reduce((a, b) => a + (b?.amount ?? 0), 0);
            const maxAmount = Math.round(limit - debit - securityAndLegalFee) * 100 / 100
  
            if (totalPayout > maxAmount) {
              this.applicationDialogService.openAlertDialog({
                message: `Warning`,
                subMessage: `The payout amount if over limit. The max payout allowed is $${maxAmount}. Please decrease your payout amount`,
              });
            } else {
              stepper.next();
            }
          }
        } else {
          this.createOverdraftAccount(stepper);
        }
      },
    });
 
  }

  initStep6() {
    this.formGroupStep6 = this.formBuilder.group({});

    setStepper2StepConfig(this.formGroupStep6, {
      nextStepClickedFn: async stepper => {
        this.createOverdraftAccount(stepper);
      },
      nextStepButtonText:"Confirm and Create Account"
    });
  }

 

  initstep7() {
    this.formGroupstep7 = this.formBuilder.group({});
    setStepper2StepConfig(this.formGroupstep7, {
      nextStepClickedFn: async stepper => {
        this.dialogRef.close(true);
      },
      nextStepButtonText:"Got it"
    });
  }

  onClickClose() {
    this.dialogRef.close();
  }

  payoutFormGroups(): PayoutFormGroup[] {
    return this.formControlStep5Payout.controls;
  }

  payoutFormGroupFormControl(payoutFormGroup: PayoutFormGroup, controlName: 'type' | 'amount' | 'bankTransfer' | 'bpay' | 'reference') {
    return payoutFormGroup.controls[controlName] as any;
  }

  addPayout() {
    const formControlType: FormControl<'bpay' | 'bank-transfer' | null> = this.formBuilder.control(null, [Validators.required]);
    const formControlAmount: FormControl<number | null> = this.formBuilder.control(null, [Validators.required]);
    const formControlReference: FormControl<string | null> = this.formBuilder.control(null, [Validators.required, Validators.maxLength(25)]);
    const formControlBankTransfer: FormControl<LocalBankAccountDetailValue | null> = this.formBuilder.control(null);
    const formControlBpay: FormControl<BpayComponentValue | null> = this.formBuilder.control(null);

    const formGroup = this.formBuilder.group({
      type: formControlType,
      amount: formControlAmount,
      reference: formControlReference,
      bankTransfer: formControlBankTransfer,
      bpay: formControlBpay
    });

    this.subscriptions.push(
      formControlType.valueChanges.subscribe((value: 'bpay' | 'bank-transfer' | null) => {
        if (value === 'bank-transfer') {
          formControlBankTransfer.setValidators([Validators.required]);
          formControlBpay.setValidators(null);
          formControlBpay.setValue(null);
          formControlBankTransfer.updateValueAndValidity();
          formControlBpay.updateValueAndValidity();
        } else if (value === 'bpay') {
          formControlBankTransfer.setValidators(null);
          formControlBpay.setValidators([Validators.required]);
          formControlBankTransfer.setValue(null);
          formControlBankTransfer.updateValueAndValidity();
          formControlBpay.updateValueAndValidity();
        } else {
          formControlBankTransfer.setValidators(null);
          formControlBpay.setValidators(null);
          formControlBankTransfer.setValue(null);
          formControlBpay.setValue(null);
          formControlBankTransfer.updateValueAndValidity();
          formControlBpay.updateValueAndValidity();
        }
      })
    )

    this.formControlStep5Payout.push(formGroup);
  }

  removePayout(payoutFormGroup: PayoutFormGroup) {
    const index = this.formControlStep5Payout.controls.indexOf(payoutFormGroup);
    if (index !== -1) {
      this.formControlStep5Payout.removeAt(index);
    }
  }

  clearPayout() {
    while (this.formControlStep5Payout.length !== 0) {
      this.formControlStep5Payout.removeAt(0)
    }
  }

  createOverdraftAccount(stepper: CdkStepper) {
    console.log(this.formGroupStep2.value);
    console.log(this.formControlStep1Opportunity.value)
    const opportunityId = this.formControlStep1Opportunity.value?.SalesforceId ?? '';

    if (this.formGroupStep2.valid && this.customer) {
      if (this.formControlStep5PayoutExistence.value) {
        if (!this.formControlStep5Payout.valid) {
          this.applicationDialogService.openAlertDialog({
            message: `Warning`,
            subMessage: `You cannot create an overdraft account because you have missing payout information.`,
          });
          return;
        }
      }
      const payout: Payout[] = this.formControlStep5Payout.value as Payout[];
      console.log('payout: ', payout)

      const add: NotNullable<Address2ComponentValue> = {
          address: this.customer?.Address?.RawAddress ?? '',
          StreetNumber: this.customer?.Address?.StreetNumber ?? '',
          StreetName: this.customer?.Address?.StreetName ?? '',
          StreetType: this.customer?.Address?.StreetType ?? '',
          Suburb: this.customer?.Address?.Suburb ?? '',
          State: this.customer?.Address?.State ?? '',
          UnitNumber: this.customer?.Address?.UnitNumber ?? undefined,
          Postcode: this.customer?.Address?.Postcode ?? '',
        };
      this.subscriptions.push(
        this.createOverdraftAccountFn(
          this.customer,
          // need to add in address as part of the custom field
          {
            ...this.formGroupStep2.value,
            ...this.formGroupStep3.value,
            ...this.formGroupStep4.value,
            address: encodePismoAddress(add, this.customer?.EntityName ?? "", this.customer?.EntityName ?? "")
          },
          opportunityId,
          payout
        ).pipe(
          this.toastService.spinnerObservable(),
        ).subscribe((response: PayloadApiResponse<PismoCreateAccountResponse>) => {
          console.log('==response: ', response);
          this.feePostingStatusStep2 = response.status;
          stepper.selectedIndex = 6;
        })
      )
    } else {
      this.applicationDialogService.openAlertDialog({
        message: `Warning`,
        subMessage: `You cannot create an overdraft account because you have missing information.`,
      });
    }
  }
}
