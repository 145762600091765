<div class="whitelabel-settings-details-page container-fluid">


  <button class="arrow-left mb-2" (click)="onClickBack()">
    <span class="mdi mdi-arrow-left"></span>
  </button>

  <ng-container>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Add Whitelabel details</mat-card-title>
        <mat-card-subtitle>
          Add new details of whitelabel for an Originator Business
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="separator">
          <!-- Originator Selection -->
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <originator-list
                [title]="'Originator'"
                [getFn]="getOriginatorsWithoutWhitelabelsFn"
                [formControl]="formControlOriginator" />
            </div>
          </div>

          <!-- Logo Url -->
          <div class="row separator">
            <div class="col-lg-6 col-md-5">
              <message-box type="info" title="Logo">
                Select a logo URL, a preview of the image should appear in
                the preview box if the logo URL is valid.
              </message-box>
            </div>
          </div>
          <div class="row separator">
            <div class="col-lg-6 col-md-12">
              <url [preview]="true" [formControl]="formControlLogoUrl" />
            </div>
          </div>

          <!-- Predefined Styles -->
          <div class="row separator">
            <div class="col-lg-6 col-md-12">
              <message-box type="info" title="Predifined Styles">
                There are some predefined styles to select from. Upon selection, click on
                Populate button will populate the color palettes with the selected predefined
                styles.
              </message-box>
            </div>
          </div>
          <div class="row separator">
            <div class="col-lg-6 col-md-12">
              <div fxLayout="row"
                   fxLayout.lt-md="column"
                   fxLayoutGap="5px">
                <mat-form-field>
                  <mat-label>Predefined Styles</mat-label>
                  <mat-select [formControl]="formControlSelectedPredefinedStyle">
                    @for (style of allPredefinedStyles; track style) {
                      <mat-option [value]="style">{{style.name}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <button mat-flat-button (click)="onPopulatePredefinedStyle()">Populate</button>
              </div>
            </div>
          </div>

          <whitelabel-styles [formControl]="formControlStyles" />

          <div class="row separator">
            <div class="col-12"
                 fxLayoutGap.lt-md="5px"
                 fxLayout.lt-md="column"
                 fxLayoutAlign.lt-md="center center"
                 fxLayout="row"
                 fxLayoutAlign="space-between center">

              <div
                class="w-lt-md-100"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-md="center center">
                <button
                  class="w-lt-md-100"
                  mat-stroked-button
                  color="primary"
                  (click)="onApply()"
                  [disabled]="formGroup.invalid">
                  Apply Theme
                </button>

                <button
                  class="w-lt-md-100"
                  mat-stroked-button
                  color="primary"
                  (click)="onReset()"
                  [disabled]="formGroup.invalid">
                  Reset Theme
                </button>
              </div>

              <button
                class="w-lt-md-100"
                mat-flat-button
                color="primary"
                (click)="onSave()"
                [disabled]="formGroup.invalid">Save
              </button>
            </div>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
