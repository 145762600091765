import {Component, inject, Input, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {
  OriginatorListValue,
  UrlValue, WhitelabelStyles,
  WhitelabelStylesValue
} from "@portal-workspace/grow-shared-library";
import {
  MessageBoxComponent,
  OriginatorListComponent,
  OriginatorListValueGetFn, PortalHotToastService, setupUntilDestroy, UrlComponent
} from "@portal-workspace/grow-ui-library";
import {AsyncPipe, JsonPipe} from "@angular/common";
import {FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatCardModule} from "@angular/material/card";
import {FlexLayoutModule} from "@angular/flex-layout";
import {navigationUrlForWhitelabelSettings} from "../../service/navigation-urls";
import {ALL_STYLES, WhitelabelService} from "../../service/whitelabel.service";
import { WhitelabelStylesComponent } from "@portal-workspace/grow-ui-library"
import {MatButtonModule} from "@angular/material/button";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Subscription} from "rxjs";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  templateUrl: './add-whitelabel-settings-details.page.html',
  styleUrls: ['./add-whitelabel-settings-details.page.scss'],
  standalone: true,
  imports: [
    MessageBoxComponent,
    AsyncPipe,
    MatCardModule,
    FlexLayoutModule,
    JsonPipe,
    ReactiveFormsModule,
    FormsModule,
    OriginatorListComponent,
    UrlComponent,
    WhitelabelStylesComponent,
    MatButtonModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
  ],
})
export class AddWhitelabelSettingsDetailsPage implements OnInit {

  subscriptions: Subscription[] = [];

  allPredefinedStyles = ALL_STYLES;

  portalHotToastService = inject(PortalHotToastService);
  whitelabelService = inject(WhitelabelService);
  router = inject(Router);
  formBuilder = inject(FormBuilder);
  formControlOriginator: FormControl<OriginatorListValue> = this.formBuilder.control(null, [Validators.required]);
  formControlLogoUrl: FormControl<UrlValue> = this.formBuilder.control(null, [Validators.required]);
  formControlStyles: FormControl<WhitelabelStylesValue> = this.formBuilder.control(null, [Validators.required]);
  formControlSelectedPredefinedStyle: FormControl<{name: string, style: WhitelabelStyles} | null> = this.formBuilder.control(null);

  formGroup = this.formBuilder.group({
    originator: this.formControlOriginator,
    logoUrl: this.formControlLogoUrl,
    styles: this.formControlStyles,
  });

  getOriginatorsWithoutWhitelabelsFn!: OriginatorListValueGetFn;

  ngOnInit(): void {
    setupUntilDestroy(this);
    this.getOriginatorsWithoutWhitelabelsFn = this.whitelabelService.getOriginatorsWithoutWhitelabelFn;
  }

  async onClickBack() {
    await this.router.navigate(navigationUrlForWhitelabelSettings());
  }

  onSave() {
    // NOTE: if we are here all those values should be valid else
    //       save button should be disabled
    this.subscriptions.push(this.whitelabelService.saveWhitelabel(
      this.formControlOriginator.value!.originatorBusinessId,
      {
        logoLink: this.formControlLogoUrl.value!,
        styles: this.formControlStyles.value!,
      }
    ).pipe(
      this.portalHotToastService.snackBarObservable(`Whitelabel saved`),
    ).subscribe());
  }

  onApply() {
    const styles = this.formControlStyles.value!;
    const logoLink = this.formControlLogoUrl.value!;
    this.whitelabelService.triggerApplyWhitelabelEvent({
      logoLink,
      styles,
    });
  }

  onReset() {
    this.whitelabelService.clearStyles();
  }

  onPopulatePredefinedStyle() {
    const selectedPredefinedStyle = this.formControlSelectedPredefinedStyle.value;
    if (selectedPredefinedStyle) {
      this.formControlStyles.setValue(selectedPredefinedStyle.style);
      // this.whitelabelService.updateStyles(selectedPredefinedStyle.style);
    }
  }
}
