import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {navigationUrlForApplications, navigationUrlForLogin} from './navigation-urls';
import {
  ApplicationDialogService,
  browserInfo,
  getUser,
  isHideErrorInErrorHandler,
  PortalHotToastService
} from '@portal-workspace/grow-ui-library';
import {clearStorage} from '@portal-workspace/grow-ui-library';
import {AppInsightService} from './app-insight.service';
import {serializeError} from 'serialize-error';
import {AuthMiddlewarePayload, HTTP_X_GROW_AUTH_HEADER, stringifyJSON} from '@portal-workspace/grow-shared-library';
import moment from 'moment';
import {v4} from 'uuid';
import { CreateHotToastRef } from '@ngneat/hot-toast';
import { OfflineDetectionService } from './offline-detection.service';


@Injectable()
export class GlobalErrorHandler extends ErrorHandler{


  offlineHotToastRef: CreateHotToastRef<any> | null = null;

  constructor(private router: Router,
              private injector: Injector) {
    super();
  }

  async handleError(error: any) {
    console.error(`*** BROWSER ERROR ***`, error);
    if (error && error.toString().toLowerCase() == 'TypeError: Cannot read properties of undefined (reading \'forEach\')'.toLowerCase()) {
      // bug #249: remove after bugfix is merged into release and google-maps-autocomplete with bugfix released
      // https://github.com/angular-material-extensions/google-maps-autocomplete/issues/249
      return;
    }
    // NOTE: see api2 errorHandlerMiddleware for details of isClientError
    if (error?.error?.isClientError) { // NOTE: this is ClientError defined in types.domain.ts
      if (error.error?.popupDialog == true) {
        this.getApplicationDialogService().openAlertDialog({
          message: 'Alert',
          subMessage: error.error.message,
        }).afterClosed().subscribe(() => {
          if (error.error?.redirectType == 'Applications') {
            const url = navigationUrlForApplications({reload: true});
            this.router.navigate(url);
          }
        });
      } else {
        this.getToastService().error(error.error.message);
      }
    } else if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {  // Unauthenticated
        await this.navigateToLogin();
      } else if (error.status === 403) { // Unauthorized
        this.unauthorized(error);
      }
      else if (error.status === 0) {
        console.log("offline")
        this.offlineHotToastRef = this.getToastService().uniqueOfflineInfo();
      }
      else {
        this.logAppInsightAndPopupMessage(error);
      }
    } else if (error instanceof Error) {
      // NOTE: handle Uncaught error in promise
      const uncaughtErrorInPromise = (error as any).rejection;
      if (uncaughtErrorInPromise && (uncaughtErrorInPromise instanceof HttpErrorResponse)) {
        if (uncaughtErrorInPromise.status === 401) {  // Unauthenticated
          await this.navigateToLogin();
        } else if (uncaughtErrorInPromise.status === 403) { // Unauthorized
          this.unauthorized(uncaughtErrorInPromise);
        } else {
          this.logAppInsightAndPopupMessage(uncaughtErrorInPromise);
        }
      }
      else if (error.message.indexOf('401') != -1 && error.message.indexOf('Unauthorized') >= 0) {
        await this.navigateToLogin();
      } else {
        this.logAppInsightAndPopupMessage(error);
      }
    } else {
      this.logAppInsightAndPopupMessage(error);
    }
  }

  getToastService(): PortalHotToastService {
    return this.injector.get(PortalHotToastService);
  }

  getApplicationDialogService(): ApplicationDialogService {
    return this.injector.get(ApplicationDialogService);
  }

  getOfflineService(): OfflineDetectionService {
    return this.injector.get(OfflineDetectionService);
  }

  getAppInsightService(): AppInsightService {
    return this.injector.get(AppInsightService);
  }

  stopPopup(error: Error) {
    return isHideErrorInErrorHandler(error);
  }

  async navigateToLogin() {
    clearStorage();
    await this.router.navigate(navigationUrlForLogin());
    const toastService = this.getToastService();
    console.log("Your session has expired. Please login again")
    // toastService.error(`Your session has expired. Please login again.`);
  }

  unauthorized(error: any) {
    if (error.error && !this.stopPopup(error)) {
      const toastService = this.getToastService();
      const payload: AuthMiddlewarePayload = error.error.payload;
      if (Array.isArray(payload?.messages) && payload.messages && payload.messages.length) {
        if (payload.messages.length > 1) {
          const errors = (payload.messages ?? []).reduce((acc, msg, index) => {
            acc += `<li>${msg}</li>`;
            return acc;
          }, ``);
          toastService.warn(`<ul>${errors}</ul>`, `Unauthorized`);
        } else {
          toastService.warn(payload?.messages?.[0] ?? 'Insufficient access',  `Unauthorized`);
        }
      } else if (error.error.message) {
        toastService.warn(error.error.message,  `Unauthorized`);
      }
    }
  }



  logAppInsightAndPopupMessage(error: any) {
    const eventUid = v4().split('-')[3];
    const user = getUser();
    const currentDate = moment().format('DD-MM-YYYY hh:mm:a');
    const location = window?.location?.href;
    const se = serializeError(error);
    const stringifyError = stringifyJSON(se);
    const browser = browserInfo();

    if (!this.stopPopup(error)) {
      const toastService = this.getToastService();
      toastService.uniqueInfo(eventUid);
    }

    // this.getAppInsightService().logException(error, 3);
    this.getAppInsightService().logEvent(`Browser Error - ${eventUid}`, {
      'eventType': 'BrowserError',
      'eventUid': eventUid,
      'effectiveUserId': user?.UserId ?? 'unknown',
      'email': user?.Email ?? 'unknown',
      'date': currentDate,
      'browserUrl': location,
      'error': se,
      'statusCode': error.status,
      // 'stringifyError': stringifyError,
      'browserName': browser.browserName,
      'browserFullVersion': browser.fullVersion,
      'browserMajorVersion': browser.majorVersion,
      'browserUserAgent': browser.userAgent,
      'browserAppName': browser.appName,
      'request-method': error?.request?.method ?? 'none',
      'request-body': error?.request?.body ?? 'none',
      'request-url': error?.request?.url ?? 'none',
    });
  }
}
