<div class="rate-card-list">
  <mat-form-field>
    <mat-label>{{title}}</mat-label>
    <mat-select [formControl]="formControl">
      @for (value of values; track value) {
        <mat-option [value]="value">
          {{value.Name}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
