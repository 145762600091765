<div class="corporate-loan-page container-fluid">
  <div class="mat-h2">New Corporate Loan Application</div>
  <application-stepper2 [linear]="true" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep1_b" ><ng-content *ngTemplateOutlet="step1_b" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnwardsOrganisationType === 'trust') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_1" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'sole-trader') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_2" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'partnership') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_3" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'company') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_4" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType === 'other') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_5" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep5" ><ng-content *ngTemplateOutlet="step5" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep6_b" ><ng-content *ngTemplateOutlet="step6_b" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep10" ><ng-content *ngTemplateOutlet="step10" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep11" ><ng-content *ngTemplateOutlet="step11" ></ng-content></cdk-step>
  </application-stepper2>
</div>

<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <aggregator-search
            [mandatory]="true"
            [searchFn]="step1BrokerSearchFn"
            [title]="'Introducer'"
            [formControl]="formControlStep1Broker"
            [disabled]="isExternalUser"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></aggregator-search>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <salesforce-contact-same-account-selection
            title="Introducer Contact"
            [getFunc]="step1GetSalesforceContactSameAccountFn"
            [salesforceAccountId]="this.step1OrgSalesforceId"
            [formControl]="formControlStep1BrokerContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
            [isMandatory]="true">
          </salesforce-contact-same-account-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <business-search [placeholder]="businessSearchPlaceholder" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Business" [searchFn]="step1BusinessSearchFn"></business-search>
        </div>
      </div>
      @if (formControlStep1Business.value && (formControlStep1Business.value.type === 'search-result') && ($any(formControlStep1Business.value).result.GSTAgeMonths ?? 0) < 12) {
        <div class="row mb-4">
          <div class="col-lg-6 col-md-12">
            <div class="mb-4">Fails product guide: GST less than 12 months. Does the applicant have a prior business with over 12 months GST?</div>
            <business-number-search
              [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
              [searchFn]="step4BusinessNumberSearchFn"
              [formControl]="formControlStep1PreviousBusiness"
            ></business-number-search>
          </div>
        </div>
      }
      @if (!step1NoOtherUsersInCompany) {
        <div class="brokerCorrespondent">
          <div class="row mb-4">
            <div class="col-lg-6 col-md-12 showCorrespondent">
              <mat-checkbox color="primary" [formControl]="formControlStep1ShowCorrespondent">Add additional broker correspondent</mat-checkbox>
              <span
                matTooltip="Add in additional broker correspondent"
                matTooltipPosition="after"
              class="tooltip-icon mdi mdi-help-circle-outline ml-1"></span>
            </div>
          </div>
          @if (formControlStep1ShowCorrespondent.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <user-same-company-selection
                  title="Additional Broker Correspondent"
                  [getFunc]="step1GetUserSameCompanyFn"
                  [salesforceId]="this.step1OrgSalesforceId"
                  [formControl]="formControlStep1Correspondent"
                  [isMandatory]="false">
                </user-same-company-selection>
              </div>
            </div>
          }
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 1_b:
##########################################
-->
<ng-template #step1_b>
  <div class="step1_b">
    <form [formGroup]="formGroupStep1_b">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Know your customer</div>
          <div class="mat-body">Have you met the applicant for {{organisationName}}?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <customer-applicant
            [formControl]="formControlStep1_bCustomerApplicantType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1_b)">
          </customer-applicant>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-12">
          <mat-checkbox color="primary" [formControl]="formControlStep1_bPrivacyStatementCheck">
            Do you have a signed privacy statement from the applicant?
          </mat-checkbox>
          @for (errorKey of errorKeys(formControlStep1_bPrivacyStatementCheck); track errorKey) {
            <mat-error>
              @if (formControlStep1_bPrivacyStatementCheck.touched && formControlStep1_bPrivacyStatementCheck.hasError(errorKey)) {
                {{errorMessages(formControlStep1_bPrivacyStatementCheck, errorKey)}}
              }
            </mat-error>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2" >
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Corporate loan application</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <facility-establishment
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
            [maxFacilityEstablishmentFee]="step2MaxFacilityEstablishmentFee"
            [formControl]="formControlStep2FacilityEstablishmentFeePercent" >
          </facility-establishment>
        </div>
        <div class="col-lg-3 col-md-12">
          <payment-frequency-selection
            [disableControl]="isDisable"
            [formControl]="formControlStep2PaymentFrequency"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </payment-frequency-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Property owner"
            [formControl]="formControlStep2PropertyOwner"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </yes-no>
        </div>
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Adverse on file"
            [formControl]="formControlStep2AdverseOnFile"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </yes-no>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <yes-no
            title="Equifax Score above 550"
            [formControl]="formControlStep2EquifaxScoreAboveThreshold"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </yes-no>
        </div>
        @if (!formControlStep2EquifaxScoreAboveThreshold.value) {
          <div class="col-lg-3 col-md-12">
            <yes-no
              title="Director Score 500 - 550"
              [formControl]="formControlStep2DirectorScore"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </yes-no>
          </div>
        }
      </div>
      @if (!formControlStep2PropertyOwner.value) {
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <yes-no title="Has the business taken another commercial loan in the past 3 years?"
              [formControl]="formControlStep2PreviousLoan"
              [notselected]="true"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
            </yes-no>
          </div>
        </div>
      }
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <currency-input title="Requested limit" [formControl]="formControlStep2LoanAmount" [min]="step2MinLimit" [max]="step2MaxLimit"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep2)">
          </currency-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <ltv-selection [formControl]="formControlStep2LtvSelection"></ltv-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <security-type-selection [formControl]="formControlStep2SecurityTypeSelection"></security-type-selection>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3" >
      <div class="row separator">
        <div class="col-12">
          <div class="mat-h3">Company details</div>
          <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <entity-type
            title="Organisation type"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OrganisationType">
          </entity-type>
        </div>
        <div class="col-lg-3 col-md-12">
          <abn [formControl]="formControlStep3Abn" [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </abn>
        </div>
        @if (step3OnwardsOrganisationType === 'company') {
          <div class="col-lg-3 col-md-12">
            <acn
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
              [formControl]="formControlStep3Acn" required >
            </acn>
          </div>
        }
        @if (step3OnwardsOrganisationType === 'trust') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [isMandatory]="false"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12 mt-2">
          <annual-revenue
            title="Annual revenue"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3AnnualRevenue">
          </annual-revenue>
        </div>
        <div class="col-lg-3 col-md-12 mt-2">
          <yes-no
            title="Operates in a commercial premise?"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OperatesInCommercialPremises">
          </yes-no>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <primary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryIndustry">
          </primary-industry-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <secondary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3IndustrySector">
          </secondary-industry-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            title="Primary business address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryBusinessAddress">
          </custom-address>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3BusinessLandline"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Business Landline"></mobile>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4_1> <!-- trust -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <trustee
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </trustee>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_2> <!-- sole trader -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sole-trader
            [formControl]="formControlStep4Applicant"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)">
          </sole-trader>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_3> <!-- partnership -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <partner
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </partner>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_4> <!-- company -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <director
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant">
          </director>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #step4_5> <!-- other (member) -->
  <div class="step4">
    <form [formGroup]="formGroupStep4">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <member
            [showAssetsAndLiabilities]="false"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
            [formControl]="formControlStep4Applicant"
            [businessNumberSearchFn]="step4BusinessNumberSearchFn">
          </member>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 5:
##########################################
-->
<ng-template #step5>
  <div class="step5">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <guarantor [businessNumberSearchFn]="step5BusinessNumberSearchFn"
          [formControl]="formControlStep5Guarantors"
          (events)="step5GuarantorEvent($event)"
          [showAssetsAndLiabilities]="false"
          [mandatoryGuarantors]="step5MandatoryGuarantors"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep5)">
        </guarantor>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form [formGroup]="formGroupStep6">
          <select-contact
            [predefinedContactSelection]="step6PossiblePrimaryContacts"
            [formControl]="formControlStep6PrimaryContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep6)">
          </select-contact>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<!--
#############################
#  STEP 6_b: Template
#############################
-->
<ng-template #step6_b>
  <div class="step6_b">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Background</div>
      </div>
    </div>
    <form [formGroup]="formGroupStep6_b">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Business Background</mat-label>
            <textarea matInput [formControl]="formControlStep6_bWhatDoesTheBusinessSell" placeholder="Enter a brief overview of the business...">
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Purpose of facility</mat-label>
            <textarea matInput [formControl]="formControlStep6_bWhatDoesTheBusinessSellTo" placeholder="Enter a brief overview on how the facility will be used...">
            </textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <div class="row separator-bottom">
      <div class="col">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <form [formGroup]="formGroupStep7">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Brokerflow document ID  <span class="mdi mdi-information-outline" matTooltip=""></span></mat-label>
            <input type="text" matInput [formControl]="formControlStep7BrokerflowDocumentId">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <reference [formControl]="formControlStep7References"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep7)">
          </reference>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <uploaded-files-list
          [uploadedFiles]="step8UploadedDocs"
          (events)="onStep8DeleteUploadedDoc($event)">
        </uploaded-files-list>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1">Upload Financials</div>
      </div>
    </div>
    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box type="info">
            <p>Please either upload the financial statements for the past two years or use the following link to submit your financials:
            <a href="https://link.codat.io/client/24ea6984-feef-464f-9f13-11addc29ecf2?user=fcaa880a-0de2-4f9f-b7d3-b6aa902b61ec" target="_blank">Financial Upload</a></p>
        </message-box>
      </div>
    </div>

    <div class="row separator">
      <div class="col-lg-6 col-md-12">
        <upload-file
          title="Two years financials plus year to date"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep8)"
          [maxFilesAllowed]="20"
          [formControl]="formControlStep8TwoYearsFinancialsUpload"
          [allowMultiple]="true">
        </upload-file>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Upload Driver Licence</div>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <message-box type="info" title="Before uploading">
          <ul>
            <li>Ensure both the front and back of the licence are supplied</li>
            <li>Ensure the text is clear and readable</li>
            <li>If using PDF, ensure the front of the driver licence is on the first page</li>
            <li>We don’t accept pictures of digital driver licences</li>
          </ul>
        </message-box>
      </div>
    </div>


    @for (dr of step8DriverLicenses; track dr) {
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-file
            [maxFilesAllowed]="2"
            [mandatory]="!skipFileUploadValidation()"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep8)"
            title="{{dr.name}}'s Driver Licence"
            [formControl]="dr.formControl"
            [allowMultiple]="true">
          </upload-file>
        </div>
      </div>
    }
  </div>
</ng-template>

<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-h3">Other supporting documents</div>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <uploaded-files-list   [uploadedFiles]="step9UploadedDocs" (events)="onStep9DeleteUploadedDoc($event)"></uploaded-files-list>
      </div>
    </div>

    <div class="row separator-bottom">
      <div class="col-lg-6 col-md-12">
        <upload-files-multi-tag-dialog
        #uploadFilesMultiTagDialogComponent="uploadFilesMultiTagDialogComponent"
          [docs]="formControlStep9OtherSupportingDocuments.value ?? []"
          [metadata]="step9OtherDocMetadata"
          [defaultTags]="documentTypes"
          (uploadDocs)="onStep9UploadOtherDoc($event)"
          (deleteDoc)="onStep9DeleteSupportingDoc($event)"
        ></upload-files-multi-tag-dialog>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 10:
##########################################
-->
<ng-template #step10>
  <div class="step10">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="mat-subtitle-1">Overview</div>
        <div class="mat-body mb-3">Detail the applicant's primary operation and reason for the application</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <mat-form-field>
          <mat-label>Application notes</mat-label>
          <textarea matInput rows="10" wrap="hard" maxlength="7000" [formControl]="formControlStep10ApplicationNotes"></textarea>
          @for (errorKey of errorKeys(formControlStep10ApplicationNotes); track errorKey) {
            <mat-error>
              @if (formControlStep10ApplicationNotes.touched && formControlStep10ApplicationNotes.hasError(errorKey)) {
                {{errorMessages(formControlStep10ApplicationNotes, errorKey)}}
              }
            </mat-error>
          }
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 11:
##########################################
-->
<ng-template #step11>
  <div class="step11">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <corporate-loan-summary [summary]="step11Summary"></corporate-loan-summary>
      </div>
    </div>
  </div>
</ng-template>
