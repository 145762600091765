@if (news && news.title) {
  <div class="card news-card">
    <div class="card-body">
      <div class="row news-list-item">
        <div class="col-sm-3">
          <img src="{{news.image ? news.image : 'assets/images/image_not_available.png'}}" class="img-fluid" height="120px"/>
        </div>
        <div class="col-sm-9">
          <h4 class="title-margin"><strong>{{news.title}}</strong></h4>
          @if (news && news.description) {
            <p class="mb-1 news-description" [innerHtml]="removeTags(news.description)"></p>
          }
          <a class="small-medium-text" [href]="news.url" target="_blank">Read More</a>
        </div>
      </div>
    </div>
  </div>
}
