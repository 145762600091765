<div class="select-contact-component">

  <div class="row separator-bottom">
    <div class="col-lg-6 col-md-12">
      <mat-form-field>
        <mat-select [multiple]="false" [formControl]="formControlPredefinedSelection" placeholder="Select primary contact...">
          @for (predefinedContact of predefinedContactSelection; track predefinedContact) {
            <mat-option [value]="predefinedContact">
              {{predefinedContact.title?.name}} {{predefinedContact.firstName}} {{predefinedContact.lastName}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div>
        <mat-checkbox color="primary" (change)="changeValidation($event)" [formControl]="formControlIsManual">Enter manually</mat-checkbox>
      </div>
    </div>
  </div>

  <form [formGroup]="formGroup">
    @if (showDetails()) {
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <title-selection
            [mark]="markObservable"
            [readonly]="readonly"
            [formControl]="formControlTitle">
          </title-selection>
        </div>
        <div class="col-3">
          <name title="First Name" [formControl]="formControlFirstName" [mark]="markObservable"></name>
        </div>
        <div class="col-3">
          <name title="Last Name" [formControl]="formControlLastName" [mark]="markObservable"></name>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <mobile [ngClass]="{readonly: readonly}" [mark]="markObservable" [onlyMobile]="true"
            [formControl]="formControlMobileNumber"
          ></mobile>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [ngClass]="{readonly: readonly}"
            [formControl]="formControlTelephone"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Telephone"></mobile>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <email class="width-75"   [mark]="markObservable"
          [ngClass]="{readonly: readonly}" [formControl]="formControlEmail"></email>
        </div>
      </div>
    }
  </form>

</div>
