<div class="upload-file-with-tag-dialog">
  <button class='custom-button' (click)="onUploadDoc()">
    <span class="mdi mdi-tray-arrow-up icon" matPrefix></span>
    <span class="text">{{buttonText}}</span>
  </button>
  <div class="uploaded-files">
    @for (doc of docs; track doc; let i = $index) {
      <div class="uploaded-file row">
        <span class="mdi mdi-attachment"></span>
        <div class="file-name">
          <span class="ellipsis">{{doc.name}}</span>
          <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row wrap">
            @for (tag of getFileTags(doc); track tag; let j = $index) {
              <span class="date-text">{{tag}}</span>
              @if (j !== getFileTags(doc).length - 1) {
                <span class="date-text">&#183;</span>
              }
            }
          </div>
        </div>
        <div fxLayoutAlign="end center">
          <span class="mdi mdi-close cursor-pointer" (click)="onDeleteDoc(i)"></span>
        </div>
      </div>
    }
  </div>
</div>
