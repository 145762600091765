<div class="insurance-premium-page container-fluid">
  <div class="mat-h2">New Insurance Premium Application</div>
  <application-stepper2 [linear]="false" #applicationStepper="applicationStepper2">
    <cdk-step [stepControl]="formGroupStep1" ><ng-content *ngTemplateOutlet="step1" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep1_b" ><ng-content *ngTemplateOutlet="step1_b" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep2" ><ng-content *ngTemplateOutlet="step2" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep3" ><ng-content *ngTemplateOutlet="step3" ></ng-content></cdk-step>
    @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'trust') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_1" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'sole-trader') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_2" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'partnership') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_3" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'company') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_4" ></ng-content></cdk-step>
    }
    @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'other') {
      <cdk-step [stepControl]="formGroupStep4"><ng-content *ngTemplateOutlet="step4_5" ></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep6" ><ng-content *ngTemplateOutlet="step6" ></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep7" ><ng-content *ngTemplateOutlet="step7" ></ng-content></cdk-step>
    @if (step6TotalNumberOfIndividuals >0) {
      <cdk-step [stepControl]="formGroupStep8" ><ng-content *ngTemplateOutlet="step8"></ng-content></cdk-step>
    }
    <cdk-step [stepControl]="formGroupStep9" ><ng-content *ngTemplateOutlet="step9"></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep10" ><ng-content *ngTemplateOutlet="step10"></ng-content></cdk-step>
    <cdk-step [stepControl]="formGroupStep11" ><ng-content *ngTemplateOutlet="step11"></ng-content></cdk-step>
  </application-stepper2>
</div>


<!--
##########################################
#  STEP 1:
##########################################
-->
<ng-template #step1>
  <div class="step1">
    <form [formGroup]="formGroupStep1" >
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <aggregator-search
            [mandatory]="true"
            [searchFn]="step1BrokerSearchFn"
            [title]="'Introducer'"
            [formControl]="formControlStep1Broker"
            [disabled]="isExternalUser"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"></aggregator-search>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <salesforce-contact-same-account-selection
            title="Introducer Contact"
            [getFunc]="step1GetSalesforceContactSameAccountFn"
            [salesforceAccountId]="this.step1OrgSalesforceId"
            [formControl]="formControlStep1BrokerContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
            [isMandatory]="true">
          </salesforce-contact-same-account-selection>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Applicant's business name</div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <business-search [placeholder]="businessSearchPlaceholder" [mark]="applicationStepper.markTriggerSubject(formGroupStep1)" [formControl]="formControlStep1Business" [searchFn]="step1SearchFn"></business-search>
        </div>
      </div>
      <!-- NOTE: PrevBusiness doesn't apply to Insurance Premium
      @if (formControlStep1Business.value && (formControlStep1Business.value.type === 'search-result') && ($any(formControlStep1Business.value).result?.ABNAgeMonths ?? 0) < 12) {
        <div class="row mb-4">
          <div class="col-lg-6 col-md-12">
            <div class="mb-2">The ABN age for the business is less than 12 months old. Does the business owner(s) have a previous ABN with over 12 months of history?</div>
            <business-number-search
              [mark]="applicationStepper.markTriggerSubject(formGroupStep1)"
              [searchFn]="step4BusinessNumberSearchFn"
              [formControl]="formControlStep1PreviousBusiness"
            ></business-number-search>
          </div>
        </div>
      }
      -->
      @if (!step1NoOtherUsersInCompany) {
        <div class="brokerCorrespondent">
          <div class="row mb-4">
            <div class="col-lg-6 col-md-12 showCorrespondent">
              <mat-checkbox color="primary" [formControl]="formControlStep1ShowCorrespondent">Add additional broker correspondent</mat-checkbox>
              <span
                matTooltip="Add in additional broker correspondent"
                matTooltipPosition="after"
              class="tooltip-icon mdi mdi-help-circle-outline ml-1"></span>
            </div>
          </div>
          @if (formControlStep1ShowCorrespondent.value) {
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <user-same-company-selection
                  title="Additional Broker Correspondent"
                  [getFunc]="step1GetUserSameCompanyFn"
                  [salesforceId]="this.step1OrgSalesforceId"
                  [formControl]="formControlStep1Correspondent"
                  [isMandatory]="false">
                </user-same-company-selection>
              </div>
            </div>
          }
        </div>
      }
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 1_b:
##########################################
-->
<ng-template #step1_b>
  <div class="step1_b">
    <form [formGroup]="formGroupStep1_b">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Know your customer</div>
          <div class="mat-body">Have you met the applicant for {{organisationName}}?</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <customer-applicant
            [formControl]="formControlStep1_bCustomerApplicantType"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep1_b)">
          </customer-applicant>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-12">
          <mat-checkbox color="primary" [formControl]="formControlStep1_bPrivacyStatementCheck">
            Do you have a signed privacy statement from the applicant?
          </mat-checkbox>
          @for (errorKey of errorKeys(formControlStep1_bPrivacyStatementCheck); track errorKey) {
            <mat-error>
              @if (formControlStep1_bPrivacyStatementCheck.touched && formControlStep1_bPrivacyStatementCheck.hasError(errorKey)) {
                {{errorMessages(formControlStep1_bPrivacyStatementCheck, errorKey)}}
              }
            </mat-error>
          }
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 2:
##########################################
-->
<ng-template #step2>
  <div class="step2">
    <form [formGroup]="formGroupStep2"></form>
    <div class="row">
      <div class="col-12">
        <policy
          [calcFn]="step2CalcFn"
          [mark]="applicationStepper.markTriggerSubject(formGroupStep2)"
          [formControl]="formControlStep2Policies">
        </policy>
      </div>
    </div>
  </div>
</ng-template>

<!--
##########################################
#  STEP 3:
##########################################
-->
<ng-template #step3>
  <div class="step3">
    <form [formGroup]="formGroupStep3">
      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Company details</div>
          <div class="mat-body">Please provide us with further details so we can provide you with a more accurate quote</div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12">
          <entity-type
            title="Organisation type"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OrganisationType">
          </entity-type>
        </div>
        <div class="col-lg-3 col-md-12">
          <abn [formControl]="formControlStep3Abn" [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </abn>
        </div>
        @if (step3OnwardsOrganisationType && step3OnwardsOrganisationType === 'company') {
          <div class="col-lg-3 col-md-12">
            <acn
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
              [formControl]="formControlStep3Acn" required >
            </acn>
          </div>
        }
        @if (step3OnwardsOrganisationType === 'trust') {
          <div class="col-lg-3 col-md-12">
            <acn [formControl]="formControlStep3Acn"
              [isMandatory]="false"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
            </acn>
          </div>
        }
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-12 mt-2">
          <annual-revenue title="Annual revenue" [formControl]="formControlStep3Revenue"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)">
          </annual-revenue>
        </div>
        <div class="col-lg-3 col-md-12 mt-2">
          <yes-no
            title="Operates in a commercial premise?"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3OperateInCommercialPremises">
          </yes-no>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <primary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryIndustry">
          </primary-industry-selection>
        </div>
        <div class="col-lg-6 col-md-12">
          <secondary-industry-selection
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3IndustrySector">
          </secondary-industry-selection>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12">
          <custom-address
            title="Business address"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep3)"
            [formControl]="formControlStep3PrimaryBusinessAddress">
          </custom-address>
        </div>
        <div class="col-lg-3 col-md-12">
          <mobile [formControl]="formControlStep3BusinessLandline"
            [mandatory]="false"
            [onlyMobile]="false"
          title="Business Landline"></mobile>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 4:
##########################################
-->
<ng-template #step4_1> <!-- trust -->
  <div class="step4">
    <trustee
      [showAssetsAndLiabilities]="false"
      [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
      [formControl]="formControlStep4Applicants"
      [showAddressForm]="showAddressFormFlag"
      [businessNumberSearchFn]="step4BusinessNumberSearchFn">
    </trustee>
  </div>
</ng-template>
<ng-template #step4_2> <!-- soletrader -->
  <div class="step4">
    <sole-trader
      [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
      [formControl]="formControlStep4Applicants"
      [showAddressForm]="showAddressFormFlag">
    </sole-trader>
  </div>
</ng-template>
<ng-template #step4_3> <!-- partnership -->
  <div class="step4">
    <partner
      [showAssetsAndLiabilities]="false"
      [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
      [formControl]="formControlStep4Applicants"
      [showAddressForm]="showAddressFormFlag"
      [businessNumberSearchFn]="step4BusinessNumberSearchFn">
    </partner>
  </div>
</ng-template>
<ng-template #step4_4> <!-- company -->
  <div class="step4">
    <director
      [showAssetsAndLiabilities]="false"
      [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
      [formControl]="formControlStep4Applicants"
      [showAddressForm]="showAddressFormFlag">
    </director>
  </div>
</ng-template>
<ng-template #step4_5> <!-- other -->
  <div class="step4">
    <member
      [showAssetsAndLiabilities]="false"
      [mark]="applicationStepper.markTriggerSubject(formGroupStep4)"
      [formControl]="formControlStep4Applicants"
      [businessNumberSearchFn]="step4BusinessNumberSearchFn"
      [showAddressForm]="showAddressFormFlag">
    </member>
  </div>
</ng-template>


<!--
##########################################
#  STEP 6:
##########################################
-->
<ng-template #step6>
  <div class="step6">
    <form [formGroup]="formGroupStep6">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <authorised-signatory
            [mark]="applicationStepper.markTriggerSubject(formGroupStep6)"
            [formControl]="formControlStep6AuthorisedSignatories">
          </authorised-signatory>
        </div>
      </div>

    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 7:
##########################################
-->
<ng-template #step7>
  <div class="step7">
    <form [formGroup]="formGroupStep7">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Select primary contact</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <select-contact [predefinedContactSelection]="step7PredefinedContacts"
            [formControl]="formControlStep7PrimaryContact"
            [mark]="applicationStepper.markTriggerSubject(formGroupStep7)">
          </select-contact>

        </div>
      </div>
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 8:
##########################################
-->
<ng-template #step8>
  <div class="step8">
    <form [formGroup]="formGroupStep8">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mat-h3">Upload Driver Licence</div>
        </div>
      </div>
      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <message-box type="info" title="Before uploading">
            <ul>
              <li>Ensure both the front and back of the licence are supplied</li>
              <li>Ensure the text is clear and readable</li>
              <li>If using PDF, ensure the front of the driver licence is on the first page</li>
              <li>We don’t accept pictures of digital driver licences</li>
            </ul>
          </message-box>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list [uploadedFiles]="step8UploadedDocs" (events)="onStep8DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      @for (dr of step8DriverLicencesRequired; track dr) {
        <div class="row separator-bottom">
          <div class="col-lg-6 col-md-12">
            <upload-file [maxFilesAllowed]="2" [mandatory]="!skipFileUploadValidation()"
              [mark]="applicationStepper.markTriggerSubject(formGroupStep8)"
            title="{{dr.name}}'s Driver Licence" [formControl]="dr.formControl" [allowMultiple]="true"></upload-file>
          </div>
        </div>
      }
    </form>
  </div>
</ng-template>


<!--
##########################################
#  STEP 9:
##########################################
-->
<ng-template #step9>
  <div class="step9">
    <form [formGroup]="formGroupStep9">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-12">
          <div class="mat-h3">Other supporting documents</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <uploaded-files-list   [uploadedFiles]="step9UploadedDocs" (events)="onStep9DeleteUploadedDoc($event)"></uploaded-files-list>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <upload-files-multi-tag-dialog
          #uploadFilesMultiTagDialogComponent="uploadFilesMultiTagDialogComponent"
            [docs]="formControlStep9OtherSupportingDocuments.value ?? []"
            [metadata]="step9OtherDocMetadata"
            [defaultTags]="documentTypes"
            (uploadDocs)="onStep9UploadOtherDoc($event)"
            (deleteDoc)="onStep9DeleteSupportingDoc($event)"
          ></upload-files-multi-tag-dialog>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 10:
##########################################
-->
<ng-template #step10>
  <div class="step10">
    <form [formGroup]="formGroupStep10">
      <div class="row separator-bottom">
        <div class="col-12">
          <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
          </application-header-segment>
        </div>
      </div>


      <div class="row">
        <div class="col-12">
          <div class="mat-subtitle-1">Overview</div>
        </div>
      </div>

      <div class="row separator-bottom">
        <div class="col-lg-6 col-md-12">
          <mat-form-field>
            <mat-label>Application notes </mat-label>
            <textarea matInput rows="10" wrap="hard" maxlength="7000" [formControl]="formControlStep10ApplicationNotes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!--
##########################################
#  STEP 11:
##########################################
-->
<ng-template #step11>
  <div class="step11">
    <div class="row separator-bottom">
      <div class="col-12">
        <application-header-segment [applicationId]="brokerApplicationId" [titleValue]="organisationName">
        </application-header-segment>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form [formGroup]="formGroupStep11">
          <insurance-premium-summary [summary]="step11Summary"></insurance-premium-summary>
        </form>
      </div>
    </div>

  </div>
</ng-template>

