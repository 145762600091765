import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import _ from "lodash";
import {navigationUrlForAssetProductSelector} from "../service/navigation-urls";
import {isValidAssetProductType, isValidBoolean} from "@portal-workspace/grow-ui-library";


export const assetFinanceProductTypeGuard = (): CanActivateFn => {
  return (
    snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot,
  ) => {
    const router = inject(Router);
    const productType = snapshot.queryParams.productType;
    const isSwitchToEasyDoc = snapshot.queryParams.isSwitchToEasyDoc;

    if ((_.isNil(productType)) ||
        (_.isNil(isSwitchToEasyDoc)) ||
        (!isValidAssetProductType(productType)) ||
        (!isValidBoolean(isSwitchToEasyDoc))
    ) {
      const url = navigationUrlForAssetProductSelector();
      return router.navigate(url);
    }

    return true;
  }
}

