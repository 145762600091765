import {Component, inject, Input, OnInit} from "@angular/core";
import {MatInputModule} from "@angular/material/input";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  formControlErrorKeys,
  formControlErrorMessage,
  PortalHotToastService,
  setupUntilDestroy
} from "@portal-workspace/grow-ui-library";
import {CurrencyInputComponent} from '../../currency-selection-component/currency-input.component';
import {CurrencyWithPeriodComponent} from '../../currency-selection-component/currency-with-period.component';
import {EmploymentStatusSelectionComponent} from '../../employment-status-selection-component/employment-status-selection.component';
import {LooseCurrencyPipe } from '../../../pipes/loose-currency.pipe';
import {MaritialStatusSelectionComponent} from '../../maritial-status-selection-component/maritial-status-selection.component';
import {NumberInputComponent} from '../../number-input-component/number-input.component';
import {PostcodeComponent} from '../../postcode-component/postcode.component';
import {DisableControlDirective} from '../../../directives/disable-control.directive';
import {PercentageInputComponent} from '../../percentage-input-component/percentage-input.component';
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout/flex";
import {InputMaskModule} from "@ngneat/input-mask";
import {
  Application, ConsumerFinanceDscrServiceabilityCalculationResult, ConsumerFinanceDsrServiceabliltyCalculationFn,
  CurrencyInputValue,
  CurrencyWithPeriodValue,
  EmploymentStatusSelectionValue,
  EmploymentStatusSelectionValueOptions, MaritialStatusSelectionName,
  MaritialStatusSelectionValue,
  MaritialStatusSelectionValueOptions, normaliseToMonthlyAmount,
  NumberInputValue, PercentageInputValue, PostcodeValue
} from "@portal-workspace/grow-shared-library";
import {MatCheckbox} from "@angular/material/checkbox";
import {AsyncPipe, JsonPipe, NgTemplateOutlet} from "@angular/common";
import {debounceTime, skip, Subscription} from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import {tap} from "rxjs/operators";
import {
  GetConsumerDscrCalculatorValueFn,
  UpdateConsumerDscrCalculatorValueFn
} from "@portal-workspace/grow-shared-library";
import {MatButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {loadingFor} from "@ngneat/loadoff";
import {ContentLoaderModule} from "@ngneat/content-loader";


@UntilDestroy({arrayName: 'subscriptions'})
@Component({
  selector: 'consumer-dscr-calculator',
  templateUrl: `./consumer-dscr-calculator.component.html`,
  styleUrls: ['./consumer-dscr-calculator.component.scss'],
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyInputComponent,
    MatCardModule,
    MatTableModule,
    FlexModule,
    LooseCurrencyPipe,
    InputMaskModule,
    MaritialStatusSelectionComponent,
    NumberInputComponent,
    EmploymentStatusSelectionComponent,
    CurrencyWithPeriodComponent,
    PostcodeComponent,
    MatCheckbox,
    AsyncPipe,
    JsonPipe,
    MatButton,
    MatTooltip,
    NgTemplateOutlet,
    ContentLoaderModule,
    DisableControlDirective,
    PercentageInputComponent,
  ]
})
export class ConsumerDscrCalculatorComponent implements OnInit {

  ready = false;

  subscriptions: Subscription[] = [];

  errorKeys = formControlErrorKeys;
  errorMessage = formControlErrorMessage;

  loader = loadingFor('dscrCalcuation');

  @Input({required: true}) getConsumerDscrCalculatorValueFn!: GetConsumerDscrCalculatorValueFn;
  @Input({required: true}) updateConsumerDscrCalculatorValueFn!: UpdateConsumerDscrCalculatorValueFn;
  @Input({required: true}) application!: Application;
  @Input({required: true}) consumerFinanceDscrServiceabilityCalculationFn!: ConsumerFinanceDsrServiceabliltyCalculationFn;

  r: ConsumerFinanceDscrServiceabilityCalculationResult | null = null;
  dscrTableDataSource:{name: string, value: string}[] | null = null;

  portalHotToastService = inject(PortalHotToastService);
  formBuilder = inject(FormBuilder);
  formControlMaritialStatus: FormControl<MaritialStatusSelectionValue> = this.formBuilder.control(null, [Validators.required]);
  formControlNumberOfDependents: FormControl<NumberInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlEmploymentStatus: FormControl<EmploymentStatusSelectionValue> = this.formBuilder.control(null, [Validators.required]);
  formControlRegularIncome: FormControl<CurrencyWithPeriodValue> = this.formBuilder.control(null, [Validators.required]);
  formControlHasSpouseOrPartner: FormControl<boolean | null> = this.formBuilder.control(true, [Validators.required]);
  formControlSpouseRegularIncome: FormControl<CurrencyWithPeriodValue> = this.formBuilder.control(null, [Validators.required]);
  formControlHomeLoans: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlCarLoans: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlTotalCreditCardLimits: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlElectricityAndGas: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlOtherUtilities: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlEducation: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPostcode: FormControl<PostcodeValue> = this.formBuilder.control(null, [Validators.required]);
  formControlPersonalLoan: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlGroceries: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlOtherLoan: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlInsurance: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlShareOfMonthlyLivingExpenses: FormControl<PercentageInputValue> = this.formBuilder.control(null, [Validators.required]);
  formControlMonthlyRepayment: FormControl<CurrencyInputValue> = this.formBuilder.control(null, [Validators.required]);
  // repayment, postcode, personalLoan, groceries, otherLoan, insuranceExpenses, shareOfMonthlyLivingExpenses

  formGroup: FormGroup<{
    maritialStatus: FormControl<MaritialStatusSelectionValue>,
    numberOfDependents: FormControl<NumberInputValue>,
    employmentStatus: FormControl<EmploymentStatusSelectionValue>,
    regularIncome: FormControl<CurrencyWithPeriodValue>,
    hasSpouseOrPartner: FormControl<boolean | null>,
    spouseRegularIncome: FormControl<CurrencyWithPeriodValue>,
    homeLoan: FormControl<CurrencyInputValue>,
    carLoans: FormControl<CurrencyInputValue>,
    totalCreditCardLimits: FormControl<CurrencyInputValue>,
    electricityAndGas: FormControl<CurrencyInputValue>,
    otherUtilities: FormControl<CurrencyInputValue>,
    education: FormControl<CurrencyInputValue>,
    postcode: FormControl<PostcodeValue>,
    personalLoan: FormControl<CurrencyInputValue>,
    groceries: FormControl<CurrencyInputValue>,
    otherLoan: FormControl<CurrencyInputValue>,
    insurance: FormControl<CurrencyInputValue>,
    shareOfMonthlyLivingExpenses: FormControl<PercentageInputValue>,
    monthlyRepayment: FormControl<CurrencyInputValue>,
  }> = this.formBuilder.group({
    maritialStatus: this.formControlMaritialStatus,
    numberOfDependents: this.formControlNumberOfDependents,
    employmentStatus: this.formControlEmploymentStatus,
    regularIncome: this.formControlRegularIncome,
    hasSpouseOrPartner: this.formControlHasSpouseOrPartner,
    spouseRegularIncome: this.formControlSpouseRegularIncome,
    homeLoan: this.formControlHomeLoans,
    carLoans: this.formControlCarLoans,
    totalCreditCardLimits: this.formControlTotalCreditCardLimits,
    electricityAndGas: this.formControlElectricityAndGas,
    otherUtilities: this.formControlOtherUtilities,
    education: this.formControlEducation,
    postcode: this.formControlPostcode,
    personalLoan: this.formControlPersonalLoan,
    groceries: this.formControlGroceries,
    otherLoan: this.formControlOtherLoan,
    insurance: this.formControlInsurance,
    shareOfMonthlyLivingExpenses: this.formControlShareOfMonthlyLivingExpenses,
    monthlyRepayment: this.formControlMonthlyRepayment,
  });

  ngOnInit() {
    setupUntilDestroy(this);
    this.formControlHasSpouseOrPartner.setValue(true);

    this.subscriptions.push(this.formControlHasSpouseOrPartner.valueChanges.pipe(
      tap(v => {
        if (v) {
          this.formControlSpouseRegularIncome.setValue(null); // force them to refill
        } else { // no spouse
          this.formControlSpouseRegularIncome.setValue({ amount: 0, period: 'Weekly'});
        }
      })
    ).subscribe());

    this.subscriptions.push(this.getConsumerDscrCalculatorValueFn(this.application.ApplicationId).pipe(
      tap(r => {
        if (r) {
          const maritialStatus = MaritialStatusSelectionValueOptions.find(opt => opt.type == r.maritialStatus);
          this.formControlMaritialStatus.setValue(maritialStatus ?? null);
          this.formControlNumberOfDependents.setValue(r.numberOfDependents);
          const employmentStatus = EmploymentStatusSelectionValueOptions.find(opt => opt.type == r.employmentStatus);
          this.formControlEmploymentStatus.setValue(employmentStatus ?? null);
          this.formControlRegularIncome.setValue(r.regularIncome);
          this.formControlHasSpouseOrPartner.setValue(r.hasSpouseOrPartner);
          this.formControlSpouseRegularIncome.setValue(r.spouseRegularIncome);
          this.formControlHomeLoans.setValue(r.homeLoans);
          this.formControlCarLoans.setValue(r.carLoans);
          this.formControlTotalCreditCardLimits.setValue(r.totalCrediCardLimits);
          this.formControlElectricityAndGas.setValue(r.electricityAndGas);
          this.formControlOtherUtilities.setValue(r.otherUtilities);
          this.formControlEducation.setValue(r.education);
          this.formControlMonthlyRepayment.setValue(r.monthlyPayment);
          this.formControlPostcode.setValue(r.postcode);
          this.formControlPersonalLoan.setValue(r.personalLoan);
          this.formControlGroceries.setValue(r.groceries);
          this.formControlOtherLoan.setValue(r.otherLoan);
          this.formControlInsurance.setValue(r.insurance);
          this.formControlShareOfMonthlyLivingExpenses.setValue(r.shareOfMonthlyLivingExpenses);
        }
      })
    ).subscribe());

    this.subscriptions.push(this.formGroup.valueChanges.pipe(
      debounceTime(2000),
      skip(1), // skip the first trigger caused by ngInit() populating formControls
      tap(r => {
        if (this.formGroup.valid) {
          this.subscriptions.push(this.updateConsumerDscrCalculatorValueFn(
            this.application.ApplicationId, {
              maritialStatus: this.formControlMaritialStatus.value!.type,
              numberOfDependents: this.formControlNumberOfDependents.value!,
              employmentStatus: this.formControlEmploymentStatus.value!.type,
              regularIncome: this.formControlRegularIncome.value!,
              hasSpouseOrPartner: this.formControlHasSpouseOrPartner.value!,
              spouseRegularIncome: this.formControlSpouseRegularIncome.value!,
              homeLoans: this.formControlHomeLoans.value!,
              carLoans: this.formControlCarLoans.value!,
              totalCrediCardLimits: this.formControlTotalCreditCardLimits.value!,
              electricityAndGas: this.formControlElectricityAndGas.value!,
              otherUtilities: this.formControlOtherUtilities.value!,
              education: this.formControlEducation.value!,
              personalLoan: this.formControlPersonalLoan.value!,
              groceries: this.formControlGroceries.value!,
              otherLoan: this.formControlOtherLoan.value!,
              insurance: this.formControlInsurance.value!,
              shareOfMonthlyLivingExpenses: this.formControlShareOfMonthlyLivingExpenses.value!,
            }).pipe(
            this.portalHotToastService.snackBarObservable(`Auto saved!`),
          ).subscribe());
        }
      })
    ).subscribe());
  }

  calculate(event: Event) {
    const nameMapping : any= {
      householdGrossIncome: 'Gross household Income',
      hemInconeBand: 'Hem Income Band',
      region:'Region',
      maritialStatus:'Maritial Status',
      numberOfDependents:'Number Of Dependents',
      hemMonthlyExpense:'Hem Monthly Expense',
      applicantAfterTaxMonthlyIncome : 'Tax After Applicant Monthly Income',
      spouseAfterTaxMonthlyIncome:'Tax After Partner Monthly Income',
      totalLivingExpenses:'Total Living Expenses',
      loanAndCreditCardRepayments:'Loan And Credit Card Repayments',
      insuranceAndEducation:'Insurance And Education',
      higherOfHemAndDeclaredLivingExpense:'Higher Of Hem And Declared Living Expense',
      totalMonthlyExpense:'Total Monthly Expense',
      maximumShareFromSpouse:'Maximum Share From Partner',
      applicantActualShareOfExpense	:'Applicant Actual Share Of Expense',
      monthlyRepayment:'Monthly Repayment',
      proposedMonthlyExpense:'Proposed Monthly Expense',
      surplusOrDeficit:'Surplus Or Deficit',
      dscr:'DSCR'
    };

    const regularIncome = normaliseToMonthlyAmount(this.formControlRegularIncome.value!.amount, this.formControlRegularIncome.value!.period);
    const spouseIncome = this.formControlMaritialStatus.value!.type == 'single' ? 0 : normaliseToMonthlyAmount(
      this.formControlSpouseRegularIncome.value?.amount ?? 0,
      this.formControlSpouseRegularIncome.value?.period ?? 'Monthly',
    );
    this.subscriptions.push(this.consumerFinanceDscrServiceabilityCalculationFn({
      marital: this.formControlMaritialStatus.value!.name,
      numberOfDependents: this.formControlNumberOfDependents.value!,
      // employmentStatus
      principalRegularIncome: regularIncome,
      // hasSpouseOrPartner
      spouseRegularIncome: spouseIncome,
      homeLoanExpenses: this.formControlHomeLoans.value!,
      carLoanExpenses: this.formControlCarLoans.value!,
      totalCreditCardLimitsExpenses: this.formControlTotalCreditCardLimits.value!,
      electricityExpenses: this.formControlElectricityAndGas.value!,
      otherUtilitiesExpenses: this.formControlOtherUtilities.value!,
      educationExpenses: this.formControlEducation.value!,
      monthlyRepayment: this.formControlMonthlyRepayment.value!,
      postcode: this.formControlPostcode.value!,
      personalLoansExpenses: this.formControlPersonalLoan.value!,
      groceriesExpenses: this.formControlGroceries.value!,
      otherLoansExpenses: this.formControlCarLoans.value!,
      insuranceExpenses: this.formControlInsurance.value!,
      shareOfMonthlyLivingExpenses: this.formControlShareOfMonthlyLivingExpenses.value!,
    }).pipe(
      this.loader.dscrCalcuation.track(),
      tap(r => {
        // NOTE: convert ConsumerFinanceDscrServiceabilityResult object into
        //       array of {name: string, value: string} entries
        //       each 'name' and 'value' is ConsumerFinanceDscrServiceabilityResult object's
        //       property value pair
        const entries = Object.entries(r);
        this.dscrTableDataSource =
          entries.map(([k,v])=>
          ({
            name: nameMapping[k] || k,
            value: v
          }));
      })
    ).subscribe());
  }


}
