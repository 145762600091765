import {inject} from "@angular/core";
import {WhitelabelService} from "./whitelabel.service";
import {ResolveFn } from "@angular/router";
import {map} from "rxjs/operators";
import {WhitelabelEntityDetails} from "@portal-workspace/grow-shared-library";


export const whiteLabelResolveFn: ResolveFn<WhitelabelEntityDetails> = (route, state) => {
  const whitelabelService = inject(WhitelabelService);
  const originatorBusinessId = route.params.originatorBusinessId;
  return whitelabelService.getWhitelabelByOriginatorBusinessId(originatorBusinessId).pipe(
    map(r => r.payload)
  );
}
