import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Base64File,
  constants,
  DocumentTag,
  UploadAzureFilesValue,
  UploadFileDialogData,
  UploadFileDialogResult,
  UploadFileValue, UploadFilesWithTagsDialogResult
} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { UploadFileComponent } from '../upload-file-component/upload-file.component';

@Component({
    selector: 'upload-files-with-tags.dialog.ts',
    templateUrl: './upload-files-with-tags.dialog.html',
    styleUrls: ['./upload-files-with-tags.dialog.scss'],
    standalone: true,
    imports: [UploadFileComponent, FormsModule, ReactiveFormsModule, MatCardModule, MatChipsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class UploadFilesWithTagsDialog implements OnInit {

  formGroup: FormGroup<{
    upload: FormControl<UploadAzureFilesValue>,
  }>;
  formControl: FormControl<UploadAzureFilesValue>;
  subscriptions: Subscription[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allTags: DocumentTag[] = [];
  tags: DocumentTag[] = [];
  selectableTags: DocumentTag[] = [];
  enableCustomTag: boolean = false;
  MAX_TAG_ALLOWED = 10;
  showMaxTagErrorMessage = false;

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: UploadFileDialogData,
              private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService,
              private matDialogRef: MatDialogRef<UploadFilesWithTagsDialog, UploadFilesWithTagsDialogResult>) {
    this.formControl = this.formBuilder.control(null, [Validators.required]);

    this.formGroup = this.formBuilder.group({
      upload: this.formControl,
    });
  }

  ngOnInit() {
    this.allTags = this.dialogData?.allTags ?? Object.values(constants.documentTypes) as DocumentTag[];
    this.allTags = this.allTags.sort((a, b) => a.type.localeCompare(b.type));
    this.enableCustomTag = !!this.dialogData?.enableCustomTag;

    if (this.dialogData?.tags && this.dialogData?.tags?.length) {
      for (const tagValue of this.dialogData.tags) {
        const tagObj = this.allTags.find(tag => tag.value === tagValue);
        if (tagObj) {
          this.tags.push(tagObj);
        }
      }
    }

    this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
  }

  onCancel($event: MouseEvent) {
    this.matDialogRef.close({
      valid: false,
      files: this.fileData ?? [],
      selectedTags: this.tags
    })
  }

  onSubmit($event: MouseEvent) {
    this.matDialogRef.close({
      valid: true,
      files: this.fileData ?? [],
      selectedTags: this.tags
    });
  }

  remove(tag: DocumentTag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);

      // remove max tag error message
      if (this.tags.length < this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = false;
      }
    }
    this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
  }

  addTag(tag: DocumentTag) {
    // avoid duplicated tags
    const tagObj = this.tags.find(t => t.value === tag.value);
    if (!tagObj) {
      // do not allow more than 10 tags
      if (this.tags.length >= this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = true;
        return;
      }

      this.tags.push(tag);
    }
    this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
  }

  addCustomTag() {
    this.subscriptions.push(
      this.dialogService.openCustomDocumentTagDialog({ nonStandardCondition: false }).afterClosed().pipe(
        tap(r => {
          if (r && r.readyForSubmission) {
            this.allTags.push(r.tag);
            this.selectableTags = this.allTags.filter(t => !this.tags.map(tag => tag.value).includes(t.value));
          }
        })
      ).subscribe()
    );
  }

  get fileData() {
    const tagValue = this.tags.map(tag => tag.value);
    return this.formControl?.value?.map((file) => {
      (file).tags = tagValue;
      if (this.dialogData.metadata) {
        (file).metadata = this.dialogData.metadata;
      }
      return file;
    })
  }
}
