import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DocumentTag, RequiredDocumentListDialogData, RequiredDocumentListDialogResult} from '@portal-workspace/grow-shared-library';
import {ApplicationDialogService} from '@portal-workspace/grow-ui-library';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './required-document-list.dialog.html',
    styleUrls: ['./required-document-list.dialog.scss'],
    standalone: true,
    imports: [MatCardModule, MatChipsModule, FlexModule, MatButtonModule, MatDialogModule]
})
export class RequiredDocumentListDialog implements OnInit {

  allTags: DocumentTag[] = [];
  dbTags: DocumentTag[] = [];
  requiredTags: DocumentTag[] = [];
  selectableTags: DocumentTag[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private dialogService: ApplicationDialogService,
    @Inject(MAT_DIALOG_DATA) public dialogData: RequiredDocumentListDialogData,
    private matDialogRef: MatDialogRef<RequiredDocumentListDialog, RequiredDocumentListDialogResult>
  ) {
    this.requiredTags = this.dialogData?.requiredTags?.length
      ? this.dialogData.requiredTags.filter(t => t.required)
        .sort((a, b) => a.type.localeCompare(b.type))
      : [];
    this.allTags = this.dialogData?.allTags.sort((a, b) => a.type.localeCompare(b.type));
    this.dbTags = this.dialogData?.dbTags?.length ? this.dialogData.dbTags : [];
    this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
  }

  ngOnInit(): void {
  }

  remove(tag: DocumentTag): void {
    const index = this.requiredTags.indexOf(tag);

    if (index >= 0) {
      this.requiredTags.splice(index, 1);
    }
    this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
  }

  addTag(tag: DocumentTag) {
    // avoid duplicated tags
    const tagObj = this.requiredTags.find(t => t.value === tag.value);
    const tagObjDb = this.dbTags.find(t => t.value === tag.value);
    if (!tagObj) {
      this.requiredTags.push(tag);
    }
    if (!tagObjDb) {
      this.dbTags.push(tag);
    }
    this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
  }

  onSubmit(readyForSubmission: boolean) {
    // get results
    const tags: DocumentTag[] = [...this.dbTags].map(t => {
      if (this.requiredTags.find(tt => tt.value === t.value)) {
        return {
          ...t,
          required: true
        }
      } else {
        return {
          ...t,
          required: false
        }
      }
    })

    this.matDialogRef.close(
      {
        readyForSubmission: readyForSubmission,
        tags: tags
      }
    );
  }

  addCustomTag(nonStandardCondition: boolean) {
    this.subscriptions.push(
      this.dialogService.openCustomDocumentTagDialog({ nonStandardCondition: nonStandardCondition }).afterClosed().pipe(
        tap(r => {
          if (r && r.readyForSubmission) {
            this.dbTags.push(r.tag);
            this.allTags.push(r.tag);
            this.addTag(r.tag);
            this.selectableTags = this.allTags.filter(t => !this.requiredTags.map(tag => tag.value).includes(t.value));
          }
        })
      ).subscribe()
    );
  }
}
